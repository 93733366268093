export class DeducibleMonedaCertificado {
    constructor(
        public id: number,
        public nombre: string,
        public porcentaje: number,
        public tipoBase: string,
        public dias: number,
        public valorMaximo: number,
        public valorMinimo: number,
        public valorFijo: number,
        public tipoCambio: number,
        public observaciones: string,
        public exoneracion: boolean,
        public CertificadoId: number,
        public PolizaId: number,
        public MonedaId: number,
        public EmpresaId: number,
    ){}

    nombreDeducible: string;
}