import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { RamosService } from 'src/app/ramos/ramos.service';
import { AseguradorasService } from 'src/app/aseguradoras/aseguradoras.service';
import { EmpresasService } from 'src/app/empresas/empresas.service';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { PolizasService } from 'src/app/polizas/polizas.service';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-contenedor-analytics-cobros-v2',
    templateUrl: './contenedor-analytics-cobros-v2.component.html',
    styleUrls: ['./contenedor-analytics-cobros-v2.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class ContenedorAnalyticsComisionesComponent implements OnInit {
    crumbs = [
        { nombre: 'Analytics', link: '/cobros/analytics-comisiones/' },
    ];
    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;

    // Banderas y generales
    cargando: boolean = false;
    titulo: string = 'Analytics Comisiones';
    apiURLs: APIUrls = new APIUrls();
    jsonConverters = new JSONConverters();

    constructor(
        public polizasService: PolizasService,
        public ramosService: RamosService,
        public aseguradorasService: AseguradorasService,
        public empresasService: EmpresasService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * *
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
    }
}
