import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { Reclamo } from '../models/reclamo';
import { FacturaReclamo } from '../models/facturaReclamo';
import { SeguimientoReclamo } from '../models/seguimientoReclamo';
import { DocumentoReclamo } from '../models/documentoReclamo';
import { Usuario } from '../models/usuario';
import { Moneda } from '../models/moneda';
import * as Papa from 'papaparse';
import { DocumentoEnvioReclamo } from '../models/documentoEnvioReclamo';
import * as FileSaver from 'file-saver';

@Injectable()
export class ReclamosService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) {
        //Papa Promise
        Papa.parsePromise = function(file) {
            return new Promise(function(complete, error) {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete,
                    error
                });
            });
        };
    }

    async obtenerReclamoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.reclamoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearReclamo(registro: Reclamo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarReclamo(registro: Reclamo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Eliminar
    public async eliminarReclamo(ReclamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosURL + '/id/' + ReclamoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Reclamo eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async marcarFechaReportado(ReclamoId: number, de: string, para: string, emailEnvio: string, asunto: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosURL + '/reportado';
            const res = await this.connection.postRequest(url, {
                ReclamoId: ReclamoId,
                de: de,
                para: para,
                emailEnvio: emailEnvio,
                asunto: asunto,
            });
            return { error: null, data: { mensaje: 'Reclamo actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async marcarFechaPresentadoAseguradora(ReclamoId: number, de: string, para: string, emailEnvio: string, asunto: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosURL + '/presentadoAseguradora';
            const res = await this.connection.postRequest(url, {
                ReclamoId: ReclamoId,
                de: de,
                para: para,
                emailEnvio: emailEnvio,
                asunto: asunto,
            });
            return { error: null, data: { mensaje: 'Reclamo actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    public async marcarTipoReclamo(ReclamoId: number, tipoReclamo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosURL + '/marcarTipo';
            const res = await this.connection.postRequest(url, { ReclamoId: ReclamoId, tipoReclamo: tipoReclamo });
            return { error: null, data: { mensaje: 'Reclamo actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Archivos * * * * *
    async guardarArchivoEnServidorReclamos(archivo: File){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosURL + '/uploadArchivo';
            const res = await this.connection.uploadFile(url, archivo, true);
            if(!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }

    async obtenerDeduciblePagado(CertificadoId: number, DeducibleCertificadoId: number, BeneficiarioDependienteCertificadoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosURL + '/datosDeducibles?';
            url += '&CertificadoId=' + CertificadoId;
            url += '&DeducibleCertificadoId=' + DeducibleCertificadoId;
            url += '&BeneficiarioDependienteCertificadoId=' + BeneficiarioDependienteCertificadoId;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { registro: json.registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerDeducibleInternacionalPagado(CertificadoId: number, DeducibleMonedaCertificadoId: number, BeneficiarioDependienteCertificadoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosURL + '/datosDeduciblesInternacional?';
            url += '&CertificadoId=' + CertificadoId;
            url += '&DeducibleMonedaCertificadoId=' + DeducibleMonedaCertificadoId;
            url += '&BeneficiarioDependienteCertificadoId=' + BeneficiarioDependienteCertificadoId;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { registro: json.registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }
    
    // * * * * * Facturas * * * * *
    async obtenerFacturaReclamoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.facturasReclamosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.facturaReclamoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearFacturaReclamo(registro: FacturaReclamo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.facturasReclamosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarFacturaReclamo(registro: FacturaReclamo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.facturasReclamosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Eliminar
    public async eliminarFacturaReclamo(FacturaReclamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.facturasReclamosURL + '/id/' + FacturaReclamoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Factura eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerTodosTiposReclamosRamo(RamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tiposReclamosURL + '/ramo/' + RamoId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.tipoReclamoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los tipos de reclamo.');
        }
    }

    async obtenerTodosTiposReclamos() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tiposReclamosURL + '/';
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.tipoReclamoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los tipos de reclamo.');
        }
    }

    // * * * Seguimientos * * *
    public async crearSeguimientoReclamo(registro: SeguimientoReclamo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosURL + '/seguimiento';
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Seguimiento creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async actualizarSeguimientoReclamo(registro: SeguimientoReclamo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosURL + '/seguimiento';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Seguimiento actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async eliminarSeguimientoReclamo(SeguimientoReclamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosURL + '/seguimiento/' + SeguimientoReclamoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Seguimiento eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Documentos * * * * *
    async guardarDocumentoReclamo(documento: any) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosReclamosURL;
            const res = await this.connection.postRequest(url, documento);
            return { error: null, data: { mensaje: 'Documento guardado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async editarDocumentoReclamo(documento: DocumentoReclamo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosReclamosURL;
            const res = await this.connection.putRequest(url, documento);
            return { error: null, data: { mensaje: 'Documento editado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async eliminarDocumento(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosReclamosURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Documento eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Archivos * * * * *
    async guardarArchivoEnServidorFacturasReclamos(archivo: File){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.facturasReclamosURL + '/uploadArchivo';
            const res = await this.connection.uploadFile(url, archivo, true);
            if(!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }

    // * * * * * Importación de datos reclamos * * * * *
    async lecturaImportacionReclamos(archivo, agentes: Usuario[], ejecutivos: Usuario[], monedas: Moneda[], EmpresaId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');
            
            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = [
                'no. poliza', 'no. reclamo', 'estatus', 'fecha presentado', 'fecha ocurrido',
                'informacion (1)', 'informacion (2)', 'informacion (3)', 'informacion (4)',
                'moneda', 'monto reclamado', 'monto pagado', 'fecha pagado', 'dias de proceso',
                'no. interno', 'asesor', 'fecha ultimo proceso', 'notas ultimo proceso', 'fecha cerrado',
                'perdida total o robo', 'fallecimientos', 'dependiente', 'certificado afectado'
            ];
            
            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;
            
            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;
            var resultadosFinales = [];
            resultados.forEach(element => {
                // Póliza
                var elementoFinal = new Reclamo(
                    null, null, trim(element['no. interno']), trim(element['no. reclamo']), null,
                    null, null, null, null, null, null, null, null, null, null, null,
                    null, null, true, false, null, null, null, null, trim(element['asesor']),
                    null, null, null, null, null, null, null, null, false, null, null, null, null, null,
                    null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, EmpresaId
                );
                // Números
                if(element['monto reclamado']) elementoFinal.montoReclamado = parseFloat(element['monto reclamado']);
                if(element['monto pagado']) elementoFinal.montoPagado = parseFloat(element['monto pagado']);
                if(element['monto pagado']) elementoFinal.totalFacturado = parseFloat(element['monto pagado']);

                // Información / Observaciones
                var observaciones = '';
                if(element['informacion (1)']) observaciones += element['informacion (1)'] + ' \n';
                if(element['informacion (2)']) observaciones += element['informacion (2)'] + ' \n';
                if(element['informacion (3)']) observaciones += element['informacion (3)'] + ' \n';
                if(element['informacion (4)']) observaciones += element['informacion (4)'];
                elementoFinal.observaciones = observaciones.trim();

                // Póliza
                elementoFinal.numeroPoliza = element['no. poliza'];

                // Certificado
                elementoFinal.numeroCertificado = element['certificado afectado'];

                // Dependiente
                elementoFinal.nombreDependiente = element['dependiente'];
                
                // Estado
                var indexEstatus = this.libraryService.indexOf(this.apiUrls.estadosReclamos, 'nombre', trim(element['estatus']));
                if(indexEstatus != -1) elementoFinal.estado = this.apiUrls.estadosReclamos[indexEstatus].id;
                else if(element['estatus']) elementoFinal.estado = this.libraryService.normalizarString(element['estatus']);

                // Fecha de presentado aseguradora
                var fechaPresentadoAseguradoraOriginal = trim(element['fecha presentado']);
                var fechaPresentadoAseguradora = null;
                if(fechaPresentadoAseguradoraOriginal) fechaPresentadoAseguradora = this.libraryService.convertirFecha(fechaPresentadoAseguradoraOriginal, 'MM/DD/YYYY', 'YYYY-MM-DD');
                elementoFinal.fechaPresentadoAseguradora = fechaPresentadoAseguradora;

                // Fecha de reportado
                var fechaReportadoOriginal = trim(element['fecha presentado']);
                var fechaReportado = null;
                if(fechaReportadoOriginal) fechaReportado = this.libraryService.convertirFecha(fechaReportadoOriginal, 'MM/DD/YYYY', 'YYYY-MM-DD');
                elementoFinal.fechaReportado = fechaReportado;
                elementoFinal.fechaIngreso = fechaReportado;

                // Fecha de pagado
                var fechaPagadoOriginal = trim(element['fecha pagado']);
                var fechaPagado = null;
                if(fechaPagadoOriginal) fechaPagado = this.libraryService.convertirFecha(fechaPagadoOriginal, 'MM/DD/YYYY', 'YYYY-MM-DD');
                elementoFinal.fechaPagado = fechaPagado;

                // Fecha de cerrado
                var fechaCierreOriginal = trim(element['fecha cerrado']);
                var fechaCierre = null;
                if(fechaCierreOriginal) fechaCierre = this.libraryService.convertirFecha(fechaCierreOriginal, 'MM/DD/YYYY', 'YYYY-MM-DD');
                elementoFinal.fechaCierre = fechaCierre;

                // Moneda
                if(monedas && element['moneda']) {
                    var indexMoneda = this.libraryService.indexOf(monedas, 'simbolo', element['moneda']);
                    if(indexMoneda != -1) elementoFinal.MonedaId = monedas[indexMoneda].id;
                }

                // Ejecutivo/Asesor
                if(ejecutivos && element['asesor']) {
                    var indexEjecutivo = this.libraryService.indexOf(ejecutivos, 'nombreCompleto', element['asesor']);
                    if(indexEjecutivo != -1) {
                        elementoFinal.UsuarioEncargadoId = ejecutivos[indexEjecutivo].id;
                        elementoFinal.UsuarioId = ejecutivos[indexEjecutivo].id;
                    }
                }

                // Seguimiento
                elementoFinal.seguimientos = [];
                var seguimiento = new SeguimientoReclamo(null, 1, element['notas ultimo proceso'], null, null, null, null, null, null, EmpresaId);
                // Fecha de presentado aseguradora
                var fechaSeguimientoOriginal = trim(element['fecha ultimo proceso']);
                var fechaSeguimiento = null;
                if(fechaSeguimientoOriginal) fechaSeguimiento = this.libraryService.convertirFecha(fechaSeguimientoOriginal, 'DD/MM/YYYY', 'YYYY-MM-DD');
                seguimiento.fecha = fechaSeguimiento;
                elementoFinal.seguimientos.push(seguimiento);

                resultadosFinales.push(elementoFinal);
            });

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async analizarImportacionReclamos(registros: Reclamo[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosURL + '/analisisImportacionReclamos';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var errores = res.errores;

            return { error: null, data: {
                mensaje: 'Importación analizada con éxito',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async subirImportacionReclamos(registros: Reclamo[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosURL + '/subirImportacionReclamos';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    obtenerColorEstado(estado) {
        switch(estado) {
            case 'activo': return 'blue';
            case 'en-espera': return 'black';
            case 'pagado': return 'green';
            case 'denegado': return 'red';
            case 'sin-efecto': return 'gray';
            default: return '#000000';
        }
    }

    // Documentos envío reclamo
    async obtenerDocumentoEnvioReclamoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosEnviosReclamosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.documentoEnvioReclamoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearDocumentoEnvioReclamo(registro: DocumentoEnvioReclamo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosEnviosReclamosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarDocumentoEnvioReclamo(registro: DocumentoEnvioReclamo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosEnviosReclamosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Eliminar
    public async eliminarDocumentoEnvioReclamo(DocumentoEnvioReclamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosEnviosReclamosURL + '/id/' + DocumentoEnvioReclamoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerAnalyticsReclamos(params: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosURL + '/analytics';
            url += '?' + params;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { data: json.results } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los analytics.');
        }
    }

    public async descargarExcelAnalytics(params: string) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.reclamosURL + '/analyticsExcel';
            url += '?' + params;
            
            const res = await this.connection.getDownloadRequest(url);
            
            // Descargar archivo
            var filename = `Reclamos.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Archivo descargado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async obtenerDocumentosReclamo(ReclamoId: number) {  
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosReclamosURL + '/reclamo/' + ReclamoId;
            var json = await this.connection.getRequest(url);
            let documentos = json.records.map(doc => this.jsonConverters.documentoReclamoDeJSON(doc));
            return { error: null, data: { documentos: documentos } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los documentos del reclamo.');
        }
    }
}
