import { Usuario } from './usuario';

export class CambioEstadoDireccionCliente {
    constructor(
        public id: number,
        public fecha: string,
        public estadoAnterior: string,
        public estadoSiguiente: string,
        public UsuarioId: number,
        public DireccionClienteId: number,
        public EmpresaId: number,
    ){}

    usuario: Usuario;
    fechaFormato: string;
    nombreEstadoAnterior: string;
    nombreEstadoSiguiente: string;
    nombreUsuario: string;
}