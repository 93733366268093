import { Usuario } from './usuario';
import { Ramo } from './ramo';
import { RazonPerdida } from './razonPerdida';
import { Moneda } from './moneda';
import { SeguimientoProspecto } from './seguimientoProspecto';

export class Prospecto {
    constructor(
        public id: number,
        public nombre: string,
        public apellido: string,
        public etapa: string,
        public fechaIngreso: string,
        public fechaContacto: string,
        public correo: string,
        public telefono1: string,
        public telefono2: string,
        public direccion: string,
        public empresa: string,
        public primaNetaCalculada: number,
        public esCliente: boolean,
        public clienteCreado: boolean,
        public pospuesto: boolean,
        public marca: string,
        public linea: string,
        public anioVehiculo: string,
        public valorVehiculo: string,
        public usoVehiculo: string,
        public comentarios: string,
        public tipoProspecto: string,
        public origen: string,
        public VendedorId: number,
        public EjecutivoId: number,
        public Ejecutivo2Id: number,
        public Ejecutivo3Id: number,
        public ClienteId: number,
        public RazonPerdidaId: number,
        public MonedaId: number,
        public EmpresaId: number,
    ){}

    nombreCompleto: string;
    nombreRazonPerdida: string;
    nombreEjecutivo: string;
    nombreCliente: string;
    fechaContactoDate: Date;
    fechaContactoFormato: string;
    ejecutivo: Usuario;
    vendedor: Usuario;
    cliente: Usuario;
    razonPerdida: RazonPerdida;
    metaContactar: string;
    moneda: Moneda;
    idsRamos: number[]
    ramos: Ramo[];
    nombreRamos: string;
    pais: string;
    producto: string;

    SeguimientosProspectos: SeguimientoProspecto[];
}
