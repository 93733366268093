import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {
    NavigationDropdown,
    NavigationItem,
    NavigationLink
} from '../../interfaces/navigation-item.interface';
import {
    dropdownAnimation
} from '../../animations/dropdown.animation';
import {
    ActivatedRoute,
    NavigationEnd,
    Router
} from '@angular/router';
import {
    untilDestroyed
} from 'ngx-take-until-destroy';
import {
    filter
} from 'rxjs/operators';
import {
    NavigationService
} from '../../services/navigation.service';
import icKeyboardArrowRight from '@iconify/icons-ic/twotone-keyboard-arrow-right';
import {
    AuthService
} from 'src/app/auth/auth.service';

@Component({
    selector: 'vex-sidenav-item',
    templateUrl: './sidenav-item.component.html',
    styleUrls: ['./sidenav-item.component.scss'],
    animations: [dropdownAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavItemComponent implements OnInit, OnChanges, OnDestroy {

    @Input() item: NavigationItem;
    @Input() level: number;
    isOpen: boolean;
    isActive: boolean;
    icKeyboardArrowRight = icKeyboardArrowRight;

    isLink = this.navigationService.isLink;
    isDropdown = this.navigationService.isDropdown;
    isSubheading = this.navigationService.isSubheading;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private cd: ChangeDetectorRef,
        public auth: AuthService,
        private navigationService: NavigationService
    ) {
        this.auth.totalesProspectosUpdated.subscribe(event => {
            this.cd.detectChanges();
        });
        this.auth.totalesCalidadUpdated.subscribe(event => {
            this.cd.detectChanges();
        });
        this.auth.totalesMensajeriaUpdated.subscribe(event => {
            this.cd.detectChanges();
        });
    }

    @HostBinding('class')
    get levelClass() {
        return `item-level-${this.level}`;
    }

    ngOnInit() {
        this.router.events.pipe(
            filter < NavigationEnd > (event => event instanceof NavigationEnd),
            filter(() => this.isDropdown(this.item)),
            untilDestroyed(this)
        ).subscribe(() => this.onRouteChange());

        this.navigationService.openChange$.pipe(
            filter(() => this.isDropdown(this.item)),
            untilDestroyed(this)
        ).subscribe(item => this.onOpenChange(item));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.hasOwnProperty('item') && this.isDropdown(this.item)) {
            this.onRouteChange();
        }
    }

    toggleOpen() {
        this.isOpen = !this.isOpen;
        this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
        this.cd.markForCheck();

        if(this.isOpen) {
            setTimeout(() => {
                let itemsMenu: HTMLCollection = document.getElementsByClassName('item-dropdown');
                if(itemsMenu) {
                    let itemActivo = null;
                    for (let i = 0; i < itemsMenu.length; i++) {
                        const element = itemsMenu[i];
                        if(getComputedStyle(element).opacity == '1') {
                            itemActivo = element;
                            break;
                        }
                    }
                    if(itemActivo) {
                        itemActivo.scrollIntoView({block: "end", inline: "nearest", behavior: "smooth"});
                    }
                }
            }, 500);
        }
    }

    onOpenChange(item: NavigationDropdown) {
        if (this.isChildrenOf(this.item as NavigationDropdown, item)) {
            return;
        }

        if (this.hasActiveChilds(this.item as NavigationDropdown)) {
            return;
        }

        if (this.item !== item) {
            this.isOpen = false;
            this.cd.markForCheck();
        }
    }

    onRouteChange() {
        if (this.hasActiveChilds(this.item as NavigationDropdown)) {
            this.isActive = true;
            this.isOpen = true;
            this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
            this.cd.markForCheck();
        } else {
            this.isActive = false;
            this.isOpen = false;
            this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
            this.cd.markForCheck();
        }
    }

    isChildrenOf(parent: NavigationDropdown, item: NavigationDropdown) {
        if (parent.children.indexOf(item) !== -1) {
            return true;
        }

        return parent.children
            .filter(child => this.isDropdown(child))
            .some(child => this.isChildrenOf(child as NavigationDropdown, item));
    }

    isActualRoute(item){
        let igual = true;
        let urlItem = item.route.split('/');
        let rutaAux = this.router.url.split('/');
        //Se quitará lo que viene después de ?, si es que tiene
        let rutas:string[] = [];
        for(let aux of rutaAux){
            rutas.push(aux.split('?')[0]);
        }
        if(rutas.length != urlItem.length) return false;
        //Comparar con los que hay en el item
        for(let index in urlItem){
            if(urlItem[index] != rutas[index]) return false;
    
        }
        return igual;
      }

    hasActiveChilds(parent: NavigationDropdown) {
        return parent.children.some(child => {
            if (this.isDropdown(child)) {
                return this.hasActiveChilds(child);
            }

            if (this.isLink(child) && !this.isFunction(child.route)) {
                let routerActive = this.router.isActive(child.route as string, false);
                // Validar ruta padre
                let partes = [], partesRuta = [];
                let activePadre = false;
                if(this.router.url) partes = this.router.url.split('/').filter(function(i){return i});
                if(child.route) partesRuta = child.route.split('/').filter(function(i){return i});
                if(partes.length > 0 && partesRuta.length > 0) {
                    activePadre = partes[0] == partesRuta[0];
                }

                return routerActive;
            }
        });
    }

    isFunction(prop: NavigationLink['route']) {
        return prop instanceof Function;
    }

    ngOnDestroy(): void {}

    calcularTotalProspectos(item) {
        if(item && item.badgeProspecto && this.auth.totalesProspectos && this.auth.totalesProspectos[item.badgeProspecto]) {
            return this.auth.totalesProspectos[item.badgeProspecto];
        }
        return null;
    }

    calcularTotalCalidad(item) {
        if(item && item.badgeCalidad && this.auth.totalesCalidad && this.auth.totalesCalidad[item.badgeCalidad]) {
            return this.auth.totalesCalidad[item.badgeCalidad];
        }
        return null;
    }

    calcularTotalMensajeria(item) {
        if(item && item.badgeMensajeria && this.auth.totalesMensajeria && this.auth.totalesMensajeria[item.badgeMensajeria]) {
            return this.auth.totalesMensajeria[item.badgeMensajeria];
        }
        return null;
    }
}