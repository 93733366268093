import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { APIUrls } from '../../api/apiUrls';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconRefresh from '@iconify/icons-fa-solid/sync';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconQuitar from '@iconify/icons-fa-solid/times';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { RamosService } from 'src/app/ramos/ramos.service';
import { Moneda } from 'src/app/models/moneda';
import { AseguradorasService } from 'src/app/aseguradoras/aseguradoras.service';
import { EmpresasService } from 'src/app/empresas/empresas.service';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { PolizasService } from 'src/app/polizas/polizas.service';
import { Chart } from 'angular-highcharts';
import { AreaRamo } from 'src/app/models/areaRamo';
import { Ramo } from 'src/app/models/ramo';
import { Agrupador } from 'src/app/models/agrupador';
import { Aseguradora } from 'src/app/models/aseguradora';
import { ClientesService } from 'src/app/clientes/clientes.service';
import { Usuario } from 'src/app/models/usuario';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
import { MiAnalytic } from 'src/app/models/miAnalytic';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { PlantillaSolicitud } from 'src/app/models/plantillaSolicitud';
import { SolicitudesService } from 'src/app/solicitudes/solicitudes.service';
HC_exporting(Highcharts);

const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-analytics-solicitudes',
    templateUrl: './analytics-solicitudes.component.html',
    styleUrls: ['./analytics-solicitudes.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class AnalyticsSolicitudesComponent implements OnInit {

  @Input() MiAnalyticId: number = null;

    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconRefresh = iconRefresh;
    iconDelete =iconDelete;
    iconQuitar = iconQuitar;

    // Banderas y generales
    cargando: boolean = false;
    apiURLs: APIUrls = new APIUrls();
    jsonConverters = new JSONConverters();

    // * Filtros *
    // Fechas
    rangosFechas = this.apiURLs.rangosFechas;
    rangoFecha: string = this.rangosFechas[0].id;
    fechaInicioDate: Date;
    fechaInicioParam: string;
    fechaFinDate: Date;
    fechaFinParam: string;
    fechasFiltro = [
        { id: 'fecha', nombre: 'Fecha' },
    ];
    campoFecha: string = this.fechasFiltro[0].id;
    // Catálogos
    areasRamos: AreaRamo[];
    idsAreasRamos: number[];
    ramos: Ramo[];
    idsRamos: number[];
    agrupadores: Agrupador[];
    idsAgrupadores: number[];
    aseguradoras: Aseguradora[];
    idsAseguradoras: number[];
    monedas: Moneda[];
    idsMonedas: number[];
    ejecutivos: Usuario[];
    idsUsuarios: number[];
    plantillas: PlantillaSolicitud[];
    idsPlantillas: number[];
    estados = this.apiURLs.estadosSolicitudesPolizas;
    idsEstados: string[];
    minDate = new Date(2010, 0, 1);
    // Clientes
    clientes: Usuario[];
    idsClientes: number[];
    columnasClientes = [
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'apellido', name: 'Apellido', sortable: true, cellTemplate: null },
        { prop: 'nombreAgrupador', name: 'Agrupador', sortable: true, cellTemplate: null },
        { prop: 'telefono1', name: 'Teléfono', sortable: true, cellTemplate: null },
        { prop: 'correo1', name: 'Correo electrónico', sortable: true, cellTemplate: null },
    ];
    camposClientes = [
        { nombre: 'Nombre', campo: 'general', tipo: 'texto', categorias: null },
    ];
    urlAutocompleteClientes = this.apiURLs.baseURL + this.apiURLs.informacionClientesURL + '/search';
    // Límites
    minimoEjeY: number;
    maximoEjeY: number;

    // * Resultados *
    data: any[];
    grafico: Chart;
    miAnalytic: MiAnalytic;

    // * Tipo de gráfica *
    tiposGraficas = {
        xAxis: [
            { id: 'mes', nombre: 'Mes' },
            { id: 'anio', nombre: 'Año' },
            { id: 'area-ramo', nombre: 'Área' },
            { id: 'ramo', nombre: 'Ramo' },
            { id: 'agrupador', nombre: 'Agrupador' },
            { id: 'cliente', nombre: 'Cliente' },
            { id: 'aseguradora', nombre: 'Aseguradora' },
            { id: 'estado', nombre: 'Estado' },
            { id: 'usuario', nombre: 'Usuario' },
            { id: 'plantilla', nombre: 'Plantilla de solicitud' },
        ],
        series: [
            { id: 'mes', nombre: 'Mes' },
            { id: 'anio', nombre: 'Año' },
            { id: 'area-ramo', nombre: 'Área' },
            { id: 'ramo', nombre: 'Ramo' },
            { id: 'agrupador', nombre: 'Agrupador' },
            { id: 'cliente', nombre: 'Cliente' },
            { id: 'aseguradora', nombre: 'Aseguradora' },
            { id: 'estado', nombre: 'Estado' },
            { id: 'usuario', nombre: 'Usuario' },
            { id: 'plantilla', nombre: 'Plantilla de solicitud' },
        ],
        yAxis: [
            { id: 'cantidad', nombre: 'Cantidad de solicitudes' },
            { id: 'promedio-puntuacion', nombre: 'Promedio puntuación' },
        ],
    }
    xAxisTipos = this.tiposGraficas.xAxis;
    yAxisTipos = this.tiposGraficas.yAxis;
    seriesTipos = this.tiposGraficas.series;
    xAxis = this.xAxisTipos[0].id;
    yAxis = this.yAxisTipos[0].id;
    series;

    nombreXAxis: string;
    nombreSeries: string;
    encabezadosTabla: string[];
    filasTabla: string[];
    datosTabla: any;

    constructor(
        public polizasService: PolizasService,
        public solicitudesService: SolicitudesService,
        public clientesService: ClientesService,
        public usuariosService: UsuariosService,
        public ramosService: RamosService,
        public aseguradorasService: AseguradorasService,
        public empresasService: EmpresasService,
        public analyticsService: AnalyticsService,
        public auth: AuthService,
        private libraryService: LibraryService,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * *
    // Inicializar componente
    ngOnInit() {
        this.inicializarDatos();
    }

    async inicializarDatos() {
        this.actualizarFechas();
        this.obtenerAreasRamos();
        this.obtenerRamos();
        this.obtenerMonedas();
        this.obtenerAgrupadores();
        this.obtenerAseguradoras();
        this.obtenerEjecutivos();
        this.obtenerPlantillasSolicitudes();
        if(this.MiAnalyticId) {
          await this.obtenerGrafica();
          await this.obtenerRegistro();
        }
    }

    // * * * * * Filtros * * * * *
    actualizarFechaInicio($event){
        this.fechaInicioParam = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
    }

    actualizarFechaFin($event){
        this.fechaFinParam = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
    }

    actualizarFechas(obtenerDatos = true) {
        if(this.rangoFecha != 'personalizado') {
            var fechas = this.libraryService.calcularFechas(this.rangoFecha);
            this.fechaInicioDate = fechas.startDate;
            this.fechaInicioParam = this.libraryService.convertirFecha(fechas.startDate, 'date', 'YYYY-MM-DD');
            this.fechaFinDate = fechas.finishDate;
            this.fechaFinParam = this.libraryService.convertirFecha(fechas.finishDate, 'date', 'YYYY-MM-DD');
        }
    }

    // * * * * * Catálogos * * * * *
    async obtenerMonedas() {
        this.cargando = true;
        let EmpresaId = await this.auth.idEmpresaActual();
        var res = await this.empresasService.obtenerTodasMonedasEmpresa(EmpresaId);
        if(!res.error) {
            this.monedas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerAreasRamos() {
        this.cargando = true;
        var res = await this.ramosService.obtenerTodasAreasRamos();
        if(!res.error) {
            this.areasRamos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerRamos() {
        this.cargando = true;
        var res = await this.ramosService.obtenerTodosRamos();
        if(!res.error) {
            this.ramos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerEjecutivos() {
        this.cargando = true;
        var res = await this.usuariosService.obtenerTodosEjecutivos();
        if(!res.error) {
            this.ejecutivos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerPlantillasSolicitudes() {
        this.cargando = true;
        var res = await this.solicitudesService.obtenerTodasPlantillasSolicitudes(null, 'polizas');
        if(!res.error) {
            this.plantillas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerAseguradoras() {
        this.cargando = true;
        var res = await this.aseguradorasService.obtenerTodasAseguradoras();
        if(!res.error) {
            this.aseguradoras = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerAgrupadores() {
        this.cargando = true;

        var res = await this.clientesService.obtenerTodosAgrupadores();
        if(!res.error) {
            this.agrupadores = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');

        this.cargando = false;
    }

    actualizarClientes(items: Usuario[]) {
        this.idsClientes = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            this.idsClientes.push(item.id);
        }
    }

    // * * * * * Obtener datos * * * * *
    // Obtener el registro o inicializar uno nuevo
    async obtenerRegistro() {
        if(this.xAxis) this.nombreXAxis = this.xAxisTipos.find(element => element.id == this.xAxis).nombre;
        if(this.series) this.nombreSeries = this.seriesTipos.find(element => element.id == this.series).nombre;
        this.cargando = true;
        var res = await this.polizasService.obtenerAnalyticsControlCalidad(this.obtenerParams());
        if(!res.error) {
            this.data = res.data.data;
            this.construirTabla();
            this.construirGrafica();
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async guardarGrafica() {
        this.cargando = true;

        let parametros: any = this.obtenerParamsJSON();

        let miAnalytic: MiAnalytic = new MiAnalytic(
          null,'control-calidad',
          JSON.stringify(parametros),
          true,
          this.auth.idEmpresaActual(),
          this.auth.idUsuarioActual()
        );
        var res = await this.analyticsService.crearMiAnalytic(miAnalytic);
        if(!res.error) {
          this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async descargarGrafica() {
        this.grafico.ref.exportChart({type: 'image/jpeg'},{})
    }

    async obtenerGrafica() {
        this.cargando = true;
        var res = await this.analyticsService.obtenerMiAnalyticPorId(this.MiAnalyticId);
        if(!res.error) {
           this.miAnalytic = res.data.registro;
           this.actualizarParams(this.miAnalytic.parametrosJSON)
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    actualizarParams(parametros: any) {
        if(parametros.campoFecha) this.campoFecha = parametros.campoFecha;
        if(parametros.rangoFecha) this.rangoFecha = parametros.rangoFecha;
        if(parametros.fechaInicioParam) this.fechaInicioParam = parametros.fechaInicioParam;
        if(parametros.fechaFinParam) this.fechaFinParam = parametros.fechaFinParam;
        this.actualizarFechas();

        // Filtros
        if(parametros.idsMonedas) this.idsMonedas = parametros.idsMonedas;
        if(parametros.idsAreasRamos) this.idsAreasRamos = parametros.idsAreasRamos;
        if(parametros.idsRamos) this.idsRamos = parametros.idsRamos;
        if(parametros.idsAseguradoras) this.idsAseguradoras = parametros.idsAseguradoras;
        if(parametros.idsAgrupadores) this.idsAgrupadores = parametros.idsAgrupadores;
        if(parametros.idsUsuarios) this.idsUsuarios = parametros.idsUsuarios;
        if(parametros.idsPlantillas) this.idsPlantillas = parametros.idsPlantillas;
        if(parametros.idsEstados) this.idsEstados = parametros.idsEstados;
        if(parametros.idsClientes) this.idsClientes = parametros.idsClientes;
        if(parametros.minimoEjeY != null) this.minimoEjeY = parametros.minimoEjeY;
        if(parametros.maximoEjeY != null) this.maximoEjeY = parametros.maximoEjeY;

        // Tipo de gráfica
        if(parametros.xAxis) this.xAxis = parametros.xAxis;
        if(parametros.series) this.series = parametros.series;
        if(parametros.yAxis) this.yAxis = parametros.yAxis;
    }

    // Params
    obtenerParams(): string {
        var params = '';

        // Fechas
        if(this.campoFecha) params += '&campoFecha=' + this.campoFecha;
        if(this.fechaInicioParam) params += '&start=' + this.fechaInicioParam;
        if(this.fechaFinParam) params += '&finish=' + this.fechaFinParam;

        // Filtros
        if(this.idsMonedas) params += '&idsMonedas=' + this.idsMonedas;
        if(this.idsAreasRamos) params += '&idsAreasRamos=' + this.idsAreasRamos;
        if(this.idsRamos) params += '&idsRamos=' + this.idsRamos;
        if(this.idsAseguradoras) params += '&idsAseguradoras=' + this.idsAseguradoras;
        if(this.idsAgrupadores) params += '&idsAgrupadores=' + this.idsAgrupadores;
        if(this.idsEstados) params += '&idsEstados=' + this.idsEstados;
        if(this.idsUsuarios) params += '&idsUsuarios=' + this.idsUsuarios;
        if(this.idsPlantillas) params += '&idsPlantillas=' + this.idsPlantillas;
        if(this.idsClientes) params += '&idsClientes=' + this.idsClientes;
        if(this.minimoEjeY != null) params += '&minimoEjeY=' + this.minimoEjeY;
        if(this.maximoEjeY != null) params += '&maximoEjeY=' + this.maximoEjeY;

        // Tipo de gráfica
        if(this.xAxis) params += '&xAxis=' + this.xAxis;
        if(this.series) params += '&series=' + this.series;
        if(this.yAxis) params += '&yAxis=' + this.yAxis;

        return params;
    }

    obtenerParamsJSON(): any {
        var params: any = {};

        // Fechas
        if(this.campoFecha) params['campoFecha'] = this.campoFecha;
        if(this.rangoFecha) params['rangoFecha'] = this.rangoFecha;
        if(this.fechaInicioParam) params['fechaInicioParam'] = this.fechaInicioParam;
        if(this.fechaFinParam) params['fechaFinParam'] = this.fechaFinParam;

        // Filtros
        if(this.idsMonedas) params['idsMonedas'] = this.idsMonedas;
        if(this.idsAreasRamos) params['idsAreasRamos'] = this.idsAreasRamos;
        if(this.idsRamos) params['idsRamos'] = this.idsRamos;
        if(this.idsAseguradoras) params['idsAseguradoras'] = this.idsAseguradoras;
        if(this.idsAgrupadores) params['idsAgrupadores'] = this.idsAgrupadores;
        if(this.idsEstados) params['idsEstados'] = this.idsEstados;
        if(this.idsUsuarios) params['idsUsuarios'] = this.idsUsuarios;
        if(this.idsPlantillas) params['idsPlantillas'] = this.idsPlantillas;
        if(this.idsClientes) params['idsClientes'] = this.idsClientes;
        if(this.minimoEjeY != null) params['minimoEjeY'] = this.minimoEjeY;
        if(this.maximoEjeY != null) params['maximoEjeY'] = this.maximoEjeY;

        // Tipo de gráfica
        if(this.xAxis) params['xAxis'] = this.xAxis;
        if(this.series) params['series'] = this.series;
        if(this.yAxis) params['yAxis'] = this.yAxis;

        return params;
    }

    abrirEliminar() {
      const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
          data: {
              titulo: 'Eliminar gráfica',
              mensaje: '¿Está seguro de que desea eliminar esta gráfica?',
          }
      });

      dialogRef.afterClosed().subscribe(result => {
          if(result == 'confirmar') {
              this.eliminar();
          }
      });
  }

  async eliminar(){
      this.cargando = true;

      // Enviar el registro para guardar
      var res = await this.analyticsService.eliminarMiAnalytic(this.MiAnalyticId);

      if(!res.error) {
          this.libraryService.crearNotificacion(res.data.mensaje, 'success');
          this.auth.buscadorReload.next();
          this.ngOnInit();
      }
      else {
          this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
      }
      this.cargando = false;
  }

    construirTabla() {
        let encabezadosTabla: string[] = [];
        let filasTabla: string[] = [];
        let datosTabla: any = {};
        if(this.data) {
            for (const el of this.data) {
                if(!encabezadosTabla.includes(el.serie)) encabezadosTabla.push(el.serie);
                if(!filasTabla.includes(el.periodo)) filasTabla.push(el.periodo);

                if(!datosTabla[el.periodo]) datosTabla[el.periodo] = {};
                datosTabla[el.periodo][el.serie] = el.total;
            }
        }
        encabezadosTabla.sort();
        filasTabla.sort();
        this.encabezadosTabla = encabezadosTabla;
        this.filasTabla = filasTabla;
        this.datosTabla = datosTabla;
    }

    async construirGrafica() {
        this.grafico = null;

        if(this.data){
            var categorias: string[] = [];

            // Captura de data para mes y anio
            if(this.xAxis == 'mes' || this.xAxis == 'anio'){
                // Obtener el periodo mínimo y máximo, y completar a partir de allí
                var minPeriodo = { anio: 2999, mes: 2999 }, maxPeriodo = { anio: 0, mes: 0 };
                for (let i = 0; i < this.data.length; i++) {
                    let element = this.data[i];
                    // Validar si este periodo es mínimo o máximo
                    if(element.periodo) {
                        // Dividir en año y mes
                        element.periodo = element.periodo + '';
                        var partes = element.periodo.split('/');
                        var anio = null;
                        var mes = null;
                        if(partes.length > 1) {
                            anio = parseInt(partes[1]);
                            mes = parseInt(partes[0]);
                        }
                        else anio = parseInt(partes[0]);

                        // Verificar si es el mínimo periodo encontrado
                        if(anio < minPeriodo.anio){
                            minPeriodo.anio = anio;
                            if(mes) minPeriodo.mes = mes;
                        }
                        else if(anio == minPeriodo.anio && mes) {
                            if(mes < minPeriodo.mes) minPeriodo.mes = mes;
                        }

                        // Verificar si es el máximo periodo encontrado
                        if(anio > maxPeriodo.anio){
                            maxPeriodo.anio = anio;
                            if(mes) maxPeriodo.mes = mes;
                        }
                        else if(anio == maxPeriodo.anio && mes) {
                            if(mes > maxPeriodo.mes) maxPeriodo.mes = mes;
                        }

                        // Limpiar en caso de que no haya mes
                        if(minPeriodo.mes == 2999) minPeriodo.mes = null;
                        if(maxPeriodo.mes == 0) maxPeriodo.mes = null;
                    }
                }

                // Agregar las categorías (x-Axis)

                var periodoActual = { anio: minPeriodo.anio, mes: minPeriodo.mes };
                do {
                    var nombreCategoria = '';
                    if(periodoActual.mes) nombreCategoria += periodoActual.mes;
                    if(nombreCategoria != '') nombreCategoria += '/';
                    nombreCategoria += periodoActual.anio;
                    if(periodoActual.mes) {
                        periodoActual.mes++;
                        if(periodoActual.mes > 12) {
                            periodoActual.mes = 1;
                            periodoActual.anio++;
                        }
                    }
                    else periodoActual.anio++;
                    categorias.push(nombreCategoria);
                } while(
                    (!periodoActual.mes && periodoActual.anio <= maxPeriodo.anio) ||
                    (periodoActual.mes && (
                        (periodoActual.anio < maxPeriodo.anio) ||
                        (periodoActual.anio == maxPeriodo.anio && periodoActual.mes <= maxPeriodo.mes)
                    ))
                );
            }
            // Agregar categorías que no sean de mes y anio
            else {
                for(let data of this.data){
                    if(data.periodo && !categorias.includes(data.periodo)) {
                        let nombreCategoria = this.nombreDeSegundoAgrupador(data.periodo, this.xAxis)
                        categorias.push(nombreCategoria);
                    }
                }
            }

            // Sobreescribir categorías para Todo
            if(this.xAxis == 'todo') categorias = ['Todo'];

            // Agregar los valores (y-Axis)
            var series = [];
            for (let element of this.data) {
                element.periodo = element.periodo + '';
                element.serie = element.serie + '';
                // Verificar si está dentro de las series existentes
                let nombreSerie = this.nombreDeSegundoAgrupador(element.serie, this.series);
                let serie = series.find(element => element.name == nombreSerie);
                var indexSerie = this.libraryService.indexOf(series, 'id', nombreSerie);
                if(!serie) {
                    // Nueva serie, agregarla con valores por defecto cero
                    var valores = [];
                    for (let j = 0; j < categorias.length; j++) valores.push(0);
                    serie = { id: nombreSerie, name: nombreSerie, data: valores };
                    series.push(serie);
                    indexSerie = series.length - 1;
                }

                // Agregar el total al valor correspondiente
                let nombreCategoria = this.nombreDeSegundoAgrupador(element.periodo, this.xAxis);
                var indexCategoria = categorias.indexOf(nombreCategoria);
                if(indexSerie != -1 && indexCategoria != -1) {
                    series[indexSerie].data[indexCategoria] = element.total;
                } else if(indexSerie != -1 && Array.isArray(series[indexSerie].data) && series[indexSerie].data.length > 0) {
                    series[indexSerie].data[0] = element.total;
                }
            }

            if(series.length == 1){
                series.forEach(element => {
                    if(!element.id || !element.name) {
                        element.id = 'Todo';
                        element.name = 'Todo';
                    }
                });

            } else {
                series.forEach(element => {
                    if(!element.id || !element.name) {
                        element.id = 'Sin valor';
                        element.name = 'Sin valor';
                    }
                });
            }

            // Encontrar nombre de y-Axis
            let simbolo = '';
            // if(this.moneda) simbolo = this.moneda.simbolo;
            let yAxisNombre = '';
            let tooltipFunction;
            switch(this.yAxis) {
                case 'cantidad': {
                    yAxisNombre = 'Cantidad de solicitudes';
                    tooltipFunction = function () {
                        return `<b>${this.series.name} ${this.x}</b><br/>
                            Total: ${this.point.y}`
                    };
                    break;
                }
                case 'promedio-puntuacion': {
                    yAxisNombre = 'Promedio puntuación';
                    tooltipFunction = function () {
                        return `<b>${this.series.name} ${this.x}</b><br/>
                            Total: ${simbolo} ${(+this.point.y).toLocaleString('en', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}`
                    };
                    break;
                }
            };

            series.forEach(element => {
                if(!element.id || !element.name) {
                    element.id = 'Sin valor';
                    element.name = 'Sin valor';
                }
            });

            let nombreEjeX = this.xAxisTipos.find(element => element.id == this.xAxis);
            // Construir elemento de Chart
            this.grafico = new Chart({
                chart: {
                    type: 'column'
                },
                exporting: {
                    chartOptions: { // specific options for the exported image
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    enabled: true
                                }
                            }
                        }
                    },
                    fallbackToExportServer: false,
                    enabled: true
                },
                title: {
                    text: 'Reporte de control de calidad'
                },
                xAxis: {
                    categories: categorias,
                    title: {
                        text: nombreEjeX ? nombreEjeX.nombre : '',
                    }
                },
                tooltip: {
                    formatter: tooltipFunction
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true,
                        }
                    }
                },
                series: series.reverse(),
                yAxis: {
                    min: 0,
                    title: {
                        text: yAxisNombre,
                    }
                }
            });

            await this.renderizarChart(this.grafico);
            setTimeout(() => {
                this.renderizarChart(this.grafico);
            }, 500);
        }

    }

    nombreDeSegundoAgrupador(id: any, nombreAgrupador: string) {
        if(id != undefined) {
            switch(nombreAgrupador) {
                case 'ramo': {
                    let el = this.ramos.find(element => element.id == id);
                    return el ? el.nombre : id;
                }
                case 'area-ramo': {
                    let el = this.areasRamos.find(element => element.id == id);
                    return el ? el.nombre : id;
                }
                case 'aseguradora': {
                    let el = this.aseguradoras.find(element => element.id == id);
                    return el ? el.nombre : id;
                }
                case 'agrupador': {
                    let el = this.agrupadores.find(element => element.id == id);
                    return el ? el.nombre : id;
                }
                case 'cliente': {
                    return id;
                    //let el = this.estados.find(element => element.id == id);
                    //return el ? el.nombre : id;
                }
                default: {
                    if(id == 'undefined') return '';
                    return id == null ? 'N/A' : id;
                }
            }
        }
        else return 'Todo';
    }

    // Método que renderiza de nuevo las gráficas
    async renderizarChart(chart: Chart) {
        if (chart && chart.ref$) {
            chart.ref$.subscribe(chartRef => {
                chartRef.reflow();
            });
        }
    }
}
