import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '../@vex/layout/layout.component';
import { Error404Component } from './login/error-404/error-404.component';
import { InicioComponent } from './usuarios/inicio/inicio.component';

const routes: Routes = [
	  {
        path: 'login',
        loadChildren: './login/login.module#LoginModule'
    },
	  {
        path: 'contacto',
        loadChildren: './contactos/contactos.module#ContactosModule'
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            // Dashboard
            {
                path: '',
                component: InicioComponent,
                pathMatch: 'full',
            },
            // Avisos
            {
                path: 'avisos',
                loadChildren: './avisos/avisos.module#AvisosModule',
            },
            // Usuarios
            {
                path: 'usuarios',
                loadChildren: './usuarios/usuarios.module#UsuariosModule',
            },
            // Clientes
            {
                path: 'clientes',
                loadChildren: './clientes/clientes.module#ClientesModule',
            },
            // Empresas
            {
                path: 'empresas',
                loadChildren: './empresas/empresas.module#EmpresasModule',
            },
            // Áreas
            {
                path: 'areas',
                loadChildren: './areas/areas.module#AreasModule',
            },
            // Aseguradoras
            {
                path: 'aseguradoras',
                loadChildren: './aseguradoras/aseguradoras.module#AseguradorasModule',
            },
            // Ramos
            {
                path: 'ramos',
                loadChildren: './ramos/ramos.module#RamosModule',
            },
            // Pólizas
            {
                path: 'polizas',
                loadChildren: './polizas/polizas.module#PolizasModule',
            },
            // Cobros
            {
                path: 'cobros',
                loadChildren: './cobros/cobros.module#CobrosModule',
            },
            // Prospectos
            {
                path: 'prospectos',
                loadChildren: './prospectos/prospectos.module#ProspectosModule',
            },
            // Mapas de proceso
            {
                path: 'mapas-procesos',
                loadChildren: './mapas-procesos/mapas-procesos.module#MapasProcesosModule',
            },
            // Mensajería
            {
                path: 'mensajeria',
                loadChildren: './mensajeria/mensajeria.module#MensajeriaModule',
            },
            // Reclamos
            {
                path: 'reclamos',
                loadChildren: './reclamos/reclamos.module#ReclamosModule',
            },
            // Solicitudes
            {
                path: 'solicitudes',
                loadChildren: './solicitudes/solicitudes.module#SolicitudesModule',
            },
            // Analytics
            {
                path: 'analytics',
                loadChildren: './analytics/analytics.module#AnalyticsModule',
            },
        ]
    },
    {
        path: '**',
        redirectTo: '/404'
    },
    {
        path: '404',
        component: Error404Component,
    },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		// preloadingStrategy: PreloadAllModules,
		scrollPositionRestoration: 'enabled',
		relativeLinkResolution: 'corrected',
		anchorScrolling: 'enabled'
	})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
