import { Component, Inject } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};
export interface DialogData {
    formaPago: string,
    banco: string,
    numeroCheque: string,
    observaciones: string,
    fecha: string,
    fechaDate: Date,
}

@Component({
    selector: 'dialog-aplicar-pago-credito',
    templateUrl: './dialog-aplicar-pago-credito.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogAplicarPagoCredito {
    apiURLs = new APIUrls();
    formasCobro = this.apiURLs.formasCobroCreacion;
    
    constructor(
        public dialogRef: MatDialogRef<DialogAplicarPagoCredito>,
        public libraryService: LibraryService,
        public service: AuthService,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close(this.data);
    }

    registroValido(): boolean {
        if(!this.data.formaPago) return false;
        if(!this.data.fecha) return false;
        
        return true;
    }

    actualizarFecha($event){
        if($event && $event.value) {
            this.data.fecha = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }
}