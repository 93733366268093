import { Aseguradora } from './aseguradora';

export class PorcentajeCoaseguros {
    constructor(
        public id: number,
        public aseguradoraString: string,
        public porcentaje: number,
        public AseguradoraId: number,
        public PolizaId: number,
    ){}

    aseguradora: Aseguradora;
}