import { Departamento } from "./departamento";
import { Municipio } from "./municipio";
import { Pais } from "./pais";
import { Zona } from "./zona";

export class DireccionCertificado {
    constructor(
        public id: number,
        public tipo: string,
        public complemento: string,
        public complemento2: string,
        public observaciones: string,
        public PaisId: number,
        public DepartamentoId: number,
        public MunicipioId: number,
        public ZonaId: number,
        public CertificadoId: number,
    ){}

    pais: Pais;
    departamento: Departamento;
    municipio: Municipio;
    zona: Zona;
    nombrePais: string;
    nombreDepartamento: string;
    nombreMunicipio: string;
    nombreZona: string;
    nombreTipo: string;
    numeroCertificado: string;
}