export class TarjetaCliente {
    constructor(
        public id: number,
        public numero: string,
        public vencimiento: string,
        public idsPolizas: string,
        public aplicaVisacuota: boolean,
        public cantidadVisacuota: number,
        public UsuarioId: number,
        public EmpresaId: number,
    ){}

    idsPolizasArray: any[] = [];
}