import { Certificado } from './certificado';
import { DetalleDeclaracion } from './detalleDeclaracion';

export class Declaracion {
    constructor(
        public id: number,
        public descripcion: string,
        public desde: string,
        public hasta: string,
        public montoDeclarado: number,
        public tasa: number,
        public primaNeta: number,
        public tipoCambio: number,
        public numeroEndoso: string,
        public fueDeclarado: boolean,
        public ingresoDetalle: boolean,
        public CertificadoId: number,
        public IncisoCertificadoId: number,
        public EmpresaId: number,
    ){}

    desdeFormato: string;
    desdeDate: Date;
    hastaFormato: string;
    hastaDate: Date;
    certificado: Certificado;
    sobreLimite: boolean = false;
    meta: any;
    numeroCertificado: string;
    fechaDeclaracion: string;
    detalles: DetalleDeclaracion[];
}