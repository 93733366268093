import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { DocumentoCobro } from '../models/documentoCobro';
import { SeguimientoCobro } from '../models/seguimientoCobro';
import { DocumentoCredito } from '../models/documentoCredito';
import { AplicacionDocumentoCredito } from '../models/aplicacionDocumentoCredito';
import * as FileSaver from 'file-saver';
import { Cobrador } from '../models/cobrador';
import { PlanillaCobro } from '../models/planillaCobro';
import { Usuario } from '../models/usuario';
import { Moneda } from '../models/moneda';
import * as Papa from 'papaparse';
import { Reclamo } from '../models/reclamo';
import { GastoProductoDocumentoCobro } from '../models/gastoProductoDocumentoCobro';
import { ImpuestoDocumentoCobro } from '../models/impuestoDocumentoCobro';
import { Poliza } from '../models/poliza';
import { PlantillaGestionCobro } from '../models/plantillaMapaProceso copy';
import { GestionCobro } from '../models/gestionCobro';
import { ComisionPolizaInciso } from '../models/comisionPolizaInciso';
import { PlanillaLiquidacion } from '../models/planillaLiquidacion';
import { Banco } from '../models/Banco';
import { DocumentoEnvioGestionCobro } from '../models/documentoEnvioGestionCobro';

@Injectable()
export class CobrosService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) {
        //Papa Promise
        Papa.parsePromise = function(file) {
            return new Promise(function(complete, error) {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete,
                    error
                });
            });
        };
    }

    async obtenerDocumentoCobroPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.documentoCobroDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearDocumentoCobro(registro: DocumentoCobro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Crear
    public async crearDocumentosCobros(registros: DocumentoCobro[], comisionesPoliza?: ComisionPolizaInciso[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/multiple';
            const res = await this.connection.postRequest(url, { documentos: registros, comisionesPoliza: comisionesPoliza });
            return { error: null, data: { mensaje: 'Registros creados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizaDocumentoCobro(registro: DocumentoCobro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async actualizaDocumentoCobroComision(registro: DocumentoCobro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/actualizarComision';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarDocumentoCobro(DocumentoCobroId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/id/' + DocumentoCobroId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Eliminar
    public async eliminarDocumentoCredito(DocumentoCreditoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCreditosURL + '/id/' + DocumentoCreditoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Cobradores * * *
    async obtenerCobradorPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cobradoresURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.cobradorDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearCobrador(registro: Cobrador) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cobradoresURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarCobrador(registro: Cobrador) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cobradoresURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarCobrador(CobradorId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cobradoresURL + '/id/' + CobradorId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerTodosCobradores() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.cobradoresURL + '/';
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.cobradorDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los cobradores.');
        }
    }

    async obtenerCorrelativoInterno() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/correlativoInterno';
            var json = await this.connection.getRequest(url);
            return { error: null, data: { correlativo: json.correlativo } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerSugerenciaComisionesPoliza(PolizaId) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.comisionesAseguradorasProductosURL + '/sugerenciaPoliza/' + PolizaId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.comisiones.length; i++) {
                const element = json.comisiones[i];
                registros.push(element);
            }

            let primasPorPagador = json.primasPorPagador;
            let primasPorCertificadoPagador = json.primasPorCertificadoPagador;

            return { error: null, data: { registros: registros, primasPorPagador: primasPorPagador, primasPorCertificadoPagador: primasPorCertificadoPagador } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los cobradores.');
        }
    }

    async obtenerComisionesPoliza(PolizaId) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.comisionesAseguradorasProductosURL + '/comisionesPoliza/' + PolizaId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.comisiones.length; i++) {
                const element = json.comisiones[i];
                registros.push(this.jsonConverters.comisionPolizaIncisoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los cobradores.');
        }
    }

    async obtenerTodosPlanillasFecha(fecha: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.planillasCobrosURL + '/fecha/' + fecha;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.planillaCobroDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los cobradores.');
        }
    }

    async obtenerDocumentosIds(ids: number[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/ids/' + ids.toString();
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.records.length; i++) {
                const element = json.records[i];
                registros.push(this.jsonConverters.documentoCobroDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los documentos.');
        }
    }

    async obtenerDocumentosCobradosFiltro(idsAseguradoras: number[], MonedaId: number, PlanillaCobroId: number, start: string, finish: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/search?';
            if(idsAseguradoras && idsAseguradoras.length > 0) url += '&idsAseguradoras=' + idsAseguradoras;
            url += '&etapa=cobrado';
            url += '&ocultarPlanilla=0';
            url += '&ignorarRegistros=1';
            if(MonedaId) url += '&MonedaId=' + MonedaId;
            url += '&limit=1000';
            url += '&field=fechaPago';
            url += '&start=' + start;
            url += '&finish=' + finish;
            if(!PlanillaCobroId || PlanillaCobroId < 0) url += '&PlanillaCobroId=-1';
            else url += '&PlanillaCobroId=' + PlanillaCobroId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.records.length; i++) {
                const element = json.records[i];
                registros.push(this.jsonConverters.documentoCobroDeJSON(element));
            }

            var idsDocumentos = [];
            if(json.idsDocumentos) {
                for (let i = 0; i < json.idsDocumentos.length; i++) {
                    idsDocumentos.push(json.idsDocumentos[i]);
                }
            }

            return { error: null, data: { registros: registros } };
        } catch (error) {
            console.error(error);
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los documentos.');
        }
    }

    // * * * Documentos de crédito * * *
    async obtenerDocumentoCreditoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCreditosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.documentoCreditoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearDocumentoCredito(registro: DocumentoCredito) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCreditosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async actualizarDocumentoCredito(registro: DocumentoCredito) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCreditosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Planillas * * *
    public async marcarPlanillaCongelada(id: number, congelada: boolean) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.planillasCobrosURL + '/marcarCongelada';
            const res = await this.connection.postRequest(url, {
                id: id,
                congelada: congelada,
            });
            return { error: null, data: { mensaje: 'Planilla actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async asignarAPlanilla(ids: number[], PlanillaCobroId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/asignarPlanilla';
            const res = await this.connection.postRequest(url, {
                ids: ids,
                PlanillaCobroId: PlanillaCobroId,
            });
            return { error: null, data: { mensaje: 'Requerimientos actualizados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async validarErroresSecuenciaPagos(ids: number[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/validarSecuencia';
            const res = await this.connection.postRequest(url, {
                ids: ids,
            });
            return { error: null, data: { mensaje: 'Requerimientos actualizados con éxito', errores: res.errores } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async pasarACobrados(ids: number[], UsuarioId: number, fechaPago: string, banco: string, numeroCheque: string, formaCobro: string, multiplesFormasPago: string, usaFormaPagoMultiple: boolean, PlanillaCobroId: number, aplicacionCredito: AplicacionDocumentoCredito) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/cobrados';
            const res = await this.connection.postRequest(url, {
                ids: ids, UsuarioId: UsuarioId,
                fechaPago: fechaPago, banco: banco,
                numeroCheque: numeroCheque,
                formaCobro: formaCobro,
                multiplesFormasPago: multiplesFormasPago,
                usaFormaPagoMultiple: usaFormaPagoMultiple,
                PlanillaCobroId: PlanillaCobroId,
                aplicacionCredito: aplicacionCredito,
            });
            return { error: null, data: { mensaje: 'Requerimientos actualizados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async pasarACobradosPlanillas(ids: number[], formaPago: string, numeroFactura: string, banco: string, numeroCheque: string, observaciones: string, UsuarioId: number, generarPlanilla: boolean) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/cobrados';
            var data = {
                ids: ids,
                formaPago: formaPago,
                banco: banco,
                numeroFactura: numeroFactura,
                numeroCheque: numeroCheque,
                observaciones: observaciones,
                generarPlanilla: generarPlanilla,
                UsuarioId: UsuarioId
            };
            const res = await this.connection.postRequest(url, data);
            return { error: null, data: { mensaje: 'Requerimientos actualizados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async descargarPDFPlanilla(PlanillaCobroId: number, returnBlob: boolean = false) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.planillasCobrosURL + '/pdf/' + PlanillaCobroId;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName = 'Planilla de cobro #' + PlanillaCobroId + '.pdf';
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });

            if(returnBlob) {
                return {
                    error: null, data: { blob: blob }
                };
            }
            else {
                FileSaver.saveAs(blob, fileName);
                return {
                    error: null,
                    data: { mensaje: 'Planilla descargada con éxito.' }
                };
            }
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Liquidados * * *
    public async pasarALiquidados(ids: number[], diferenciaAceptable: number, porcentajeAceptable:  number, UsuarioId: number, observacionesLiquidacion: any[] = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/liquidados';
            const res = await this.connection.postRequest(url, { ids: ids, UsuarioId: UsuarioId, observacionesLiquidacion: observacionesLiquidacion, diferenciaAceptable: diferenciaAceptable, porcentajeAceptable: porcentajeAceptable });
            return { error: null, data: { mensaje: 'Requerimientos actualizados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async pasarAPlanillaLiquidacion(ids: number[], diferenciaAceptable: number, porcentajeAceptable: number, montoAseguradora: number, UsuarioId: number, observacionesLiquidacion: any[] = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/liquidadosPlanilla';
            const res = await this.connection.postRequest(url, { ids: ids, UsuarioId: UsuarioId, observacionesLiquidacion: observacionesLiquidacion, diferenciaAceptable: diferenciaAceptable, porcentajeAceptable: porcentajeAceptable, montoAseguradora: montoAseguradora });
            return { error: null, data: { mensaje: 'Requerimientos actualizados con éxito', result: res, PlanillaLiquidacionId: res.PlanillaLiquidacionId } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async eliminarDePlanillaLiquidacion(ids: number[], UsuarioId: number, PlanillaLiquidacionId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/eliminarPlanillaLiquidacion';
            const res = await this.connection.postRequest(url, { ids: ids, UsuarioId: UsuarioId, PlanillaLiquidacionId: PlanillaLiquidacionId });
            return { error: null, data: { mensaje: 'Requerimientos actualizados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async regresarACobrados(ids: number[], UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/regresarCobrados';
            const res = await this.connection.postRequest(url, { ids: ids, UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Requerimientos actualizados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async regresarACobro(ids: number[], UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/regresarCobro';
            const res = await this.connection.postRequest(url, { ids: ids, UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Requerimientos actualizados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async anularDocumentosCobros(ids: number[], UsuarioId: number, razonAnulacion: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/anular';
            const res = await this.connection.postRequest(url, { ids: ids, razonAnulacion: razonAnulacion, UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Requerimientos actualizados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async eliminarDocumentosCobros(ids: number[], UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/eliminar';
            const res = await this.connection.postRequest(url, { ids: ids, UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Requerimientos eliminados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Acciones créditos * * *
    public async regresarANotaDevolucion(id: number, UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCreditosURL + '/regresarNotaDevolucion';
            const res = await this.connection.postRequest(url, { id: id, UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Requerimiento actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async pasarACreditoPagado(id: number, formaPago: string, fecha: string, banco: string, numeroCheque: string, observaciones: string, UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCreditosURL + '/pasarCreditoPagado';
            const res = await this.connection.postRequest(url, { id: id, formaPago: formaPago, fecha: fecha, banco: banco, numeroCheque: numeroCheque, observaciones: observaciones, UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Requerimiento actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Seguimientos * * *
    public async crearSeguimientoCobro(registro: SeguimientoCobro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/seguimiento';
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Seguimiento creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async actualizarSeguimientoCobro(registro: SeguimientoCobro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/seguimiento';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Seguimiento actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async eliminarSeguimientoCobro(SeguimientoCobroId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/seguimiento/' + SeguimientoCobroId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Seguimiento eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * Planillas de cobro * * *
    async obtenerPlanillaCobroPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.planillasCobrosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.planillaCobroDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async eliminarPlanillaCobro(PlanillaCobroId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.planillasCobrosURL + '/id/' + PlanillaCobroId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Planilla eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear planilla
    public async crearPlanillaCobro(registro: PlanillaCobro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.planillasCobrosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar planilla
    public async actualizaPlanillaCobro(registro: PlanillaCobro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.planillasCobrosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Planillas de liquidación * * *
    async obtenerPlanillaLiquidacionPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.planillasLiquidacionURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.planillaLiquidacionDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async eliminarPlanillaLiquidacion(PlanillaLiquidacionId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.planillasLiquidacionURL + '/id/' + PlanillaLiquidacionId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Planilla eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear planilla
    public async crearPlanillaLiquidascion(registro: PlanillaLiquidacion) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.planillasLiquidacionURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar planilla
    public async actualizaPlanillaLiquidascion(registro: PlanillaLiquidacion) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.planillasLiquidacionURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Créditos para cobros * * * * *
    async obtenerDocumentosCreditosDisponiblesCobros(DocumentoCobroId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCreditosURL + '/disponibles/' + DocumentoCobroId;
            var json = await this.connection.getRequest(url);
            var documentosCreditos = [];
            for (let i = 0; i < json.documentosCreditos.length; i++) {
                const element = json.documentosCreditos[i];
                var credito = this.jsonConverters.documentoCreditoDeJSON(element);
                documentosCreditos.push(credito);
            }

            return { error: null, data: { documentosCreditos: documentosCreditos } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async crearAplicacionCredito(registro: AplicacionDocumentoCredito) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/aplicacion';
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Crédito aplicado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async crearAplicacionCreditoCheque(registro: AplicacionDocumentoCredito) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCreditosURL + '/aplicacion';
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Cheque de devolución aplicado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async eliminarAplicacionCredito(AplicacionDocumentoCreditoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/aplicacion/' + AplicacionDocumentoCreditoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Crédito eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Estado de cuenta * * * * *
    public async descargarPDFEstadoCuenta(
        AgrupadorId: number, idsPagadores: number[], idsClientes: number[], idsAseguradoras: number[],
        idsRamos: number[], idsPolizas: number[], idsCertificados: number[], fechaInicio: string,
        fechaFin: string, incluirPorCobrar: boolean, incluirCobrados: boolean, incluirNotasCredito: boolean, incluirNotasCreditoPendientes: boolean,
        tipoAgrupador: string, camposAMostrar: string[], returnBlob: boolean = false
    ) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/estadoCuenta';
            var data = {
                AgrupadorId: AgrupadorId,
                idsPagadores: idsPagadores,
                idsClientes: idsClientes,
                idsAseguradoras: idsAseguradoras,
                idsRamos: idsRamos,
                idsPolizas: idsPolizas,
                idsCertificados: idsCertificados,
                fechaInicio: fechaInicio,
                fechaFin: fechaFin,
                incluirCobrados: incluirCobrados,
                incluirPorCobrar: incluirPorCobrar,
                incluirNotasCredito: incluirNotasCredito,
                incluirNotasCreditoPendientes: incluirNotasCreditoPendientes,
                tipoAgrupador: tipoAgrupador,
                camposAMostrar: camposAMostrar,
            }
            
            const res = await this.connection.postRequestDownload(url, data);
            // Descargar archivo
            var fileName = 'Estado de cuenta.pdf';
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });

            if(returnBlob) {
                return {
                    error: null, data: { blob: blob }
                };
            }
            else {
                FileSaver.saveAs(blob, fileName);
                return { error: null, data: { mensaje: 'Estado de cuenta descargado con éxito', result: res } };
            }
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async descargarExcelEstadoCuenta(
        AgrupadorId: number, idsPagadores: number[], idsClientes: number[], idsAseguradoras: number[],
        idsRamos: number[], idsPolizas: number[], idsCertificados: number[], fechaInicio: string,
        fechaFin: string, incluirPorCobrar: boolean, incluirCobrados: boolean, incluirNotasCredito: boolean, incluirNotasCreditoPendientes: boolean,
        tipoAgrupador: string, camposAMostrar: string[], returnBlob: boolean = false
    ) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/estadoCuentaExcel';
            var data = {
                AgrupadorId: AgrupadorId,
                idsPagadores: idsPagadores,
                idsClientes: idsClientes,
                idsAseguradoras: idsAseguradoras,
                idsRamos: idsRamos,
                idsPolizas: idsPolizas,
                idsCertificados: idsCertificados,
                fechaInicio: fechaInicio,
                fechaFin: fechaFin,
                incluirCobrados: incluirCobrados,
                incluirPorCobrar: incluirPorCobrar,
                incluirNotasCredito: incluirNotasCredito,
                incluirNotasCreditoPendientes: incluirNotasCreditoPendientes,
                tipoAgrupador: tipoAgrupador,
                camposAMostrar: camposAMostrar,
            }
            
            const res = await this.connection.postRequestDownload(url, data);
            
            // Descargar archivo
            var filename = `Estado de cuenta.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Estado de cuenta descargado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async descargarExcelReportePorCobrar(fechaFin: string, fechaInicio: string, fechaProyeccionAtraso: string, etapa: string) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/reportePorCobrarExcel';
            var data = {
                fechaFin: fechaFin,
                fechaInicio: fechaInicio,
                fechaProyeccionAtraso: fechaProyeccionAtraso,
                etapa: etapa,
            }
            
            const res = await this.connection.postRequestDownload(url, data);
            
            // Descargar archivo
            var filename = `Reporte documentos ${etapa == 'cobro' ? 'por cobrar' : 'cobrados'}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Archivo descargado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async descargarExcelReporteComisionesProyectadas(fechaFin: string, fechaInicio: string) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/reporteComisionesProyectadas';
            var data = {
                fechaFin: fechaFin,
                fechaInicio: fechaInicio,
            }
            
            const res = await this.connection.postRequestDownload(url, data);
            
            // Descargar archivo
            var filename = `Reporte comisiones proyectadas.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Archivo descargado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async descargarExcelReporteComisiones(
        idsAseguradoras: number[], idsAgentes: number[], MonedaId: number,
        fechaInicio: string, fechaFin: string, etapa: string,
        returnBlob: boolean = false
    ) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.comisionesAseguradorasProductosURL + '/reporteComisionesExcel';
            var data = {
                idsAseguradoras: idsAseguradoras,
                idsAgentes: idsAgentes,
                MonedaId: MonedaId,
                fechaInicio: fechaInicio,
                fechaFin: fechaFin,
                etapa: etapa,
            }
            
            const res = await this.connection.postRequestDownload(url, data);
            
            // Descargar archivo
            var filename = `Reporte de comisiones.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Reporte de comisiones descargado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Importación de datos cobros * * * * *
    async lecturaImportacionCobros(archivo, clientes: Usuario[], EmpresaId: number, UsuarioId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');
            
            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = [
                'no. poliza',
                'no. documento',
                'fecha original',
                'no. endoso',
                'prima neta',
                'gastos de emision',
                'iva',
                'otros gastos',
                'fraccionamiento',
                'prima total',
                '# cuota',
                'no. factura',
                'id de cliente',
            ];
            
            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;
            
            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;
            var resultadosFinales = [];
            let fechaActual = this.libraryService.convertirFecha(new Date(), 'date', 'YYYY-MM-DD');
            resultados.forEach(element => {
                // Póliza
                var elementoFinal = new DocumentoCobro(
                    null, trim(element['no. documento']), trim(element['no. endoso']), trim(element['no. factura']),
                    'cobro', this.apiUrls.tiposCobros[0].id, fechaActual, null, null, null, null, null, null, null, null, null, null, null,
                    null, null, null, null, null, false, false, null, null, null,
                    trim(element['no. factura']), false, null, null, false, null, null, null, false, null,
                    null, null, null, null, null, null, null, null, null, null, null, null,
                    null, null, UsuarioId, null, null, null, null, null, null, null, null, EmpresaId, null
                );
                
                
                // Números
                if(element['prima neta']) elementoFinal.primaNeta = Math.abs(parseFloat(element['prima neta']));
                if(element['# cuota']) elementoFinal.numeroPago = parseFloat(element['# cuota']);
                
                // * Gastos *
                elementoFinal.gastos = [];
                // Gastos de emisión
                if(element['gastos de emision']) {
                    var gastos = parseFloat(element['gastos de emision']);
                    gastos = Math.abs(gastos);
                    if(gastos > 0) elementoFinal.gastos.push(new GastoProductoDocumentoCobro(null, 'Gastos de emisión', null, gastos, elementoFinal.MonedaId, null, null));
                    elementoFinal.totalEmision = gastos;
                }
                // Otros gastos
                if(element['otros gastos']) {
                    var gastos = parseFloat(element['otros gastos']);
                    gastos = Math.abs(gastos);
                    if(gastos > 0) elementoFinal.gastos.push(new GastoProductoDocumentoCobro(null, 'Otros gastos', null, gastos, elementoFinal.MonedaId, null, null));
                    elementoFinal.totalGastos = gastos;
                }
                // Fraccionamiento
                if(element['fraccionamiento']) {
                    var gastos = parseFloat(element['fraccionamiento']);
                    gastos = Math.abs(gastos);
                    if(gastos > 0) elementoFinal.gastos.push(new GastoProductoDocumentoCobro(null, 'Fraccionamiento', null, gastos, elementoFinal.MonedaId, null, null));
                    elementoFinal.totalFraccionamiento = gastos;
                }
                var totalGastos = 0;
                for (const gasto of elementoFinal.gastos) totalGastos += gasto.monto;
                elementoFinal.totalGastos = totalGastos;

                // * Impuestos *
                elementoFinal.impuestos = [];
                // Gastos de emisión
                if(element['iva']) {
                    var impuestos = parseFloat(element['iva']);
                    impuestos = Math.abs(impuestos);
                    if(impuestos > 0) elementoFinal.impuestos.push(new ImpuestoDocumentoCobro(null, 'IVA', null, impuestos, elementoFinal.MonedaId, null, null));;
                }
                var totalImpuestos = 0;
                for (const impuesto of elementoFinal.impuestos) totalImpuestos += impuesto.monto;
                elementoFinal.totalImpuestos = totalImpuestos;

                // Total
                elementoFinal.primaTotal = elementoFinal.primaNeta + elementoFinal.totalGastos + elementoFinal.totalImpuestos + elementoFinal.totalFraccionamiento;

                // * Fechas *
                // Fecha de emisión
                var fechaEmisionOriginal = trim(element['fecha original']);
                var fechaEmision = null;
                if(fechaEmisionOriginal) fechaEmision = this.libraryService.convertirFecha(fechaEmisionOriginal, 'DD/MM/YYYY', 'YYYY-MM-DD');
                elementoFinal.fechaEmision = fechaEmision;

                // Póliza
                elementoFinal.numeroPoliza = element['no. poliza'];

                // Pagador
                elementoFinal.PagadorId = element['id de cliente'];

                resultadosFinales.push(elementoFinal);
            });

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async analizarImportacionCobros(registros: DocumentoCobro[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/analisisImportacionCobros';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var errores = res.errores;

            return { error: null, data: {
                mensaje: 'Importación analizada con éxito',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async subirImportacionCobros(registros: DocumentoCobro[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/subirImportacionCobros';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarExcelPlantillaCobros(nombreArchivo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/plantillaExcelPagos';

            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarExcelPlantillaLiquidados(nombreArchivo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/plantillaExcelLiquidados';

            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarExcelPlantillaCobrosGeneral(nombreArchivo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/plantillaExcel';

            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarExcelCobros(nombreArchivo: string, params: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/search' + params + '&generarExcel=1';

            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Excel descargado con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async lecturaImportacionPagosPlantilla(archivo, EmpresaId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = [
                'no. poliza', 'no. requerimiento', 'fecha vencimiento', 'fecha de pago', 'prima total', 'no. cuota', 'no. factura', 'forma de pago'
            ];

            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;

            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;
            var resultadosFinales = [];
            resultados.forEach(element => {
                // Póliza
                var elementoFinal = {
                    numeroPoliza: element['no. poliza'],
                    numeroDocumento: element['no. requerimiento'],
                    fechaEmision: element['fecha vencimiento'],
                    fechaPagado: element['fecha de pagado'],
                    primaTotal: element['prima total'],
                    numeroFactura: element['no. factura'],
                    formaCobro: element['forma de pago'],
                    // numeroPago: element['no. cuota'],
                };
                elementoFinal.fechaEmision = this.libraryService.convertirFecha(element['fecha vencimiento'], 'DD/MM/YYYY', 'YYYY-MM-DD');
                elementoFinal.fechaPagado = this.libraryService.convertirFecha(element['fecha de pagado'], 'DD/MM/YYYY', 'YYYY-MM-DD');

                if(typeof elementoFinal.primaTotal == 'string') {
                    // Reemplazar comas y símbolos de moneda
                    elementoFinal.primaTotal = elementoFinal.primaTotal.replace(/,/g, '');
                    elementoFinal.primaTotal = elementoFinal.primaTotal.replace('$', '');
                    elementoFinal.primaTotal = elementoFinal.primaTotal.replace('Q', '');
                    elementoFinal.primaTotal = parseFloat(elementoFinal.primaTotal);
                }

                resultadosFinales.push(elementoFinal);
            });

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async subirImportacionPagosPlantilla(registros: any[], EmpresaId: number, analisis: boolean, conRequerimientos: boolean) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/subirImportacionPagosPlantilla';
            var data = {
                registros: registros,
                analisis: analisis,
                EmpresaId: EmpresaId,
                conRequerimientos: conRequerimientos,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async lecturaImportacionLiquidadosPlantilla(archivo, EmpresaId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = [
                'no. poliza', 'aseguradora', 'no. requerimiento', 'prima neta', 'prima total', 'comision pagada', 'mes y año de liquidacion'
            ];

            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;

            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;
            var resultadosFinales = [];
            resultados.forEach(element => {
                // Póliza
                var elementoFinal = {
                    numeroPoliza: element['no. poliza'],
                    nombreAseguradora: element['aseguradora'],
                    numeroDocumento: element['no. requerimiento'],
                    primaNeta: element['prima neta'],
                    primaTotal: element['prima total'],
                    comisionPagada: element['comision pagada'],
                    mesComision: null,
                    anioComision: null,
                };
                let mesAnio = element['mes y año de liquidacion'];
                if(mesAnio) {
                    let mes = parseInt(mesAnio.split('/')[0]);
                    let anio = parseInt(mesAnio.split('/')[1]);
                    elementoFinal.mesComision = mes;
                    elementoFinal.anioComision = anio;
                }

                resultadosFinales.push(elementoFinal);
            });

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async subirImportacionLiquidadosPlantilla(registros: any[], EmpresaId: number, analisis: boolean, conRequerimientos: boolean) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/subirImportacionLiquidadosPlantilla';
            var data = {
                registros: registros,
                analisis: analisis,
                EmpresaId: EmpresaId,
                conRequerimientos: conRequerimientos,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Analytics * * * * *
    async obtenerAnalytics(params: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/analyticsComisiones';
            url += '?' + params;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { data: json.results } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los analytics para este módulo.');
        }
    }

    async obtenerAnalyticsCobros(params: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/analytics';
            url += '?' + params;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { data: json.results } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los analytics para este módulo.');
        }
    }

    async obtenerAnalyticsComisiones(params: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/analytics-comisiones';
            url += '?' + params;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { data: json.results } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los analytics para este módulo.');
        }
    }

    obtenerColorNombreEstadoPlanilla(registro: PlanillaCobro) {
        if(registro.congelada) return { nombre: 'Congelada', color: 'red' };
        else return { nombre: 'Abierta', color: 'green' };
    }

    obtenerColorEstado(estado) {
        switch(estado) {
            case 'cobro': return 'green';
            case 'cobrado': return 'blue';
            case 'liquidado': return 'blue';
            case 'anulado': return 'red';
            default: return 'black';
        }
    }

    async descargarExcelPlantillaDeclaraciones(nombreArchivo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/plantillaExcelCobros';

            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Plantillas de gestión de cobro * * *
    async obtenerPlantillaGestionCobroPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasGestionesCobrosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.plantillaGestionCobroDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearPlantillaGestionCobro(registro: PlantillaGestionCobro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasGestionesCobrosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarPlantillaGestionCobro(registro: PlantillaGestionCobro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasGestionesCobrosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarPlantillaGestionCobro(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasGestionesCobrosURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerTodosPlantillasGestionesCobros() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasGestionesCobrosURL + '/';
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.plantillaGestionCobroDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los cobradores.');
        }
    }

    // * * * * * Archivos * * * * *
    async guardarArchivoEnServidorPolizas(archivo: File){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.polizasURL + '/uploadArchivo';
            const res = await this.connection.uploadFile(url, archivo, true);
            if(!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }

    async obtenerGestionCobroPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.gestionesCobrosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.gestionCobroDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearGestionCobro(registro: GestionCobro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.gestionesCobrosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarGestionCobro(registro: GestionCobro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.gestionesCobrosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarGestionCobro(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.gestionesCobrosURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async enviarCorreoGestionCobro(GestionCobroId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.gestionesCobrosURL + '/enviarCorreo';
            const res = await this.connection.postRequest(url, { GestionCobroId: GestionCobroId });
            return { error: null, data: { mensaje: 'Correo enviado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async descargarExcelAnalytics(params: string) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/analyticsExcel';
            url += '?' + params;
            
            const res = await this.connection.getDownloadRequest(url);
            
            // Descargar archivo
            var filename = `Cobros.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Archivo descargado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async descargarExcelAnalyticsComisiones(params: string) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCobrosURL + '/analyticsExcelComisiones';
            url += '?' + params;
            
            const res = await this.connection.getDownloadRequest(url);
            
            // Descargar archivo
            var filename = `Comisiones.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Archivo descargado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Importación de datos planilla liquidación * * * * *
    async lecturaImportacionPlanillaLiquidacion(archivo, EmpresaId: number, pais: string) {
        try {
            // Obtener resultados del CSV
            let modoCostaRica = pais == 'CR';
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');
            
            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = [
                'aseguradora', 'poliza', 'requerimiento', 'monto comision'
            ];
            if(modoCostaRica) {
                columnasUtilizadas = [
                    'aseguradora', 'poliza', 'no. nota', 'fecha pago', 'monto comision'
                ]
            }
            
            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;
            
            // Preparar resultados según modelo
            var resultadosFinales = [], resultadosNotasCredito = [];
            resultados.forEach(element => {
                let elementoFinal: any = {};
                let esNotaCredito = false;

                if(modoCostaRica) {
                    elementoFinal = {
                        nombreAseguradora: element['aseguradora'],
                        numeroPoliza: element['poliza'],
                        numeroNota: element['no. nota'],
                        fechaPago: element['fecha pago'],
                        montoComision: parseFloat(element['monto comision']),
                    };
                    elementoFinal.fechaPago = this.libraryService.convertirFecha(element['fecha pago'], 'DD/MM/YYYY', 'YYYY-MM-DD');
                    if(elementoFinal.numeroNota) esNotaCredito = true;
                }
                else {
                    elementoFinal = {
                        nombreAseguradora: element['aseguradora'],
                        numeroPoliza: element['poliza'],
                        numeroDocumento: element['requerimiento'],
                        montoComision: parseFloat(element['monto comision']),
                    };
                }

                if(esNotaCredito) resultadosNotasCredito.push(elementoFinal);
                else resultadosFinales.push(elementoFinal);
            });

            // Agrupar resultados para Costa Rica
            if(modoCostaRica) {
                // let resultadosAgrupados = [];

                // for (let el of resultadosFinales) {
                //     let agrupado = resultadosAgrupados.find(x => x.numeroPoliza == el.numeroPoliza && x.nombreAseguradora == el.nombreAseguradora && x.fechaEmision == el.fechaEmision && el.fechaAplicacion);
                //     if(!agrupado) {
                //         resultadosAgrupados.push(el);
                //     }
                //     else {
                //         agrupado.montoPrima += el.montoPrima;
                //         agrupado.montoComision += el.montoComision;
                //     }
                // }

                // resultadosFinales = resultadosAgrupados;
            }

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                resultadosNotasCredito: resultadosNotasCredito,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async descargarExcelPlantillaPlanillaLiquidacion(nombreArchivo: string, modoCostaRica: boolean = false) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.planillasLiquidacionURL + '/plantillaExcel';
            if(modoCostaRica) url += '?&modoCostaRica=true';

            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarExcelErrores(nombreArchivo: string, errores: any[], columnas: any[] = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.planillasLiquidacionURL + '/excelErrores';

            var res = await this.connection.postRequestDownload(url, { errores: errores, columnas: columnas });

            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Archivo descargado con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarExcelPlanillaLiquidacion(nombreArchivo: string, id: number, soloDiferencias: boolean) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.planillasLiquidacionURL + '/excel/' + id + '?soloDiferencias=' + soloDiferencias;

            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Planilla de liquidación descargada con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Bancos * * * * *
    async obtenerBancos() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.bancosURL + '/';
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.bancoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los bancos.');
        }
    }

    async obtenerBancoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.bancosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.bancoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearBanco(registro: Banco) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.bancosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarBanco(registro: Banco) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.bancosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarBanco(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.bancosURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async revertirAplicacionesCreditos(ids: number[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosCreditosURL + '/revertir';
            const res = await this.connection.postRequest(url, {
                ids: ids,
            });
            return { error: null, data: { mensaje: 'Aplicaciones revertidas con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Documentos envío gestión cobro
    async obtenerDocumentoEnvioGestionCobroPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosEnviosGestionesCobrosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.documentoEnvioGestionCobroDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearDocumentoEnvioGestionCobro(registro: DocumentoEnvioGestionCobro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosEnviosGestionesCobrosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarDocumentoEnvioGestionCobro(registro: DocumentoEnvioGestionCobro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosEnviosGestionesCobrosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Eliminar
    public async eliminarDocumentoEnvioGestionCobro(DocumentoEnvioGestionCobroId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosEnviosGestionesCobrosURL + '/id/' + DocumentoEnvioGestionCobroId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async descargarExcelReporteComisionesAgentes(params: string) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.comisionesAseguradorasProductosURL + '/reporteComisionesAgentes?' + params;
            var data = {
            }
            
            const res = await this.connection.postRequestDownload(url, data);
            
            // Descargar archivo
            var filename = `Comisiones de agentes.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Archivo descargado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
}