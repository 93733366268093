import { Ramo } from './ramo';

export class Cobertura {
    constructor(
        public id: number,
        public codigo: string,
        public nombre: string,
        public codigoAnterior: string,
        public condicionEspecial: boolean,
        public activo: boolean,
        public RamoId: number,
        public EmpresaId: number,
    ){}

    ramo: Ramo;
    seleccionado: boolean = false;
    sumaAseguradaCertificado: string;
}