import { Ramo } from './ramo';
import { Cobertura } from './cobertura';
import { Deducible } from './deducible';

export class Inciso {
    constructor(
        public id: number,
        public nombre: string,
        public declarativo: boolean,
        public acumulaSuma: boolean,
        public descripcion: string,
        public activo: boolean,
        public RamoId: number,
        public EmpresaId: number,
    ){}

    seleccionado: boolean = false;
    ramo: Ramo;
    coberturas: Cobertura[];
    idsCoberturas: number[];
    deducibles: Deducible[];
    idsDeducibles: number[];
}