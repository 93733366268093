import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatDialogModule, MatButtonModule, MatInputModule, MatDatepickerModule, MatPaginatorModule, MatProgressBarModule, MatSelectModule, MatIconModule, MatChipsModule, MatAutocompleteModule, MatTabsModule, MatExpansionModule, MatCheckboxModule } from '@angular/material';
import { GenericDialog } from './library.service';
import { BusquedaTablaComponent } from './busqueda-tabla/busqueda-tabla.component';
import { MatSelectSearchComponent } from './mat-select-search/mat-select-search.component';
import { IconModule } from '@visurel/iconify-angular';
import { MatSelectAutocompleteComponent } from './mat-select-autocomplete/mat-select-autocomplete.component';
import { GenericDialogConfirmacion } from './generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { CalidadCheckComponent } from './calidad-check/calidad-check.component';
import { PageLayoutModule } from '../../@vex/components/page-layout/page-layout.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { MatAutocompletePopupComponent } from './mat-autocomplete-popup/mat-autocomplete-popup.component';
import { DialogSugerenciasComponent } from './dialog-sugerencias/dialog-sugerencias.component';
import { GenericDialogErrores } from './generic-dialog-errores/generic-dialog-errores.component';
import { GenericDialogConfirmacionComentarios } from './generic-dialog-confirmacion-comentarios/generic-dialog-confirmacion-comentarios.component';
import { DialogPreviewDocumentoComponent } from './dialog-preview-documento/dialog-preview-documento.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DialogPreviewDownloadDocumentComponent } from './dialog-preview-download-document/dialog-preview-download-document.component'
import { GenericDialogConfirmacionCampos } from './generic-dialog-campos/generic-dialog-campos.component';
import { GenericDialogConfirmacionOpciones } from './generic-dialog-confirmacion-opciones/generic-dialog-confirmacion-opciones.component';
import { GenericDialogDatepicker } from './generic-dialog-datepicker/generic-dialog-datepicker.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { GenericDialogConfirmacionNumeroPorcentaje } from './generic-dialog-confirmacion-numero-porcentaje/generic-dialog-confirmacion-numero-porcentaje.component';
import { MatSelectLocalComponent } from './mat-select-local/mat-select-local.component';
import { GenericDialogSeleccionarOpcion } from './generic-dialog-seleccionar-opcion/generic-dialog-seleccionar-opcion.component';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgxDatatableModule,
        NgxDocViewerModule,
        // Material
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        MatDatepickerModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatSelectModule,
        MatIconModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatTabsModule,
        MatExpansionModule,
        MatCheckboxModule,
        IconModule,
        PageLayoutModule,
        BreadcrumbsModule,
        SecondaryToolbarModule,
        PdfViewerModule,
        NgxCurrencyModule,
    ],
    declarations: [
        GenericDialog,
        GenericDialogConfirmacion,
        BusquedaTablaComponent,
        CalidadCheckComponent,
        MatSelectSearchComponent,
        MatSelectAutocompleteComponent,
        MatAutocompletePopupComponent,
        DialogSugerenciasComponent,
        GenericDialogErrores,
        GenericDialogConfirmacionComentarios,
        DialogPreviewDocumentoComponent,
        DialogPreviewDownloadDocumentComponent,
        GenericDialogConfirmacionCampos,
        GenericDialogConfirmacionOpciones,
        GenericDialogDatepicker,
        GenericDialogConfirmacionNumeroPorcentaje,
        GenericDialogSeleccionarOpcion,
        MatSelectLocalComponent
    ],
    entryComponents: [
        GenericDialog,
        GenericDialogConfirmacion,
        DialogSugerenciasComponent,
        GenericDialogErrores,
        GenericDialogConfirmacionComentarios,
        DialogPreviewDocumentoComponent,
        DialogPreviewDownloadDocumentComponent,
        GenericDialogConfirmacionCampos,
        GenericDialogConfirmacionOpciones,
        GenericDialogDatepicker,
        GenericDialogSeleccionarOpcion,
        GenericDialogConfirmacionNumeroPorcentaje,
    ],
    exports: [
        BusquedaTablaComponent,
        CalidadCheckComponent,
        MatSelectSearchComponent,
        MatSelectAutocompleteComponent,
        MatAutocompletePopupComponent,
        DialogPreviewDocumentoComponent,
        DialogPreviewDownloadDocumentComponent,
        MatSelectLocalComponent
    ]
})
export class LibraryModule {
}
