import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import * as _moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { LibraryService } from 'src/app/library/library.service';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconView from '@iconify/icons-fa-solid/info-circle';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import { Router } from '@angular/router';
import { MapasProcesosService } from 'src/app/mapas-procesos/mapas-procesos.service';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    PasoMapaProcesoId: number,
    MapaProcesoId: number,
    observaciones: string,
}

@Component({
    selector: 'dialog-observaciones-paso-mapa',
    templateUrl: './dialog-observaciones-paso-mapa.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogObservacionesPasoMapaProceso {
    apiURLs = new APIUrls();
    jsonConverters = new JSONConverters();

    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconView = iconView;
    iconCheck = iconCheck;
    iconClose = iconClose;
    iconPlus = iconPlus;

    // Datos
    cargando: boolean = false;
    observaciones: string;
    PasoMapaProcesoId: number;
    MapaProcesoId: number;

    constructor(
        public dialogRef: MatDialogRef<DialogObservacionesPasoMapaProceso>,
        public mapasProcesosService: MapasProcesosService,
        public usuariosService: UsuariosService,
        public libraryService: LibraryService,
        public auth: AuthService,
        public router: Router,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit(){
        this.PasoMapaProcesoId = this.data.PasoMapaProcesoId;
        this.MapaProcesoId = this.data.MapaProcesoId;
        this.observaciones = this.data.observaciones;
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    async confirmar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.mapasProcesosService.observacionesPasoMapaProceso(this.PasoMapaProcesoId, this.MapaProcesoId, this.observaciones);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.dialogRef.close(true);
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    registroValido(): boolean {
        if(!this.observaciones) return false;
        return true;
    }
}