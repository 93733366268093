import { ValorCertificado } from './valorCertificado';

export class CampoTipoCertificado {
    constructor(
        public id: number,
        public nombre: string,
        public obligatorio: boolean,
        public activo: boolean,
        public tipo: string,
        public orden: number,
        public campoAuxiliar: boolean,
        public TipoCertificadoId: number,
        public EmpresaId: number,
    ){}

    valor: ValorCertificado;
    usaPorcentaje: boolean = false;
    usaMoneda: boolean = false;
    usaValor: boolean = false;
    usaTexto: boolean = false;
    usaFecha: boolean = false;
}