import { Component, Inject, Input } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { Agrupador } from 'src/app/models/agrupador';
import moment from 'moment';
import iconDownload from '@iconify/icons-fa-solid/download';
import { Usuario } from 'src/app/models/usuario';
import { Aseguradora } from 'src/app/models/aseguradora';
import { Poliza } from 'src/app/models/poliza';
import { Certificado } from 'src/app/models/certificado';
import { CobrosService } from '../cobros.service';
import { AseguradorasService } from 'src/app/aseguradoras/aseguradoras.service';
import { DialogPreviewDownloadDocumentComponent } from 'src/app/library/dialog-preview-download-document/dialog-preview-download-document.component';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};
export interface DialogData {
    polizaDefault: Poliza,
    pagadorDefault: Usuario,
    incluirCobrados: boolean,
    etapa: string,
}

@Component({
    selector: 'dialog-reporte-por-cobrar',
    templateUrl: './dialog-reporte-por-cobrar.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogReportePorCobrar {
    apiURLs = new APIUrls();
    fechaFinDate: Date;
    fechaFin: string;
    fechaInicioDate: Date;
    fechaInicio: string;
    fechaProyeccionAtrasoDate: Date;
    fechaProyeccionAtraso: string;
    cargando: boolean = false;
    iconDownload = iconDownload;

    constructor(
        public dialogRef: MatDialogRef<DialogReportePorCobrar>,
        public dialog: MatDialog,
        public service: CobrosService,
        public aseguradorasService: AseguradorasService,
        public libraryService: LibraryService,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit(){
        this.fechaFinDate = moment().endOf('month').toDate();
        this.actualizarFechaFin({ value: { _d: this.fechaFinDate } });
        this.fechaInicioDate = moment().startOf('year').toDate();
        this.actualizarFechaInicio({ value: { _d: this.fechaInicioDate } });
        this.fechaProyeccionAtrasoDate = moment().toDate();
        this.actualizarFechaProyeccionAtraso({ value: { _d: this.fechaProyeccionAtrasoDate } });
    }

    // * * * Confirmar * * *
    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close(null);
    }

    // * * * Descargar * * *
    async descargarExcel() {
        this.cargando = true;
        var res = await this.service.descargarExcelReportePorCobrar(
            this.fechaFin,
            this.fechaInicio,
            this.fechaProyeccionAtraso,
            this.data.etapa,
        );
        
        if(!res.error) {
            //this.confirmar();
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        
        this.cargando = false;
    }

    actualizarFechaFin($event){
        if($event && $event.value) {
            this.fechaFin = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    actualizarFechaInicio($event){
        if($event && $event.value) {
            this.fechaInicio = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    actualizarFechaProyeccionAtraso($event){
        if($event && $event.value) {
            this.fechaProyeccionAtraso = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }
}