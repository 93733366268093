import { Usuario } from './usuario';
import { DocumentoCobro } from './documentoCobro';
import { Moneda } from './moneda';
import { Aseguradora } from './aseguradora';

export class PlanillaCobro {
    constructor(
        public id: number,
        public formaPago: string,
        public banco: string,
        public numeroCheque: string,
        public numeroFactura: string,
        public observaciones: string,
        public fecha: string,
        public total: number,
        public congelada: boolean,
        public AseguradoraId: number,
        public MonedaId: number,
        public UsuarioId: number,
        public EmpresaId: number,
    ){}

    fechaFormato: string;
    fechaDate: Date;
    fechaHasta: string;
    fechaHastaDate: Date;
    formaPagoNombre: string;
    nombreAseguradora: string;
    nombreMoneda: string;
    usuario: Usuario;
    moneda: Moneda;
    meta: any = {};
    aseguradora: Aseguradora;
    documentos: DocumentoCobro[];
    idsAseguradoras: number[];
    idsDocumentos: number[];
}