import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { Empresa } from '../models/empresa';
import { Pais } from '../models/pais';
import { Departamento } from '../models/departamento';
import { Municipio } from '../models/municipio';
import { Zona } from '../models/zona';
import { TipoVehiculo } from '../models/TipoVehiculo';
import { MarcaVehiculo } from '../models/marcaVehiculo';
import { LineaVehiculo } from '../models/lineaVehiculo';

@Injectable()
export class EmpresasService {
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    // * * * * * Obtener datos * * * * *
    // Obtener cliente con su información
    async obtenerEmpresaPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.empresaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerTodasMonedas() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.monedasURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.monedaDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las monedas.');
        }
    }

    async obtenerTodasMonedasEmpresa(EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL + '/monedas?';
            if(EmpresaId) url += '&EmpresaId=' + EmpresaId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.monedaDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las monedas.');
        }
    }

    async obtenerTodasEmpresasRegionales() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL + '/empresasRegionales';
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.empresaDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las aseguradoras.');
        }
    }


    async obtenerTodosImpuestos() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.impuestosEmpresaURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.impuestoEmpresaDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los impuestos.');
        }
    }

    async obtenerTodosFormulariosClientes() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosClientesURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.formularioClienteDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los impuestos.');
        }
    }

    public async obtenerPaises() {
        try {
            return { error: null, data: { registros: this.apiUrls.paises } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los países.');
        }
    }

    // * * * * * Editar datos * * * * *
    // Crear un nuevo cliente
    public async crearEmpresa(registro: Empresa) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL;
            const res = await this.connection.postRequest(url, { data: registro });
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Editar cliente existente
    public async actualizarEmpresa(registro: Empresa) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL;
            const res = await this.connection.putRequest(url, { data: registro });
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Monedas y tipo de cambio * * * * *
    public async actualizarTipoDeCambio(MonedaId: number, tipoCambioDolares: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.monedasURL + '/tipoCambio';
            const res = await this.connection.postRequest(url, { MonedaId: MonedaId, tipoCambioDolares: tipoCambioDolares });
            return { error: null, data: { mensaje: 'Tipo de cambio actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Archivos * * * * *
    // Subir archivo al servidor
    async guardarArchivoEnServidorEmpresas(archivo: File){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.empresasURL + '/uploadArchivo';
            const res = await this.connection.uploadFile(url, archivo, true);
            if(!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }

    // * * * * * Países * * * * *
    async obtenerTodosPaises() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.paisesURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.paisDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los países.');
        }
    }

    async obtenerPaisPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.paisesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.paisDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearPais(registro: Pais) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.paisesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarPais(registro: Pais) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.paisesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarPais(RegistroId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.paisesURL + '/id/' + RegistroId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Departamento * * * * *
    async obtenerTodosDepartamentos() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.departamentosURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.departamentoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las razones de pérdida.');
        }
    }

    async obtenerDepartamentoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.departamentosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.departamentoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearDepartamento(registro: Departamento) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.departamentosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarDepartamento(registro: Departamento) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.departamentosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarDepartamento(RegistroId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.departamentosURL + '/id/' + RegistroId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Muncipio * * * * *
    async obtenerTodosMunicipios() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.municipiosURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.municipioDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las razones de pérdida.');
        }
    }

    async obtenerMunicipioPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.municipiosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.municipioDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearMunicipio(registro: Municipio) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.municipiosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarMunicipio(registro: Municipio) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.municipiosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarMunicipio(RegistroId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.municipiosURL + '/id/' + RegistroId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Zonas * * * * *
    async obtenerTodosZonas() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.zonasURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.zonaDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las razones de pérdida.');
        }
    }

    async obtenerZonaPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.zonasURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.zonaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearZona(registro: Zona) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.zonasURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarZona(registro: Zona) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.zonasURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarZona(RegistroId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.zonasURL + '/id/' + RegistroId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Tipo de vehículo * * * * *
    async obtenerTodosTiposVehiculos() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tipoVehiculosURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.tipoVehiculoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los tipos de vehículo.');
        }
    }

    async obtenerTipoVehiculoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tipoVehiculosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.tipoVehiculoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearTipoVehiculo(registro: TipoVehiculo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tipoVehiculosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarTipoVehiculo(registro: TipoVehiculo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tipoVehiculosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarTipoVehiculo(RegistroId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tipoVehiculosURL + '/id/' + RegistroId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Marca vehículo * * * * *
    async obtenerTodosMarcasVehiculos() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.marcasVehiculosURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.marcaVehiculoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las razones de pérdida.');
        }
    }

    async obtenerMarcaVehiculoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.marcasVehiculosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.marcaVehiculoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearMarcaVehiculo(registro: MarcaVehiculo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.marcasVehiculosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarMarcaVehiculo(registro: MarcaVehiculo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.marcasVehiculosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarMarcaVehiculo(RegistroId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.marcasVehiculosURL + '/id/' + RegistroId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Lineas vehículo * * * * *
    async obtenerTodosLineasVehiculos() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lineasVehiculosURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.lineaVehiculoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las razones de pérdida.');
        }
    }

    async obtenerLineaVehiculoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lineasVehiculosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.municipioDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearLineaVehiculo(registro: LineaVehiculo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lineasVehiculosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarLineaVehiculo(registro: LineaVehiculo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lineasVehiculosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarLineaVehiculo(RegistroId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.lineasVehiculosURL + '/id/' + RegistroId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }
}
