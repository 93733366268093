export class DocumentoPoliza {
    constructor(
        public id:              number,
        public nombre:          string,
        public descripcion:     string,
        public extension:       string,
        public link:            string,

        // Relaciones
        public CertificadoId:         number,
        public PolizaId:         number,
        public SolicitudPolizaId:         number,
        public UsuarioId:         number,
        public EmpresaId:         number,
    ){}

    copiarEnPoliza: boolean = false;
    copiarEnCliente: boolean = false;
    seleccionado: boolean = false;
}