import { Usuario } from './usuario';
import { Poliza } from './poliza';
import { Moneda } from './moneda';
import { SeguimientoCobro } from './seguimientoCobro';
import { Certificado } from './certificado';
import { PlanillaCobro } from './planillaCobro';
import { GastoProductoDocumentoCobro } from './gastoProductoDocumentoCobro';
import { ImpuestoDocumentoCobro } from './impuestoDocumentoCobro';
import { AplicacionDocumentoCredito } from './aplicacionDocumentoCredito';
import { Cobrador } from './cobrador';
import { Mensajeria } from './mensajeria';
import { DocumentoCredito } from './documentoCredito';

export class DocumentoCobro {
    constructor(
        public id: number,
        public numeroRequisito: string,
        public numeroEndoso: string,
        public numeroFacturaAseguradora: string,
        public etapa: string,
        public tipo: string,
        public fechaIngreso: string,
        public fechaEmision: string,
        public fechaPago: string,
        public fechaAplicacion: string,
        public fechaCambio: string,
        public fechaAnulacion: string,
        public motivoAnulacion: string,
        public numeroPago: number,
        public numeroPagoTotal: number,
        public primaNeta: number,
        public totalEmision: number,
        public totalGastos: number,
        public totalImpuestos: number,
        public totalFraccionamiento: number,
        public primaTotal: number,
        public formaCobro: string,
        public observaciones: string,
        public liberado: boolean,
        public rechazado: boolean,
        public razon: string,
        public banco: string,
        public numeroCheque: string,
        public numeroFactura: string,
        public chequeRechazado: boolean,
        public razonAnulacion: string,
        public multiplesFormasPago: string,
        public usaFormaPagoMultiple: boolean,
        public observacionesChequeRechazado: string,
        public observacionesPlanilla: string,
        public observacionesLiquidacion: string,
        public enviadoMensajeria: boolean,
        public ocultarPlanilla: boolean,
        public porcentajeComisionTotal: number,
        public porcentajeComisionProductor: number,
        public porcentajeComisionEmisor: number,
        public porcentajeComisionAgente: number,
        public comisionTotal: number,
        public comisionProductor: number,
        public comisionEmisor: number,
        public comisionEmpresa: number,
        public comisionAgente: number,
        public comisionEmisorAuxiliar: number,
        public historialComisiones: string,
        public distribucionComision: string,
        public CobradorId: number,
        public PagadorId: number,
        public UsuarioId: number,
        public UsuarioAplicadorId: number,
        public UsuarioLiquidadorId: number,
        public UsuarioAnuladorId: number,
        public PolizaId: number,
        public MonedaId: number,
        public PlanillaCobroId: number,
        public MensajeriaId: number,
        public AgenteComisionId: number,
        public EmpresaId: number,
        public PlanillaLiquidacionId: number,
    ){}

    fechaIngresoFormato: string;
    fechaIngresoDate: Date;
    fechaEmisionFormato: string;
    fechaEmisionDate: Date;
    fechaPagoFormato: string;
    fechaPagoDate: Date;
    fechaAplicacionFormato: string;
    fechaAplicacionDate: Date;
    fechaCambioFormato: string;
    fechaCambioDate: Date;
    fechaAnulacionFormato: string;
    fechaAnulacionDate: Date;
    fechaLiquidacion: string;
    fechaLiquidacionFormato: string;
    fechaLiquidacionDate: Date;
    clientes: Usuario[] = [];
    pagador: Usuario;
    agenteComision: Usuario;
    cobrador: Cobrador;
    usuario: Usuario;
    nombreUsuario: string;
    usuarioAplicador: Usuario;
    nombreUsuarioAplicador: string;
    usuarioLiquidador: Usuario;
    nombreUsuarioLiquidador: string;
    usuarioAnulacion: Usuario;
    nombreUsuarioAnulacion: string;
    poliza: Poliza;
    moneda: Moneda;
    mensajeria: Mensajeria;
    planilla: PlanillaCobro;
    seguimientos: SeguimientoCobro[];
    certificados: Certificado[];
    certificadosPagador: Certificado[] = [];
    gastos: GastoProductoDocumentoCobro[];
    impuestos: ImpuestoDocumentoCobro[];
    idsCertificados: number[] = [];
    nombresCertificados: string;
    diasAtraso: number = 0;
    numeroPagoNombre: string;
    nombreFormaPago: string;
    nombreProducto: string;
    nombreAgrupador: string;
    nombreCliente: string;
    nombreEtapa: string;
    meta: any = {};
    selected: boolean = false;
    aplicacionesCreditos: AplicacionDocumentoCredito[];
    numeroPoliza: string;
    nombrePagador: string;
    multiplesFormasPagoArray: FormaCobroMultiple[];
    comisionSistema: number;
    diferenciaComisionAuxiliar: number;
    distribucionComisionArray: any[];
    textoPagadoresRelacionados: string;
}

export class FormaCobroMultiple {
    constructor(
        public formaCobro: string,
        public monto: number,
        public numeroDocumento: string,
        public AplicacionDocumentoCreditoId: number,
    ){}

    aplicacionCredito: AplicacionDocumentoCredito;
    creditoSeleccionado: DocumentoCredito;
}