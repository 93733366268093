import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DATE_FORMATS, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MatDialog } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import { PolizasService } from 'src/app/polizas/polizas.service';
import { DocumentoCobro } from 'src/app/models/documentoCobro';
import { CobrosService } from '../cobros.service';
import { EmpresasService } from 'src/app/empresas/empresas.service';
import { Moneda } from 'src/app/models/moneda';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    id: number,
}

@Component({
  selector: 'vex-dialog-editar-cobro-comisiones',
  templateUrl: './dialog-editar-cobro-comisiones.component.html',
  styleUrls: ['./dialog-editar-cobro-comisiones.component.scss']
})
export class DialogEditarCobroComisionesComponent implements OnInit {

    apiURLs = new APIUrls();
    cargando: boolean = false;
    id: number
    registro: DocumentoCobro;
    monedas: Moneda[] = [];

    constructor(
        public dialogRef: MatDialogRef<DialogEditarCobroComisionesComponent>,
        public polizasService: PolizasService,
        public cobrosService: CobrosService,
        public empresasService: EmpresasService,
        public auth: AuthService,
        public dialog: MatDialog,
        private libraryService: LibraryService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit() {
        var dialog = document.getElementById("dialogOverflow");
        var dialogContainer = null;
        if(dialog) dialogContainer = dialog.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer.className += ' dialogOverflow';

        this.id = this.data.id;
        this.inicializarDatos();
    }
    
    async inicializarDatos() {
        await this.obtenerMonedas();
        await this.obtenerRegistro();
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close({
            accion: 'confirmar',
        });
    }

    async obtenerMonedas() {
        this.cargando = true;
        let EmpresaId = await this.auth.idEmpresaActual();
        var res = await this.empresasService.obtenerTodasMonedasEmpresa(EmpresaId);
        if(!res.error) {
            this.monedas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerRegistro() {
        this.cargando = true;
        // Obtener registro
        var res = await this.cobrosService.obtenerDocumentoCobroPorId(this.id);
        if(!res.error) {
            this.registro = res.data.registro;
        }
        else {
            this.cerrar();
        }
        this.cargando = false;
    }

    // * * * * * Editar datos * * * * *
    // Guardar datos
    async guardar(){
        this.cargando = true;

        // Enviar el registro para guardar
        let res = await this.cobrosService.actualizaDocumentoCobroComision(this.registro);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.confirmar();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    registroValido() {
        if(!this.registro) return false;
        if(
            this.registro.primaNeta == null ||
            this.registro.primaTotal == null ||
            this.registro.totalEmision == null ||
            this.registro.totalFraccionamiento == null ||
            this.registro.totalGastos == null ||
            this.registro.totalImpuestos == null ||
            this.registro.porcentajeComisionEmisor == null ||
            this.registro.comisionEmisor == null
        ) return false;
        return true;
    }

    calcularMontosDocumento() {
        this.registro.primaTotal = this.registro.primaNeta + this.registro.totalImpuestos + this.registro.totalEmision + this.registro.totalGastos + this.registro.totalFraccionamiento;
    }

    calcularComision() {
        if(!this.registro.porcentajeComisionEmisor) this.registro.porcentajeComisionEmisor = 0;
        this.registro.comisionEmisor = this.registro.primaNeta * this.registro.porcentajeComisionEmisor / 100;
    }
}
