import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { DocumentoMensajeria } from '../models/documentoMensajeria';
import { JSONConverters } from '../models/JSONConverters';
import { Mensajeria } from '../models/mensajeria';
import * as FileSaver from 'file-saver';

@Injectable()
export class MensajeriaService {
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    async obtenerMensajeriaPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mensajeriaURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.mensajeriaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearMensajeria(registro: Mensajeria) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mensajeriaURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarMensajeria(registro: Mensajeria) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mensajeriaURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarMensajeria(MensajeriaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mensajeriaURL + '/id/' + MensajeriaId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Mensaje eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async marcarCerrado(registro: Mensajeria) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mensajeriaURL + '/cerrado';
            const res = await this.connection.postRequest(url, { MensajeriaId: registro.id });
            return { error: null, data: { mensaje: 'Mensaje actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Documentos * * * * *
    async guardarDocumentoMensajeria(documento: DocumentoMensajeria) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosMensajeriasURL;
            const res = await this.connection.postRequest(url, documento);
            return { error: null, data: { mensaje: 'Documento guardado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async editarDocumentoMensajeria(documento: DocumentoMensajeria) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosMensajeriasURL;
            const res = await this.connection.putRequest(url, documento);
            return { error: null, data: { mensaje: 'Documento editado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async eliminarDocumento(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.documentosMensajeriasURL + '/id/' + id;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Documento eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async descargarPDFGuia(MensajeriaId: number, nombreFinal: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mensajeriaURL + '/descargarGuia?MensajeriaId=' + MensajeriaId;
            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var fileName = nombreFinal + '.pdf';
            var mediaType = 'application/pdf';
            var blob = new Blob([res], {
                type: mediaType
            });
            FileSaver.saveAs(blob, fileName);

            return {
                error: null,
                data: {
                    mensaje: 'Guía descargada con éxito.'
                }
            };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async descargarExcelRuta(fecha: string, estados: string[], asignado: number, fileName: string) {
      // Obtener string HTML
      try {
          var url = this.apiUrls.baseURL + this.apiUrls.mensajeriaURL + '/descargar-excel-ruta';
          var data = {
              fecha: fecha,
              estados: estados,
              UsuarioAsignadoId: asignado
          }

          const res = await this.connection.postRequestDownload(url, data);

          // Descargar archivo
          var filename = fileName + '.xlsx';
          var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          var blob = new Blob([res], { type: mediaType });
          FileSaver.saveAs(blob, filename);

          return { error: null, data: { mensaje: 'Estado de cuenta descargado con éxito' } };
      } catch (error) {
          return this.connection.obtenerMensajeError(error.status, error.error.message);
      }
  }

    // * * * * * Archivos * * * * *
    async guardarArchivoEnServidorMensajeria(archivo: File){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mensajeriaURL + '/uploadArchivo';
            const res = await this.connection.uploadFile(url, archivo, true);
            if(!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }

    async obtenerAnalyticsMensajeria(params: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mensajeriaURL + '/analytics';
            url += '?' + params;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { data: json.results } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los analytics.');
        }
    }
}
