import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { APIUrls } from '../../api/apiUrls';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { JSONConverters } from 'src/app/models/JSONConverters';
import iconEdit from '@iconify/icons-fa-solid/edit';
import iconList from '@iconify/icons-fa-solid/list';
import { PolizasService } from '../polizas.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Poliza } from 'src/app/models/poliza';
import { MatDialog } from '@angular/material';
import { DialogGenerarEstadoCuenta } from 'src/app/cobros/dialog-generar-estado-cuenta/dialog-generar-estado-cuenta.component';
import { RamosService } from 'src/app/ramos/ramos.service';

@Component({
    selector: 'app-polizas',
    templateUrl: './polizas.component.html',
    styleUrls: ['./polizas.component.scss']
})
export class PolizasComponent implements OnInit {
    crumbs = [
        { nombre: 'Pólizas', link: '/polizas' },
    ];
    // Templates de celdas
    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;

    apiURLs = new APIUrls();
    jsonConverters = new JSONConverters();
    urlAutocompleteClientes = this.apiURLs.baseURL + this.apiURLs.informacionClientesURL + '/search';
    urlAutocompleteAgrupadores = this.apiURLs.baseURL + this.apiURLs.agrupadoresURL + '/search';
    urlAutocompleteAseguradoras = this.apiURLs.baseURL + this.apiURLs.aseguradorasURL + '/search';
    posiblesEstados = [
        { nombre: 'Emisión', color: '#0b60b5' },
        { nombre: 'Nueva', color: '#52b50b' },
        { nombre: 'Traslado', color: '#853b07' },
        { nombre: 'Renovada', color: '#000000' },
        { nombre: 'Histórica', color: '#878787' },
        { nombre: 'Cancelada', color: '#d40000' },
    ];

    // Columnas de tabla
    columnas = [];
    columnasPolizas = [
        { prop: 'numero', name: 'Póliza aseguradora', sortable: true, cellTemplate: null },
        { prop: 'numeroEmision', name: 'Póliza Prointel', sortable: true, cellTemplate: null },
        { prop: 'aniosVigencia', name: 'No. vigencia', sortable: true, cellTemplate: null },
        { prop: 'cliente.nombreCompleto', name: 'Cliente', sortable: true, cellTemplate: null },
        { prop: 'nombreAgrupador', name: 'Agrupador', sortable: true, cellTemplate: null },
        { prop: 'aseguradora.nombre', name: 'Aseguradora', sortable: true, cellTemplate: null },
        { prop: 'ramo.nombre', name: 'Ramo', sortable: true, cellTemplate: null },
        { prop: 'producto.nombre', name: 'Producto', sortable: true, cellTemplate: null },
        { prop: 'fechaVigenciaFinFormato', name: 'Fecha de fin de vigencia', sortable: true, cellTemplate: null },
        { prop: 'textoDependientesRelacionados', name: 'Info', sortable: false, cellTemplate: null },
        { prop: 'ordenBPM', name: 'Orden BPM', sortable: false, cellTemplate: null },
    ];
    columnasControlCalidad = [
        { prop: 'fechaIngresoFormato', name: 'Fecha de ingreso', sortable: true, cellTemplate: null },
        { prop: 'numero', name: 'Número', sortable: true, cellTemplate: null },
        { prop: 'aseguradora.nombre', name: 'Aseguradora', sortable: true, cellTemplate: null },
        { prop: 'ramo.nombre', name: 'Ramo', sortable: true, cellTemplate: null },
        { prop: 'cliente.nombreCompleto', name: 'Cliente', sortable: true, cellTemplate: null },
        { prop: 'usuario.nombreCompleto', name: 'Ingreso', sortable: true, cellTemplate: null },
        { prop: 'diasIngreso', name: 'Días desde ingreso', sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    campos = [
        { nombre: 'No. póliza aseguradora', campo: 'numero', tipo: 'texto', categorias: null },
        { nombre: 'No. póliza Prointel', campo: 'numeroEmision', tipo: 'texto', categorias: null },
        { nombre: 'General', campo: 'general', tipo: 'texto', categorias: null },
        // { nombre: 'ID interno', campo: 'id', tipo: 'texto', categorias: null },
    ];

    // Filtros
    filtros = [
        // Estado
        {
            nombre: 'Estado', campo: 'estado', tipo: 'select-multiple',
            mostrar: true,
            categorias: this.apiURLs.estadosPolizas, valor: this.apiURLs.estadosPolizas.filter(e => e.mostrarPorDefecto).map(e => e.id),
            ignorarLimpieza: true,
        },
        {
            nombre: 'Ramo', campo: 'idsRamos', tipo: 'select-multiple',
            categorias: null, valor: null,
        },
        // Cliente
        { nombre: 'Cliente', campo: 'nombreCliente', tipo: 'texto', valor: null, mostrar: true, categorias: null},
        /*
        {
            nombre: 'Cliente', campo: 'cliente', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteClientes, seleccionados: [], mostrar: false,
            idProp: 'id', titleProp: 'nombreCompleto', fieldSearch: 'general',
            parser: this.jsonConverters.usuarioDeJSON, parserService: this.jsonConverters,
        },
        */
        // Certificado
        { nombre: 'Certificado', campo: 'nombreCertificado', tipo: 'texto', valor: null, mostrar: false, categorias: null},
        // Agrupador
        { nombre: 'Agrupador', campo: 'nombreAgrupador', tipo: 'texto', valor: null, mostrar: false, categorias: null},
        // Agrupador
        { nombre: 'Dependiente o beneficiario', campo: 'nombreDependiente', tipo: 'texto', valor: null, mostrar: false, categorias: null},
        /*
        {
            nombre: 'Agrupador', campo: 'agrupador', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteAgrupadores, seleccionados: [], mostrar: false,
            idProp: 'id', titleProp: 'nombre', fieldSearch: 'nombre',
            parser: this.jsonConverters.agrupadorDeJSON, parserService: this.jsonConverters,
        },
        */
        // Aseguradora
        {
            nombre: 'Aseguradora', campo: 'aseguradora', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteAseguradoras, seleccionados: [], mostrar: false,
            idProp: 'id', titleProp: 'nombre', fieldSearch: 'nombre',
            parser: this.jsonConverters.aseguradoraDeJSON, parserService: this.jsonConverters,
        },
    ];

    urlBusqueda = this.apiURLs.baseURL + this.apiURLs.polizasURL + '/search';
    titulo: string = 'Pólizas';
    mostrarTabla: boolean = false;
    iconEdit = iconEdit;
    iconList = iconList;
    modo: string = 'polizas';

    constructor(
        public service: PolizasService,
        public ramosService: RamosService,
        public auth: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private titleService: Title
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.agregarFiltros();
        await this.obtenerRamos();
        this.agregarTemplates();
        this.mostrarTabla = true;
    }

    obtenerRuta(params: Params) {
        this.titulo = 'Pólizas';
        this.columnas = this.columnasPolizas;
        if(this.route.snapshot.url.length > 0) {
            var ruta = this.route.snapshot.url[0].path;
            this.modo = ruta;
            switch(this.modo) {
                case 'pendientes-calidad': {
                    this.titulo = 'Pólizas pendientes de revisión';
                    this.columnas = this.columnasControlCalidad;
                    this.crumbs[0].nombre = this.titulo;
                    this.crumbs[0].link = '/polizas/pendientes-calidad';
                    break;
                }
                case 'corregidas-calidad': {
                    this.titulo = 'Pólizas corregidas';
                    this.columnas = this.columnasControlCalidad;
                    this.crumbs[0].nombre = this.titulo;
                    this.crumbs[0].link = '/polizas/corregidas-calidad';
                    break;
                }
                case 'revisadas-calidad': {
                    this.titulo = 'Pólizas revisadas';
                    this.columnas = this.columnasControlCalidad;
                    this.crumbs[0].nombre = this.titulo;
                    this.crumbs[0].link = '/polizas/revisadas-calidad';
                    break;
                }
            }
        }
        else {
            this.titulo = 'Pólizas';
            this.columnas = this.columnasPolizas;
            this.crumbs[0].nombre = this.titulo;
            this.crumbs[0].link = '/polizas';
        }
        this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
    }

    agregarTemplates() {
        this.columnas.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
    }

    async agregarFiltros() {

    }

    clickPoliza(poliza: Poliza) {
        let PolizaId = poliza.id;
        if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.ESCRITURA)) {
            this.router.navigate(['/polizas/editar/' + PolizaId]);
        }
        else if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.LECTURA)) {
            this.router.navigate(['/polizas/detalle/' + PolizaId]);
        }
        else {
            this.router.navigate(['/polizas/detalle/' + PolizaId]);
        }
    }

    calcularParamsEspeciales(): string {
        var paramsEspeciales = '';

        if(this.modo == 'pendientes-calidad') {
            paramsEspeciales += '&estadoControlCalidad=pendiente';
        }
        else if(this.modo == 'revisadas-calidad') {
            paramsEspeciales += '&estadoControlCalidad=revisada';
        }
        else if(this.modo == 'corregidas-calidad') {
            paramsEspeciales += '&estadoControlCalidad=corregida';
        }

        if(this.auth.esAccesoACliente()) {
            paramsEspeciales += '&accesoACliente=1';
        }

        if(this.auth.idsAgentesCartera()) {
            paramsEspeciales += '&soloCarterasAgentes=' + this.auth.idsAgentesCartera().toString();
        }

        if(this.auth.idsVendedoresCartera()) {
            paramsEspeciales += '&soloCarterasVendedores=' + this.auth.idsVendedoresCartera().toString();
        }

        return paramsEspeciales;
    }

    // * * * Estado de cuenta * * *
    abrirGenerarEstadoCuenta() {
        const dialogRef = this.dialog.open(DialogGenerarEstadoCuenta, {
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result != null) {}
        });
    }

    async obtenerRamos() {
        var res = await this.ramosService.obtenerTodosRamos();
        if(!res.error) {
            this.filtros[1].categorias = res.data.registros;
        }
    }
}
