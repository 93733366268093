import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import moment from 'moment';
import { PolizasService } from '../polizas.service';
import iconDelete from '@iconify/icons-fa-solid/trash';
import { Certificado } from 'src/app/models/certificado';
import { Router } from '@angular/router';
import { PlantillaRamo } from 'src/app/models/plantillaRamo';
import { Poliza } from 'src/app/models/poliza';
import { RamosService } from 'src/app/ramos/ramos.service';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    poliza: Poliza,
    certificadoDefault: Certificado,
}

@Component({
    selector: 'dialog-aplicar-plantilla-certificados',
    templateUrl: './dialog-aplicar-plantilla-certificados.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogAplicarPlantillaCertificados {
    apiURLs = new APIUrls();
    plantillas: PlantillaRamo[] = [];
    plantillaSeleccionada: PlantillaRamo;
    PlantillaRamoId: number;
    certificados: Certificado[];
    idsCertificados: number[] = [];
    poliza: Poliza;
    certificadoDefault: Certificado;
    cargando: boolean;
    jsonConverters: JSONConverters = new JSONConverters();

    fechaAltaDate: Date = new Date();
    fechaBajaDate: Date = new Date();
    fechaReporteAltaDate: Date = new Date();
    fechaReporteBajaDate: Date = new Date();
    fechaAlta: string;
    fechaBaja: string;
    fechaReporteAlta: string;
    fechaReporteBaja: string;
    
    iconDelete = iconDelete;

    // Certificados
    urlAutocompleteCertificados = this.apiURLs.baseURL + this.apiURLs.certificadosURL + '/search';
    columnasCertificados = [
        { prop: 'numero', name: 'Número', sortable: true, cellTemplate: null },
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'nombreEstado', name: 'Estado', sortable: true, cellTemplate: null },
        { prop: 'nombresPagadores', name: 'Pagadores', sortable: true, cellTemplate: null },
        { prop: 'tipoCertificado.nombre', name: 'Tipo', sortable: true, cellTemplate: null },
        { prop: 'sumaAsegurada', name: 'Suma asegurada', sortable: true, cellTemplate: null },
    ];
    camposCertificados = [
        { nombre: 'General', campo: 'nombre', tipo: 'texto', categorias: null },
        { nombre: 'ID', campo: 'id', tipo: 'texto', categorias: null },
    ];

    constructor(
        public dialogRef: MatDialogRef<DialogAplicarPlantillaCertificados>,
        public polizasService: PolizasService,
        public ramosService: RamosService,
        public auth: AuthService,
        public libraryService: LibraryService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
    }
    
    ngOnInit() {
        this.poliza = this.data.poliza;
        this.certificadoDefault = this.data.certificadoDefault;
        if(this.certificadoDefault) {
            //this.certificados = [this.certificadoDefault];
            this.idsCertificados = [this.certificadoDefault.id];
        }
        this.obtenerPlantillas();
        this.obtenerCertificados();

        this.actualizarFecha({ value: { _d: this.fechaAltaDate } }, 'fechaAlta');
        this.actualizarFecha({ value: { _d: this.fechaBajaDate } }, 'fechaBaja');
        this.actualizarFecha({ value: { _d: this.fechaReporteAltaDate } }, 'fechaReporteAlta');
        this.actualizarFecha({ value: { _d: this.fechaReporteBajaDate } }, 'fechaReporteBaja');
    }

    async obtenerPlantillas() {
        this.cargando = true;
        var res = await this.ramosService.obtenerTodasPlantillasDeRamo(this.poliza.RamoId);
        if (!res.error) {
            this.plantillas = res.data.registros;
        } else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    actualizarCertificados(items: Certificado[]) {
        this.idsCertificados = [];
        items.forEach(item => {
            if(item.id) this.idsCertificados.push(item.id);
        });
    }

    calcularParamsEspecialesCertificados(){
        var params = '';
        params += '&PolizaId=' + this.poliza.id;
        return params;
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close(true);
    }

    async obtenerCertificados() {
        this.cargando = true;
        var res = await this.polizasService.obtenerTodosCertificadosPoliza(this.poliza.id);
        if(!res.error) {
            this.certificados = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async guardar(){
        this.cargando = true;

        let idsCertificadosFinales = [];
        for (const id of this.idsCertificados) {
            if(id) idsCertificadosFinales.push(id);
        }

        if(this.registroValido()) {
            // Enviar el registro para guardar
            var res = await this.ramosService.aplicarPlantillaRamoCertificados(idsCertificadosFinales, this.PlantillaRamoId, this.poliza.id, this.fechaAlta, this.fechaBaja, this.fechaReporteAlta, this.fechaReporteBaja);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.confirmar();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    registroValido(): boolean {
        if(!this.idsCertificados || this.idsCertificados.length == 0) return false;
        if(!this.PlantillaRamoId) return false;
        return true;
    }

    seleccionarTodosCertificados() {
        let todosSeleccionados = (this.idsCertificados.length >= this.certificados.length);
        this.idsCertificados = [];
        if(!todosSeleccionados) {
            for (const certificado of this.certificados) {
                if(certificado.id) this.idsCertificados.push(certificado.id);
            }
        }
    }

    actualizarFecha($event, campo: string){
        if($event && $event.value) {
            this[campo] = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
        else {
            this[campo] = null;
        }
    }
}
