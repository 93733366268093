import { GastoProducto } from './gastoProducto';

export class GastoProductoDocumentoCobro {
    constructor(
        public id: number,
        public nombre: string,
        public porcentaje: number,
        public monto: number,
        public MonedaId: number,
        public GastoProductoId: number,
        public DocumentoCobroId: number,
    ){}

    gastoProducto: GastoProducto;

    clone() {
        var nuevoRegistro = new GastoProductoDocumentoCobro(
            null,
            this.nombre,
            this.porcentaje,
            this.monto,
            this.MonedaId,
            this.GastoProductoId,
            this.DocumentoCobroId,
        );
        nuevoRegistro.gastoProducto = this.gastoProducto;
        return nuevoRegistro;
    }
}