import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { LibraryService } from 'src/app/library/library.service';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconEdit from '@iconify/icons-fa-solid/edit';
import { DocumentoPoliza } from 'src/app/models/documentoPoliza';
import { PolizasService } from '../polizas.service';

export interface DialogData {
    modo: string,
    ClienteId: number,
    PolizaId: number,
    ReclamoId: number,
    SolicitudPolizaId: number
}

@Component({
    selector: 'vex-dialog-agregar-multiples-documentos',
    templateUrl: './dialog-agregar-multiples-documentos.component.html',
    styleUrls: ['./dialog-agregar-multiples-documentos.component.scss']
})
export class DialogAgregarMultiplesDocumentosPolizaComponent implements OnInit {
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconEdit = iconEdit;
    archivoCargado:boolean = false;
    cargando: boolean = false;
    titulo:string = '';
    archivosLinks: any[] = [];

    constructor(
        public dialogRef: MatDialogRef<DialogAgregarMultiplesDocumentosPolizaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private service: PolizasService,
        private libraryService: LibraryService,
        private router: Router,
        public dialog: MatDialog,
        private auth: AuthService
    ) { }

    ngOnInit(): void {
        if(this.data.modo === 'creacion'){
            this.titulo = 'Nuevo documento';
        }
        else if(this.data.modo === 'detalle'){
            this.titulo = 'Detalle de documento';
        }
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    guardar() {
        this.dialogRef.close(this.archivosLinks);
    }

    async seleccionarDocumentoResultado(event: any) {
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            for (let i = 0; i < fileList.length; i++) {
                let file: File = fileList[i];
                var rutaArchivo = file.name;
                var auxExtension = rutaArchivo.split(".");
                var extension = auxExtension[auxExtension.length-1];
                let nombreArchivo = auxExtension[0];
                var archivo = file;
                await this.crearYSubirDocumento(archivo, rutaArchivo, extension, nombreArchivo);
            }
        }
    }

    activarSeleccionDocumentoResultado() {
        document.getElementById('file-input-archivo').click();
    }

    async crearYSubirDocumento(archivo, rutaArchivo, extension, nombreArchivo) {
        this.cargando = true;
        if (archivo && rutaArchivo && extension) {
            var res = await this.service.guardarArchivoClasificadoEnServidor(archivo, this.data.ClienteId, this.data.PolizaId, this.data.ReclamoId, this.data.SolicitudPolizaId);
            if (!res.error) {
                this.archivosLinks.push({
                    link: res.data.url,
                    extension: extension,
                    nombre: nombreArchivo
                });
                this.archivoCargado = true;
            } else { 
                this.libraryService.crearNotificacion('Ha ocurrido un error al subir su documento al servidor.', 'danger');
            }
        }
        this.cargando = false;
    }

    cambiarEditar(){
        this.titulo = 'Edición de documento';
        this.data.modo = 'edicion';
    }

}
