import { Usuario } from './usuario';
import { Poliza } from './poliza';
import { Empresa } from './empresa';
import { Certificado } from './certificado';

export class SolicitudCliente {
    constructor(
        public id: number,
        public fecha: string,
        public titulo: string,
        public descripcion: string,
        public estado: string,
        public observacionesInternas: string,
        
        // Relaciones
        public AgrupadorId: number,
        public UsuarioId: number,
        public ClienteId: number,
        public PolizaId: number,
        public CertificadoId: number,
        public EmpresaId: number,
    ) {}

    cliente: Usuario;
    usuario: Usuario;
    poliza: Poliza;
    empresa: Empresa;
    certificado: Certificado;

    // Datos
    nombrePoliza: string;
    nombreCertificado: string;
    nombreCliente: string;
    nombreEstado: string;
    nombreUsuario: string;
    descripcionResumen: string;

    // Fechas
    fechaFormato: string;
    fechaDate: Date;
}
