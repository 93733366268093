import { Empresa } from './empresa';
import { Usuario } from './usuario';

export class MiAnalytic {
    constructor(
        public id: number,
        public tipoGrafica: string,
        public parametros: string,
        public activo: boolean,
        public EmpresaId: number,
        public UsuarioId: number,
    ){
        this.obtenerJSON();
    }

    Empresa:Empresa;
    Usuario: Usuario;

    parametrosJSON: any;

    obtenerJSON() {
        this.parametrosJSON = JSON.parse(this.parametros);
    }
}
