import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import moment from 'moment';
import { PolizasService } from '../polizas.service';
import iconDelete from '@iconify/icons-fa-solid/trash';
import { Certificado } from 'src/app/models/certificado';
import { Router } from '@angular/router';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Poliza } from 'src/app/models/poliza';
import { PagadorCertificado } from 'src/app/models/pagadorCertificado';
import { Usuario } from 'src/app/models/usuario';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    pagador: Usuario,
    poliza: Poliza,
}

@Component({
    selector: 'dialog-certificados-pagador',
    templateUrl: './dialog-certificados-pagador.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogCertificadosPagador {
    apiURLs = new APIUrls();
    cargando: boolean;
    jsonConverters: JSONConverters = new JSONConverters();
    titulo: string = 'Certificados de pagador de póliza';
    iconDelete = iconDelete;
    certificados: Certificado[];
    certificadosPagador: PagadorCertificado[];
    idsCertificados: number[] = [];

    constructor(
        public dialogRef: MatDialogRef<DialogCertificadosPagador>,
        public polizasService: PolizasService,
        public auth: AuthService,
        public libraryService: LibraryService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
    }
    
    ngOnInit() {
        this.initializeData();
    }
    
    async initializeData() {
        await this.obtenerCertificados();
        await this.obtenerPagadorCertificados();
        this.transformarCertificados(false);
    }

    async obtenerCertificados() {
        this.cargando = true;
        var res = await this.polizasService.obtenerTodosCertificadosPoliza(this.data.poliza.id);
        if(!res.error) {
            this.certificados = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerPagadorCertificados() {
        this.cargando = true;
        var res = await this.polizasService.obtenerTodosCertificadosPagadorPoliza(this.data.poliza.id, this.data.pagador.id);
        if(!res.error) {
            this.certificadosPagador = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    seleccionarTodosCertificados() {
        let todosSeleccionados = (this.idsCertificados.length >= this.certificados.length);
        this.idsCertificados = [];
        if(!todosSeleccionados) {
            for (const certificado of this.certificados) {
                if(certificado.id) this.idsCertificados.push(certificado.id);
            }
        }
        this.transformarCertificados(true);
    }

    async guardar(){
        this.cargando = true;

        if(this.formularioValido()) {
            var res = await this.polizasService.guardarPagadoresCertificadoPagadorPoliza(this.data.poliza.id, this.data.pagador.id, this.certificadosPagador);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.ngOnInit();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close({});
    }
    
    formularioValido() {
        return true;
    }

    eliminarRegistro(index) {
        if(this.certificadosPagador) {
            this.certificadosPagador.splice(index, 1);
        }
    }

    transformarCertificados(desdeIds: boolean) {
        if(desdeIds) { // Construir pagadores desde los ids seleccionados
            let pagadoresFinales = [];
            for (const CertificadoId of this.idsCertificados) {
                let indexPagador = this.libraryService.indexOf(this.certificadosPagador, 'CertificadoId', CertificadoId);
                if(indexPagador != -1) { // Jalar el pagador a los finales
                    pagadoresFinales.push(this.certificadosPagador[indexPagador]);
                }
                else { // Crear una nueva relación de pagador para ese certificado
                    let indexCertificado = this.libraryService.indexOf(this.certificados, 'id', CertificadoId);
                    let certificado = this.certificados[indexCertificado];
                    let pagador = new PagadorCertificado(null, null, null, null, null, null, null, null, this.data.pagador.id, CertificadoId, this.auth.idEmpresaActual());
                    pagador.certificado = certificado;
                    pagadoresFinales.push(pagador);
                }
            }
            this.certificadosPagador = pagadoresFinales;
        }
        else { // Construir ids seleccioandos desde pagadores
            let idsFinales = [];
            for (const pagador of this.certificadosPagador) {
                idsFinales.push(pagador.CertificadoId);
            }
            this.idsCertificados = idsFinales;
        }
    }
}
