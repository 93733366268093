import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APIUrls } from './apiUrls';
import { Usuario } from '../models/usuario';
import * as queryString from 'query-string';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ConnectionService {
    private usuario: Usuario;
    private apiUrls: APIUrls = new APIUrls();
    // * * * Headers para Basic Auth * * *
    private GETheaders: HttpHeaders = new HttpHeaders({
        'Authorization': 'Basic ' + btoa(this.apiUrls.readUsername + ":" + this.apiUrls.readPassword)
    });
    private POSTheaders: HttpHeaders = new HttpHeaders({
        'Authorization': 'Basic ' + btoa(this.apiUrls.writeUsername + ":" + this.apiUrls.writePassword),
        'Content-Type': 'application/json'
    });
    private DELETEheaders: HttpHeaders = new HttpHeaders({
        'Authorization': 'Basic ' + btoa(this.apiUrls.deleteUsername + ":" + this.apiUrls.deletePassword),
    });

    constructor(
        private http: HttpClient
    ) { }

    /**
     * Devuelve el resultado de una llamada GET.
     * @param url URL del request
     * @param tokenAuth Determina si debe usarse autenticación por tokens o Basic Auth
     */
    public getRequest(url: string, tokenAuth: boolean = true): Promise<any> {
        url = this.addSignatureParams(url);
        var headers = this.GETheaders;
        if(tokenAuth) headers = this.GETTokenHeaders();
        return this.http.get(url, { headers: headers }).toPromise();
    }

    public getRequestObservable(url: string, tokenAuth: boolean = true): Observable<any> {
        url = this.addSignatureParams(url);
        var headers = this.GETheaders;
        if(tokenAuth) headers = this.GETTokenHeaders();
        return this.http.get<any>(url, { headers: headers }).pipe(
            tap((response: any) => {
                return response;
            })
        );
    }

    /**
     * Devuelve el resultado de una llamada POST.
     * @param url URL del request
     * @param data Data a enviar en el request
     * @param tokenAuth Determina si debe usarse autenticación por tokens o Basic Auth
     */
    public postRequest(url: string, data: any, tokenAuth: boolean = true): Promise<any> {
        data = this.addSignatureData(data);
        var headers = this.POSTheaders;
        if(tokenAuth) headers = this.POSTTokenHeaders();
        return this.http.post(url, data, { headers: headers }).toPromise();
    }

    public postRequestDownload(url: string, data: any, tokenAuth: boolean = true): Promise<any> {
        data = this.addSignatureData(data);
        var headers = this.POSTheaders;
        if(tokenAuth) headers = this.POSTTokenHeaders();
        return this.http.post(url, data, { headers: headers, responseType: 'blob' }).toPromise();
    }

    public postReq(url: string, data: any, tokenAuth: boolean = true): Promise<any> {
        var headers = this.POSTheaders;
        if(tokenAuth) headers = this.POSTTokenHeaders();
        return this.http.post(url, {data:data}, { headers: headers }).toPromise();
    }

    /**
     * Devuelve el resultado de una llamada PUT.
     * @param url URL del request
     * @param data Data a enviar en el request
     * @param tokenAuth Determina si debe usarse autenticación por tokens o Basic Auth
     */
    public putRequest(url: string, data: any, tokenAuth: boolean = true): Promise<any> {
        data = this.addSignatureData(data);
        var headers = this.POSTheaders;
        if(tokenAuth) headers = this.POSTTokenHeaders();
        return this.http.put(url, data, { headers: headers }).toPromise();
    }

    /**
     * Devuelve el resultado de una llamada DELETE.
     * @param url URL del request
     * @param tokenAuth Determina si debe usarse autenticación por tokens o Basic Auth
     */
    public deleteRequest(url: string, tokenAuth: boolean = true): Promise<any> {
        url = this.addSignatureParams(url);
        var headers = this.DELETEheaders;
        if(tokenAuth) headers = this.GETTokenHeaders();
        return this.http.delete(url, { headers: headers }).toPromise();
    }

    public getDownloadRequest(url: string, tokenAuth: boolean = true): Promise<any> {
        url = this.addSignatureParams(url);
        var headers = this.GETheaders;
        if(tokenAuth) headers = this.GETTokenHeaders();
        return this.http.get(url, { headers: headers, responseType: 'blob' }).toPromise();
    }

    /**
     * Envía 
     * @param url 
     * @param file 
     */
    public uploadFile(url: string, file: File, tokenAuth: boolean = true, data: any = null): Promise<any> {
        return new Promise((resolve, reject) => {
            var formData: any = new FormData();
            var xhr = new XMLHttpRequest();
            formData.append("file", file, file.name);
            
            // Add any additional data to formData
            if (data) {
                url = url + '?';
                for (const key in data) {
                    if (Object.prototype.hasOwnProperty.call(data, key)) {
                        const element = data[key];
                        url += '&' + key + '=' + element;
                    }
                }
            }

            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                    if (xhr.status == 200) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr.response);
                    }
                }
            }
            xhr.open("POST", url, true);

            if(!tokenAuth) xhr.setRequestHeader('Authorization', 'Basic ' + btoa(this.apiUrls.writeUsername + ":" + this.apiUrls.writePassword));
            else xhr.setRequestHeader('Authorization', 'Bearer ' + this.getLocalToken());
            xhr.send(formData);
        });
    }

    public downloadPDFFromHTML(htmlString: string) {
        var url = this.apiUrls.pdfURL;
        return this.http.post(url, htmlString, { responseType: 'blob' }).toPromise()
    }

    /**
     * Devuelve headers de autenticación por token con el token local
     */
    private GETTokenHeaders() {
        var token = this.getLocalToken();
        var GETheaders: HttpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return GETheaders;
    }

    /**
     * Devuelve headers de autenticación por token con el token local y agregar el content-type application/json
     */
    private POSTTokenHeaders() {
        var token = this.getLocalToken();
        var POSTheaders: HttpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return POSTheaders;
    }
    
    /**
     * Devuelve el token guardado en localStorage. Devuelve 'null' si no hay nada.
     */
    private getLocalToken() {
        var token = localStorage.getItem('token');
        if(!token || token.trim() == '') token = 'null';
        return token;
    }

    /**
     * Devuelve el mensaje de error apropiado para un request que obtuvo un error
     * @param status 
     * @param serverMessage 
     */
    obtenerMensajeError(status, serverMessage){
        var mensaje;
        if(serverMessage) mensaje = serverMessage;
        else {
            if(status == 400 || status == 500) {
                mensaje = 'Ha ocurrido un error al realizar su consulta.';
            }
            else if(status == 401) {
                mensaje = 'Error de autenticación. Por favor inicie sesión nuevamente.';
            }
            else if(status == 403) {
                mensaje = 'No cuenta con los permisos suficientes para realizar esta acción.';
            }
            else if(status == 404) {
                mensaje = 'El recurso solicitado no está disponible o no existe.';
            }
        }

        return { error: { mensajeError: mensaje }, data: null };
    }

    /**
     * Agrega una firma de datos a todos los requests.
     * @param data 
     */
    private addSignatureData(data: any) {
        // Obtener usuario
        if(this.usuario) {
            data.SignatureUsuarioId = this.usuario.id;
        }
        else { 
            data.SignatureUsuarioId = null;
        }
        return data;
    }

    private addSignatureParams(url: string) {
        // Obtener usuario
        if(this.usuario) {
            var paramsActuales = this.queryParamsFromURL(url);
            if(this.isEmpty(paramsActuales)) url += '?';
            url += '&SignatureUsuarioId=' + this.usuario.id;
        }
        return url;
    }

    private queryParamsFromURL(url) {
        const parsed = queryString.parseUrl(url);
        return parsed.query;
    };

    private isEmpty(obj) {
        for(var i in obj) { return false; }
        return true;
    }

    public actualizarUsuario(usuarioData: Usuario) {
        this.usuario = usuarioData;
    }
}