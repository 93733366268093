import { Usuario } from './usuario';

export class SeguimientoCobro {
    constructor(
        public id: number,
        public numero: number,
        public observaciones: string,
        public fecha: string,
        public DocumentoCobroId: number,
        public UsuarioId: number,
        public EmpresaId: number,
    ){}

    fechaFormato: string;
    fechaDate: Date;
    usuario: Usuario;
    nombreUsuario: string;
    observacionesResumen: string;
}