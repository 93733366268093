import { CampoTipoReclamo } from './campoTipoReclamo';

export class ValorReclamo {
    constructor(
        public id: number,
        public valor: string,
        public texto: string,
        public fecha: string,
        public ReclamoId: number,
        public CampoTipoReclamoId: number,
    ){}

    campo: CampoTipoReclamo;
}