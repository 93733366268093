import { CoberturaIncisoCertificado } from './coberturaIncisoCertificado';
import { CertificadoReclamo } from './certificadoReclamo';
import { DeducibleCertificado } from './deducibleCertificado';

export class CoberturaCertificadoReclamo {
    constructor(
        public id: number,
        public montoDeducible: number,
        public CoberturaIncisoCertificadoId: number,
        public CertificadoReclamoId: number,
        public DeducibleCertificadoId: number,
    ){}

    coberturaIncisoCertificado: CoberturaIncisoCertificado;
    certificadoReclamo: CertificadoReclamo;
    deducibleCertificado: DeducibleCertificado;
}