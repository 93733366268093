import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconCopy from '@iconify/icons-fa-solid/copy';
import iconInfo from '@iconify/icons-fa-solid/info-circle';
import iconError from '@iconify/icons-fa-solid/exclamation-circle';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { Poliza } from 'src/app/models/poliza';
import { Certificado } from 'src/app/models/certificado';
import { CobrosService } from '../cobros.service';
import { PolizasService } from 'src/app/polizas/polizas.service';
import { Usuario } from 'src/app/models/usuario';
import { DocumentoCobro, FormaCobroMultiple } from 'src/app/models/documentoCobro';
import { GastoProductoDocumentoCobro } from 'src/app/models/gastoProductoDocumentoCobro';
import { ImpuestoDocumentoCobro } from 'src/app/models/impuestoDocumentoCobro';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { Cobrador } from 'src/app/models/cobrador';
import { ComisionPolizaInciso } from 'src/app/models/comisionPolizaInciso';
import { GenericDialogConfirmacionComentarios } from 'src/app/library/generic-dialog-confirmacion-comentarios/generic-dialog-confirmacion-comentarios.component';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
import { Banco } from 'src/app/models/Banco';
import { PagadorPoliza } from 'src/app/models/pagadorPoliza';
import { PagadorCertificado } from 'src/app/models/pagadorCertificado';
import { Moneda } from 'src/app/models/moneda';
import { EmpresasService } from 'src/app/empresas/empresas.service';

const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-nuevo-documento-cobro',
    templateUrl: './nuevo-documento-cobro.component.html',
    styleUrls: ['./nuevo-documento-cobro.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class NuevoDocumentoCobroComponent implements OnInit {
    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconCopy = iconCopy;
    iconInfo = iconInfo;
    iconError = iconError;

    // Banderas y generales
    cargando: boolean = false;
    bloqueoComision: boolean = true;
    modoEdicion: boolean;
    modoCreacion: boolean;
    titulo: string;
    apiURLs: APIUrls = new APIUrls();

    // Datos
    id: number;
    PolizaId: number;
    registros: DocumentoCobro[];
    poliza: Poliza;
    cobradores: Cobrador[];
    certificados: Certificado[];
    pagadores: Usuario[];
    pagadoresPoliza: PagadorPoliza[];
    pagadoresCertificados: PagadorCertificado[];
    urlAutocompleteClientes = this.apiURLs.baseURL + this.apiURLs.informacionClientesURL + '/search';
    tiposCobros: any[] = this.apiURLs.tiposCobros;
    mensajeError: string;
    comisionesPoliza: ComisionPolizaInciso[];
    primasPorPagador: any[];
    primasPorCertificadoPagador: any[];
    comisionesPolizaOriginales: ComisionPolizaInciso[];
    numeroPagos: number;
    requerimientoBase: string;
    numeroEndosoBase: string;
    fechaBase: string;
    fechaBaseDate: Date;
    PagadorIdGeneral: number;
    documentosGenerados: boolean = false;
    historialComisiones: string;
    bancos: Banco[];
    certificadosGeneralesPagador: Certificado[];
    idsCertificadosGenerales: number[];
    monedas: Moneda[];

    // Tabs
    selectedIndex: number = 0;
    tabId: string = null;

    crumbs = [
        { nombre: 'Detalle de póliza', link: '/polizas/detalle' },
        { nombre: 'Nuevo documento de cobro', link: '/cobros/cobro' },
    ];

    constructor(
        public service: CobrosService,
        public empresasService: EmpresasService,
        public polizasService: PolizasService,
        public usuariosService: UsuariosService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * *
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    trimRequisito($event: KeyboardEvent) {
      const value = (<HTMLInputElement>$event.target).value;
      (<HTMLInputElement>$event.target).value = value.replace(/\D/g, '');
    } 

    // Inicializar datos
    async inicializarDatos(params: Params){
        await this.obtenerBancos();
        this.obtenerRuta(params);
        await this.obtenerMonedas();
        await this.obtenerRegistro();
        this.obtenerCorrelativoBase();
        this.obtenerCobradores();
        this.obtenerPagadores();
        this.obtenerCertificados();
        if(this.modoCreacion) this.obtenerSugerenciaComisionesPoliza();

        this.route.queryParams.subscribe((params: Params) => {
            this.tabId = params.tab;
            if(this.tabId) {
                this.selectedIndex = this.obtenerIndexTab(this.tabId);
            }
            // this.actualizarTabSeleccionada(this.selectedIndex);
        });
    }

    async obtenerBancos() {
        this.cargando = true;
        var res = await this.service.obtenerBancos();
        if(!res.error) {
            this.bancos = res.data.registros;

            // Ordenamos alfabéticamente los bancos
            this.bancos.sort((a, b) => a.nombre.localeCompare(b.nombre));
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    
    }

    actualizarTabSeleccionada(index: number) {
        let tabs = this.obtenerTabs();
        if(index != null && index < tabs.length) {
            let idTab = tabs[index];
            let url = this.router.createUrlTree([], { relativeTo: this.route, skipLocationChange: true, queryParams: { tab: idTab }}).toString();
            this.location.go(url);
        }
    }

    obtenerIndexTab(id: string) {
        if(!id) return null;
        let tabs = this.obtenerTabs();
        let index = tabs.indexOf(id);
        if(index == -1) index = null;
        return index;
    }

    obtenerTabs() {
      let tabs = [];
      tabs.push('comisiones-poliza');
      tabs.push('datos-cobro');
      return tabs;
  }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        this.modoCreacion = ruta === 'nuevo-documento-cobro';
        this.modoEdicion = ruta === 'editar-documento-cobro';
        if(params['id']){
            this.id = parseInt(params['id']);
        }
        if(params['PolizaId']){
            this.PolizaId = parseInt(params['PolizaId']);
        }
    }

    completarDocumentos() {
        if(!this.registros) this.registros = [];
        // let total = this.poliza.numeroPagos;
        let total = this.numeroPagos;
        let veces = total - this.registros.length;
        for (let i = 0; i < veces; i++) {
            this.agregarDocumentoCobro();
        }
    }

    agregarDocumentoCobro() {
        if(!this.registros) this.registros = [];
        if(this.registros.length > 0) {
            let nuevoDocumento: DocumentoCobro = JSON.parse(JSON.stringify(this.registros[this.registros.length - 1]));

            // Número de cuota
            let nuevoNumeroCuota = nuevoDocumento.numeroPago;
            nuevoNumeroCuota += 1;
            nuevoDocumento.numeroPago = nuevoNumeroCuota;

            // Número de documento
            let nuevoNumeroDocumento = parseInt(nuevoDocumento.numeroRequisito);
            nuevoNumeroDocumento += 1;
            nuevoDocumento.numeroRequisito = nuevoNumeroDocumento.toString();

            // Fecha de pago
            var fechaOriginal = nuevoDocumento.fechaEmision;
            var intervaloMeses = this.libraryService.numeroMesesParaFrecuencia(this.poliza.frecuenciaPago);
            var fechaPago = moment(fechaOriginal).add(intervaloMeses * 1, 'months').format('YYYY-MM-DD');
            var fechaPagoDate = moment(fechaPago).toDate();
            nuevoDocumento.fechaEmisionDate = fechaPagoDate;
            nuevoDocumento = this.actualizarFecha({ value: { _d: fechaPagoDate } }, nuevoDocumento, 'fechaEmision');

            this.registros.push(nuevoDocumento);
        }
        else {
            var nuevoDocumento = new DocumentoCobro(
                -1, null, null, null,
                this.apiURLs.etapasCobros[0].id,
                this.tiposCobros[0].id,
                null, null, null, null, null, null, null, 1,
                this.numeroPagos,
                null, null, null, null, null,
                null, null, null,
                false, false, null,
                null, null, null, null, null, null, false, null, null, null,
                false, null,
                null, null, null, null, null, null, null, null, null, null, null,
                null, null, this.PagadorIdGeneral,
                this.auth.idUsuarioActual(), null, null, null,
                this.PolizaId,
                this.poliza.MonedaId,
                null, null, null,
                this.auth.idEmpresaActual(),
                null
            );
            // Prima neta
            let primaNetaCompleta = this.calcularTotalesComisiones(this.comisionesPoliza).primaNeta;
            if(this.poliza.primaNeta && this.numeroPagos) {
                // nuevoDocumento.primaNeta = parseFloat((this.poliza.primaNeta / this.poliza.numeroPagos).toFixed(2));
                nuevoDocumento.primaNeta = parseFloat((primaNetaCompleta / this.numeroPagos).toFixed(2));
            }
    
            // Fecha de emisión
            nuevoDocumento.fechaEmisionDate = new Date();
            nuevoDocumento = this.actualizarFecha({ value: { _d: new Date() } }, nuevoDocumento, 'fechaEmision');
    
            // Número de pago
            var maxNumero = 1;
            for (let i = 0; i < this.registros.length; i++) {
                if(this.registros[i].numeroPago > maxNumero) maxNumero = this.registros[i].numeroPago;
            }
    
            if(this.pagadores && this.pagadores.length > 0) {
                if(this.PagadorIdGeneral) nuevoDocumento.PagadorId = this.PagadorIdGeneral;
                else nuevoDocumento.PagadorId = this.pagadores[0].id;
            }
    
            // Agregar productos
            nuevoDocumento.gastos = [];
            if(this.poliza && this.poliza.producto && this.poliza.producto.gastos) {
                for (let i = 0; i < this.poliza.producto.gastos.length; i++) {
                    const element = this.poliza.producto.gastos[i];
                    var nuevoGasto = new GastoProductoDocumentoCobro(null, null, null, null, this.poliza.MonedaId, element.id, null);
                    nuevoGasto.gastoProducto = element;
                    if(element.tipo == 'valor') nuevoGasto.monto = element.montoFijo;
                    if(element.tipo == 'porcentaje') nuevoGasto.porcentaje = element.porcentaje;
                    nuevoGasto.nombre = element.nombre;
                    nuevoDocumento.gastos.push(nuevoGasto);
                }
            }
    
            // Agregar impuestos
            nuevoDocumento.impuestos = [];
            if(this.poliza && this.poliza.producto && this.poliza.producto.impuestos) {
                for (let i = 0; i < this.poliza.producto.impuestos.length; i++) {
                    const element = this.poliza.producto.impuestos[i];
                    var nuevoImpuesto = new ImpuestoDocumentoCobro(null, null, null, null, this.poliza.MonedaId, element.id, null);
                    nuevoImpuesto.impuesto = element;
                    if(element.tipo == 'valor') nuevoImpuesto.monto = element.montoFijo;
                    if(element.tipo == 'porcentaje') nuevoImpuesto.porcentaje = element.porcentaje;
                    nuevoImpuesto.nombre = element.nombre;
                    nuevoDocumento.impuestos.push(nuevoImpuesto);
                }
            }
    
            this.registros.push(nuevoDocumento);
        }

    }

    eliminarDocumentoCobro(index) {
        if(this.registros && this.registros.length > index) {
            this.registros.splice(index, 1);
        }
    }

    agregar1Documento() {
        this.agregarDocumentoCobro();
        this.registros[0].numeroRequisito = this.requerimientoBase;
        this.registros[0].numeroEndoso = this.numeroEndosoBase;
        this.registros[0].fechaEmision = this.fechaBase;
        this.registros[0].fechaEmisionDate = this.fechaBaseDate;
        this.calcularMontosDocumento(true, this.registros[0]);
        this.documentosGenerados = true;
        this.selectedIndex = 1;
    }

    copiarDocumentos() {
        this.agregarDocumentoCobro();
        this.registros[0].numeroRequisito = this.requerimientoBase;
        this.registros[0].numeroEndoso = this.numeroEndosoBase;
        this.registros[0].fechaEmision = this.fechaBase;
        this.registros[0].fechaEmisionDate = this.fechaBaseDate;

        this.calcularMontosDocumento(true, this.registros[0]);

        if(this.registros && this.registros.length > 0) {
            var documentoBase = this.registros[this.registros.length - 1];
            var numeroBase = parseInt(documentoBase.numeroPago.toString());
            var cuotasRestantes = this.numeroPagos - numeroBase;

            for (let i = 1; i < cuotasRestantes + 1; i++) {
                let nuevoDocumento = JSON.parse(JSON.stringify(documentoBase));

                // Número de cuota
                let nuevoNumeroCuota = parseInt(nuevoDocumento.numeroPago);
                nuevoNumeroCuota += i;
                nuevoDocumento.numeroPago = nuevoNumeroCuota;

                // Número de documento
                let nuevoNumeroDocumento = parseInt(nuevoDocumento.numeroRequisito);
                nuevoNumeroDocumento += i;
                nuevoDocumento.numeroRequisito = nuevoNumeroDocumento;

                // Fecha de pago
                var fechaOriginal = documentoBase.fechaEmision;
                var intervaloMeses = this.libraryService.numeroMesesParaFrecuencia(this.poliza.frecuenciaPago);
                var fechaPago = moment(fechaOriginal).add(intervaloMeses * i, 'months').format('YYYY-MM-DD');
                var fechaPagoDate = moment(fechaPago).toDate();
                nuevoDocumento.fechaEmisionDate = fechaPagoDate;
                nuevoDocumento = this.actualizarFecha({ value: { _d: fechaPagoDate } }, nuevoDocumento, 'fechaEmision');

                this.registros.push(nuevoDocumento);
            }
            this.documentosGenerados = true;
            this.selectedIndex = 1;
        }

        for (let i = 0; i < this.registros.length; i++) {
            this.certificadosPagador(this.registros[i]);
        }
    }

    actualizarTotales(documentoCobro: DocumentoCobro, reiniciarValores: boolean = false) {
        if(reiniciarValores && this.certificados) {
            let certificadosAplican = this.certificados.filter(el => documentoCobro.idsCertificados.indexOf(el.id) != -1);
            if(documentoCobro.idsCertificados.length == 0) certificadosAplican = this.certificados;

            let primaNetaTotal = 0;
            for (const el of certificadosAplican) {
                primaNetaTotal += el.primaFacturada;
            }
            documentoCobro.primaNeta = primaNetaTotal;
        }

        

        documentoCobro.primaTotal = documentoCobro.primaNeta + documentoCobro.totalImpuestos + documentoCobro.totalEmision + documentoCobro.totalGastos + documentoCobro.totalFraccionamiento;
    }

    // * * * * * Obtener datos * * * * *
    // Obtener el registro o inicializar uno nuevo
    async obtenerRegistro() {
        this.cargando = true;
        if(this.modoCreacion) {
            // Asignar título
            this.titulo = 'Agregar documento de cobro';
            this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
            // Inicializar registro
            var resPoliza = await this.polizasService.obtenerPolizaPorId(this.PolizaId, false);
            if(!resPoliza.error) {
                this.poliza = resPoliza.data.registro;
                this.numeroPagos = this.poliza.numeroPagos;
                this.crumbs[0].link = '/polizas/detalle/' + this.PolizaId;
                this.registros = [];
                // this.agregarDocumentoCobro();
                // this.calcularMontosDocumento(true, this.registros[0]);
            }
            else {
                this.router.navigate(['/404']);
            }
        }
        else if(this.modoEdicion) {
            this.titulo = 'Editar documento de cobro';
            // Obtener registro
            var res = await this.service.obtenerDocumentoCobroPorId(this.id);
            if(!res.error) {
                this.registros = [res.data.registro];
                this.titleService.setTitle('Editar documento de cobro #' + this.id + this.apiURLs.nombreSitioTitle);
                this.poliza = this.registros[0].poliza;
                this.PolizaId = this.registros[0].PolizaId;
                this.crumbs[0].link = '/polizas/detalle/' + this.PolizaId;
            }
            else {
                this.router.navigate(['/404']);
            }
        }
        this.cargando = false;
    }

    // * * * * * Editar datos * * * * *
    // Guardar datos
    async guardar(){
        this.cargando = true;
        // Arreglar múltiples
        for (let doc of this.registros) {
            let multiplesFormasPago = null;
            if(doc.multiplesFormasPagoArray) multiplesFormasPago = JSON.stringify(doc.multiplesFormasPagoArray);
            doc.multiplesFormasPago = multiplesFormasPago;
            doc.historialComisiones = this.historialComisiones;
        }

        // Enviar el registro para guardar
        var res;
        if(this.modoCreacion) {
            res = await this.service.crearDocumentosCobros(this.registros, this.comisionesPoliza);
        }
        else {
            res = await this.service.actualizaDocumentoCobro(this.registros[0]);
        }

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            if(this.modoCreacion) this.router.navigate(['/cobros/detalle/' + res.data.result.result.id]);
            this.auth.comprobarAutenticacion(false);
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * * * Pagadores * * * * *
    async obtenerPagadores() {
        this.cargando = true;
        if(this.PolizaId) {
            var res = await this.polizasService.obtenerTodosPagadoresPoliza(this.PolizaId);
            if(!res.error) {
                this.pagadores = res.data.registros;
                this.pagadoresPoliza = res.data.pagadoresPoliza;
                this.pagadoresCertificados = res.data.pagadoresCertificados;
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    actualizarFecha($event, documento: DocumentoCobro, campo: string){
        if($event && $event.value) {
            documento[campo] = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
            return documento;
        }
        return null;
    }

    actualizarFechaBase($event){
        if($event && $event.value) {
            this.fechaBase = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    async obtenerCertificados() {
        this.cargando = true;
        if(this.PolizaId) {
            var res = await this.polizasService.obtenerTodosCertificadosPoliza(this.PolizaId);
            if(!res.error) {
                this.certificados = res.data.registros;
                if(this.registros) {
                    for (let i = 0; i < this.registros.length; i++) {
                        this.certificadosPagador(this.registros[i]);
                    }
                }
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    certificadosPagador(documento: DocumentoCobro) {
        if(this.certificados) {
            documento.certificadosPagador = [];
            var PagadorId = documento.PagadorId;
            var idsCertificadosPagador = [];

            if(PagadorId) {
                for (let i = 0; i < this.certificados.length; i++) {
                    var certificado = this.certificados[i];
                    if(certificado.idsPagadores.indexOf(PagadorId) != -1) {
                        documento.certificadosPagador.push(certificado);
                        idsCertificadosPagador.push(certificado.id);
                    }
                }
            }

            // Agregar los que sí son
            documento.certificados = [];
            for (let i = 0; i < documento.idsCertificados.length; i++) {
                const CertificadoId = documento.idsCertificados[i];
                var index = this.libraryService.indexOf(documento.certificadosPagador, 'id', CertificadoId);
                if(index != -1) {
                    documento.certificados.push(documento.certificadosPagador[index]);
                }
            }
        }
    }

    totalCertificadosSeleccionados(documento: DocumentoCobro) {
        var total = 0;
        if(documento.certificados) {
            for (let i = 0; i < documento.certificados.length; i++) {
                const element = documento.certificados[i];
                total += element.primaFacturada;
            }
        }
        return total;
    }

    async obtenerCorrelativoBase() {
        this.cargando = true;
        var res = await this.service.obtenerCorrelativoInterno();
        if(!res.error) {
            this.requerimientoBase = res.data.correlativo;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    // * * * * * Cobradores * * * * *
    async obtenerCobradores() {
        this.cargando = true;
        var res = await this.service.obtenerTodosCobradores();
        if(!res.error) {
            this.cobradores = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    // * * * * * Comisiones de póliza * * * * *
    async obtenerSugerenciaComisionesPoliza() {
        this.cargando = true;
        var res = await this.service.obtenerSugerenciaComisionesPoliza(this.PolizaId);
        if(!res.error) {
            this.comisionesPoliza = res.data.registros;
            this.primasPorPagador = res.data.primasPorPagador;
            this.comisionesPolizaOriginales = res.data.registros;
            this.primasPorCertificadoPagador = res.data.primasPorCertificadoPagador;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    // * * * * * Validaciones * * * * *
    formularioValido() {
        this.mensajeError = null;

        if(this.modoCreacion && this.numeroPagos > this.poliza.numeroPagos) {
            this.mensajeError = 'El número de pagos no puede ser mayor al número de pagos de la póliza';
            return false;
        }

        if(this.modoCreacion && !this.PagadorIdGeneral) {
            this.mensajeError = 'Debe seleccionar un pagador.';
            return false;
        }

        if(!this.registros || this.registros.length == 0) return false;

        for (let i = 0; i < this.registros.length; i++) {
            const element = this.registros[i];
            if(this.panelTieneErrores(i)) return false;
        }

        return true;
    }

    panelTieneErrores(index: number) {
        if(index == null || !this.registros || this.registros.length <= index) return true;
        var registro = this.registros[index];
        
        if(!this.libraryService.validateText(registro.fechaEmision)) {
            this.mensajeError = 'Fecha de requerimiento faltante en documento de cobro #' + registro.numeroPago;
            return true;
        }
        
        if(!this.libraryService.validateText(registro.numeroEndoso)) {
            this.mensajeError = 'No. endoso faltante en documento de cobro #' + registro.numeroPago;
            return true;
        }

        if(!registro.numeroPago) {
            this.mensajeError = 'Número de pago inválido en documento de cobro #' + registro.numeroPago;
            return true;
        }

        if(!registro.primaNeta) {
            this.mensajeError = 'Prima neta inválida en documento de cobro #' + registro.numeroPago;
            return true;
        }

        if(!registro.numeroRequisito) {
            this.mensajeError = 'Número de requerimiento faltante en documento de cobro #' + registro.numeroPago;
            return true;
        }

        if(!registro.PagadorId) {
            this.mensajeError = 'Pagador faltante en documento de cobro #' + registro.numeroPago;
            return true;
        }


        return false;
    }

    actualizarCliente(items: Usuario[], documento: DocumentoCobro) {
        var ClienteId = null;
        if(items && items.length > 0) {
            ClienteId = items[0].id;
        }
        documento.PagadorId = ClienteId;
    }

    // * * * Eliminar datos * * *
    abrirEliminar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar documento de cobro',
                mensaje: '¿Está seguro de que desea eliminar este documento de cobro?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminar();
            }
        });
    }

    async eliminar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.eliminarDocumentoCobro(this.id);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.router.navigate(['/cobros/cobro/']);
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    seleccionarTodosCertificados(documento: DocumentoCobro) {
        let todosSeleccionados = (documento.idsCertificados.length > (documento.certificadosPagador.length - 1));
        documento.idsCertificados = [];
        if(!todosSeleccionados) {
            for (const el of documento.certificadosPagador) documento.idsCertificados.push(el.id);
        }
        
        this.calcularMontosDocumento(true, documento, true);
    }

    seleccionarTodosCertificadosGenerales() {
        let todosSeleccionados = (this.idsCertificadosGenerales.length > (this.certificadosGeneralesPagador.length - 1));
        this.idsCertificadosGenerales = [];
        if(!todosSeleccionados) {
            for (const el of this.certificadosGeneralesPagador) this.idsCertificadosGenerales.push(el.id);
        }

        this.actualizarCertificadosGenerales();
    }

    async actualizarCertificadosGenerales() {
        await this.calcularPrimaBase();

        if(this.registros) {
            for (const el of this.registros) {
                el.idsCertificados = this.idsCertificadosGenerales;
                el.certificadosPagador = this.certificadosGeneralesPagador;

                this.calcularMontosDocumento(true, el, true);
            }
        }
    }

    agregarFormaCobroMultiple(documento: DocumentoCobro) {
        if(!documento.multiplesFormasPagoArray) documento.multiplesFormasPagoArray = [];
        documento.multiplesFormasPagoArray.push(new FormaCobroMultiple(null, null, null, null));
    }

    eliminarFormaCobroMultiple(index: number, documento: DocumentoCobro) {
        if(documento.multiplesFormasPagoArray) documento.multiplesFormasPagoArray.splice(index, 1);
    }

    totalMultiplesFormas(documento: DocumentoCobro) {
        if(!documento) return 0;
        let total = 0;
        if(documento.multiplesFormasPagoArray) {
            for (const el of documento.multiplesFormasPagoArray) total += el.monto;
        }
        return total;
    }

    limpiarPrimasNetas() {
        if(this.comisionesPoliza) {
            for (let comision of this.comisionesPoliza) {
                comision.primaNeta = 0;
            }
        }
        this.calcularComisiones();
    }

    calcularComisiones() {
        if(this.comisionesPoliza) {
            for (let comision of this.comisionesPoliza) {
                if(comision.primaNeta && comision.porcentajeComision) {
                    comision.comision = comision.primaNeta * (comision.porcentajeComision / 100);
                }
                else comision.comision = 0;

                if(this.numeroPagos) comision.comisionPorPago = comision.comision / this.numeroPagos;
                else comision.comisionPorPago = comision.comision;
            }
        }
    }

    calcularTotalesComisiones(comisiones: ComisionPolizaInciso[]) {
        let primaNeta = 0, comision = 0, comisionPorPago = 0;
        if(comisiones) {
            for (const el of comisiones) {
                primaNeta += el.primaNeta;
                comision += el.comision;
                comisionPorPago += el.comisionPorPago;
            }
        }

        let porcentajeGeneral = (comision / primaNeta) * 100;
        return { primaNeta, comision, comisionPorPago, porcentajeGeneral };
    }

    calcularMontosDocumento(asignarPrimaNeta: boolean, documentoCobro: DocumentoCobro, ignorarComisiones = false) {
        this.actualizarTotales(documentoCobro, asignarPrimaNeta);
        
        // Asignar prima neta en primer cálculo
        let resCalculo = this.calcularTotalesComisiones(this.comisionesPoliza);
        let primaNetaCompleta = resCalculo.primaNeta;
        if(asignarPrimaNeta && !ignorarComisiones) {
            documentoCobro.primaNeta = primaNetaCompleta / this.numeroPagos;
            documentoCobro.totalGastos = 0;
            documentoCobro.comisionEmisor = resCalculo.comisionPorPago;
            documentoCobro.porcentajeComisionEmisor = resCalculo.porcentajeGeneral;
        }

        // Asignar gastos de emisión (5%, 8% en fianzas)
        if(this.auth.paisEmpresaActual() == 'GT') {
            documentoCobro.totalEmision = documentoCobro.primaNeta * 0.05;
            if(this.polizasService.esFianzas(this.poliza)) documentoCobro.totalEmision = documentoCobro.primaNeta * 0.08;
        }
        else {
            documentoCobro.totalEmision = 0;
        }

        // Gastos de fraccionamiento
        if(this.auth.paisEmpresaActual() == 'GT') {
            let fraccionamiento = this.apiURLs.porcentajesFraccionamiento.find(el => el.cuotas == this.numeroPagos);
            if(fraccionamiento) {
                documentoCobro.totalFraccionamiento = documentoCobro.primaNeta * (fraccionamiento.porcentaje / 100);
            }
        }
        else {
            documentoCobro.totalFraccionamiento = 0;
        }

        // Impuestos (IVA)
        let porcentajeIVA = 0.12;
        if(this.auth.paisEmpresaActual() == 'SV') {
            porcentajeIVA = 0.13;
            if(this.polizasService.esGastosMedicos(this.poliza) || this.polizasService.esVida(this.poliza)) {
                porcentajeIVA = 0;
            }
        }
        if(this.auth.paisEmpresaActual() == 'CR') porcentajeIVA = 0.13;
        else if(this.auth.paisEmpresaActual() == 'HN') porcentajeIVA = 0.15;
        documentoCobro.totalImpuestos = (documentoCobro.primaNeta + documentoCobro.totalEmision + documentoCobro.totalFraccionamiento + documentoCobro.totalGastos) * porcentajeIVA;

        this.actualizarTotales(documentoCobro);
    }

    actualizarEndoso(endoso: string, index: number) {
        if(this.registros) {
            for (let el of this.registros) {
                el.numeroEndoso = endoso;
            }
        }
    }

    abrirCodigoDesbloqueoComisiones() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacionComentarios, {
            data: {
                titulo: 'Código de seguridad',
                mensaje: 'Ingrese el código de seguridad de su supervisor para desbloquear las comisiones de póliza.',
                nombreComentarios: 'Código de seguridad',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.validarCodigoSeguridad(result.comentarios);
            }
        });
    }

    async validarCodigoSeguridad(codigoSeguridad: string){
        try {
            this.cargando = true;
    
            // Enviar el registro para guardar
            var res = await this.usuariosService.validarCodigoSeguridad(codigoSeguridad, this.apiURLs.modulos.COMISIONES.id, this.apiURLs.nivelesAutorizacion.ADMINISTRADOR);
            if(res.error) throw new Error(res.error.mensajeError);
            let tienePermiso = res.data.valido;
    
            if(!res.error && tienePermiso) {
                this.libraryService.crearNotificacion('Se ha habilitado la edición de comisiones.', 'success');
                this.historialComisiones = JSON.stringify(this.comisionesPoliza);
                this.bloqueoComision = false;
            }
            else {
                this.libraryService.crearNotificacionGrande(res.error.mensajeError, 'danger');
            }
            this.cargando = false;
        } catch(error) {
            this.cargando = false;
            this.libraryService.crearNotificacionGrande(error, 'danger');
        }
    }

    async calcularPrimaBase() {
        this.certificadosGeneralesPagador = this.certificados.filter(el => el.idsPagadores.indexOf(this.PagadorIdGeneral) != -1);
        this.comisionesPoliza = JSON.parse(JSON.stringify(this.comisionesPolizaOriginales));

        if(!this.idsCertificadosGenerales || this.idsCertificadosGenerales.length == 0) {
            // Cálculo general para todos los certificados
            let total = 0;
            for (const el of this.primasPorPagador) {
                total += el.primaFacturada;
            }
            
            let pag = this.primasPorPagador.find(el => el.ClienteId == this.PagadorIdGeneral);
            if(pag) {
                let porcentaje = (pag.primaFacturada / total) * 100;
                for (let comision of this.comisionesPoliza) {
                    comision.primaNeta = comision.primaNeta * (porcentaje / 100);
                }
            }
    
            this.calcularComisiones();
        }
        else {
            // Cálculo general para todos los certificados
            let total = 0;
            for (const el of this.primasPorCertificadoPagador) {
                if(this.idsCertificadosGenerales.includes(el.CertificadoId) && el.ClienteId == this.PagadorIdGeneral) {
                    total += el.primaFacturada;
                }
            }
            
            let totalGeneralOriginal = 0;
            for (const el of this.comisionesPolizaOriginales) {
                totalGeneralOriginal += el.primaNeta;
            }

            for (let comision of this.comisionesPoliza) {
                let porcentaje = 0;
                let objOriginal = this.comisionesPolizaOriginales.find(el => el.nombreInciso == comision.nombreInciso);
                if(objOriginal) {
                    porcentaje = (objOriginal.primaNeta / totalGeneralOriginal);
                }
                comision.primaNeta = total * porcentaje;
            }
    
            this.calcularComisiones();
        }
    }

    async obtenerMonedas() {
        this.cargando = true;
        let EmpresaId = await this.auth.idEmpresaActual();
        var res = await this.empresasService.obtenerTodasMonedasEmpresa(EmpresaId);
        if(!res.error) {
            this.monedas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }
}
