import { PlantillaMapaProceso } from './plantillaMapaProceso';

export class PlantillaPasoMapaProceso {
    constructor(
        public id: number,
        public numero: number,
        public tipo: string,
        public descripcion: string,
        public duracionDias: number,
        public activo: boolean,
        public isDeleted: boolean,
        public llevaPunteo: boolean,
        public PlantillaMapaProcesoId: number,
        public EmpresaId: number,
    ){}

    nombreTipo: string;
    meta: any = {};
    plantillaMapa: PlantillaMapaProceso;
}