export class Notificacion {
    constructor(
        public id: number,
        public titulo: string,
        public descripcion: string,
        public tipo1: string,
        public tipo2: string,
        public tipo3: string,
        public id1: string,
        public id2: string,
        public id3: string,
        public fecha: string,
        public mostrarPortal: boolean,
        public enviarMail: boolean,
        public EmpresaId: number,
        public UsuarioId: number,
        public isRead: boolean
    ){}

}