import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import moment from 'moment';
import { SolicitudPoliza } from 'src/app/models/solicitudPoliza';
import { MapaProceso } from 'src/app/models/mapaProceso';
import { PolizasService } from '../polizas.service';
import iconDelete from '@iconify/icons-fa-solid/trash';
import { Certificado } from 'src/app/models/certificado';
import { CertificadoSolicitud } from 'src/app/models/certificadoSolicitud';
import { Router } from '@angular/router';
import { PlantillaSolicitud } from 'src/app/models/plantillaSolicitud';
import { SolicitudesService } from 'src/app/solicitudes/solicitudes.service';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { PlantillaMapaProceso } from 'src/app/models/plantillaMapaProceso';
import { MapasProcesosService } from 'src/app/mapas-procesos/mapas-procesos.service';
import { ValorCertificado } from 'src/app/models/valorCertificado';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    CertificadoId: number,
}

@Component({
    selector: 'dialog-datos-certificado',
    templateUrl: './dialog-datos-certificado.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogDatosCertificado {
    apiURLs = new APIUrls();
    cargando: boolean;
    certificado: Certificado;
    titulo: string = 'Datos de certificado';

    constructor(
        public dialogRef: MatDialogRef<DialogDatosCertificado>,
        public servicePoliza: PolizasService,
        public solicitudesService: SolicitudesService,
        public mapasProcesosService: MapasProcesosService,
        public auth: AuthService,
        public libraryService: LibraryService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
    }
    
    ngOnInit() {
        // Arreglar estilo
        var dialog = document.getElementById("dialogOverflow");
        var dialogContainer = null;
        if(dialog) dialogContainer = dialog.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer.className += ' dialogOverflow';
        
        this.obtenerRegistro();
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    async obtenerRegistro() {
        this.cargando = true;
    
        // Obtener registro
        var res = await this.servicePoliza.obtenerCertificadoPorId(this.data.CertificadoId);
        if(!res.error) {
            this.certificado = res.data.registro;
            //this.titulo = 'Datos de certificado ' + this.certificado.nombreCertificado;
            this.asignarValoresCampos();
        }
        else {
            this.cerrar();
        }
        
        this.cargando = false;
    }

    async asignarValoresCampos() {
        this.cargando = true;
        var tipo = this.certificado.tipoCertificado;
        this.certificado.campos = tipo.campos;

        // Revisar si hay valores para los campos actuales
        for (let i = 0; i < this.certificado.campos.length; i++) {
            const campo = this.certificado.campos[i];
            var indexValor = this.libraryService.indexOf(this.certificado.valores, 'CampoTipoCertificadoId', campo.id);
            if(indexValor == -1) {
                // No hay valor, poner uno nuevo
                this.certificado.campos[i].valor = new ValorCertificado(null, null, null, null, null, campo.id);
            }
            else {
                // Asignar el valor existente
                this.certificado.campos[i].valor = this.certificado.valores[indexValor];
            }
        }

        this.cargando = false;
    }
}
