import { Injectable, Component, Inject } from '@angular/core';
import { ConnectionService } from '../api/connection.service';
import * as moment from 'moment';
moment.locale('es');
import * as momentTimezone from 'moment-timezone';
import { MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';

export interface DialogData {
    titulo: string,
    texto: string,
}

@Injectable()
export class LibraryService {
    constructor(
        private connection: ConnectionService,
        private _snackBar: MatSnackBar,
        public dialog: MatDialog, 
    ) { }

    public async obtenerRegistros(url: string, parser, campoRecords: string) {
        try {
            if(!campoRecords) campoRecords = 'records';
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json[campoRecords].length; i++) {
                const element = json[campoRecords][i];
                registros.push(parser(element));
            }
            return { error: null, data: { registros: registros, total: json.total, originalJSON: json } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    obtenerRegistrosObservable(url: string, parser, campoRecords: string): Observable<any> {
        try {
            if(!campoRecords) campoRecords = 'records';
            return this.connection.getRequestObservable(url).pipe(
                tap((json: any) => {
                    var registros = [];
                    for (let i = 0; i < json[campoRecords].length; i++) {
                        const element = json[campoRecords][i];
                        registros.push(parser(element));
                    }
                    return { error: null, data: { registros: registros, total: json.total, originalJSON: json } };
                })
            )
        } catch (error) {
            return null;
        }
    }

    /**
     * Convierte una fecha a otro formato
     * @param fecha Fecha a convertir
     * @param formatoEntrada Formato de moment.js, 'date' si viene como objeto Date
     * @param formatoSalida Formato de moment.js
     */
    public convertirFecha(fechaOriginal: any, formatoEntrada: string, formatoSalida: string, multiFormato: boolean = false) {
        if(!fechaOriginal) return null;
        let fecha = null;
        if(formatoEntrada === 'date') fecha = moment(fechaOriginal);
        else fecha = moment(fechaOriginal, formatoEntrada);

        var res;
        if(formatoSalida === 'date') res = fecha;
        else res = fecha.format(formatoSalida); 

        if(res == 'Fecha inválida' || res == 'Invalid date') {
            res = null;
            if(formatoEntrada == 'DD/MM/YYYY') {
                // Intentar segunda conversión si la primera no se pudo
                let fechaSegunda = moment(fechaOriginal, 'MM/DD/YYYY');
                if(formatoSalida === 'date') res = fechaSegunda;
                else res = fechaSegunda.format(formatoSalida); 

                if(res == 'Fecha inválida' || res == 'Invalid date') res = null;
            }
        }

        return res;
    }

    public convertirFechaATimezone(fecha: any, formatoSalida: string, timezone: string){
        var fechaConvertida = momentTimezone.tz(fecha, timezone);
        return fechaConvertida.format(formatoSalida);
    }

    public extraerNumero(valor) {
        let valorLimpio = valor;
        let valorFinal = null;
        if(typeof valor == 'string') {
            valorLimpio = valorLimpio.replace(/,/g, '');
            valorLimpio = valorLimpio.replace(/Q/g, '');
            valorLimpio = valorLimpio.replace(/\$/g, '');
            valorLimpio = valorLimpio.replace(/%/g, '');
            valorLimpio = valorLimpio.replace(/ /g, '');
            valorFinal = parseFloat(valorLimpio);
        }
        else valorFinal = valor;
        if(!valorFinal) valorFinal = 0;
        return valorFinal;
    }

    /**
     * Valida que una hora esté en el formato de 24 horas HH:MM
     * @param hora 
     */
    public validarHora(hora: string): boolean {
        var horaElements = hora.split(':');
        if(parseInt(horaElements[0]) > 23 || parseInt(horaElements[1]) > 59 || horaElements[0].length < 2 || horaElements[1].length < 2 || hora.indexOf('_') != -1) {
            return false;
        }
        return true;
    }

    /**
     * Convierte la fecha a un formato de texto de mejor lectura
     * @param fechaStr 
     * @param formatoEntrada Acepta 'YYYY-MM-DD', 'DD/MM/YYYY'
     * @param incluirDiaSemana 
     */
    convertirFechaATexto(fechaStr: string, formatoEntrada: string, incluirDiaSemana: boolean): string {
        switch(formatoEntrada){
            case 'YYYY-MM-DD':{
                var elementos = fechaStr.split('-');
                anio = Number.parseInt(elementos[0]);
                mes = Number.parseInt(elementos[1]);
                dia = Number.parseInt(elementos[2]);
                break;
            }
            case 'DD/MM/YYYY':{
                var elementos = fechaStr.split('/');
                anio = Number.parseInt(elementos[2]);
                mes = Number.parseInt(elementos[1]);
                dia = Number.parseInt(elementos[0]);
                break;
            }
            default:{
                return null;
            }
        }
        var dia: number;
        var mes: number;
        var anio: number;
        var fecha = new Date(anio, mes - 1, dia);
        var diaSemana = fecha.getDay();
        var finalString = '';
        if (incluirDiaSemana){
            switch(diaSemana){
                case 0: finalString += 'domingo'; break;
                case 1: finalString += 'lunes'; break;
                case 2: finalString += 'martes'; break;
                case 3: finalString += 'miércoles'; break;
                case 4: finalString += 'jueves'; break;
                case 5: finalString += 'viernes'; break;
                case 6: finalString += 'sábado'; break;
            }
            finalString += ' ';
        }
        finalString += dia + ' de ';
        switch(mes){
            case 1: finalString += 'enero'; break;
            case 2: finalString += 'febrero'; break;
            case 3: finalString += 'marzo'; break;
            case 4: finalString += 'abril'; break;
            case 5: finalString += 'mayo'; break;
            case 6: finalString += 'junio'; break;
            case 7: finalString += 'julio'; break;
            case 8: finalString += 'agosto'; break;
            case 9: finalString += 'septiembre'; break;
            case 10: finalString += 'octubre'; break;
            case 11: finalString += 'noviembre'; break;
            case 12: finalString += 'diciembre'; break;
        }
        finalString += ' de ' + anio;
        return finalString;
    }

    indexOf(array, findKey, value) {
        if(array) {
            for (let i = 0; i < array.length; i++) {
                const element = array[i];
                if(element[findKey] == value) {
                    return i;
                }
            }
        }
        return -1;
    }

    objectAtIndexOf(array, findKey, value) {
        var index = this.indexOf(array, findKey, value);
        if(index != -1) return array[index];
        else return null;
    }

    redondearNumero2Decimales(num) {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }

    aproximarDecimal(n: number, decimales: number) {
        return Number(n.toFixed(decimales));
    }

    stringMonedaANumero(valor) {
        if(!valor) return null;
        if(typeof valor == 'string') {
            // Reemplazar símbolos de moneda
            valor = valor.replace('$', '');
            valor = valor.replace('Q', '');
            // Reemplazar todas las comas
            valor = valor.replace(/,/g, '');
            valor = parseFloat(valor);
        }
        return valor;
    }

    limpiarNumeroIdentificacion(valor) {
        if(!valor) return null;
        let valorLimpio = valor;
        valorLimpio = valorLimpio.toLowerCase();
        valorLimpio = valorLimpio.trim();
        valorLimpio = valorLimpio.replace(/-/g, '');
        valorLimpio = valorLimpio.replace(/\./g, '');
        valorLimpio = valorLimpio.replace(/ /g, '');
        return valorLimpio;
    }

    isNormalInteger(str) {
        var n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    sanitizeCorreo(correo) {
        if(correo && typeof correo == 'string') {
            correo = correo.trim();
            correo = correo.toLowerCase();
        }
        return correo;
    }

    validateText(text){
        if(text == null){
            return false;
        }
        text = text.toString();
        if(text.trim() == ''){
            return false;
        }
        return true;
    }

    validatePassword(text){
        if(text == null){
            return false;
        }
        if(text.trim() == ''){
            return false;
        }
        if (text.length < 6){
            return false;
        }
        return true;
    }

    // * * * Notificaciones * * * 
    crearNotificacion(mensaje, tipo) {
        /*
        this.dialog.open(GenericDialog, {
            data: {
                titulo: mensaje,
                texto: null,
            }
        });
        */
        this._snackBar.open(mensaje, "OK", {
            horizontalPosition: "center",
            verticalPosition: "top",
            duration: 4000,
            //panelClass: 'snack' + tipo,
        });
    }

    crearNotificacionGrande(mensaje, tipo) {
        this.dialog.open(GenericDialog, {
            data: {
                titulo: mensaje,
                texto: null,
            }
        });
    }

    numeroMesesParaFrecuencia(frecuenciaPago) {
        switch(frecuenciaPago) {
            case 'mensual': return 1;
            case 'bimestral': return 2;
            case 'trimestral': return 3;
            case 'semestral': return 6;
            case 'anual': return 12;
            default: return 1;
        }
    }

    calcularFechas(mode) {
        var startDate = new Date();
        var finishDate = new Date();
        switch(mode) {
            case 'hoy': {
                startDate = new Date();
                finishDate = new Date();
                break;
            }
            case 'ayer': {
                startDate = new Date();
                startDate.setDate(startDate.getDate() - 1);
                finishDate = new Date();
                finishDate.setDate(finishDate.getDate() - 1);
                break;
            }
            case 'esta-semana': {
                startDate = moment().startOf('week').toDate();
                finishDate = moment().endOf('week').toDate();
                break;
            }
            case 'ultima-semana': {
                finishDate = moment().startOf('week').toDate();
                finishDate.setDate(finishDate.getDate() - 1);
                startDate = moment(finishDate).startOf('week').toDate();
                break;
            }
            case 'mes-actual': {
                startDate = moment().startOf('month').toDate();
                finishDate = moment().endOf('month').toDate();
                break;
            }
            case 'mes-pasado': {
                finishDate = moment().startOf('month').toDate();
                finishDate.setDate(finishDate.getDate() - 1);
                startDate = moment(finishDate).startOf('month').toDate();
                break;
            }
            case 'ano-actual': {
                startDate = moment().startOf('year').toDate();
                finishDate = moment().endOf('year').toDate();
                break;
            }
            case 'ano-pasado': {
                finishDate = moment().startOf('year').toDate();
                finishDate.setDate(finishDate.getDate() - 1);
                startDate = moment(finishDate).startOf('year').toDate();
                break;
            }
        }
        return { startDate: startDate, finishDate: finishDate };
    }

    /**
     * Elimina todas las tildes y caracteres extraños, también elimina espacios a los lados y convierte a minúsculas
     * @param texto 
     */
    normalizarString(texto: string) {
        if(!texto) return null;
        texto = this.eliminarDiacriticosEs(texto);
        texto = texto.toLowerCase().trim();
        return texto;
    }

    eliminarDiacriticosEs(texto) {
        if(!texto) return null;
        return texto
               .normalize('NFD')
               .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
               .normalize();
    }

    trimString(texto) {
        if(!texto) return null;
        return texto.trim();
    }
    
    limpiarColumnasResultadosExcel(data: any[], columnasUtilizadas: string[]) {
        try {
            var errores = [];
            var resultados = [];
            data.forEach(element => {
                // Se construyen elementos normalizados
                var elementoNormalizado = {};
                for (const [key, value] of Object.entries(element)) {
                    if(key) {
                        // Se normaliza la propiedad
                        var keyString = key.toString();
                        var columnaFinal: string = this.normalizarString(keyString);
                        if(columnasUtilizadas.indexOf(columnaFinal) == -1) {
                            // La columna que viene no corresponde con una de las que se utilizarán
                            if(this.indexOf(errores, 'id', 'columna-' + key, ) == -1) {
                                errores.push({ id: 'columna-' + key, mensaje: 'La columna ' + key + ' no corresponde a una columna esperada y será ignorada.' });
                            }
                        }
                        var finalValue = value;
                        if(finalValue && typeof finalValue == 'string') finalValue.trim();
                        elementoNormalizado[columnaFinal] = value;
                    }
                }
                resultados.push(elementoNormalizado);
            });
            return {
                errores: errores,
                resultados: resultados,
            }
        } catch(error) {
            return null;
        }
    }

    openModal(link: string) {
        document.getElementById('myModal').style.display = "block";
        (document.getElementById("img01") as HTMLImageElement).src = link;
    }
    closeModal() {
        document.getElementById('myModal').style.display = "none";
    }

    cuiValido(cui) {
        if (!cui) {
            return false;
        }
    
        var cuiRegExp = /^[0-9]{4}\s?[0-9]{5}\s?[0-9]{4}$/;
    
        if (!cuiRegExp.test(cui)) {
            return false;
        }
    
        cui = cui.replace(/\s/, '');
        var depto = parseInt(cui.substring(9, 11), 10);
        var muni = parseInt(cui.substring(11, 13));
        var numero = cui.substring(0, 8);
        var verificador = parseInt(cui.substring(8, 9));
    
        var munisPorDepto = [
            /* 01 - Guatemala tiene:      */ 17 /* municipios. */,
            /* 02 - El Progreso tiene:    */  8 /* municipios. */,
            /* 03 - Sacatepéquez tiene:   */ 16 /* municipios. */,
            /* 04 - Chimaltenango tiene:  */ 16 /* municipios. */,
            /* 05 - Escuintla tiene:      */ 13 /* municipios. */,
            /* 06 - Santa Rosa tiene:     */ 14 /* municipios. */,
            /* 07 - Sololá tiene:         */ 19 /* municipios. */,
            /* 08 - Totonicapán tiene:    */  8 /* municipios. */,
            /* 09 - Quetzaltenango tiene: */ 24 /* municipios. */,
            /* 10 - Suchitepéquez tiene:  */ 21 /* municipios. */,
            /* 11 - Retalhuleu tiene:     */  9 /* municipios. */,
            /* 12 - San Marcos tiene:     */ 30 /* municipios. */,
            /* 13 - Huehuetenango tiene:  */ 32 /* municipios. */,
            /* 14 - Quiché tiene:         */ 21 /* municipios. */,
            /* 15 - Baja Verapaz tiene:   */  8 /* municipios. */,
            /* 16 - Alta Verapaz tiene:   */ 17 /* municipios. */,
            /* 17 - Petén tiene:          */ 14 /* municipios. */,
            /* 18 - Izabal tiene:         */  5 /* municipios. */,
            /* 19 - Zacapa tiene:         */ 11 /* municipios. */,
            /* 20 - Chiquimula tiene:     */ 11 /* municipios. */,
            /* 21 - Jalapa tiene:         */  7 /* municipios. */,
            /* 22 - Jutiapa tiene:        */ 17 /* municipios. */
        ];
    
        if (depto === 0 || muni === 0) {
            return false;
        }
    
        if (depto > munisPorDepto.length) {
            return false;
        }
    
        if (muni > munisPorDepto[depto - 1]) {
            return false;
        }
    
        // Se verifica el correlativo con base
        // en el algoritmo del complemento 11.
        var total = 0;
    
        for (var i = 0; i < numero.length; i++) {
            total += numero[i] * (i + 2);
        }
    
        var modulo = (total % 11);
    
        return modulo === verificador;
    }
    
    /**
     * @param {string} nit - número de DPI
     * @returns {boolean} true para NIT válido y false para NIT no válido
     * */
    nitValido(nit) {
        if (!nit) {
            return false;
        }
    
        var nitRegExp = new RegExp('^[0-9]+(-?[0-9kK])?$');
    
        if (!nitRegExp.test(nit)) {
            return false;
        }
    
        nit = nit.replace(/-/, '');
        var lastChar = nit.length - 1;
        var number = nit.substring(0, lastChar);
        var expectedCheker = nit.substring(lastChar, lastChar + 1).toLowerCase();
    
        var factor = number.length + 1;
        var total = 0;
    
        for (var i = 0; i < number.length; i++) {
            var character = number.substring(i, i + 1);
            var digit = parseInt(character, 10);
    
            total += (digit * factor);
            factor = factor - 1;
        }
    
        var modulus = (11 - (total % 11)) % 11;
        var computedChecker = (modulus == 10 ? "k" : modulus.toString());
    
        return expectedCheker === computedChecker;
    }
}

@Component({
    selector: 'generic-dialog',
    templateUrl: 'generic-dialog/generic-dialog.html',
})
export class GenericDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}