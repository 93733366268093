import { Component, OnInit } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { DateTime } from 'luxon';
import { trackById } from '../../../utils/track-by';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import icClearAll from '@iconify/icons-ic/twotone-clear-all';
import { AuthService } from 'src/app/auth/auth.service';
import { Notificacion } from 'src/app/models/notificacion';
import { Router } from '@angular/router';

@Component({
    selector: 'vex-toolbar-notifications-dropdown',
    templateUrl: './toolbar-notifications-dropdown.component.html',
    styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit {

    icSettings = icSettings;
    icChevronRight = icChevronRight;
    icClearAll = icClearAll;
    icNotificationsOff = icNotificationsOff;
    trackById = trackById;
    cargando: boolean = false;

    constructor(
        public auth: AuthService,
        private route: Router
    ) { }

    ngOnInit() {
    }

    async obtenerRuta(notificacion:Notificacion){
        notificacion.isRead = true;
        this.route.navigate(["/usuarios/perfil"]);
        await this.auth.marcarLeido(notificacion.id);
        await this.eliminarNotificacion(notificacion.id);
    }

    async eliminarNotificacion(id:number){
        let Index = 0;
        this.auth.ultimasNotficaciones.forEach((element,index)=>{
            if(element.id === id){
                Index = index;
            }
        });
        this.auth.ultimasNotficaciones.splice(Index,1); 
    }

}
