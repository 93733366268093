import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { LibraryService } from 'src/app/library/library.service';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconEdit from '@iconify/icons-fa-solid/edit';
import { PolizasService } from '../polizas.service';
import { Poliza } from 'src/app/models/poliza';
import { RazonPerdida } from 'src/app/models/razonPerdida';
import { ProspectosService } from 'src/app/prospectos/prospectos.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    registro: Poliza,
}

@Component({
    selector: 'vex-dialog-cancelar-poliza',
    templateUrl: './dialog-cancelar-poliza.component.html',
    styleUrls: ['./dialog-cancelar-poliza.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogCancelarPolizaComponent implements OnInit {
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconEdit = iconEdit;
    cargando: boolean = false;
    titulo:string = '';
    razonesPerdidas: RazonPerdida[] = [];

    constructor(
        public dialogRef: MatDialogRef<DialogCancelarPolizaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private prospectosService: ProspectosService,
        private service: PolizasService,
        private libraryService: LibraryService,
        private router: Router,
        public dialog: MatDialog,
        private auth: AuthService
    ) { }

    ngOnInit(): void {
        this.obtenerRazonesPerdidas();
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close({
            FechaCancelacion: this.data.registro.FechaCancelacion,
            RazonPerdidaId: this.data.registro.RazonPerdidaId,
        });
    }

    async obtenerRazonesPerdidas() {
        this.cargando = true;
        var res = await this.prospectosService.obtenerTodasRazonesPerdidas();
        if(!res.error) {
            this.razonesPerdidas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    actualizarFechaVigenciaFin($event){
        if($event && $event.value) {
            this.data.registro.fechaVigenciaFin = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    actualizarFechaCancelacion($event){
        if($event && $event.value) {
            this.data.registro.FechaCancelacion = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }
}
