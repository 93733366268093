
export class FormularioCliente {
    constructor(
        public id: number,
        public nombre: string,
        public descripcion: string,
        public plantilla1: string,
        public plantilla2: string,
        public EmpresaId: number,
    ){}

    // Archivos 
    rutaPlantilla1: string;
    archivoPlantilla1: File;
    modoSubirPlantilla1: boolean = true;
    rutaPlantilla2: string;
    archivoPlantilla2: File;
    modoSubirPlantilla2: boolean = true;
}