import { Injectable } from '@angular/core';
import { Usuario } from '../models/usuario';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { Agrupador } from '../models/agrupador';
import { InformacionCliente } from '../models/informacionCliente';
import * as Papa from 'papaparse';
import * as FileSaver from 'file-saver';
import { SolicitudCliente } from '../models/solicitudCliente';
import { DireccionCliente } from '../models/direccionCliente';
import { ConfiguracionEmpresa } from '../models/configuracionEmpresa';
import { Ocupacion } from '../models/ocupacion';
import { AccionistaCliente } from '../models/accionistaCliente';

@Injectable()
export class ClientesService {
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) {
        //Papa Promise
        Papa.parsePromise = function(file) {
            return new Promise(function(complete, error) {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete,
                    error
                });
            });
        };
    }

    // * * * * * Obtener datos * * * * *
    // Obtener cliente con su información
    async obtenerClientePorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.informacionClientesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.usuarioDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Obtener agrupador
    async obtenerAgrupadorPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.agrupadoresURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.agrupadorDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Obtener tipos de documentos
    public async obtenerTiposDocumentos() {
        try {
            var registros = [
                { id: 'DPI', nombre: 'DPI' },
                { id: 'CEDULA', nombre: 'Cédula' },
                { id: 'PASAPORTE', nombre: 'Pasaporte' },
            ];
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los tipos de documento.');
        }
    }

    public async obtenerClasificaciones() {
        try {
            var registros = this.apiUrls.clasificaciones;
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los tipos de documento.');
        }
    }

    public async obtenerClases() {
        try {
            var registros = this.apiUrls.clases;
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los tipos de documento.');
        }
    }

    public async obtenerTiposClientes() {
        try {
            var registros = this.apiUrls.tiposClientes;
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los tipos de documento.');
        }
    }

    public async obtenerPaises() {
        try {
            return { error: null, data: { registros: this.apiUrls.paises } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los países.');
        }
    }

    public async validarDocumentosCliente(nit, numeroDocumento, pasaporte, InformacionClienteId, tipoCliente: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.informacionClientesURL + '/validarIdentificacion';
            let data = {
                nit: nit,
                numeroDocumento: numeroDocumento,
                pasaporte: pasaporte,
                InformacionClienteId: InformacionClienteId,
                tipoCliente: tipoCliente,
            };
            const res = await this.connection.postRequest(url, { data: data });
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Editar datos * * * * *
    // Crear un nuevo cliente
    public async crearCliente(registro: Usuario) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.informacionClientesURL;
            const res = await this.connection.postRequest(url, { data: registro });
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Editar cliente existente
    public async actualizarCliente(registro: Usuario) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.informacionClientesURL;
            const res = await this.connection.putRequest(url, { data: registro });
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Desactivar un cliente
    public async desactivarCliente(UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.informacionClientesURL + '/desactivar';
            const res = await this.connection.postRequest(url, { UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Cliente desactivado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Activar un cliente
    public async activarCliente(UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.informacionClientesURL + '/activar';
            const res = await this.connection.postRequest(url, { UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Cliente activado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    calcularRiesgoCliente(cliente: Usuario, configuracion: ConfiguracionEmpresa, ocupaciones: Ocupacion[]) {
        try {
            var informacionCliente = cliente.informacionCliente;
            var riesgoTotal = 0;
            var totalFactores = 0;
            var tablaFactores = [];

            // Tipo de cliente
            if(informacionCliente.nivelRiesgoTipoCliente) {
                riesgoTotal += informacionCliente.nivelRiesgoTipoCliente;
                totalFactores++;
                tablaFactores.push({ factor: 'Tipo de cliente', riesgo: informacionCliente.nivelRiesgoTipoCliente });
            }

            // Ciudadanía
            if(informacionCliente.nivelRiesgoCiudadania) {
                riesgoTotal += informacionCliente.nivelRiesgoCiudadania;
                totalFactores++;
                tablaFactores.push({ factor: 'Ciudadanía', riesgo: informacionCliente.nivelRiesgoCiudadania });
            }

            // País de nacimiento
            if(informacionCliente.nivelRiesgoPaisNacimiento) {
                riesgoTotal += informacionCliente.nivelRiesgoPaisNacimiento;
                totalFactores++;
                tablaFactores.push({ factor: 'País de nacimiento', riesgo: informacionCliente.nivelRiesgoPaisNacimiento });
            }

            // Actividad generadora de recursos
            if(informacionCliente.nivelRiesgoActividadGeneradoraRecursos) {
                riesgoTotal += informacionCliente.nivelRiesgoActividadGeneradoraRecursos;
                totalFactores++;
                tablaFactores.push({ factor: 'Actividad generadora de recursos', riesgo: informacionCliente.nivelRiesgoActividadGeneradoraRecursos });
            }

            // País donde realiza la actividad económica
            if(informacionCliente.nivelRiesgoPaisActividadEconomica) {
                riesgoTotal += informacionCliente.nivelRiesgoPaisActividadEconomica;
                totalFactores++;
                tablaFactores.push({ factor: 'País donde realiza la actividad económica', riesgo: informacionCliente.nivelRiesgoPaisActividadEconomica });
            }

            // Tipo de activos propios de la actividad del cliente
            if(informacionCliente.nivelRiesgoTipoActivoPropio) {
                riesgoTotal += informacionCliente.nivelRiesgoTipoActivoPropio;
                totalFactores++;
                tablaFactores.push({ factor: 'Tipo de activos propios de la actividad del cliente', riesgo: informacionCliente.nivelRiesgoTipoActivoPropio });
            }

            // Productos y servicios utilizados
            if(informacionCliente.nivelRiesgoProductos) {
                riesgoTotal += informacionCliente.nivelRiesgoProductos;
                totalFactores++;
                tablaFactores.push({ factor: 'Productos y servicios utilizados', riesgo: informacionCliente.nivelRiesgoProductos });
            }

            // Carácter
            if(informacionCliente.nivelRiesgoCaracter) {
                riesgoTotal += informacionCliente.nivelRiesgoCaracter;
                totalFactores++;
                tablaFactores.push({ factor: 'Carácter', riesgo: informacionCliente.nivelRiesgoCaracter });
            }

            // Valor del monto asegurado
            if(informacionCliente.nivelRiesgoValorAsegurado) {
                riesgoTotal += informacionCliente.nivelRiesgoValorAsegurado;
                totalFactores++;
                tablaFactores.push({ factor: 'Valor del monto asegurado', riesgo: informacionCliente.nivelRiesgoValorAsegurado });
            }

            // Medio de pago
            if(informacionCliente.nivelRiesgoMedioPago) {
                riesgoTotal += informacionCliente.nivelRiesgoMedioPago;
                totalFactores++;
                tablaFactores.push({ factor: 'Medio de pago', riesgo: informacionCliente.nivelRiesgoMedioPago });
            }

            // Por tipo de ingresos u origen de los recursos
            if(informacionCliente.nivelRiesgoTipoIngresos) {
                riesgoTotal += informacionCliente.nivelRiesgoTipoIngresos;
                totalFactores++;
                tablaFactores.push({ factor: 'Por tipo de ingresos u origen de los recursos', riesgo: informacionCliente.nivelRiesgoTipoIngresos });
            }

            if(!totalFactores) return null;
            var riesgoPromedio = riesgoTotal / totalFactores;
            return {
                riesgoPromedio: this.libraryService.redondearNumero2Decimales(riesgoPromedio),
                tablaFactores: tablaFactores,
            };
        } catch (error) {
            return null;
        }
    }

    obtenerRiesgoFactorConfiguracion(arreglo, valor, campo = 'nombre') {
        var riesgo = 0, factor = 0;

        var objeto = this.libraryService.objectAtIndexOf(arreglo, campo, valor);
        if(objeto) {
            riesgo = objeto.riesgo;
            factor = 1;
        }

        return { riesgo: riesgo, factor: factor };
    }

    obtenerNombreRiesgo(riesgo: number, configuracion: ConfiguracionEmpresa) {
        let nombre = 'Bajo';
        if(configuracion && configuracion.rangosRiesgoArray && riesgo) {
            for (const el of configuracion.rangosRiesgoArray) {
                if(riesgo <= el.maximo) {
                    nombre = el.nivel;
                    break;
                }
                else {
                    nombre = el.nivel;
                }
            }
        }
        return 'Riesgo ' + nombre;
    }

    colorRiesgo(riesgo: number, configuracion: ConfiguracionEmpresa) {
        let nombreRiesgo = this.obtenerNombreRiesgo(riesgo, configuracion);
        if(nombreRiesgo == 'Riesgo Bajo') return 'green';
        else if(nombreRiesgo == 'Riesgo Medio') return '#dba100';
        else return 'red';
    }

    // * * * * * Agrupadores * * * * *
    // Crear un nuevo cliente
    public async crearAgrupador(registro: Agrupador) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.agrupadoresURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Editar cliente existente
    public async actualizarAgrupador(registro: Agrupador) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.agrupadoresURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Eliminar
    public async eliminarAgrupador(AgrupadorId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.agrupadoresURL + '/id/' + AgrupadorId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Agrupador eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Obtener agrupadores
    async obtenerTodosAgrupadores() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.agrupadoresURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.agrupadorDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los agrupadores.');
        }
    }

    // Obtener clientes
    async obtenerTodosClientes() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.informacionClientesURL + '/todosClientes';
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.usuarioDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los clientes.');
        }
    }

    async obtenerTodosClientesAgrupador(AgrupadorId) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.informacionClientesURL + '/todosClientesAgrupador/' + AgrupadorId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.usuarioDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los clientes.');
        }
    }

    // Obtener formularios
    async obtenerTodosFormulariosClientes() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.formulariosClientesURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.formularioClienteDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los agrupadores.');
        }
    }

    // * * * * * Importación de datos * * * * *
    async lecturaImportacionAgrupadores(archivo, EmpresaId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = ['nombre', 'linea'];
            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;

            // Preparar resultados según modelo
            var resultadosFinales = [];
            resultados.forEach(element => {
                var nombre = element['nombre'];
                if(nombre) nombre = nombre.trim();
                var elementoFinal = new Agrupador(null, nombre, null, null, EmpresaId);
                // Línea
                var indexLinea = this.libraryService.indexOf(this.apiUrls.lineasAgrupadores, 'nombre', element['linea']);
                if(indexLinea != -1) elementoFinal.linea = this.apiUrls.lineasAgrupadores[indexLinea].id;
                else if(element['linea']) elementoFinal.linea = this.libraryService.normalizarString(element['linea']);

                resultadosFinales.push(elementoFinal);
            });

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async analizarImportacionAgrupadores(registros: Agrupador[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.agrupadoresURL + '/analisisImportacionAgrupadores';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var errores = res.errores;

            return { error: null, data: {
                mensaje: 'Importación analizada con éxito',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async subirImportacionAgrupadores(registros: Agrupador[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.agrupadoresURL + '/subirImportacionAgrupadores';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Importación de datos clientes * * * * *
    async lecturaImportacionClientes(archivo, agrupadores: Agrupador[], agentes: Usuario[], EmpresaId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = [
                'tipo', 'agente', 'nombre', 'apellido', 'direccion principal', 'telefono principal', 'codigo agente',
                'movil', 'correo electronico 1', 'correo electronico 2', 'agrupador', 'lugar de cobro',
                'lugar de correspondencia', 'direccion fiscal', 'direccion adicional', 'nit', 'fecha de ingreso', 'fecha de nacimiento'
            ];

            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;

            // Preparar resultados según modelo
            var trim = this.libraryService.trimString;
            var resultadosFinales = [];
            let filaLectura = 1;
            resultados.forEach(element => {
                // Usuario
                var elementoFinal = new Usuario(
                    null, null, trim(element['nombre']), trim(element['apellido']),
                    null, null, trim(element['correo electronico 1']), trim(element['correo electronico 2']),
                    trim(element['telefono principal']), trim(element['movil']), trim(element['direccion principal']),
                    true, false, true, false, false, false, false, false, false,
                    null, null, null, null, null, null, EmpresaId
                );
                // Información de cliente
                elementoFinal.informacionCliente = new InformacionCliente(
                    null, null, null, null, true, trim(element['nit']), null, trim(element['direccion fiscal']),
                    false, null, null, null, trim(element['direccion trabajo']), trim(element['direccion adicional']), null, trim(element['lugar de cobro']),
                    trim(element['lugar de correspondencia']), null, null, null, null, null,null,
                    null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,
                    null, null, null, null, null, null, null, null, null, null, null, null, null, 
                    null, null, null, null, null, null, null, null, null, null, null
                );

                // Tipo de cliente
                var indexTipo = this.libraryService.indexOf(this.apiUrls.tiposClientes, 'nombre', element['tipo']);
                if(indexTipo != -1) elementoFinal.informacionCliente.tipo = this.apiUrls.tiposClientes[indexTipo].id;
                else {
                    errores.push({ mensaje: 'El tipo de cliente ' + element['tipo'] + ' no es válido en la fila ' + filaLectura });
                }

                // Validaciones de datos
                if(!elementoFinal.correo1) errores.push({ mensaje: 'El correo electrónico 1 es requerido para el registro en la fila ' + filaLectura });
                if(!elementoFinal.nombre) errores.push({ mensaje: 'El nombre es requerido para el registro en la fila ' + filaLectura });
                if(!elementoFinal.apellido) errores.push({ mensaje: 'El apellido es requerido para el registro en la fila ' + filaLectura });

                // Dividir nombre y apellido
                // if(elementoFinal.nombre) {
                //     var partesNombre = elementoFinal.nombre.split(' ');
                //     if(partesNombre.length >= 2 && elementoFinal.informacionCliente.tipo == 'individual') {
                //         var posicionesNombre = 1;
                //         if(partesNombre.length > 2) posicionesNombre = 2;
                //         var nombre = '', apellido = '';
                //         for (let i = 0; i < posicionesNombre; i++) nombre += partesNombre[i] + ' ';
                //         for (let i = posicionesNombre; i < partesNombre.length; i++) apellido += partesNombre[i] + ' ';
                //         nombre = nombre.trim();
                //         apellido = apellido.trim();
                //         elementoFinal.nombre = nombre;
                //         elementoFinal.apellido = apellido;
                //     }
                // }

                // Agrupador
                if(agrupadores && element['agrupador']) {
                    var indexAgrupador = this.libraryService.indexOf(agrupadores, 'nombre', element['agrupador']);
                    if(indexAgrupador != -1) elementoFinal.AgrupadorId = agrupadores[indexAgrupador].id;
                    else {
                        errores.push({ mensaje: 'No se encontró al agrupador con el nombre ' + element['agrupador'] + ' en la fila ' + filaLectura });
                    }
                }

                // Agente
                if(agentes && element['codigo agente']) {
                    var indexAgente = this.libraryService.indexOf(agentes, 'codigo', element['codigo agente']);
                    if(indexAgente != -1) elementoFinal.agentesIds = [agentes[indexAgente].id];
                    else {
                        errores.push({ mensaje: 'No se encontró al agente con el código ' + element['codigo agente'] + ' en la fila ' + filaLectura });
                    }
                }

                // Cliente desde / Fecha de ingreso
                var fechaIngresoOriginal = trim(element['fecha de ingreso']);
                var fechaFinal = null;
                if(fechaIngresoOriginal) fechaFinal = this.libraryService.convertirFecha(fechaIngresoOriginal, 'DD/MM/YYYY', 'YYYY-MM-DD');
                elementoFinal.informacionCliente.clienteDesde = fechaFinal;

                // Fecha de nacimiento
                var fechaNacimientoOriginal = trim(element['fecha de nacimiento']);
                var fechaFinalNacimiento = null;
                if(fechaNacimientoOriginal) fechaFinalNacimiento = this.libraryService.convertirFecha(fechaNacimientoOriginal, 'DD/MM/YYYY', 'YYYY-MM-DD');
                elementoFinal.fechaNacimiento = fechaFinalNacimiento;
                if(elementoFinal.informacionCliente.tipo == 'individual' && !fechaFinalNacimiento) errores.push({ mensaje: 'La fecha de nacimiento es requerida para el registro en la fila ' + filaLectura });

                let nitLimpio = this.libraryService.limpiarNumeroIdentificacion(elementoFinal.informacionCliente.nit);
                elementoFinal.informacionCliente.nit = nitLimpio;
                let pasaporteLimpio = this.libraryService.limpiarNumeroIdentificacion(elementoFinal.informacionCliente.pasaporte);
                elementoFinal.informacionCliente.pasaporte = pasaporteLimpio;
                let numeroDocumentoLimpio = this.libraryService.limpiarNumeroIdentificacion(elementoFinal.informacionCliente.numeroDocumento);
                elementoFinal.informacionCliente.numeroDocumento = numeroDocumentoLimpio;

                resultadosFinales.push(elementoFinal);
                filaLectura++;
            });

            // Validar duplicados dentro del archivo
            let fila = 1;
            for (let el of resultadosFinales) {
                if(el.informacionCliente) {
                    if(!el.informacionCliente.nit) errores.push({ mensaje: 'El NIT es requerido para el registro en la fila ' + fila });
                    if(el.informacionCliente.nit && resultadosFinales.filter(x => x.informacionCliente.nit == el.informacionCliente.nit).length > 1) {
                        errores.push({ mensaje: 'El NIT ' + el.informacionCliente.nit + ' está duplicado en el archivo.' });
                    }
                    if(el.informacionCliente.numeroDocumento && resultadosFinales.filter(x => x.informacionCliente.numeroDocumento == el.informacionCliente.numeroDocumento).length > 1) {
                        errores.push({ mensaje: 'El número de documento ' + el.informacionCliente.numeroDocumento + ' está duplicado en el archivo.' });
                    }
                    if(el.informacionCliente.pasaporte && resultadosFinales.filter(x => x.informacionCliente.pasaporte == el.informacionCliente.pasaporte).length > 1) {
                        errores.push({ mensaje: 'El pasaporte ' + el.informacionCliente.pasaporte + ' está duplicado en el archivo.' });
                    }
                }
                fila++;
            }

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async analizarImportacionClientes(registros: Usuario[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.informacionClientesURL + '/analisisImportacionClientes';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var errores = res.errores;

            return { error: null, data: {
                mensaje: 'Importación analizada con éxito',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async subirImportacionClientes(registros: Usuario[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.informacionClientesURL + '/subirImportacionClientes';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Administradores y acceso a agrupador * * * * *
    // Crear un nuevo cliente
    public async crearAdministradorAgrupador(registro: Usuario) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/administradorAgrupador';
            const res = await this.connection.postRequest(url, { data: registro });
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Solicitudes de clientes * * * * *
    async obtenerSolicitudClientePorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesClientesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.solicitudClienteDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async actualizarSolicitudCliente(registro: SolicitudCliente) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesClientesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async eliminarSolicitudCliente(SolicitudClienteId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.solicitudesClientesURL + '/id/' + SolicitudClienteId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Solicitud eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Archivos * * * * *
    // Subir archivo al servidor
    async guardarArchivoEnServidorClientes(archivo: File){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/uploadArchivoClientes';
            const res = await this.connection.uploadFile(url, archivo, true);
            if(!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }

    async descargarExcelPlantillaClientes(nombreArchivo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.informacionClientesURL + '/plantillaExcel';

            var res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `${nombreArchivo}.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Plantilla descargada con éxito' } };

        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async obtenerAnalyticsClientes(params: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.informacionClientesURL + '/analytics';
            url += '?' + params;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { data: json.results } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los analytics.');
        }
    }

    async obtenerTodosContactosFiltros(idsPolizas: number[], idsClientes: number[]) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.informacionClientesURL + '/contactosFiltros?';
            if(idsPolizas && idsPolizas.length > 0) url += '&idsPolizas=' + idsPolizas.toString();
            if(idsClientes && idsClientes.length > 0) url += '&idsClientes=' + idsClientes.toString();
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.contactoUsuarioDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los agrupadores.');
        }
    }

    public async descargarExcelAnalytics(params: string) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.informacionClientesURL + '/analyticsExcel';
            url += '?' + params;

            const res = await this.connection.getDownloadRequest(url);

            // Descargar archivo
            var filename = `Clientes.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Archivo descargado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async descargarExcelClientes(params: string) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.informacionClientesURL + '/search-excel';
            url += params;

            const res = await this.connection.getRequest(url);

            return { error: null, data: { mensaje: 'Archivo enviado con éxito', data: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    obtenerColorEstadoCliente(cliente: Usuario) {
        if(!cliente) return '#000000';
        if(cliente.activo) return 'green';
        else return 'red';
    }

    // * * * * * Direcciones * * * * *
    async obtenerTodosDireccionesClientes(UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.direccionesClientesURL;
            if(UsuarioId) url += '?&UsuarioId=' + UsuarioId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.direccionClienteDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las razones de pérdida.');
        }
    }

    async obtenerDireccionClientePorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.direccionesClientesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.direccionClienteDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async activarInactivarDireccionClientePorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.direccionesClientesURL + '/cambiarActivo/' + id;
            var json = await this.connection.getRequest(url);
            return { error: null, data: {} };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearDireccionCliente(registro: DireccionCliente) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.direccionesClientesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarDireccionCliente(registro: DireccionCliente) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.direccionesClientesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarDireccionCliente(RegistroId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.direccionesClientesURL + '/id/' + RegistroId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async descargarExcelOficialia(fechaFin: string, fechaInicio: string, recalcular: boolean) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.informacionClientesURL + '/reporteOficialia';
            var data = {
                fechaFin: fechaFin,
                fechaInicio: fechaInicio,
                recalcular: recalcular,
            }
            
            const res = await this.connection.postRequestDownload(url, data);
            
            // Descargar archivo
            var filename = `Reporte oficialía.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Archivo descargado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async descargarExcelMatrizRiesgo(fechaFin: string, fechaInicio: string, recalcular: boolean) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.informacionClientesURL + '/reporteRiesgo';
            var data = {
                fechaFin: fechaFin,
                fechaInicio: fechaInicio,
                recalcular: recalcular,
            }
            
            const res = await this.connection.postRequestDownload(url, data);
            
            // Descargar archivo
            var filename = `Reporte matriz de riesgo.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Archivo descargado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async descargarExcelMatrizRiesgoHistorico(fechaFin: string, fechaInicio: string, ClienteId: number) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.informacionClientesURL + '/reporteRiesgoHistorico';
            var data = {
                fechaFin: fechaFin,
                fechaInicio: fechaInicio,
                ClienteId: ClienteId,
            }
            
            const res = await this.connection.postRequestDownload(url, data);
            
            // Descargar archivo
            var filename = `Reporte matriz de riesgo.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Archivo descargado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Accionistas de cliente * * * * *
    async obtenerAccionistaClientePorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.accionistasClientesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.accionistaClienteDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearAccionistaCliente(registro: AccionistaCliente) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.accionistasClientesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarAccionistaCliente(registro: AccionistaCliente) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.accionistasClientesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarAccionistaCliente(AccionistaClienteId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.accionistasClientesURL + '/id/' + AccionistaClienteId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Registro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }
}
