import { ChangeDetectorRef } from '@angular/core';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { APIUrls } from 'src/app/api/apiUrls';
import { AuthService } from 'src/app/auth/auth.service';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { GenericDialogConfirmacion } from '../generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { LibraryService } from '../library.service';
import iconClose from '@iconify/icons-fa-solid/times';
import { PolizasService } from 'src/app/polizas/polizas.service';

export interface DialogDataSegurencias {
    columnas:           any[],
    fieldSearch:        any[],
    url:                string,
    placeholder:        any,
    parser:             any,
    parserService:      any,
    paramsEspeciales:   string,
    seleccionados:      any[],
    multiSelect:        boolean,
    titleProp:          string,
    colorFila:          string,
    posiblesEstados:    any[],
}

@Component({
    selector: 'vex-dialog-sugerencias',
    templateUrl: './dialog-sugerencias.component.html',
    styleUrls: ['./dialog-sugerencias.component.scss']
})

export class DialogSugerenciasComponent implements OnInit {

    mostrarTabla: boolean = true;

    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;
    @ViewChild('cellBotones', { static: true }) cellBotones: TemplateRef<any>;

    apiURLs = new APIUrls();
    jsonConverters = new JSONConverters();
    iconClose = iconClose;

    constructor(
        public polizasService: PolizasService,
        private route: ActivatedRoute,
        private titleService: Title,
        public dialogRef: MatDialogRef<GenericDialogConfirmacion>,
        public auth: AuthService,
        public libraryService: LibraryService,
        private changeDetector: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: DialogDataSegurencias,
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    async inicializarDatos(params: Params){
        await this.agregarFiltros();
        this.agregarTemplates();
        this.mostrarTabla = true;
    }


    agregarTemplates() {
        this.data.columnas.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
    }

    async agregarFiltros() {
        
    }

    calcularParamsEspeciales(): string {
        var paramsEspeciales = '';
        if(this.data.paramsEspeciales){
            paramsEspeciales += (this.data.paramsEspeciales.startsWith('&') ? '' : '&') + this.data.paramsEspeciales;
        }
        return paramsEspeciales;
    }

    cerrar() {
        this.dialogRef.close(this.data.seleccionados);
    }

    click($event){
        if(this.data.seleccionados && this.data.seleccionados.length > 0 && $event.id != undefined){
            let existe = false;
            for(let registro of this.data.seleccionados){
                if($event.id == registro.id) {
                    existe = true;
                    break;
                }
            }

            if(!existe) {
                if(this.data.multiSelect){
                    this.data.seleccionados.push($event);
                    this.changeDetector.detectChanges();
                } else {
                    this.data.seleccionados = [$event];
                    this.changeDetector.detectChanges();
                    this.dialogRef.close(this.data.seleccionados);
                }
            }
            // else        this.libraryService.crearNotificacion('El registro deseado ya esta seleccionado, por favor seleccione otro registro.', 'danger');
        }
        else {
            this.data.seleccionados = [$event];
            this.changeDetector.detectChanges();
            this.dialogRef.close(this.data.seleccionados);
        }
    }

    eliminarSeleccionado(index) {
        this.data.seleccionados.splice(index, 1);
        this.changeDetector.detectChanges();
    }

    obtenerColorFila(row) {
        if(this.data.colorFila == 'estado-certificado') {
            return this.polizasService.obtenerColorEstadoCertificado(row.nombreEstado);
        }
        if(this.data.colorFila == 'estado-poliza') {
            return this.polizasService.obtenerColorEstado(row.estado, row);
        }
        else return null;
    }
}
