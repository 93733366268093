import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { PlantillaMapaProceso } from '../models/plantillaMapaProceso';
import { PasoMapaProceso } from '../models/pasoMapaProceso';
import * as FileSaver from 'file-saver';

@Injectable()
export class MapasProcesosService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    async obtenerMapaProcesoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mapasProcesosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.mapaProcesoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Actualizar paso de mapa
    public async actualizarPasoMapaProceso(pasoMapaProceso: PasoMapaProceso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mapasProcesosURL + '/paso';
            const res = await this.connection.postRequest(url, pasoMapaProceso);
            return { error: null, data: { mensaje: 'Paso actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar paso de mapa
    public async asignarPasoMapaProceso(PasoMapaProcesoId: number, MapaProcesoId: number, UsuarioResponsableId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mapasProcesosURL + '/responsablePaso';
            var data = {
                PasoMapaProcesoId: PasoMapaProcesoId,
                MapaProcesoId: MapaProcesoId,
                UsuarioResponsableId: UsuarioResponsableId,
            }
            const res = await this.connection.postRequest(url, data);
            return { error: null, data: { mensaje: 'Usuario responsable actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar paso de mapa
    public async observacionesPasoMapaProceso(PasoMapaProcesoId: number, MapaProcesoId: number, observaciones: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mapasProcesosURL + '/observacionesPaso';
            var data = {
                PasoMapaProcesoId: PasoMapaProcesoId,
                MapaProcesoId: MapaProcesoId,
                observaciones: observaciones,
            }
            const res = await this.connection.postRequest(url, data);
            return { error: null, data: { mensaje: 'Observaciones actualizadas con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar paso de mapa
    public async marcarAceptadoPasoMapaProceso(PasoMapaProcesoId: number, MapaProcesoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mapasProcesosURL + '/aceptadoPaso';
            var data = {
                PasoMapaProcesoId: PasoMapaProcesoId,
                MapaProcesoId: MapaProcesoId,
            }
            const res = await this.connection.postRequest(url, data);
            return { error: null, data: { mensaje: 'Paso aceptado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar paso de mapa
    public async marcarCompletadoPasoMapaProceso(PasoMapaProcesoId: number, MapaProcesoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mapasProcesosURL + '/completadoPaso';
            var data = {
                PasoMapaProcesoId: PasoMapaProcesoId,
                MapaProcesoId: MapaProcesoId,
            }
            const res = await this.connection.postRequest(url, data);
            return { error: null, data: { mensaje: 'Paso completado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async revertirCompletadoPasoMapaProceso(PasoMapaProcesoId: number, MapaProcesoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mapasProcesosURL + '/revertirCompletadoPaso';
            var data = {
                PasoMapaProcesoId: PasoMapaProcesoId,
                MapaProcesoId: MapaProcesoId,
            }
            const res = await this.connection.postRequest(url, data);
            return { error: null, data: { mensaje: 'Paso revertido con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async duplicarPasoMapaProceso(PasoMapaProcesoId: number, MapaProcesoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mapasProcesosURL + '/duplicarPaso';
            var data = {
                PasoMapaProcesoId: PasoMapaProcesoId,
                MapaProcesoId: MapaProcesoId,
            }
            const res = await this.connection.postRequest(url, data);
            return { error: null, data: { mensaje: 'Paso duplicado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarMapaProceso(MapaProcesoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mapasProcesosURL + '/id/' + MapaProcesoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Mapa de proceso eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Cancelar mapa
    public async cancelarMapaProceso(MapaProcesoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mapasProcesosURL + '/cancelar';
            const res = await this.connection.postRequest(url, { MapaProcesoId: MapaProcesoId });
            return { error: null, data: { mensaje: 'Mapa de proceso cancelado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerPlantillaMapaProcesoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasMapasProcesosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.plantillaMapaProcesoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearPlantillaMapaProceso(registro: PlantillaMapaProceso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasMapasProcesosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Crear
    public async crearMapaProceso(PlantillaMapaProcesoId: number, PolizaId: number, idsCertificados: number[], observaciones: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mapasProcesosURL;
            var data = {
                PlantillaMapaProcesoId: PlantillaMapaProcesoId,
                PolizaId: PolizaId,
                idsCertificados: idsCertificados,
                observaciones: observaciones,
            };
            const res = await this.connection.postRequest(url, data);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarPlantillaMapaProceso(registro: PlantillaMapaProceso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasMapasProcesosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarPlantillaMapaProceso(PlantillaMapaProcesoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasMapasProcesosURL + '/id/' + PlantillaMapaProcesoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Plantilla eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Desactivar
    public async desactivarPlantillaMapaProceso(PlantillaMapaProcesoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasMapasProcesosURL + '/desactivar';
            const res = await this.connection.postRequest(url, { PlantillaMapaProcesoId: PlantillaMapaProcesoId });
            return { error: null, data: { mensaje: 'Plantilla de mapa de proceso desactivada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Activar
    public async activarPlantillaMapaProceso(PlantillaMapaProcesoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasMapasProcesosURL + '/activar';
            const res = await this.connection.postRequest(url, { PlantillaMapaProcesoId: PlantillaMapaProcesoId });
            return { error: null, data: { mensaje: 'Plantilla de mapa de proceso activada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async guardarPuntuacionPaso(puntuacion: number, id: number) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mapasProcesosURL + '/puntuacionPaso';
            const res = await this.connection.postRequest(url, {
                puntuacion: puntuacion, id: id,
            });
            return { error: null, data: { mensaje: 'Paso actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async guardarNumeroAseguradora(numeroAseguradora: string, id: number) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mapasProcesosURL + '/numeroAseguradora';
            const res = await this.connection.postRequest(url, {
                numeroAseguradora: numeroAseguradora, id: id,
            });
            return { error: null, data: { mensaje: 'Mapa de proceso actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Obtener datos * * * * *
    async obtenerTodasPlantillasRamo(RamoId: number = null) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasMapasProcesosURL;
            if(RamoId) url += '/ramo/' + RamoId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.plantillaMapaProcesoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las plantillas de mapas de proceso.');
        }
    }

    // * * * * * Archivos * * * * *
    async guardarArchivoEnServidorPlantillasMapasProcesos(archivo: File){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasMapasProcesosURL + '/uploadArchivo';
            const res = await this.connection.uploadFile(url, archivo, true);
            if(!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }

    async obtenerAnalyticsMapasProcesos(params: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mapasProcesosURL + '/analytics';
            url += '?' + params;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { data: json.results } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los analytics.');
        }
    }

    async enviarExcelSolicitudes(params = null){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mapasProcesosURL + '/analytics-excel';
            if (params) url += '?' + params;
            var res = await this.connection.getRequest(url);

            return { error: null, data: res };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async descargarExcelReporteMapas(params: string) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.mapasProcesosURL + '/reporteMapas?' + params;
            var data = {
            }
            
            const res = await this.connection.postRequestDownload(url, data);
            
            // Descargar archivo
            var filename = `Reporte mapas en proceso.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Archivo descargado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
}