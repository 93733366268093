import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconSave from '@iconify/icons-fa-solid/save';
import iconEdit from '@iconify/icons-fa-solid/edit';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconSearch from '@iconify/icons-fa-solid/search';
import iconCandadoDetalle from '@iconify/icons-fa-solid/lock';
import iconCandadoEditar from '@iconify/icons-fa-solid/lock-open';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog, PageEvent } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { CobrosService } from '../cobros.service';
import { Aseguradora } from 'src/app/models/aseguradora';
import { Moneda } from 'src/app/models/moneda';
import { EmpresasService } from 'src/app/empresas/empresas.service';
import { AseguradorasService } from 'src/app/aseguradoras/aseguradoras.service';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { Producto } from 'src/app/models/producto';
import { RamosService } from 'src/app/ramos/ramos.service';
import { Usuario } from 'src/app/models/usuario';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { DialogEditarCobroComisionesComponent } from '../dialog-editar-cobro-comisiones/dialog-editar-cobro-comisiones.component';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-liquidacion-cobros',
    templateUrl: './liquidacion-cobros.component.html',
    styleUrls: ['./liquidacion-cobros.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class LiquidacionCobrosComponent implements OnInit {
    @ViewChild('cellMoneda', { static: true }) cellMoneda: TemplateRef<any>;

    // Íconos
    iconSave = iconSave;
    iconEdit = iconEdit;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconSearch = iconSearch;
    iconCandadoDetalle = iconCandadoDetalle;
    iconCandadoEditar = iconCandadoEditar;
    
    // Banderas y generales
    cargando: boolean = false;
    titulo: string = 'Liquidación de requerimientos';
    apiURLs: APIUrls = new APIUrls();
    jsonConverters = new JSONConverters();
    mostrarTabla: boolean = false;

    // Fechas
    rangosFechas = this.apiURLs.rangosFechas;
    rangoFecha: string = this.rangosFechas[0].id;
    fechaInicioDate: Date;
    fechaInicioParam: string;
    fechaFinDate: Date;
    fechaFinParam: string;
    idsAseguradoras: number[] = [];
    idsProductos: number[] = [];
    idsMonedas: number[] = [];
    idsAgentes: number[] = [];
    numeroPoliza: string;

    aseguradoras: Aseguradora[];
    productos: Producto[];
    monedas: Moneda[];
    agentes: Usuario[];

    acciones = [
        { id: 'liquidar-seleccionados', nombre: 'Liquidar seleccionados' },
    ]

    crumbs = [
        { nombre: 'Liquidación de requerimientos', link: '/cobros/liquidacion-cobros' },
    ];

    columnas = [
        { name: '', sortable: false, checkboxable: true, headerCheckboxable: true, cellTemplate: null },
        { prop: 'numeroRequisito', name: 'No. req.', sortable: true, cellTemplate: null },
        { prop: 'poliza.numero', name: 'No. póliza', sortable: true, cellTemplate: null },
        { prop: 'numeroPagoNombre', name: 'No. pago', sortable: true, cellTemplate: null },
        { prop: 'primaNeta', name: 'Prima neta', sortable: true, cellTemplate: null },
        { prop: 'totalFraccionamiento', name: 'Gastos de fraccionamiento', sortable: true, cellTemplate: null },
        { prop: 'comisionEmisor', name: 'Comisión', sortable: true, cellTemplate: null },
        { prop: 'nombreCliente', name: 'Asegurado', sortable: true, cellTemplate: null },
        { prop: 'fechaPagoFormato', name: 'Pagado', sortable: true, cellTemplate: null },
    ];
    campos = [
        { nombre: 'Número de requerimiento', campo: 'numeroRequisito', tipo: 'texto', categorias: null },
    ];
    urlAutocompleteClientes = this.apiURLs.baseURL + this.apiURLs.informacionClientesURL + '/search';
    filtros = [
        {
            nombre: 'Pagador', campo: 'pagador', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteClientes, seleccionados: [], mostrar: false,
            idProp: 'id', titleProp: 'nombreCompleto', fieldSearch: 'general',
            parser: this.jsonConverters.usuarioDeJSON, parserService: this.jsonConverters,
        },
        {
            nombre: 'Asegurado', campo: 'ClienteId', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteClientes, seleccionados: [], mostrar: false,
            idProp: 'id', titleProp: 'nombreCompleto', fieldSearch: 'general',
            parser: this.jsonConverters.usuarioDeJSON, parserService: this.jsonConverters,
        },
    ];
    urlBusqueda = this.apiURLs.baseURL + this.apiURLs.documentosCobrosURL + '/search';

    constructor(
        public service: CobrosService,
        public empresasService: EmpresasService,
        public aseguradorasService: AseguradorasService,
        public ramosService: RamosService,
        public usuariosService: UsuariosService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * * 
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
        this.route.queryParams.subscribe((params: Params) => {
            
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        this.actualizarFechas();
        await this.obtenerMonedas();
        await this.obtenerAseguradoras();
        await this.obtenerProductos();
        await this.obtenerAgentes();
        this.agregarTemplates();
        this.mostrarTabla = true;
    }

    agregarTemplates() {
        for (const el of this.columnas) {
            if([ 'primaNeta', 'totalEmision', 'totalGastos', 'totalFraccionamiento', 'totalImpuestos', 'primaTotal', 'comisionEmisor'].includes(el.prop)) {
                el.cellTemplate = this.cellMoneda;
            }
        }
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;

        this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
    }

    // * * * * * Monedas * * * * *
    async obtenerMonedas() {
        this.cargando = true;
        let EmpresaId = await this.auth.idEmpresaActual();
        var res = await this.empresasService.obtenerTodasMonedasEmpresa(EmpresaId);
        if(!res.error) {
            this.monedas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerAseguradoras() {
        this.cargando = true;
        var res = await this.aseguradorasService.obtenerTodasAseguradoras();
        if(!res.error) {
            this.aseguradoras = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerProductos() {
        this.cargando = true;
        var res = await this.ramosService.obtenerTodosProductos(this.auth.idEmpresaActual());
        if(!res.error) {
            this.productos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerAgentes() {
        this.cargando = true;
        
        var res = await this.usuariosService.obtenerTodosAgentes();
        if(!res.error) {
            this.agentes = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        
        this.cargando = false;
    }

    abrirEnviarALiquidados(ids: number[], observacionesLiquidacion: any[]) {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Marcar como liquidados',
                mensaje: '¿Está seguro de que desea liquidar los requerimientos seleccionados?',
                payload: {
                    ids: ids,
                }
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.enviarALiquidados(ids, observacionesLiquidacion);
            }
        });
    }

    async enviarALiquidados(ids: number[], observacionesLiquidacion: any[]) {
        this.cargando = true;
        if(ids && ids.length > 0) {
            // Enviar el registro para guardar
            var res = await this.service.pasarALiquidados(ids, null, null, this.auth.idUsuarioActual(), observacionesLiquidacion);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    actualizarFechas(obtenerDatos = true) {
        if(this.rangoFecha != 'personalizado') {
            var fechas = this.libraryService.calcularFechas(this.rangoFecha);
            this.fechaInicioDate = fechas.startDate;
            this.fechaInicioParam = this.libraryService.convertirFecha(fechas.startDate, 'date', 'YYYY-MM-DD');
            this.fechaFinDate = fechas.finishDate;
            this.fechaFinParam = this.libraryService.convertirFecha(fechas.finishDate, 'date', 'YYYY-MM-DD');
        }
    }

    actualizarFechaInicio($event){
        this.fechaInicioParam = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
    }

    actualizarFechaFin($event){
        this.fechaFinParam = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
    }

    calcularParamsEspeciales() {
        let paramsEspeciales = '';
        paramsEspeciales += '&etapa=cobrado';
        paramsEspeciales += '&orderBy=fechaPagoFormato';
        if(this.fechaInicioParam || this.fechaFinParam) {
            paramsEspeciales += '&field=fechaPago';
            if(this.fechaInicioParam) paramsEspeciales += '&start=' + this.fechaInicioParam;
            if(this.fechaFinParam) paramsEspeciales += '&finish=' + this.fechaFinParam;
        } 
        
        if(this.idsAseguradoras && this.idsAseguradoras.length > 0) {
            paramsEspeciales += '&idsAseguradoras=' + this.idsAseguradoras.join(',');
        }
        if(this.idsProductos && this.idsProductos.length > 0) {
            paramsEspeciales += '&idsProductos=' + this.idsProductos.join(',');
        }
        if(this.idsAgentes && this.idsAgentes.length > 0) {
            paramsEspeciales += '&idsAgentes=' + this.idsAgentes.join(',');
        }
        if(this.idsMonedas && this.idsMonedas.length > 0) {
            paramsEspeciales += '&idsMonedas=' + this.idsMonedas.join(',');
        }
        if(this.numeroPoliza && this.numeroPoliza.trim() != '') {
            paramsEspeciales += '&numeroPoliza=' + this.numeroPoliza.trim();
        }
        return paramsEspeciales;
    }

    actualizar() {
        this.auth.buscadorReload.next();
    }

    // * * * Acciones * * *
    aplicarAccion($event) {
        if($event && !this.cargando) {
            var accion = $event.accion;
            var seleccionados = $event.seleccionados;

            var ids = $event.ids;
            switch(accion.id) {
                case 'liquidar-seleccionados': {
                    this.abrirEnviarALiquidados(ids, null);
                    break;
                }
            }
        }
    }

    abrirDialogCobro(DocumentoCobroId: number) {
        // Abrir dialog cobro
        const dialogRef = this.dialog.open(DialogEditarCobroComisionesComponent, {
            data: {
                id: DocumentoCobroId
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.auth.buscadorReload.next();
            }
        });
    }
}