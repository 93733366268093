import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconCandadoDetalle from '@iconify/icons-fa-solid/lock';
import iconCandadoEditar from '@iconify/icons-fa-solid/lock-open';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { Declaracion } from 'src/app/models/declaracion';
import { DocumentoCredito } from 'src/app/models/documentoCredito';
import { Poliza } from 'src/app/models/poliza';
import { CobrosService } from '../cobros.service';
import { PolizasService } from 'src/app/polizas/polizas.service';
import { Usuario } from 'src/app/models/usuario';
import { GastoProductoDocumentoCredito } from 'src/app/models/gastoProductoDocumentoCredito';
import { ImpuestoDocumentoCredito } from 'src/app/models/impuestoDocumentoCredito';
import { JSONConverters } from 'src/app/models/JSONConverters';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-nuevo-documento-credito',
    templateUrl: './nuevo-documento-credito.component.html',
    styleUrls: ['./nuevo-documento-credito.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class NuevoDocumentoCreditoComponent implements OnInit {
    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconCandadoDetalle = iconCandadoDetalle;
    iconCandadoEditar = iconCandadoEditar;
    
    // Banderas y generales
    cargando: boolean = false;
    modoEdicion: boolean;
    modoDetalle: boolean;
    modoCreacion: boolean;
    tipoCredito: string = 'nota-devolucion';
    titulo: string;
    apiURLs: APIUrls = new APIUrls();
    
    // Datos
    jsonConverters: JSONConverters = new JSONConverters();
    id: number;
    PolizaId: number;
    PolizaIdReferencia: number;
    registro: DocumentoCredito;
    poliza: Poliza;
    pagadores: Usuario[];

    crumbs = [
        { nombre: 'Detalle de póliza', link: '/polizas/detalle' },
        { nombre: 'Editar documento de crédito', link: '/cobros/notas-devolucion' },
    ];

    columnasPolizas = [
        { prop: 'numero', name: 'Número', sortable: true, cellTemplate: null },
        { prop: 'cliente.nombreCompleto', name: 'Cliente', sortable: true, cellTemplate: null },
        { prop: 'aseguradora.nombre', name: 'Aseguradora', sortable: true, cellTemplate: null },
        { prop: 'ramo.nombre', name: 'Ramo', sortable: true, cellTemplate: null },
        { prop: 'producto.nombre', name: 'Producto', sortable: true, cellTemplate: null }, 
        { prop: 'nombreEstado', name: 'Estado', sortable: false, cellTemplate: null },
    ];
    camposPolizas = [
        { nombre: 'General', campo: 'general', tipo: 'texto', categorias: null },
        { nombre: 'Número de póliza', campo: 'numero', tipo: 'texto', categorias: null },
        { nombre: 'ID interno', campo: 'id', tipo: 'texto', categorias: null },
    ];
    urlAutocompletePolizas = this.apiURLs.baseURL + this.apiURLs.polizasURL + '/search';
    polizas: Poliza[] = [];

    constructor(
        public service: CobrosService,
        public polizasService: PolizasService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * * 
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.obtenerRegistro();
        await this.obtenerPagadores();
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        this.modoCreacion = ruta === 'nuevo-nota-devolucion' || ruta === 'nuevo-prima-deposito';
        this.modoEdicion = ruta === 'editar-credito';
        this.modoDetalle = ruta === 'detalle-documento-credito';
        this.tipoCredito = (ruta === 'nuevo-prima-deposito') ? 'prima-deposito' : 'nota-devolucion';
        if(params['id']){
            this.id = parseInt(params['id']);
        }
        if(params['PolizaId']){
            this.PolizaId = parseInt(params['PolizaId']);
            this.PolizaIdReferencia = this.PolizaId;
        }
    }

    // * * * * * Obtener datos * * * * * 
    // Obtener el registro o inicializar uno nuevo
    async obtenerRegistro() {
        this.cargando = true;
        if(this.modoCreacion) {
            // Asignar título
            var complementoTitulo = this.tipoCredito == 'prima-deposito' ? 'prima en depósito' : 'nota de devolución';
            this.titulo = 'Agregar ' + complementoTitulo;
            // Creación con póliza de referencia
            if(this.PolizaId) {
                // Inicializar registro 
                var resPoliza = await this.polizasService.obtenerPolizaPorId(this.PolizaId, false);
                if(!resPoliza.error) {
                    this.poliza = resPoliza.data.registro;
                    this.crumbs[0].link = '/polizas/detalle/' + this.PolizaId;
                    this.crumbs[1].link = '/polizas/nuevo-' + this.tipoCredito + '/' + this.PolizaId;
                    this.crumbs[1].nombre = this.titulo;
                }
            }

            this.registro = new DocumentoCredito(-1, null, null, this.tipoCredito, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, false, false, null, this.auth.idUsuarioActual(), this.PolizaId, null, this.auth.idEmpresaActual());
            if(this.poliza) this.registro.MonedaId = this.poliza.MonedaId;
            this.registro.gastos = [];
            this.registro.impuestos = [];

            this.asignarValoresPoliza();
            this.actualizarTotales(true, true, true);
        }
        else if(this.modoEdicion || this.modoDetalle) {
            // Obtener registro
            var res = await this.service.obtenerDocumentoCreditoPorId(this.id);
            if(!res.error) {
                this.registro = res.data.registro;
                this.tipoCredito = this.registro.etapa;
                this.poliza = this.registro.poliza;
                this.PolizaId = this.registro.PolizaId;
                this.crumbs[0].link = '/polizas/detalle/' + this.PolizaId;

                var complementoTitulo = this.tipoCredito == 'prima-deposito' ? 'prima en depósito' : 'nota de devolución';
                if(this.modoEdicion) this.titulo = 'Editar ' + complementoTitulo;
                if(this.modoDetalle) this.titulo = 'Detalle de ' + complementoTitulo;
            }
            else {
                this.router.navigate(['/404']);
            }
        }
        this.cargando = false;
        this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
    }

    asignarValoresPoliza() {
        if(this.modoCreacion) {
            if(this.tipoCredito == 'nota-devolucion' && this.poliza && this.poliza.producto && this.poliza.producto.gastos) {
                for (let i = 0; i < this.poliza.producto.gastos.length; i++) {
                    const element = this.poliza.producto.gastos[i];
                    var nuevoGasto = new GastoProductoDocumentoCredito(null, null, null, null, this.poliza.MonedaId, element.id, null);
                    nuevoGasto.gastoProducto = element;
                    if(element.tipo == 'valor') nuevoGasto.monto = element.montoFijo;
                    if(element.tipo == 'porcentaje') nuevoGasto.porcentaje = element.porcentaje;
                    nuevoGasto.nombre = element.nombre;
                    this.registro.gastos.push(nuevoGasto);
                }
            }

            if(this.tipoCredito == 'nota-devolucion' && this.poliza && this.poliza.producto && this.poliza.producto.impuestos) {
                for (let i = 0; i < this.poliza.producto.impuestos.length; i++) {
                    const element = this.poliza.producto.impuestos[i];
                    var nuevoImpuesto = new ImpuestoDocumentoCredito(null, null, null, null, this.poliza.MonedaId, element.id, null);
                    nuevoImpuesto.impuesto = element;
                    if(element.tipo == 'valor') nuevoImpuesto.monto = element.montoFijo;
                    if(element.tipo == 'porcentaje') nuevoImpuesto.porcentaje = element.porcentaje;
                    nuevoImpuesto.nombre = element.nombre;
                    this.registro.impuestos.push(nuevoImpuesto);
                }
            }
        }
    }

    actualizarFecha($event, campo: string){
        if($event && $event.value) {
            this.registro[campo] = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
            return true;
        }
        return null;
    }

    actualizarPoliza(items: Poliza[]) {
        if(items && items.length > 0) {
            this.PolizaId = items[0].id;
            this.registro.PolizaId = items[0].id;
            this.poliza = items[0];
            this.registro.MonedaId = this.poliza.MonedaId;
            this.registro.moneda = this.poliza.moneda;
            this.obtenerPagadores();
        }
        else {
            this.registro.PolizaId = null;
            this.poliza = null;
        }
    }

    // * * * * * Pagadores * * * * *
    async obtenerPagadores() {
        this.cargando = true;
        if(this.PolizaId) {
            var res = await this.polizasService.obtenerTodosPagadoresPoliza(this.PolizaId);
            if(!res.error) {
                this.pagadores = res.data.registros;
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    actualizarTotales(calcularPorcentajesGastos: boolean, calcularPorcentajesImpuestos: boolean, recalcularPrimaNeta: boolean = false) {
        if(this.registro) {
            var documento = this.registro;
            
            // Total
            documento.creditoTotal = documento.primaNeta + documento.totalEmision + documento.totalGastos + documento.totalImpuestos + documento.totalFraccionamiento;
        }
    }

    // * * * * * Editar datos * * * * *
    // Guardar datos
    async guardar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res;
        if(this.modoCreacion) {
            res = await this.service.crearDocumentoCredito(this.registro);
        }
        else {
            res = await this.service.actualizarDocumentoCredito(this.registro);
        }
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            if(this.modoCreacion) this.router.navigate(['/cobros/detalle-credito/' + res.data.result.result.id]);
            this.auth.comprobarAutenticacion(false);
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * * * Validaciones * * * * * 
    formularioValido() {
        if(!this.registro) return false;
        if(!this.registro.PagadorId) return false;
        return true;
    }
}
