import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigName, ConfigService } from '../@vex/services/config.service';
import { MatIconRegistry } from '@angular/material/icon';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import icLayers from '@iconify/icons-ic/twotone-layers';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import { AuthService } from './auth/auth.service';
import { APIUrls } from './api/apiUrls';

@Component({
    selector: 'vex-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'vex';
    apiURLs = new APIUrls();

    constructor(
        private configService: ConfigService,
        private styleService: StyleService,
        public auth: AuthService,
        private iconRegistry: MatIconRegistry,
        private renderer: Renderer2,
        private platform: Platform,
        @Inject(DOCUMENT) private document: Document,
        @Inject(LOCALE_ID) private localeId: string,
        private layoutService: LayoutService,
        private route: ActivatedRoute,
        private navigationService: NavigationService,
        private splashScreenService: SplashScreenService
    ) {
        // No redirigir a inicio si es una URL de registro
        var redirectNoLogueado = true;
        if(window && window.location && window.location.pathname) {
            for (const path of this.apiURLs.noRedirectRoutes) {
                redirectNoLogueado = redirectNoLogueado && window.location.pathname.indexOf(path) == -1;
            }
        }

        this.auth.comprobarAutenticacion(false, redirectNoLogueado);

        this.iconRegistry.setDefaultFontSetClass('iconify');
        Settings.defaultLocale = this.localeId;

        if (this.platform.BLINK) {
            this.renderer.addClass(this.document.body, 'is-blink');
        }

        this.route.queryParamMap.pipe(
            filter(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl')))
        ).subscribe(queryParamMap => {
            this.document.body.dir = 'rtl';
            this.configService.updateConfig({
                rtl: true
            });
        });

        this.route.queryParamMap.pipe(
            filter(queryParamMap => queryParamMap.has('layout'))
        ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

        this.route.queryParamMap.pipe(
            filter(queryParamMap => queryParamMap.has('style'))
        ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));

        // * * * Menú * * *
        // TODO: Agregar  menús
        this.auth.cargarMenus();
    }
}
