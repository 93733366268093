import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { LibraryService } from 'src/app/library/library.service';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconEdit from '@iconify/icons-fa-solid/edit';
import { DocumentoPoliza } from 'src/app/models/documentoPoliza';
import { PolizasService } from '../polizas.service';
import { ReclamosService } from 'src/app/reclamos/reclamos.service';
import { DocumentoReclamo } from 'src/app/models/documentoReclamo';

export interface DialogData {
    PolizaId: number,
    ReclamoId: number,
    ClienteId: number,
}

@Component({
    selector: 'vex-dialog-agregar-documentos-expediente',
    templateUrl: './dialog-agregar-documentos-expediente.component.html',
    styleUrls: ['./dialog-agregar-documentos-expediente.component.scss']
})
export class DialogAgregarDocumentosExpedienteComponent implements OnInit {
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconEdit = iconEdit;
    archivoCargado:boolean = false;
    cargando: boolean = false;
    titulo:string = '';
    
    documentosReclamo: DocumentoReclamo[] = [];
    documentosPoliza: DocumentoPoliza[] = [];
    documentosCliente: DocumentoPoliza[] = [];

    constructor(
        public dialogRef: MatDialogRef<DialogAgregarDocumentosExpedienteComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private service: PolizasService,
        private reclamosService: ReclamosService,
        private libraryService: LibraryService,
        private router: Router,
        public dialog: MatDialog,
        private auth: AuthService
    ) { }

    ngOnInit(): void {
        // Arreglar estilo
        var dialog = document.getElementById("dialogOverflow2");
        var dialogContainer = null;
        if(dialog) dialogContainer = dialog.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer.className += ' dialogOverflow';

        this.titulo = 'Agregar documentos del expediente';
        if(this.data.ReclamoId) this.obtenerDocumentosReclamo(this.data.ReclamoId);
        if(this.data.ClienteId) this.obtenerDocumentosCliente(this.data.ClienteId);
        if(this.data.PolizaId) this.obtenerDocumentosPoliza(this.data.PolizaId);
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    guardar() {
        if(this.validForm()) {
            let docsReclamosSeleccionados = this.documentosReclamo.filter(doc => doc.seleccionado);
            let docsPolizasSeleccionados = this.documentosPoliza.filter(doc => doc.seleccionado);
            let docsClienteSeleccionados = this.documentosCliente.filter(doc => doc.seleccionado);
            this.dialogRef.close({
                documentosReclamo: docsReclamosSeleccionados,
                documentosPoliza: docsPolizasSeleccionados,
                documentosCliente: docsClienteSeleccionados
            });
        }
    }

    validForm() {
        // Al menos un documento seleccionado
        return this.documentosReclamo.some(doc => doc.seleccionado) || this.documentosPoliza.some(doc => doc.seleccionado) || this.documentosCliente.some(doc => doc.seleccionado);
    }

    async obtenerDocumentosReclamo(ReclamoId: number) {
        this.cargando = true;
        let res = await this.reclamosService.obtenerDocumentosReclamo(ReclamoId);
        this.cargando = false;
        if(res.error) {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'error');
        }
        else {
            this.documentosReclamo = res.data.documentos;
        }
    }
    
    async obtenerDocumentosPoliza(PolizaId: number) {
        this.cargando = true;
        let res = await this.service.obtenerDocumentosPoliza(PolizaId);
        if(res.error) {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'error');
        }
        else {
            this.documentosPoliza = res.data.documentos;
        }
        this.cargando = false;
    }

    async obtenerDocumentosCliente(ClienteId: number) {
        this.cargando = true;
        let res = await this.service.obtenerDocumentosCliente(ClienteId);
        if(res.error) {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'error');
        }
        else {
            this.documentosCliente = res.data.documentos;
        }
        this.cargando = false;
    }
}
