import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';

export interface DialogData {
    titulo: string,
    mensaje: string,
    montoAseguradora: number,
    nombreMontoAseguradora: string,
    monto: number,
    nombreMonto: string,
    porcentaje: number,
    nombrePorcentaje: string,
    payload: any,
}

@Component({
    selector: 'generic-dialog-confirmacion-numero-porcentaje',
    templateUrl: './generic-dialog-confirmacion-numero-porcentaje.component.html',
})
export class GenericDialogConfirmacionNumeroPorcentaje {
    constructor(
        public dialogRef: MatDialogRef<GenericDialogConfirmacionNumeroPorcentaje>,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close({
            accion: 'confirmar',
            montoAseguradora: this.data.montoAseguradora,
            monto: this.data.monto,
            porcentaje: this.data.porcentaje,
            payload: this.data.payload,
        });
    }

    validForm() {
        if(!this.data.monto || !this.data.porcentaje) {
            return false;
        }
        return true;
    }
}