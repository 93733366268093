import { Injectable } from '@angular/core';
import { Usuario } from '../models/usuario';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import * as Papa from 'papaparse';
import { MiAnalytic } from '../models/miAnalytic';

@Injectable()
export class UsuariosService {
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) {
        //Papa Promise
        Papa.parsePromise = function(file) {
            return new Promise(function(complete, error) {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete,
                    error
                });
            });
        };
    }

    // * * * * * Perfil * * * * *
    // Obtener usuario logueado con token
    async obtenerUsuarioPerfil() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/perfil';
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.usuarioDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Actualizar el perfil del usuario logueado con token
    public async actualizarPerfil(registro: Usuario) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/perfil';
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Perfil actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    public async reiniciarPassword(password: string, UsuarioId: number, modoPerfil: boolean = false) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL;
            if(modoPerfil) url += '/passwordPerfil';
            else url += '/password';

            var data = {
                password: password,
                UsuarioId: UsuarioId
            }
            const res = await this.connection.putRequest(url, data);
            return { error: null, data: { mensaje: 'Contraseña actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerUsuarioPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.usuarioDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear usuario
    public async crearUsuario(registro: Usuario) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL;
            const res = await this.connection.postRequest(url, { data: registro });
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar usuario
    public async actualizarUsuario(registro: Usuario) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL;
            const res = await this.connection.putRequest(url, { data: registro });
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Desactivar un usuario
    public async desactivarUsuario(UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/desactivar';
            const res = await this.connection.postRequest(url, { UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Usuario desactivado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Activar un usuario
    public async activarUsuario(UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/activar';
            const res = await this.connection.postRequest(url, { UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Usuario activado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Obtener datos * * * * *
    async obtenerTodasAreas() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.areasURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.areaDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las áreas.');
        }
    }

    async obtenerTodosAgentes() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/agentes';
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.usuarioDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los agentes.');
        }
    }

    async obtenerTodosEjecutivos() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/ejecutivos';
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.usuarioDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los ejecutivos.');
        }
    }

    async obtenerTodosUsuariosInternos() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/internos';
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.usuarioDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los ejecutivos.');
        }
    }

    // * * * * * Archivos * * * * *
    async guardarArchivoEnServidorUsuarios(archivo: File){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/uploadArchivo';
            const res = await this.connection.uploadFile(url, archivo, true);
            if(!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }

    // * * * * * Reset contraseña * * * * *
    public async enviarCorreoRestaurarContrasena(correo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/solicitarReinicioPassword';
            const res = await this.connection.postRequest(url, { correo: correo }, false);
            return { error: null, data: { mensaje: 'Por favor revise su correo para obtener el código de seguridad.', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async recuperarPasswordCodigoSeguridad(correo: string, codigoSeguridad: string, password: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/recuperarPasswordCodigoSeguridad';
            var data = {
                correo: correo,
                codigoSeguridad: codigoSeguridad,
                password: password,
            };
            const res = await this.connection.postRequest(url, data, false);
            return { error: null, data: { mensaje: 'Contraseña recuperada con éxito.', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.message);
        }
    }

    // * * * * * Importación de datos * * * * *
    async lecturaImportacionEjecutivosAgentes(archivo, esVendedor: boolean, AreaId: number, EmpresaId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');

            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = ['codigo', 'nombre'];
            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;

            // Preparar resultados según modelo
            var resultadosFinales = [];
            resultados.forEach(element => {
                var elementoFinal = new Usuario(
                    null, element['codigo'], element['nombre'],
                    null, null, null, null, null, null, null, null,
                    true, false, false, false, false, esVendedor, false, false, false,
                    null, null, null, null, null, null, EmpresaId
                );
                elementoFinal.AreaId = AreaId;

                // Dividir nombre y apellido
                if(elementoFinal.nombre) {
                    var partesNombre = elementoFinal.nombre.split(' ');
                    if(partesNombre.length >= 2) {
                        var posicionesNombre = 1;
                        if(partesNombre.length > 2) posicionesNombre = 2;
                        var nombre = '', apellido = '';
                        for (let i = 0; i < posicionesNombre; i++) nombre += partesNombre[i] + ' ';
                        for (let i = posicionesNombre; i < partesNombre.length; i++) apellido += partesNombre[i] + ' ';
                        nombre = nombre.trim();
                        apellido = apellido.trim();
                        elementoFinal.nombre = nombre;
                        elementoFinal.apellido = apellido;
                    }
                }

                resultadosFinales.push(elementoFinal);
            });

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async analizarImportacionEjecutivosAgentes(registros: Usuario[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/analisisImportacionEjecutivosAgentes';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var errores = res.errores;

            return { error: null, data: {
                mensaje: 'Importación analizada con éxito',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async subirImportacionEjecutivosAgentes(registros: Usuario[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/subirImportacionEjecutivosAgentes';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async obtenerTodasMisAnalytics(params: string = '') {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.misAnalyticsURL;
            if (params) url += '?' + params;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.miAnalyticDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerMiAnalyticPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.misAnalyticsURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.miAnalyticDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearMiAnalytic(registro: MiAnalytic) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.misAnalyticsURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Solicitud creada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarMiAnalytic(registro: MiAnalytic) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.misAnalyticsURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Solicitud actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarMiAnalytic(MiAnalyticId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.misAnalyticsURL + '/id/' + MiAnalyticId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Solicitud eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async solictarCodigoAccesoEspecial() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/actualizarCodigoAccesoEspecial';
            var json = await this.connection.getRequest(url);
            return { error: null, data: { nuevoCodigo: json.nuevoCodigo } };
        } catch (error) {
            console.error(error);
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async validarCodigoSeguridad(codigo: string, modulo: string, permiso: string) {
        try {
            let url = this.apiUrls.baseURL + this.apiUrls.usuariosURL + '/validarCodigoPermiso';
            let res = await this.connection.postRequest(url, { codigo: codigo, modulo: modulo, permiso: permiso });
            return { error: null, data: { valido: res.valido } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
}
