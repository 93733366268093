import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardPermisos } from '../auth/auth-guard-permisos';
import { APIUrls } from '../api/apiUrls';
import { NuevoPolizaComponent } from './nuevo-poliza/nuevo-poliza.component';
import { PolizasComponent } from './polizas/polizas.component';
import { NuevoCertificadoComponent } from './nuevo-certificado/nuevo-certificado.component';
import { NuevoDeclaracionComponent } from './nuevo-declaracion/nuevo-declaracion.component';
import { ImportacionPolizasComponent } from './importacion-polizas/importacion-polizas.component';
import { ImportacionCertificadosPlantillaComponent } from './importacion-certificados-plantilla/importacion-certificados-plantilla.component';
import { ImportacionCertificadosTiposCamposComponent } from './importacion-certificados-tipos-campos/importacion-certificados-tipos-campos.component';
import { ImportacionCertificadosDependientesBeneficiariosComponent } from './importacion-certificados-dependientes-beneficiarios/importacion-certificados-dependientes-beneficiarios.component';
import { SolicitudesPolizasComponent } from './solicitudes-polizas/solicitudes-polizas.component';
import { ContenedorAnalyticsPolizasComponent } from './analytics-polizas/contenedor-analytics-polizas.component';
import { ContenedorAnalyticsSolicitudesComponent } from './analytics-solicitudes/contenedor-analytics-solicitudes.component';
import { ImportacionClausulasComponent } from './importacion-clausulas/importacion-clausulas.component';

var apiURLs = new APIUrls();
const routes: Routes = [
    {
        path: '',
        component: PolizasComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.POLIZAS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'solicitudes-pendientes-calidad',
        component: SolicitudesPolizasComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.CONTROL_CALIDAD.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'solicitudes-revisadas-calidad',
        component: SolicitudesPolizasComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.CONTROL_CALIDAD.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'pendientes-calidad',
        component: PolizasComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.CONTROL_CALIDAD.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'corregidas-calidad',
        component: PolizasComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.CONTROL_CALIDAD.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'revisadas-calidad',
        component: PolizasComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.CONTROL_CALIDAD.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'nuevo',
        component: NuevoPolizaComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.POLIZAS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'editar/:id',
        component: NuevoPolizaComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.POLIZAS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'detalle/:id',
        component: NuevoPolizaComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.POLIZAS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'nuevo-certificado/:PolizaId',
        component: NuevoCertificadoComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.POLIZAS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'editar-certificado/:id',
        component: NuevoCertificadoComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.POLIZAS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'detalle-certificado/:id',
        component: NuevoCertificadoComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.POLIZAS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'nuevo-declaracion/:CertificadoId/:IncisoCertificadoId',
        component: NuevoDeclaracionComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.POLIZAS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'editar-declaracion/:id',
        component: NuevoDeclaracionComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.POLIZAS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'importacion-polizas',
        component: ImportacionPolizasComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.POLIZAS.id, nivel: apiURLs.nivelesAutorizacion.ADMINISTRADOR }
    },
    {
        path: 'importacion-certificados-plantilla/:PolizaId',
        component: ImportacionCertificadosPlantillaComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.POLIZAS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'importacion-certificados-tipos-campos/:PolizaId',
        component: ImportacionCertificadosTiposCamposComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.POLIZAS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'importacion-certificados-dependientes/:PolizaId',
        component: ImportacionCertificadosDependientesBeneficiariosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.POLIZAS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'importacion-certificados-beneficiarios/:PolizaId',
        component: ImportacionCertificadosDependientesBeneficiariosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.POLIZAS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'analytics',
        component: ContenedorAnalyticsPolizasComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.POLIZAS_ANALYTICS.id, nivel: apiURLs.nivelesAutorizacion.PROPIO }
    },
    {
        path: 'analytics-control-calidad',
        component: ContenedorAnalyticsSolicitudesComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.CONTROL_CALIDAD_ANALYTICS.id, nivel: apiURLs.nivelesAutorizacion.PROPIO }
    },
    {
        path: 'importacion-clausulas/:PolizaId',
        component: ImportacionClausulasComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.POLIZAS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PolizasRoutingModule {
}
