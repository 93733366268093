import { CampoTipoReclamo } from './campoTipoReclamo';
import { Ramo } from './ramo';

export class TipoReclamo {
    constructor(
        public id: number,
        public nombre: string,
        public observaciones: string,
        public codigo: string,
        public codigoCompatibilidad: string,
        public procedimiento: string,
        public duracion: number,
        public RamoId: number,
        public EmpresaId: number,
    ){}

    ramo: Ramo;
    campos: CampoTipoReclamo[];
    nombreConRamo: string;
}