import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { MiAnalytic } from '../models/miAnalytic';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) {
    }

  async obtenerTodasMisAnalytics(params: string = '') {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.misAnalyticsURL;
            if (params) url += '?' + params;
            var json = await this.connection.getRequest(url);
            var registros = [];
            for (let i = 0; i < json.length; i++) {
                var element = json[i];
                var registro = this.jsonConverters.miAnalyticDeJSON(element);
                registros.push(registro);
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerMiAnalyticPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.misAnalyticsURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.miAnalyticDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearMiAnalytic(registro: MiAnalytic) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.misAnalyticsURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Solicitud creada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarMiAnalytic(registro: MiAnalytic) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.misAnalyticsURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Solicitud actualizada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarMiAnalytic(MiAnalyticId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.misAnalyticsURL + '/id/' + MiAnalyticId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Solicitud eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }
}
