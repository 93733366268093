import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule } from './auth/auth.module';
import { APIModule } from './api/api.module';
import { UsuariosModule } from './usuarios/usuarios.module';
import { LoginModule } from './login/login.module';
import { MatPaginatorIntl } from '@angular/material';
import { JoditAngularModule } from 'jodit-angular'
import { MatPaginatorIntlES } from './library/busqueda-tabla/MatPaginatorIntlES';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,

        // Vex
        VexModule,

        // App
        AuthModule,
        APIModule,
        UsuariosModule,
        LoginModule,
        JoditAngularModule,
    ],
    providers: [
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlES }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
