import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import iconClose from '@iconify/icons-fa-solid/times';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogSugerenciasComponent } from '../dialog-sugerencias/dialog-sugerencias.component';
import { MatDialog } from '@angular/material/dialog';
import { LibraryService } from '../library.service';

@Component({
    selector: 'mat-autocomplete-popup',
    templateUrl: './mat-autocomplete-popup.component.html',
    styleUrls: ['./mat-autocomplete-popup.component.scss']
})
export class MatAutocompletePopupComponent implements OnInit {

    @Input() placeholder: string;
    @Input() searchPlaceholder: string = 'Buscar';
    @Input() idProp: string = 'id';
    @Input() fieldSearch: any[];
    @Input() titleProp: string = 'title';
    @Input() colorFila: string;
    @Input() posiblesEstados: any[];
    @Input() subtitleProp: string;
    @Input() records: any[];
    @Input() disabled: boolean;
    @Input() seleccionados: any[];
    @Input() defaultRecord: any;
    @Input() url: string;
    @Input() paramsEspeciales: string;
    @Input() parser: Function;
    @Input() parserService: any;
    @Input() multiSelect: boolean = false;
    @Input() columnas: any[];
    @Input() quitarPaddingBottom: boolean = false;
    @Input() obligatorio: boolean = false;
    @Output() selectionChange: EventEmitter<any> = new EventEmitter();
    separatorKeysCodes: number[] = [ENTER, COMMA];
    iconClose = iconClose;

    recordsQuery: any[];
    query: string;
    cargando: boolean = false;
    show:boolean = false;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private service:        LibraryService,
        public dialog:          MatDialog,
    ) { }

    ngOnInit() {
        
    }

    showPopUp(){
        if(!this.disabled){

            let dialogRef = this.dialog.open(DialogSugerenciasComponent, {
                disableClose: true,
                data: {
                    columnas:           this.columnas,
                    fieldSearch:        this.fieldSearch,
                    url:                this.url,
                    placeholder:        this.placeholder,
                    parser:             this.parser,
                    parserService:      this.parserService,
                    paramsEspeciales:   this.paramsEspeciales,
                    seleccionados:      this.seleccionados,
                    multiSelect:        this.multiSelect,
                    titleProp:          this.titleProp,
                    colorFila:          this.colorFila,
                    posiblesEstados:    this.posiblesEstados,
                }
            });

            dialogRef.afterClosed().subscribe(async result => {
                if(result) {
                    if(Array.isArray(result)) {
                        this.seleccionados = result;
                        this.recordsQuery = [];
                        this.records = [];
                        this.selectionChange.emit(this.seleccionados);
                        this.changeDetector.detectChanges();
                    } else {
                        this.seleccionados = [result];
                        this.recordsQuery = [];
                        this.records = [];
                        this.selectionChange.emit(this.seleccionados);
                        this.changeDetector.detectChanges();
                    }
                }
            });

        }
    }

    eliminarSeleccionado(index) {
        this.seleccionados.splice(index, 1);
        this.selectionChange.emit(this.seleccionados);
    }
}
