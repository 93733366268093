import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check-circle';
import iconNoCheck from '@iconify/icons-fa-solid/circle';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconRevision from '@iconify/icons-fa-solid/check-double';
import iconCandadoDetalle from '@iconify/icons-fa-solid/lock';
import iconCandadoEditar from '@iconify/icons-fa-solid/lock-open';
import iconError from '@iconify/icons-fa-solid/exclamation-circle';
import iconRenovar from '@iconify/icons-fa-solid/sync';
import iconCopy from '@iconify/icons-fa-solid/copy';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { RamosService } from 'src/app/ramos/ramos.service';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { Certificado } from 'src/app/models/certificado';
import { PolizasService } from '../polizas.service';
import { Poliza } from 'src/app/models/poliza';
import { TipoCertificado } from 'src/app/models/tipoCertificado';
import { Inciso } from 'src/app/models/inciso';
import { IncisoCertificado } from 'src/app/models/incisoCertificado';
import { ValorCertificado } from 'src/app/models/valorCertificado';
import { Moneda } from 'src/app/models/moneda';
import { Cobertura } from 'src/app/models/cobertura';
import { DeducibleCertificado } from 'src/app/models/deducibleCertificado';
import { BeneficiarioDependienteCertificado } from 'src/app/models/beneficiarioDependienteCertificado';
import { CampoTipoCertificado } from 'src/app/models/campoTipoCertificado';
import { Usuario } from 'src/app/models/usuario';
import { PagadorCertificado } from 'src/app/models/pagadorCertificado';
import { Declaracion } from 'src/app/models/declaracion';
import { DialogNuevoCliente } from '../dialog-nuevo-cliente/dialog-nuevo-cliente.component';
import { InformacionCliente } from 'src/app/models/informacionCliente';
import { ClientesService } from 'src/app/clientes/clientes.service';
import { RevisionPoliza } from 'src/app/models/revisionPoliza';
import { CorreccionControlCalidad } from 'src/app/models/correccionControlCalidad';
import { DialogAplicarPlantillaCertificados } from '../dialog-aplicar-plantilla-certificados/dialog-aplicar-plantilla-certificados.component';
import { DeducibleMonedaCertificado } from 'src/app/models/deducibleMonedaCertificado';
import { EmpresasService } from 'src/app/empresas/empresas.service';
import { DialogCopiarIncisoRamo } from '../dialog-copiar-inciso-ramo/dialog-copiar-inciso-ramo.component';
import { DocumentoPoliza } from 'src/app/models/documentoPoliza';
import { DialogAgregarDocumentoPolizaComponent } from '../dialog-agregar-documento-poliza/dialog-agregar-documento-poliza.component';
import { DialogAgregarMultiplesDocumentosPolizaComponent } from '../dialog-agregar-multiples-documentos/dialog-agregar-multiples-documentos.component';
import { DialogNuevoDireccionCertificado } from '../dialog-nuevo-direccion-certificado/dialog-nuevo-direccion-certificado.component';
import { TipoVehiculo } from 'src/app/models/TipoVehiculo';
import { MarcaVehiculo } from 'src/app/models/marcaVehiculo';
import { LineaVehiculo } from 'src/app/models/lineaVehiculo';
import { ClausulaGarantiaPoliza } from 'src/app/models/clausulaGarantiaPoliza';
import { DialogNuevoClausulaGarantiaPoliza } from '../dialog-nuevo-clausula-garantia-poliza/dialog-nuevo-clausula-garantia-poliza.component';
import { GenericDialogDatepicker } from 'src/app/library/generic-dialog-datepicker/generic-dialog-datepicker.component';
import { RazonPerdida } from 'src/app/models/razonPerdida';
import { ProspectosService } from 'src/app/prospectos/prospectos.service';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-nuevo-certificado',
    templateUrl: './nuevo-certificado.component.html',
    styleUrls: ['./nuevo-certificado.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class NuevoCertificadoComponent implements OnInit {
    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconNoCheck = iconNoCheck;
    iconPlus = iconPlus;
    iconRevision = iconRevision;
    iconCandadoEditar = iconCandadoEditar;
    iconCandadoDetalle = iconCandadoDetalle;
    iconError = iconError;
    iconRenovar = iconRenovar;
    iconCopy = iconCopy;

    // Banderas y generales
    cargando: boolean = false;
    cargandoPagador: boolean = false;
    modoEdicion: boolean;
    modoDetalle: boolean;
    modoCreacion: boolean;
    titulo: string;
    apiURLs: APIUrls = new APIUrls();
    jsonConverters = new JSONConverters();
    tabIndex: number = 0;
    stepIncisos = null;
    stepIncisosHistoricos = null;
    chasisDuplicado = false;
    nombreDuplicado = false;
    deshabilitado: boolean = false;
    CertificadoChasisDuplicadoId: number;
    CertificadoNombreDuplicadoId: number;
    monedas: Moneda[];

    // Datos
    id: number;
    PolizaId: number;
    registro: Certificado;
    poliza: Poliza;
    moneda: Moneda;
    tiposCertificados: TipoCertificado[];
    incisosRamo: Inciso[];
    parentescosBeneficiario: any[];
    parentescosDependiente: any[];
    estadosCertificados: any[] = this.apiURLs.estadosCertificados;
    mensajeError: string;

    tiposVehiculos: TipoVehiculo[];
    marcas: MarcaVehiculo[];
    lineas: LineaVehiculo[];

    rutaArchivoBeneficiarios: string;
    archivoBeneficiarios: File;

    // Coberturas
    coberturas: Cobertura[];

    // Tabs
    selectedIndex: number = 0;
    tabId: string = null;

    // Revisión
    modoRevision: boolean = false;
    modoVerRevision: boolean = false;
    revision: RevisionPoliza;

    crumbs: any[] = [
        { nombre: 'Pólizas', link: '/polizas/' },
        { nombre: 'Editar póliza', link: '/polizas/editar' },
        { nombre: 'Certificados', link: '/polizas/editar' },
        { nombre: 'Editar certificado', link: '/polizas/editar-certificado' },
    ];

    idsSeleccionados: number[];
    fechaBajaClausulaGarantia: any;
    razonesPerdidas: RazonPerdida[] = [];

    // Declaraciones
    // Templates de celdas
    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;
    @ViewChild('cellMoneda', { static: true }) cellMoneda: TemplateRef<any>;
    @ViewChild('cellMonedaPoliza', { static: true }) cellMonedaPoliza: TemplateRef<any>;
    @ViewChild('cellPorcentaje', { static: true }) cellPorcentaje: TemplateRef<any>;
    @ViewChild('cellCheck', { static: true }) cellCheck: TemplateRef<any>;
    @ViewChild('cellDescargarDocumento', { static: true }) cellDescargarDocumento: TemplateRef<any>;

    // Columnas de tabla
    columnasDeclaraciones = [
        { prop: 'descripcion', name: 'Descripción', sortable: true, cellTemplate: null },
        { prop: 'desdeFormato', name: 'Desde', sortable: true, cellTemplate: null },
        { prop: 'hastaFormato', name: 'Hasta', sortable: true, cellTemplate: null },
        { prop: 'montoDeclarado', name: 'Monto declarado', sortable: true, cellTemplate: null },
        { prop: 'tasa', name: 'Tasa', sortable: true, cellTemplate: null },
        { prop: 'primaNeta', name: 'Prima neta', sortable: true, cellTemplate: null },
        { prop: 'numeroEndoso', name: 'No. Endoso', sortable: true, cellTemplate: null },
        { prop: 'fueDeclarado', name: 'Ya fue declarado', sortable: false, cellTemplate: null },
    ];

    // Campos para buscador
    camposDeclaraciones = [
        { nombre: 'Descripción', campo: 'descripcion', tipo: 'texto', categorias: null },
        { nombre: 'No. Endoso', campo: 'numeroEndoso', tipo: 'texto', categorias: null },
    ];

    // Filtros
    filtrosDeclaraciones = [];
    urlBusquedaDeclaraciones = this.apiURLs.baseURL + this.apiURLs.declaracionesURL + '/search';

    urlAutocompleteClientes = this.apiURLs.baseURL + this.apiURLs.informacionClientesURL + '/search';
    columnasClientes = [
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'apellido', name: 'Apellido', sortable: true, cellTemplate: null },
        { prop: 'nombreAgrupador', name: 'Agrupador', sortable: true, cellTemplate: null },
        { prop: 'telefono1', name: 'Teléfono', sortable: true, cellTemplate: null },
        { prop: 'correo1', name: 'Correo electrónico', sortable: true, cellTemplate: null },
    ];
    camposClientes = [
        { nombre: 'Nombre', campo: 'general', tipo: 'texto', categorias: null },
    ];

    // * * * Documentos * * *
    columnasDocumentos = [
        { prop: 'nombre',       name: 'Nombre'          , sortable: true, cellTemplate: null },
        { prop: 'descripcion',  name: 'Descripcion'     , sortable: true, cellTemplate: null },
        { prop: 'link',         name: 'Descarga'        , sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    camposDocumentos = [
        { nombre: 'Nombre'      , campo: 'nombre'       , tipo: 'texto', categorias: null },
        { nombre: 'Descripcion' , campo: 'descripcion'  , tipo: 'texto', categorias: null },
    ];
    filtrosDocumentos = [];
    urlBusquedaDocumentos = this.apiURLs.baseURL + this.apiURLs.documentosPolizasURL + '/search';

    // Campos para direcciones
    columnasDirecciones = [
        { name: 'País', prop: 'nombrePais', sortable: true, cellTemplate: null },
        { name: this.auth.traduccionCampos('Departamento'), prop: 'nombreDepartamento', sortable: true, cellTemplate: null },
        { name: this.auth.traduccionCampos('Municipio'), prop: 'nombreMunicipio', sortable: true, cellTemplate: null },
        { name: this.auth.traduccionCampos('Zona'), prop: 'nombreZona', sortable: true, cellTemplate: null },
        { name: 'Complemento', prop: 'complemento', sortable: true, cellTemplate: null },
        { name: 'Tipo', prop: 'nombreTipo', sortable: true, cellTemplate: null },
    ];

    camposDirecciones = [
        { nombre: 'Complemento', campo: 'complemento', tipo: 'texto', categorias: null },
        { nombre: 'Observaciones', campo: 'observaciones', tipo: 'texto', categorias: null },
    ];
    filtrosDirecciones = [];
    urlBusquedaDirecciones = this.apiURLs.baseURL + this.apiURLs.direccionesCertificadosURL + '/search';

    // * Cláusulas de garantía pólizas *
    columnasClausulasGarantia = [
      { name: '', sortable: false, checkboxable: true, headerCheckboxable: true, cellTemplate: null },
      // Beneficiario
      { prop: 'beneficiario', name: 'Beneficiario', sortable: true, cellTemplate: null },
      // Descripción
      { prop: 'descripcionResumen', name: 'Descripción', sortable: false, cellTemplate: null },
      // Propietario
      { prop: 'propietario', name: 'Propietario', sortable: true, cellTemplate: null },
      // Valor
      { prop: 'valorClausula', name: 'Valor', sortable: true, cellTemplate: null },
      { prop: 'nombreUsuarioIngreso', name: 'Usuario', sortable: true, cellTemplate: null },
      { prop: 'fechaFormato', name: 'Fecha', sortable: true, cellTemplate: null },
      { prop: 'estado', name: 'Estado', sortable: true, cellTemplate: null },
      { prop: 'fechaAltaFormato', name: 'Fecha de Alta', sortable: true, cellTemplate: null },
      { prop: 'fechaBajaFormato', name: 'Fecha de Baja', sortable: true, cellTemplate: null },
  ];
  camposClausulasGarantia = [
      { nombre: 'Descripción', campo: 'descripcion', tipo: 'texto', categorias: null },
      { nombre: 'Fecha', campo: 'fecha', tipo: 'rango-fechas', categorias: null },
      { nombre: 'Beneficiario', campo: 'beneficiario', tipo: 'texto', categorias: null },
      { nombre: 'Propietario', campo: 'propietario', tipo: 'texto', categorias: null },

  ];
  filtrosClausulasGarantia = [];
  urlBusquedaClausulasGarantia = this.apiURLs.baseURL + this.apiURLs.polizasURL + '/searchClausulasGarantia';

  acciones = [
    { id: 'activo', nombre: 'Marcar como activo' },
    { id: 'excluido', nombre: 'Marcar como excluido' },
  ]

  mostrarTabla = false;

    // * Cambios de estado * 
    urlBusquedaCambiosEstado = this.apiURLs.baseURL + this.apiURLs.cambiosEstadosCertificadosURL + '/search';
    columnasCambiosEstados = [
        { prop: 'fechaFormato', name: 'Fecha', sortable: true, cellTemplate: null },
        { prop: 'nombreUsuario', name: 'Usuario', sortable: true, cellTemplate: null },
        { prop: 'nombreEstadoAnterior', name: 'Anterior', sortable: false, cellTemplate: null },
        { prop: 'nombreEstadoSiguiente', name: 'Siguiente', sortable: false, cellTemplate: null },
        { prop: 'nombreInciso', name: 'Inciso', sortable: false, cellTemplate: null },
        { prop: 'nombreCobertura', name: 'Cobertura', sortable: false, cellTemplate: null },
    ];
    camposCambiosEstados = [
        { nombre: 'Fecha', campo: 'fecha', tipo: 'rango-fechas', categorias: null },
    ];
    filtrosCambiosEstados = [];

    constructor(
        public service: PolizasService,
        public empresasService: EmpresasService,
        public prospectosService: ProspectosService,
        public clientesService: ClientesService,
        public ramosService: RamosService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        private cd: ChangeDetectorRef,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * *
    // Inicializar componente
    ngOnInit() {
        this.revision = null;
        this.modoRevision = false;
        // Query params
        this.route.queryParams.subscribe((params: Params) => {
            if(params['tab']) {
                var tab = params['tab'];
                switch(tab) {
                    case 'declaraciones': {
                        this.tabIndex = 3;
                        break;
                    }
                }
            }
        });
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        this.agregarTemplates();
        await this.obtenerRegistro();
        await this.obtenerMonedas();
        this.obtenerProximoCorrelativo();
        this.obtenerRazonesPerdidas();
        await this.obtenerTiposCertificados();
        this.obtenerTiposVehiculos();
        this.obtenerMarcasVehiculos();
        this.obtenerLineasVehiculos();
        this.obtenerIncisosRamo();
        this.obtenerTodasCoberturas();
        this.obtenerParentescosBeneficiario();
        this.obtenerParentescosDependiente();
        this.asignarValoresCampos();
        this.validarChasis();
        this.validarNombre();

        this.route.queryParams.subscribe((params: Params) => {
            this.tabId = params.tab;
            if(this.tabId) {
                this.selectedIndex = this.obtenerIndexTab(this.tabId);
            }
            this.actualizarTabSeleccionada(this.selectedIndex);
        });

        this.mostrarTabla = true;
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        this.modoCreacion = ruta === 'nuevo-certificado';
        this.modoEdicion = ruta === 'editar-certificado';
        this.modoDetalle = ruta === 'detalle-certificado';

        if((this.modoEdicion || this.modoCreacion) && this.auth.esAccesoACliente()) this.router.navigate(['/404']);

        if(params['id']){
            this.id = parseInt(params['id']);
            this.crumbs[3].link = '/polizas/editar-certificado/' + this.id;
        }
        if(params['PolizaId']){
            this.PolizaId = parseInt(params['PolizaId']);
            this.crumbs[1].link = '/polizas/editar/' + this.PolizaId;
            this.crumbs[2].link = '/polizas/editar/' + this.PolizaId;
            this.crumbs[2].queryParams = { tab: 'certificados' };
        }
    }

    agregarTemplates() {
        this.columnasDeclaraciones.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
        this.columnasDeclaraciones[4].cellTemplate = this.cellPorcentaje;
        this.columnasDeclaraciones[3].cellTemplate = this.cellMonedaPoliza;
        this.columnasDeclaraciones[5].cellTemplate = this.cellMonedaPoliza;
        this.columnasDeclaraciones[7].cellTemplate = this.cellCheck;

        this.columnasDocumentos.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
        this.columnasDocumentos[this.columnasDocumentos.length - 1].cellTemplate = this.cellDescargarDocumento;
    }

    // * * * * * Obtener datos * * * * *
    // Obtener el registro o inicializar uno nuevo
    async obtenerRegistro() {
        this.cargando = true;
        if(this.modoCreacion) {
            // Asignar título
            this.titulo = 'Agregar certificado';
            this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
            // Inicializar registro
            var resPoliza = await this.service.obtenerPolizaPorId(this.PolizaId, false);
            if(!resPoliza.error) {
                this.poliza = resPoliza.data.registro;
                if(!this.poliza) this.router.navigate(['/404']);
                this.registro = new Certificado(
                    -1, null, this.estadosCertificados[0].id, this.poliza.fechaVigenciaInicio, null, null, null,
                    this.poliza.fechaVigenciaInicio, this.poliza.fechaVigenciaFin, 0, 0, 0, 0, 0, null, null, null, null, null, null,
                    null,null,null,null, null, null, this.PolizaId, null, null, this.auth.idEmpresaActual(),
                    null, null, null, null,
                    null, null, null, null, null, null
                );
                this.registro.fechaVigenciaInicioDate = this.poliza.fechaVigenciaInicioDate;
                this.registro.fechaVigenciaFinDate = this.poliza.fechaVigenciaFinDate;
                this.registro.fechaAltaDate = this.poliza.fechaVigenciaInicioDate;
                this.moneda = this.poliza.moneda;

                // Colocar estado incluido si la póliza no está en emisión
                if(!this.polizaEstaEnEmision()) {
                    this.registro.estado = 'incluido';
                }

                // Copiar pagadores
                if(this.poliza.pagadores && this.poliza.pagadores.length == 1) {
                    this.registro.pagadores = [];
                    for (let i = 0; i < this.poliza.pagadores.length; i++) {
                        const element = this.poliza.pagadores[i];
                        var nuevoPagador = new PagadorCertificado((i + 1) * -1, null, element.porcentaje, null, null, null, null, null, element.ClienteId, null, this.auth.idEmpresaActual());
                        nuevoPagador.clientes = [element.cliente];
                        nuevoPagador.cliente = element.cliente;
                        this.registro.pagadores.push(nuevoPagador);
                    }
                }
                this.crumbs[3].link = '/polizas/nuevo-certificado/' + this.PolizaId;
                // Cargar y crear copia de incisos de producto
                //this.crearCopiaDeIncisos();
            }
            else {
                this.router.navigate(['/404']);
            }
        }
        else if(this.modoEdicion || this.modoDetalle) {
            if(this.modoEdicion) this.titulo = 'Editar certificado';
            if(this.modoDetalle) this.titulo = 'Detalle de certificado';
            // Obtener registro
            var res = await this.service.obtenerCertificadoPorId(this.id);
            if(!res.error) {
                this.registro = res.data.registro;
                this.poliza = this.registro.poliza;

                if(this.auth.esAccesoACliente() && this.poliza.AgrupadorId != this.auth.usuario.AgrupadorId) this.router.navigate(['/404']);
                if(['historica'].includes(this.poliza.estado)) this.deshabilitado = true;

                this.moneda = this.poliza.moneda;
                this.PolizaId = this.registro.PolizaId;
                this.crumbs[1].link = '/polizas/editar/' + this.PolizaId;
                this.crumbs[2].link = '/polizas/editar/' + this.PolizaId;
                this.crumbs[2].queryParams = { tab: 'certificados' };
                this.crumbs[1].nombre = this.poliza.nombrePoliza;
                this.crumbs[3].nombre = 'Certificado #' + this.registro.numero;

                if(!this.registro.pagadores || this.registro.pagadores.length == 0) {
                    // Copiar pagadores
                    if(this.poliza.pagadores && this.poliza.pagadores.length == 1) {
                        this.registro.pagadores = [];
                        for (let i = 0; i < this.poliza.pagadores.length; i++) {
                            const element = this.poliza.pagadores[i];
                            var nuevoPagador = new PagadorCertificado((i + 1) * -1, null, element.porcentaje, null, null, null, null, null, element.ClienteId, null, this.auth.idEmpresaActual());
                            nuevoPagador.clientes = [element.cliente];
                            nuevoPagador.cliente = element.cliente;
                            this.registro.pagadores.push(nuevoPagador);
                        }
                    }
                }

                if(this.registro.incisos) {
                    for (const inciso of this.registro.incisos) {
                        if(!inciso.fechaVigenciaInicio) {
                            inciso.fechaVigenciaInicio = this.registro.fechaVigenciaInicio;
                            inciso.fechaVigenciaInicioDate = this.registro.fechaVigenciaInicioDate;
                        }
                        if(!inciso.fechaVigenciaFin) {
                            inciso.fechaVigenciaFin = this.registro.fechaVigenciaFin;
                            inciso.fechaVigenciaFinDate = this.registro.fechaVigenciaFinDate;
                        }
                        if(!inciso.estado) inciso.estado = this.registro.estado;

                        if(inciso.estado == 'activo' && inciso.fechaVigenciaInicio != this.registro.fechaVigenciaInicio) {
                            inciso.fechaVigenciaInicio = this.registro.fechaVigenciaInicio;
                            inciso.fechaVigenciaInicioDate = this.registro.fechaVigenciaInicioDate;
                            inciso.fechaVigenciaInicioFormato = this.registro.fechaVigenciaInicioFormato;
                        }

                        if(inciso.estado == 'activo' && inciso.fechaVigenciaFin != this.registro.fechaVigenciaFin) {
                            inciso.fechaVigenciaFin = this.registro.fechaVigenciaFin;
                            inciso.fechaVigenciaFinDate = this.registro.fechaVigenciaFinDate;
                            inciso.fechaVigenciaFinFormato = this.registro.fechaVigenciaFinFormato;
                        }

                        this.calcularPrimaInciso(inciso, false, false, false);
                    }
                }

                // Revisión
                if(this.auth.tienePermisoPara(this.apiURLs.modulos.CONTROL_CALIDAD.id, this.apiURLs.nivelesAutorizacion.ESCRITURA)) {
                    this.revision = this.registro.revision;
                    if(!this.revision) this.revision = new RevisionPoliza(null, null, null, null, null, null, false, false, null, this.PolizaId, this.auth.idUsuarioActual(), this.auth.idEmpresaActual());
                }
                // Asignar título
                this.titleService.setTitle('Certificado de póliza #' + this.PolizaId + ' ' + this.apiURLs.nombreSitioTitle);
            }
            else {
                this.router.navigate(['/404']);
            }
        }
        this.cargando = false;
    }

    // * * * * * Editar datos * * * * *
    // Guardar datos
    async guardar(){
        this.cargando = true;

        if(this.formularioValido()) {
            // Regresar valores de campos
            this.registro.valores = [];
            for (let i = 0; i < this.registro.campos.length; i++) {
                var element = this.registro.campos[i];
                if(element.valor) {
                    this.registro.valores.push(element.valor);
                }
            }

            // Enviar el registro para guardar
            var res;
            if(this.modoCreacion) {
                res = await this.service.crearCertificado(this.registro);
            }
            else if (this.modoEdicion) {
                var revisionIncluir: RevisionPoliza = null;
                if(this.modoRevision && this.revision) revisionIncluir = this.revision;

                res = await this.service.actualizarCertificado(this.registro);
            }

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                if(this.modoCreacion) this.router.navigate(['/polizas/editar-certificado/' + res.data.result.result.id]);
                this.auth.comprobarAutenticacion(false);
                this.ngOnInit();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    // Eliminar datos
    abrirEliminar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar certificado',
                mensaje: '¿Está seguro de que desea eliminar este certificado?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminar();
            }
        });
    }

    async eliminar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.eliminarCertificado(this.id);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.router.navigate(['/polizas/editar/' + this.PolizaId]);
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * * * Validaciones * * * * *
    formularioValido() {
        if(!this.registro) return false;
        if(this.deshabilitado) {
            this.mensajeError = 'No puede editar datos de una póliza histórica.'
            return false;
        }

        // Campos de datos de certificado
        //if(this.tabTieneErrores('Datos de certificado')) return false;

        // Pagadores
        if(this.tabTieneErrores('Pagadores')) return false;

        // Datos particulares
        if(this.tabTieneErrores('Datos particulares')) return false;

        this.mensajeError = null;
        return true;
    }

    tabTieneErrores(tab: string) {
        switch(tab) {
            case 'Datos de certificado': {
                if(
                    !this.libraryService.validateText(this.registro.numero) ||
                    !this.registro.TipoCertificadoId
                ) {
                    this.mensajeError = 'Datos incompletos en la pestaña Datos de certificado.';
                    return true;
                }
                break;
            }
            case 'Pagadores': {
                if(!this.registro.pagadores || this.registro.pagadores.length == 0) {
                    this.mensajeError = 'Debe incluir al menos un pagador para poder guardar este certificado.';
                    return true;
                }
                if(!this.validarPorcentajePagadores()) {
                    this.mensajeError = 'Los porcentajes por pagador del certificado deben sumar el 100%.';
                    return true;
                }
                break;
            }
            case 'Datos particulares': {
                if(this.registro.campos) {
                    for (let i = 0; i < this.registro.campos.length; i++) {
                        var campo = this.registro.campos[i];
                        if(campo.obligatorio && campo.activo) {
                            if(!campo.valor) {
                                this.mensajeError = 'Campos incompletos en Datos particulares.';
                                return true;
                            }
                            if((campo.usaValor || campo.usaPorcentaje || campo.usaMoneda) && campo.valor.valor == null) {
                                this.mensajeError = 'Campos incompletos en Datos particulares.';
                                return true;
                            }
                            if((campo.usaTexto) && !this.libraryService.validateText(campo.valor.texto)) {
                                this.mensajeError = 'Campos incompletos en Datos particulares.';
                                return true;
                            }
                            if((campo.usaFecha) && !this.libraryService.validateText(campo.valor.fecha)) {
                                this.mensajeError = 'Campos incompletos en Datos particulares.';
                                return true;
                            }
                        }
                    }
                }
                break;
            }
        }
        return false;
    }

    deduciblesConError(inciso: IncisoCertificado) {
        if(!inciso) return false;
        if(inciso.deducibles) {
            for (const el of inciso.deducibles) {
                if(!el.nombre || el.nombre.trim() == '') return true;
            }
        }
        return false;
    }

    // * * * * * Fechas * * * * *

    actualizarFechaCertificado($event, atributo){
        if($event && $event.value && atributo) {
            this.registro[atributo] = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
        else {
            this.registro[atributo] = null;
        }
    }
    actualizarFecha($event, campo: CampoTipoCertificado){
        if($event && $event.value) {
            campo.valor.fecha = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
        else {
            campo.valor.fecha = null;
        }
    }

    actualizarFechaAlta($event){
        if($event && $event.value) {
            this.registro.fechaAlta = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
        else {
            this.registro.fechaAlta = null;
        }
    }

    actualizarFechaBaja($event){
        if($event && $event.value) {
            this.registro.fechaBaja = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
        else {
            this.registro.fechaBaja = null;
        }
    }

    actualizarFechaReporteAlta($event){
        if($event && $event.value) {
            this.registro.fechaReporteAlta = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
        else {
            this.registro.fechaReporteAlta = null;
        }
    }

    actualizarFechaReporteBaja($event){
        if($event && $event.value) {
            this.registro.fechaReporteBaja = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
        else {
            this.registro.fechaReporteBaja = null;
        }
    }

    actualizarFechaVigenciaFin($event){
        if($event && $event.value) {
            this.registro.fechaVigenciaFin = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
            if(['excluido'].indexOf(this.registro.estado) != -1) {
                this.registro.fechaBaja = this.registro.fechaVigenciaFin;
                this.registro.fechaBajaDate = this.registro.fechaVigenciaFinDate;
            }
        }
    }

    actualizarFechaVigenciaInicio($event){
        if($event && $event.value) {
            this.registro.fechaVigenciaInicio = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
            if(['incluido'].indexOf(this.registro.estado) != -1) {
                this.registro.fechaAlta = this.registro.fechaVigenciaInicio;
                this.registro.fechaAltaDate = this.registro.fechaVigenciaInicioDate;
            }
        }
    }

    actualizarFechaBeneficiario($event, beneficiario: BeneficiarioDependienteCertificado, campo){
        if($event && $event.value) {
            beneficiario[campo] = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
        else {
            beneficiario[campo] = null;
        }
    }

    // * * * * * Tipos de certificado * * * * *
    async obtenerTiposVehiculos() {
        this.cargando = true;
        if(this.poliza && this.poliza.RamoId) {
            var res = await this.empresasService.obtenerTodosTiposVehiculos();
            if(!res.error) {
                this.tiposVehiculos = res.data.registros;
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    async obtenerMarcasVehiculos() {
        this.cargando = true;
        if(this.poliza && this.poliza.RamoId) {
            var res = await this.empresasService.obtenerTodosMarcasVehiculos();
            if(!res.error) {
                this.marcas = res.data.registros;
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    async obtenerLineasVehiculos() {
        this.cargando = true;
        if(this.poliza && this.poliza.RamoId) {
            var res = await this.empresasService.obtenerTodosLineasVehiculos();
            if(!res.error) {
                this.lineas = res.data.registros;
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    async obtenerTiposCertificados() {
        this.cargando = true;
        if(this.poliza && this.poliza.RamoId) {
            var res = await this.service.obtenerTodosTiposCertificadosRamo(this.poliza.RamoId);
            if(!res.error) {
                this.tiposCertificados = res.data.registros;
                if(!this.registro.TipoCertificadoId && this.tiposCertificados.length > 0) {
                    this.registro.TipoCertificadoId = this.tiposCertificados[0].id;
                }
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    async obtenerProximoCorrelativo() {
        this.cargando = true;
        if(this.PolizaId && this.modoCreacion) {
            var res = await this.service.obtenerSiguienteNumeroCertificado(this.PolizaId);
            if(!res.error) {
                let numeroInt = res.data.correlativo;
                this.registro.numero = numeroInt;
                this.registro.numeroInt = numeroInt;
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * * * Incisos * * * * *
    async crearCopiaDeIncisos() {
        this.cargando = true;
        if(!this.registro.incisos || this.registro.incisos.length == 0) {
            if(this.poliza && this.poliza.ProductoId) {
                // Obtener los incisos del producto
                var resIncisos = await this.ramosService.obtenerIncisosCompletosProducto(this.poliza.ProductoId);
                if(!resIncisos.error) {
                    var incisos: Inciso[] = resIncisos.data.registros;
                    this.registro.incisos = [];
                    // Crear copias de los incisos
                    for (let i = 0; i < incisos.length; i++) {
                        const incisoProducto = incisos[i];
                        var nuevoInciso = this.jsonConverters.convertirIncisoAIncisoCertificado(incisoProducto, this.id, this.auth.idEmpresaActual());
                        this.registro.incisos.push(nuevoInciso);
                    }
                }
            }
        }
        this.cargando = false;
    }

    async asignarValoresCampos() {
        this.cargando = true;
        var indexTipo = this.libraryService.indexOf(this.tiposCertificados, 'id', this.registro.TipoCertificadoId);
        if(indexTipo >= 0) {
            var tipo = this.tiposCertificados[indexTipo];
            this.registro.campos = tipo.campos;

            // Revisar si hay valores para los campos actuales
            for (let i = 0; i < this.registro.campos.length; i++) {
                const campo = this.registro.campos[i];
                var indexValor = this.libraryService.indexOf(this.registro.valores, 'CampoTipoCertificadoId', campo.id);
                if(indexValor == -1) {
                    // No hay valor, poner uno nuevo
                    this.registro.campos[i].valor = new ValorCertificado(null, null, null, null, this.id, campo.id);
                }
                else {
                    // Asignar el valor existente
                    this.registro.campos[i].valor = this.registro.valores[indexValor];
                }

            }
        }

        this.cargando = false;
    }

    agregarInciso() {
        const dialogRef = this.dialog.open(DialogCopiarIncisoRamo, {
            data: { RamoId: this.poliza.RamoId }
        });
        dialogRef.afterClosed().subscribe(res => {
            let result: Inciso = res;
            if(!this.registro.incisos) this.registro.incisos = [];

            if(result) {
                var nuevoInciso = new IncisoCertificado(null, null, false, true, null, 0, 0, 0, 0, 0, 0, 0, null, null, null, null, 'tasa', null, null, null, null, null, null, null, this.id, this.auth.idEmpresaActual(), null);

                if(result) {
                    // Copiar de inciso
                    nuevoInciso = new IncisoCertificado(
                        null, result.nombre, result.declarativo, result.acumulaSuma, result.descripcion, 0, 0, 0, 0, 0, 0,
                        0, null, 0, null, 0, 'tasa', null, null, null,
                        null, null, null, null, this.id, this.auth.idEmpresaActual(), null
                    );
                    nuevoInciso.fechaVigenciaInicio = this.registro.fechaVigenciaInicio;
                    nuevoInciso.fechaVigenciaInicioDate = this.registro.fechaVigenciaInicioDate;
                    nuevoInciso.fechaVigenciaFin = this.registro.fechaVigenciaFin;
                    nuevoInciso.fechaVigenciaFinDate = this.registro.fechaVigenciaFinDate;
                    nuevoInciso.estado = 'activo';
                    if(this.registro.estado == 'incluido') nuevoInciso.estado = 'incluido';

                    // Copiar deducibles
                    if(result.deducibles) {
                        nuevoInciso.deducibles = [];
                        for (const el of result.deducibles) {
                            var nuevoDeducible = new DeducibleCertificado(null, el.nombre, el.porcentaje, el.tipoBase, el.dias, el.valorMaximo, el.valorMinimo, el.valorFijo, el.tipoCambio, el.observaciones, el.participacion, null, this.auth.idEmpresaActual());
                            nuevoInciso.deducibles.push(nuevoDeducible);
                        }
                    }
                }

                this.registro.incisos.push(nuevoInciso);
                this.arreglarCoberturasSinAsignar();
                this.stepIncisos = this.registro.incisos.length - 1;
                this.actualizarFechaVigenciaIncisoSegunEstado(this.registro.incisos[this.registro.incisos.length - 1])
            }
        });
    }

    agregarBeneficiario() {
        if(!this.registro.beneficiarios) this.registro.beneficiarios = [];
        if(this.registro.beneficiarios) {
            var nuevoBeneficiario = new BeneficiarioDependienteCertificado(null, 'Nuevo beneficiario', 'beneficiario', 'masculino', 'hijo', 'activo', null, null, null, null, null, this.registro.fechaVigenciaInicio, this.registro.fechaVigenciaFin, null, null, this.id);
            nuevoBeneficiario.fechaVigenciaInicioDate = this.registro.fechaVigenciaInicioDate;
            nuevoBeneficiario.fechaVigenciaFinDate = this.registro.fechaVigenciaFinDate;
            this.registro.beneficiarios.push(nuevoBeneficiario);
        }
    }

    agregarDependiente() {
        if(!this.registro.dependientes) this.registro.dependientes = [];
        if(this.registro.dependientes) {
            var nuevoDependiente = new BeneficiarioDependienteCertificado(null, 'Nuevo dependiente', 'dependiente', 'masculino', 'hijo', 'activo', null, null, null, null, null, null, null, null, null, this.id);
            nuevoDependiente.fechaVigenciaInicioDate = this.registro.fechaVigenciaInicioDate;
            nuevoDependiente.fechaVigenciaFinDate = this.registro.fechaVigenciaFinDate;
            this.actualizarFechaBeneficiario({ value: { _d: this.registro.fechaVigenciaInicioDate } }, nuevoDependiente, 'fechaVigenciaInicio');
            this.actualizarFechaBeneficiario({ value: { _d: this.registro.fechaVigenciaFinDate } }, nuevoDependiente, 'fechaVigenciaFin');
            this.registro.dependientes.push(nuevoDependiente);
        }

        // Reasignar prima facturada
        for (var inciso of this.registro.incisos) {
            if(this.registro.dependientes.length == 0 && inciso.primaSinDependientes) inciso.primaFacturada = inciso.primaSinDependientes;
            else if(this.registro.dependientes.length == 1 && inciso.primaCon1Dependiente) inciso.primaFacturada = inciso.primaCon1Dependiente;
            else if(this.registro.dependientes.length >= 2 && inciso.primaConMasDependientes) inciso.primaFacturada = inciso.primaConMasDependientes;
        }
    }

    // * * * * * Coberturas * * * * *
    // Obtener
    async obtenerTodasCoberturas() {
        this.cargando = true;
        var res = await this.ramosService.obtenerTodasCoberturasRamo(this.poliza.RamoId);
        if(!res.error) {
            this.coberturas = res.data.registros;
            this.arreglarCoberturasSinAsignar();
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    arreglarCoberturasSinAsignar() {
        // Arreglar por inciso
        if(this.registro.incisos) {
            for (let i = 0; i < this.registro.incisos.length; i++) {
                var inciso = this.registro.incisos[i];
                inciso.coberturasSinAsignar = [];
                for (let i = 0; i < this.coberturas.length; i++) {
                    var element = this.coberturas[i];
                    if(inciso.idsCoberturas && inciso.idsCoberturas.indexOf(element.id) == -1) {
                        if(element.activo) {
                            var nuevaCobertura = this.jsonConverters.copiarCobertura(element);
                            inciso.coberturasSinAsignar.push(nuevaCobertura);
                        }
                    }
                }
                this.registro.incisos[i] = inciso;
            }
        }
    }

    calcularPrimaInciso(inciso: IncisoCertificado, asignarPrimaNeta: boolean = true, reiniciarValores = false, asignarFacturadaDirecto = false) {
        if(reiniciarValores) {
            inciso.primaAnualizada = 0;
            inciso.porcentajeTasa = 0;
            inciso.tarifa = 0;
            inciso.primaFacturada = 0;
            inciso.primaEnDeposito = 0;
            inciso.primaSinDependientes = 0;
            inciso.primaCon1Dependiente = 0;
            inciso.primaConMasDependientes = 0;
        }

        let porcentajeProrrateo = 100;
        if(inciso.estado == 'incluido' && this.auth.paisEmpresaActual() == 'GT') {
            let diasCompletos = moment(this.poliza.fechaVigenciaFin).diff(moment(this.poliza.fechaVigenciaInicio), 'days');
            let diasInciso = moment(inciso.fechaVigenciaFin).diff(moment(inciso.fechaVigenciaInicio), 'days');
            porcentajeProrrateo = this.libraryService.redondearNumero2Decimales((diasInciso / diasCompletos) * 100);
            inciso.diasProrrateo = diasInciso + ' de ' + diasCompletos + ' días (' + porcentajeProrrateo + '%)';
        }
        inciso.porcentajeProrrateo = porcentajeProrrateo;

        var configuracion = this.auth.configuracionEmpresaActual();
        let aplicaProrrateo = !this.service.esPersonas(this.poliza);
        if(configuracion && configuracion.incisosNoProrrateoArray) {
            let objInciso = configuracion.incisosNoProrrateoArray.find(x => x.nombre == inciso.nombre);
            if(objInciso) aplicaProrrateo = false;
        }
        if(this.auth.paisEmpresaActual() == 'SV') aplicaProrrateo = false;

        // * Se solicita calcular la prima neta facturada *
        if(asignarPrimaNeta && !asignarFacturadaDirecto) {
            if(inciso.tipoTasa == 'tasa') {
                let tarifa = inciso.tarifa;
                if(!tarifa) tarifa = 0;
                let porcentajeTasa = inciso.porcentajeTasa ? inciso.porcentajeTasa : 0;
                let sumaAsegurada = inciso.sumaAsegurada ? inciso.sumaAsegurada : 0;
                let primaAnualizada = this.libraryService.redondearNumero2Decimales((porcentajeTasa / 100) * sumaAsegurada + tarifa);
                let diasCompletos = moment(this.poliza.fechaVigenciaFin).diff(moment(this.poliza.fechaVigenciaInicio), 'days');
                let diasInciso = moment(inciso.fechaVigenciaFin).diff(moment(inciso.fechaVigenciaInicio), 'days');
                if(!aplicaProrrateo) diasInciso = diasCompletos;
                let montoDiario = primaAnualizada / diasCompletos;
                let primaFacturada = montoDiario * diasInciso;
                inciso.primaFacturada = primaFacturada;
            }
            else if(inciso.tipoTasa == 'monto-fijo') {
                // Tomar primero la prima anualizada y sobre eso calcular la prima facturada
                let primaAnualizada = inciso.primaAnualizada;
                let diasCompletos = moment(this.poliza.fechaVigenciaFin).diff(moment(this.poliza.fechaVigenciaInicio), 'days');
                let diasInciso = moment(inciso.fechaVigenciaFin).diff(moment(inciso.fechaVigenciaInicio), 'days');
                if(!aplicaProrrateo) diasInciso = diasCompletos;
                let montoDiario = primaAnualizada / diasCompletos;
                let primaFacturada = montoDiario * diasInciso;
                inciso.primaFacturada = primaFacturada;
                inciso.tarifa = primaFacturada;
            }
        }

        // * Calcular prima anualizada *
        let primaAnualizada = 0;
        // Escenario 1 - Certificado activo e inciso activo
        if(this.registro.estado == 'activo' && (inciso.estado == 'activo' || !inciso.estado)) {
            primaAnualizada = inciso.primaFacturada;
            if(!asignarPrimaNeta) {
                if(this.poliza.caracter == 'colectiva' && this.service.esGastosMedicos(this.poliza)) {
                    primaAnualizada = inciso.primaFacturada * this.poliza.numeroPagos;
                }
            }
        }
        // Escenario 2 - Certificado activo e inciso incluido
        else if(this.registro.estado == 'activo' && inciso.estado == 'incluido') {
            let fechaVigenciaInicioInciso = inciso.fechaVigenciaInicio ? inciso.fechaVigenciaInicio : this.registro.fechaVigenciaInicio;
            let fechaVigenciaFinInciso = inciso.fechaVigenciaFin ? inciso.fechaVigenciaFin : this.registro.fechaVigenciaFin;
            let diasCompletos = moment(this.poliza.fechaVigenciaFin).diff(moment(this.poliza.fechaVigenciaInicio), 'days');
            let diasInciso = moment(fechaVigenciaFinInciso).diff(moment(fechaVigenciaInicioInciso), 'days');
            if(!aplicaProrrateo) diasInciso = diasCompletos;
            // 
            let montoPorDiaIniciso = inciso.primaFacturada / diasInciso;
            let diasCertificado = moment(this.registro.fechaVigenciaFin).diff(moment(this.registro.fechaVigenciaInicio), 'days');
            primaAnualizada = montoPorDiaIniciso * diasCertificado;
            if(this.poliza.caracter == 'colectiva' && this.service.esGastosMedicos(this.poliza)) primaAnualizada = (montoPorDiaIniciso * diasCertificado) * this.poliza.numeroPagos;
        }
        // Escenario 3 - Certificado incluido
        else if(this.registro.estado == 'incluido') {
            let fechaVigenciaInicioInciso = inciso.fechaVigenciaInicio ? inciso.fechaVigenciaInicio : this.registro.fechaVigenciaInicio;
            let fechaVigenciaFinInciso = inciso.fechaVigenciaFin ? inciso.fechaVigenciaFin : this.registro.fechaVigenciaFin;
            let diasCompletos = moment(this.poliza.fechaVigenciaFin).diff(moment(this.poliza.fechaVigenciaInicio), 'days');
            let diasInciso = moment(fechaVigenciaFinInciso).diff(moment(fechaVigenciaInicioInciso), 'days');
            if(!aplicaProrrateo) diasInciso = diasCompletos;
            let montoPorDiaIniciso = inciso.primaFacturada / diasInciso;
            let diasPoliza = moment(this.poliza.fechaVigenciaFin).diff(moment(this.poliza.fechaVigenciaInicio), 'days');
            primaAnualizada = montoPorDiaIniciso * diasPoliza;
            if(this.poliza.caracter == 'colectiva' && this.service.esGastosMedicos(this.poliza)) primaAnualizada = (montoPorDiaIniciso * diasPoliza) * this.poliza.numeroPagos;
        }
        inciso.primaAnualizada = primaAnualizada;

        // * Otros datos *
        if(!inciso.declarativo) inciso.primaEnDeposito = 0;
        else {
            inciso.primaEnDeposito = inciso.primaFacturada * (inciso.porcentajePrimaDeposito / 100);
        }
    }

    marcarTodosSinAsignar(inciso: IncisoCertificado, seleccionado: boolean) {
        for (let i = 0; i < inciso.coberturasSinAsignar.length; i++) {
            inciso.coberturasSinAsignar[i].seleccionado = seleccionado;
        }
    }

    marcarTodosAsignados(inciso: IncisoCertificado, seleccionado: boolean) {
        for (let i = 0; i < inciso.coberturas.length; i++) {
            inciso.coberturas[i].seleccionado = seleccionado;
        }
    }

    cargarSeleccionados(inciso: IncisoCertificado) {
        for (let i = 0; i < inciso.coberturasSinAsignar.length; i++) {
            var element = inciso.coberturasSinAsignar[i];
            if(element.seleccionado && inciso.idsCoberturas.indexOf(element.id) == -1) {
                element.seleccionado = false;
                inciso.idsCoberturas.push(element.id);
                inciso.coberturas.push(element);
            }
        }
        this.arreglarCoberturasSinAsignar();
    }

    eliminarSeleccionados(inciso: IncisoCertificado) {
        for (let i = 0; i < inciso.coberturas.length; i++) {
            var element = inciso.coberturas[i];
            if(element.seleccionado) {
                element.seleccionado = false;
                inciso.idsCoberturas.splice(i, 1);
                inciso.coberturas.splice(i, 1);
                i--;
            }
        }
        this.arreglarCoberturasSinAsignar();
    }

    // * * * Deducibles * * *
    abrirEliminarDeducible(inciso: IncisoCertificado, index: number) {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: { titulo: 'Eliminar deducible', mensaje: '¿Está seguro de que desea eliminar este deducible?' }
        });
        dialogRef.afterClosed().subscribe(result => { if(result == 'confirmar') this.eliminarDeducible(inciso, index); });
    }

    eliminarDeducible(inciso: IncisoCertificado, index: number) {
        if(inciso.deducibles && inciso.deducibles.length > index) {
            inciso.deducibles.splice(index, 1);
            inciso.idsDeducibles.splice(index, 1);
        }
    }

    abrirEliminarBeneficiario(index: number) {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: { titulo: 'Eliminar beneficiario', mensaje: '¿Está seguro de que desea eliminar este beneficiario?' }
        });
        dialogRef.afterClosed().subscribe(result => { if(result == 'confirmar') this.eliminarBeneficiario(index); });
    }

    eliminarBeneficiario(index: number) {
        if(this.registro.beneficiarios && this.registro.beneficiarios.length > index) {
            this.registro.beneficiarios.splice(index, 1);
        }
    }

    abrirEliminarDependiente(index: number) {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: { titulo: 'Eliminar dependiente', mensaje: '¿Está seguro de que desea eliminar este dependiente?' }
        });
        dialogRef.afterClosed().subscribe(result => { if(result == 'confirmar') this.eliminarDependiente(index); });
    }

    eliminarDependiente(index: number) {
        if(this.registro.dependientes && this.registro.dependientes.length > index) {
            this.registro.dependientes.splice(index, 1);
        }

        // Reasignar prima facturada
        for (var inciso of this.registro.incisos) {
            if(this.registro.dependientes.length == 0 && inciso.primaSinDependientes) inciso.primaFacturada = inciso.primaSinDependientes;
            else if(this.registro.dependientes.length == 1 && inciso.primaCon1Dependiente) inciso.primaFacturada = inciso.primaCon1Dependiente;
            else if(this.registro.dependientes.length >= 2 && inciso.primaConMasDependientes) inciso.primaFacturada = inciso.primaConMasDependientes;
        }
    }

    abrirEliminarInciso(index: number) {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: { titulo: 'Eliminar inciso', mensaje: '¿Está seguro de que desea eliminar este inciso?' }
        });
        dialogRef.afterClosed().subscribe(result => { if(result == 'confirmar') this.eliminarInciso(index); });
    }

    abrirEliminarIncisoHistorico(index: number) {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: { titulo: 'Eliminar inciso histórico', mensaje: '¿Está seguro de que desea eliminar este inciso histórico?' }
        });
        dialogRef.afterClosed().subscribe(result => { if(result == 'confirmar') this.eliminarIncisoHistorico(index); });
    }

    eliminarInciso(index: number) {
        if(this.registro.incisos && this.registro.incisos.length > index) {
            this.registro.incisos.splice(index, 1);
        }
    }

    eliminarIncisoHistorico(index: number) {
        if(this.registro.incisosHistoricos && this.registro.incisosHistoricos.length > index) {
            this.registro.incisosHistoricos.splice(index, 1);
        }
    }

    agregarDeducible(inciso: IncisoCertificado) {
        if(!inciso.deducibles) inciso.deducibles = [];
        if(inciso.deducibles) {
            var nuevoDeducible = new DeducibleCertificado(null, null, null, null, null, null, null, null, null, null, null, inciso.id, this.auth.idEmpresaActual());
            inciso.deducibles.push(nuevoDeducible);
        }
    }

    async obtenerMonedas() {
        this.cargando = true;
        let EmpresaId = await this.auth.idEmpresaActual();
        var res = await this.empresasService.obtenerTodasMonedasEmpresa(EmpresaId);
        if(!res.error) {
            this.monedas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    agregarDeducibleMoneda() {
        if(!this.registro.deduciblesMoneda) this.registro.deduciblesMoneda = [];
        var nuevoDeducible = new DeducibleMonedaCertificado(null, null, null, null, null, null, null, null, null, null, this.id, this.PolizaId, this.poliza.MonedaId, this.auth.idEmpresaActual());
        this.registro.deduciblesMoneda.push(nuevoDeducible);
    }

    abrirEliminarDeducibleMoneda(index: number) {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: { titulo: 'Eliminar deducible', mensaje: '¿Está seguro de que desea eliminar este deducible?' }
        });
        dialogRef.afterClosed().subscribe(result => { if(result == 'confirmar') this.eliminarDeducibleMoneda(index); });
    }

    eliminarDeducibleMoneda(index: number) {
        if(this.registro.deduciblesMoneda && this.registro.deduciblesMoneda.length > index) {
            this.registro.deduciblesMoneda.splice(index, 1);
            this.registro.idsDeduciblesMoneda.splice(index, 1);
        }
    }

    actualizarValorFijoMoneda(deducible: DeducibleMonedaCertificado) {
        if(deducible.porcentaje) {
            deducible.valorFijo = (deducible.porcentaje / 100) * this.registro.sumaAsegurada;
        }
    }

    // * * * Declaraciones * * *
    hayDeclarativo() {
        if(this.registro.poliza) {
            if(this.registro.poliza.caracter == 'declarativa') return true;
        }
        if(this.registro && this.registro.incisos) {
            for (let i = 0; i < this.registro.incisos.length; i++) {
                if(this.registro.incisos[i].declarativo) return true;
            }
        }

        if(this.service.esPersonas(this.poliza)) return false;

        return false;
    }

    calcularParamsEspecialesDeclaraciones(paramsAdicionales: string = null): string {
        var paramsEspeciales = '';
        if(this.id) paramsEspeciales += '&CertificadoId=' + this.id;
        if(paramsAdicionales) paramsEspeciales += paramsAdicionales;
        return paramsEspeciales;
    }

    calcularParamsEspecialesClientesPagador(): string {
        var paramsEspeciales = '';
        if(this.id) paramsEspeciales += '&idsPolizas=' + this.PolizaId;
        return paramsEspeciales;
    }

    async obtenerParentescosBeneficiario() {
        this.cargando = true;
        var res = await this.service.obtenerTodosParentescosBeneficiario();
        if(!res.error) {
            this.parentescosBeneficiario = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerParentescosDependiente() {
        this.cargando = true;
        var res = await this.service.obtenerTodosParentescosDependiente();
        if(!res.error) {
            this.parentescosDependiente = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    // * * * Pagadores * * *
    abrirNuevoPagador() {
        var cliente = new Usuario(-1, null, null, null, null, null, null, null, null, null, null, true, false, true, false, false, false, false, false, false, null, null, null, null, null, null, null);
        cliente.informacionCliente = new InformacionCliente(
            -1, 'individual', null, null, true, null, null, null, false, null, null, null, null, null,
            null, null, null, null, null, null, null, null, null, false, true, null, null, null, null,
            null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,
            null, null, null, null, null, null, null, null, null, null, null, null, null,
            null, null, null, null, null, null, null, null, null, null, null
        );

        const dialogRef = this.dialog.open(DialogNuevoCliente, {
            data: {
                cliente: cliente,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result != null) {
                this.guardarPagador(result);
            }
        });
    }

    async guardarPagador(nuevoCliente: Usuario) {
        this.cargandoPagador = true;
        if(nuevoCliente && nuevoCliente.nombre) {
            // Enviar el registro para guardar
            var res = await this.clientesService.crearCliente(nuevoCliente);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                var pagador = this.service.jsonConverters.usuarioDeJSON(res.data.result.result);
                var PagadorId = pagador.id;

                if(!this.registro.pagadores) this.registro.pagadores = [];
                var nuevoPagador = new PagadorCertificado((this.registro.pagadores.length + 1) * -1, null, null, null, null, null, null, null, null, this.id, this.auth.idEmpresaActual());
                nuevoPagador.ClienteId = PagadorId;
                nuevoPagador.clientes = [pagador];
                this.registro.pagadores.push(nuevoPagador);
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            nuevoCliente = null;
        }
        this.cargandoPagador = false;
    }

    actualizarClientePagador(items: Usuario[], pagador: PagadorCertificado, indexPagador: number) {
        var ClienteId = null;
        var cliente = null;
        if(items && items.length > 0) {
            ClienteId = items[0].id;
            cliente = items[0];
        }

        // Validar que no exista este pagador en otra línea
        if(this.registro.pagadores) {
            let indexAux = 0;
            for (const el of this.registro.pagadores) {
                if(el.ClienteId == ClienteId && indexAux != indexPagador) {
                    this.libraryService.crearNotificacion('El cliente ya está agregado como pagador', 'danger');
                    this.registro.pagadores.splice(indexPagador, 1);
                    return;
                }
                indexAux++;
            }
        }

        pagador.ClienteId = ClienteId;
        pagador.cliente = cliente;
    }

    validarPorcentajePagadores() {
        var totalPorcentaje = 0;
        if(this.registro.pagadores) {
            for (let i = 0; i < this.registro.pagadores.length; i++) {
                const element = this.registro.pagadores[i];
                if(element.porcentaje == null) return false;
                totalPorcentaje += element.porcentaje;
            }
        }
        if(totalPorcentaje != 100) return false;

        return true;
    }

    agregarPagador(){
        if(!this.registro.pagadores) this.registro.pagadores = [];
        var pagador = new PagadorCertificado((this.registro.pagadores.length + 1) * -1, null, null, null, null, null, null, null, null, this.id, this.auth.idEmpresaActual());
        if(this.poliza.ClienteId) {
            pagador.ClienteId = this.poliza.ClienteId;
        }

        this.registro.pagadores.push(pagador);
        this.ordenarIdsPagadores();
    }

    abrirEliminarPagador(id: number) {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: { titulo: 'Eliminar pagador', mensaje: '¿Está seguro de que desea eliminar este pagador?' }
        });
        dialogRef.afterClosed().subscribe(result => { if(result == 'confirmar') this.eliminarPagador(id); });
    }

    eliminarPagador(id){
        for (let i = 0; i < this.registro.pagadores.length; i++) {
            const element = this.registro.pagadores[i];
            if(element.id == id){
                this.registro.pagadores.splice(i, 1);
                i = this.registro.pagadores.length;
            }
        }
        this.ordenarIdsPagadores();
    }

    ordenarIdsPagadores(){
        for (let i = 0; i < this.registro.pagadores.length; i++) {
            if(this.registro.pagadores[i].id < 0) {
                this.registro.pagadores[i].id = -1 * (i + 1);
            }
        }
    }

    resaltarDeclaracion(declaracion: Declaracion) {
        if(!declaracion || !this.registro || !this.registro.sumaAsegurada) return false;
        return declaracion.montoDeclarado > this.registro.sumaAsegurada;
    }

    // * * * Control de calidad * * *
    iniciarRevision() {
        if(this.modoEdicion) {
            this.modoRevision = true;
        }
    }

    actualizarCampo(data) {
        if(data && this.revision) {
            var campo = data.campo;
            var index = this.libraryService.indexOf(this.revision.correcciones, 'campo', campo);
            if(index != -1) {
                this.revision.correcciones[index].correcto = data.correcto;
                this.revision.correcciones[index].valorAnterior = data.valorInicial;
                this.revision.correcciones[index].valorCorregido = data.valorActual;
            }
            else {
                if(!this.revision.correcciones) this.revision.correcciones = [];
                this.revision.correcciones.push(new CorreccionControlCalidad(null, campo, data.valorInicial, data.valorActual, null, data.correcto, this.PolizaId, this.id, null, this.auth.idEmpresaActual()));
            }
        }
    }

    correctoDeCampo(campo: string) {
        var index = this.libraryService.indexOf(this.revision.correcciones, 'campo', campo);
        if(index != -1) {
            return this.revision.correcciones[index].correcto;
        }
        return false;
    }

    validarFechaInclusion(dependiente: BeneficiarioDependienteCertificado) {
        if(dependiente.estado == 'incluido') {
            dependiente.fechaVigenciaInicio = dependiente.fechaAlta;
            dependiente.fechaVigenciaInicioDate = dependiente.fechaAltaDate;
        }
    }

    abrirDialogAplicarPlantilla() {
        const dialogRef = this.dialog.open(DialogAplicarPlantillaCertificados, {
            data: {
                poliza: this.poliza,
                certificadoDefault: this.registro,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                this.ngOnInit();
            }
        });
    }

    coberturaApareceEnBusqueda(busqueda: string, cobertura: Cobertura) {
        if(!busqueda || busqueda.trim() == '') return true;
        if(!cobertura || !cobertura.nombre) return true;
        let nombreLimpio = cobertura.nombre.trim().toLowerCase();
        let codigoLimpio = '';
        if(cobertura.codigo) cobertura.codigo.trim().toLowerCase();
        let busquedaLimpia = busqueda.trim().toLowerCase();
        if(
            nombreLimpio.indexOf(busquedaLimpia) != -1 ||
            codigoLimpio.indexOf(busquedaLimpia) != -1
        ) return true;

        return false;
    }

    abrirDuplicar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Duplicar certificado',
                mensaje: '¿Está seguro de que desea duplicar este certificado? Se copiarán todos sus datos a un nuevo certificado.',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.duplicar();
            }
        });
    }

    async duplicar(){
        this.cargando = true;

        // Enviar el registro para guardar
        let res = await this.service.duplicarCertificado(this.id);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.router.navigate(['/polizas/editar-certificado/' + res.data.result.result.id]);
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }

        this.cargando = false;
    }

    triggerValidarChasis(campo: CampoTipoCertificado, index) {
        let campoSearch = campo.valor;
        setTimeout(() => {
            if (
                campoSearch == this.registro.campos[index].valor &&
                (campo.nombre && campo.nombre.trim().toLocaleLowerCase() == 'chasis')
            ) {
                this.validarChasis();
            }
            else if (
                campoSearch == this.registro.campos[index].valor &&
                (campo.nombre && (campo.nombre.trim().toLocaleLowerCase() == 'nombres' || campo.nombre.trim().toLocaleLowerCase() == 'apellidos'))
            ) {
                this.validarNombre();
            }
        }, 1500);
    }

    async validarChasis(){
        try {
            let chasis = null, placa = null, motor = null;
            if(this.registro.campos) {
                for (const campo of this.registro.campos) {
                    if(campo.nombre && campo.nombre.trim().toLowerCase() == 'chasis') {
                        chasis = campo.valor.texto;
                    }
                    if(campo.nombre && campo.nombre.trim().toLowerCase() == 'placa') {
                        placa = campo.valor.texto;
                    }
                    if(campo.nombre && campo.nombre.trim().toLowerCase() == 'motor') {
                        motor = campo.valor.texto;
                    }
                }
            }

            if(chasis) {
                let res = await this.service.validarChasis(chasis, placa, motor, this.registro.id);
                if(!res.error) {
                    this.chasisDuplicado = res.data.result.chasisDuplicado;
                    this.CertificadoChasisDuplicadoId = res.data.result.CertificadoChasisDuplicadoId;
                }
                else {
                    this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
                    this.chasisDuplicado = false;
                    this.CertificadoChasisDuplicadoId = null;
                }
            }
        } catch(error) {

        }
    }

    async validarNombre(){
        try {
            let nombres = null, apellidos = null;
            if(this.registro.campos) {
                for (const campo of this.registro.campos) {
                    if(campo.nombre && campo.nombre.trim().toLowerCase() == 'nombres') {
                        nombres = campo.valor.texto;
                    }
                    if(campo.nombre && campo.nombre.trim().toLowerCase() == 'apellidos') {
                        apellidos = campo.valor.texto;
                    }
                }
            }

            if(nombres && apellidos) {
                let res = await this.service.validarNombres(nombres, apellidos, this.registro.id, this.registro.PolizaId);
                if(!res.error) {
                    this.nombreDuplicado = res.data.result.nombreDuplicado;
                    this.CertificadoNombreDuplicadoId = res.data.result.CertificadoNombreDuplicadoId;
                }
                else {
                    this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
                    this.nombreDuplicado = false;
                    this.CertificadoNombreDuplicadoId = null;
                }
            }
        } catch(error) {

        }
    }

    irACertificadoDuplicado() {
        if(this.CertificadoChasisDuplicadoId) {
            const url = this.router.serializeUrl(this.router.createUrlTree(['/polizas/detalle-certificado/' + this.CertificadoChasisDuplicadoId]));
            window.open(url, '_blank');
        }
    }

    irACertificadoDuplicadoNombres() {
        if(this.CertificadoNombreDuplicadoId) {
            const url = this.router.serializeUrl(this.router.createUrlTree(['/polizas/detalle-certificado/' + this.CertificadoNombreDuplicadoId]));
            window.open(url, '_blank');
        }
    }

    async descargarPlantillaBeneficiariosDependientes(tipo: string){
        this.cargando = true;
        // Enviar el registro para guardar
        var res = await this.service.descargarExcelPlantillaBeneficiariosDependientes('Plantilla de ' + tipo + '.xlsx', tipo, this.poliza.id);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    seleccionarArchivoBeneficiarios(event: any, tipo: string){
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.rutaArchivoBeneficiarios = file.name;
            this.archivoBeneficiarios = file;
            this.analizarArchivoExcelBeneficiarios(tipo);
        }
    }

    async analizarArchivoExcelBeneficiarios(tipo: string) {
        if(this.archivoBeneficiarios) {
            this.cargando = true;
            var resLectura = await this.service.lecturaImportacionBeneficiariosPlantilla(this.archivoBeneficiarios, tipo, this.PolizaId, this.registro.id, this.auth.idEmpresaActual());
            if(resLectura.error) {
                // Error al leer archivo
                this.libraryService.crearNotificacion(resLectura.mensaje, 'danger');
                this.cargando = false;
            }
            else {
                let registrosBeneficiarios = resLectura.resultados;
                this.subirRegistrosBeneficiarios(registrosBeneficiarios, tipo);
            }
        }
    }

    async subirRegistrosBeneficiarios(registros: BeneficiarioDependienteCertificado[], tipo: string){
        try {
            this.cargando = true;
            if(!registros || registros.length == 0) throw new Error('No hay datos para subir.');

            // Enviar el registro para guardar
            var res = await this.service.subirImportacionBeneficiariosPlantilla(registros, this.id, tipo, this.auth.idEmpresaActual(), null, false);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.obtenerRegistro();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            this.cargando = false;
        } catch(error) {
            this.cargando = false;
            this.libraryService.crearNotificacion(error.message, 'danger');
        }
    }

    activarSeleccionArchivoBeneficiarios(tipo: string){
        this.limpiarArchivoBeneficiarios();
        document.getElementById('file-input-archivo-' + tipo).click();
    }

    limpiarArchivoBeneficiarios(){
        this.rutaArchivoBeneficiarios = null;
        this.archivoBeneficiarios = null;
    }

    actualizarFechaVigenciaSegunEstado() {
        if(this.registro.estado == 'activo') {
            // Activo - Copiar vigencias de póliza, y limpiar altas y bajas
            this.registro.fechaVigenciaInicioDate = this.poliza.fechaVigenciaInicioDate;
            this.actualizarFechaVigenciaInicio({ value: { _d: this.registro.fechaVigenciaInicioDate } });
            this.registro.fechaVigenciaFinDate = this.poliza.fechaVigenciaFinDate;
            this.actualizarFechaVigenciaFin({ value: { _d: this.registro.fechaVigenciaFinDate } });
            this.registro.fechaAltaDate = null;
            this.registro.fechaAlta = null;
            this.registro.fechaBajaDate = null;
            this.registro.fechaBaja = null;
        }
        if(this.registro.estado == 'incluido') {
            this.registro.fechaVigenciaFinDate = this.poliza.fechaVigenciaFinDate;
            this.actualizarFechaVigenciaFin({ value: { _d: this.registro.fechaVigenciaFinDate } });
            this.registro.fechaVigenciaInicioDate = new Date();
            this.actualizarFechaVigenciaInicio({ value: { _d: this.registro.fechaVigenciaInicioDate } });
            this.registro.fechaAltaDate = this.registro.fechaVigenciaInicioDate;
            this.actualizarFechaAlta({ value: { _d: this.registro.fechaAltaDate } });
        }
    }

    async descargarDeclaraciones(IncisoCertificadoId: number) {
        this.cargando = true;
        const nombreArchivo = `Declaraciones de certificado #${this.registro.numero}`;
        var res = await this.service.descargarExcelDeclaracionesCertificado(nombreArchivo, this.registro.id, IncisoCertificadoId);
        if (!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        } else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    actualizarValorFijo(deducible: DeducibleCertificado, inciso: IncisoCertificado) {
        if(deducible.porcentaje && inciso.sumaAsegurada) {
            deducible.valorFijo = (deducible.porcentaje / 100) * inciso.sumaAsegurada;
        }
    }

    mostrarAlertaFechaNacimiento(campo: CampoTipoCertificado) {
        if(campo.usaFecha) {
            if(campo.nombre.toLowerCase().trim() == 'fecha de nacimiento' || campo.nombre.toLowerCase().trim() == 'fecha nacimiento') {
                if(this.poliza && this.poliza.cliente && this.poliza.cliente.fechaNacimiento) {
                    return this.poliza.cliente.fechaNacimiento != campo.valor.fecha;
                }
            }
        }
        return false;
    }

    actualizarFechaVigenciaIncisoSegunEstado(inciso: IncisoCertificado) {
        if(inciso.estado == 'activo') {
            inciso.fechaVigenciaInicioDate = this.registro.fechaVigenciaInicioDate;
            this.actualizarIncisoFechaVigenciaInicio({ value: { _d: inciso.fechaVigenciaInicioDate } }, inciso);
            inciso.fechaVigenciaFinDate = this.registro.fechaVigenciaFinDate;
            this.actualizarIncisoFechaVigenciaFin({ value: { _d: inciso.fechaVigenciaFinDate } }, inciso);
        }
        else if(inciso.estado == 'incluido') {
            inciso.fechaVigenciaInicioDate = new Date();
            this.actualizarIncisoFechaVigenciaInicio({ value: { _d: inciso.fechaVigenciaInicioDate } }, inciso);
            inciso.fechaVigenciaFinDate = this.registro.fechaVigenciaFinDate;
            this.actualizarIncisoFechaVigenciaFin({ value: { _d: inciso.fechaVigenciaFinDate } }, inciso);
        }
        else if(inciso.estado == 'excluido') {
            inciso.fechaVigenciaFinDate = new Date();
            this.actualizarIncisoFechaVigenciaFin({ value: { _d: inciso.fechaVigenciaFinDate } }, inciso);
        }
    }

    actualizarIncisoFechaVigenciaFin($event, inciso: IncisoCertificado){
        if($event && $event.value) {
            inciso.fechaVigenciaFin = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
        this.calcularPrimaInciso(inciso, true, false, false);
    }

    actualizarIncisoFechaVigenciaInicio($event, inciso: IncisoCertificado){
        if($event && $event.value) {
            inciso.fechaVigenciaInicio = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
        this.calcularPrimaInciso(inciso, true, false, false);
    }

    actualizarIncisoFecha($event, inciso: IncisoCertificado, campo: string){
        if($event && $event.value) {
            inciso[campo] = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
        this.calcularPrimaInciso(inciso, true, false, false);
    }

    // * Tabs *
    obtenerTabs() {
        let tabs = [];
        tabs.push('datos-certificado');
        tabs.push('datos-particulares');
        tabs.push('incisos');
        if(this.service.esPersonas(this.poliza)) tabs.push('deducibles-internacionales');
        if(this.modoEdicion && this.hayDeclarativo()) tabs.push('declaraciones');
        if(this.poliza && this.poliza.ramo && (this.poliza.ramo.tipoBeneficiario == 'beneficiario' || this.poliza.ramo.tipoBeneficiario == 'beneficiario-dependiente') && this.service.esPersonas(this.poliza)) tabs.push('beneficiarios');
        if(this.poliza && this.poliza.ramo && (this.poliza.ramo.tipoBeneficiario == 'dependiente' || this.poliza.ramo.tipoBeneficiario == 'beneficiario-dependiente' || this.poliza.ramo.tipoBeneficiario == 'beneficiario') && this.service.esPersonas(this.poliza)) tabs.push('dependientes');
        tabs.push('pagadores');
        if(this.service.esVehiculos(this.poliza)) tabs.push('clausulas-garantia');

        return tabs;
    }

    obtenerIndexTab(id: string) {
        if(!id) return null;
        let tabs = this.obtenerTabs();
        let index = tabs.indexOf(id);
        if(index == -1) index = null;
        return index;
    }

    actualizarTabSeleccionada(index: number) {
        let tabs = this.obtenerTabs();
        if(index != null && index < tabs.length) {
            let idTab = tabs[index];
            let url = this.router.createUrlTree([], { relativeTo: this.route, queryParams: { tab: idTab }}).toString();
            this.location.go(url);
        }
    }

    async obtenerIncisosRamo() {
        this.cargando = true;
        if(this.poliza && this.poliza.RamoId) {
            var res = await this.ramosService.obtenerTodosIncisosRamo(this.poliza.RamoId);
            if(!res.error) {
                this.incisosRamo = res.data.registros;
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * * * Documentos * * * * *
    abrirSubirMultiplesDocumento() {
        const dialogRef = this.dialog.open(DialogAgregarMultiplesDocumentosPolizaComponent, {
            data: {
                modo: 'creacion'
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result && result.length > 0) {
                for (const el of result) {
                    let doc = new DocumentoPoliza(null, el.nombre, null, el.extension, el.link, this.id, null, null, null, this.auth.idEmpresaActual());
                    await this.guardarDocumentoPoliza({registro: doc});
                }
            }
        });
    }

    abrirSubirDocumento() {
        var document = new DocumentoPoliza(-1, null, null, null, null, this.id, null, null, null, this.auth.idEmpresaActual());
        const dialogRef = this.dialog.open(DialogAgregarDocumentoPolizaComponent, {
            data: {
                registro: document,
                modo: 'creacion'
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.guardarDocumentoPoliza(result);
            }
        });
    }

    async guardarDocumentoPoliza(documento: any) {
        this.cargando = true;
        var res = await this.service.guardarDocumentoPoliza(documento);
        if (!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        } else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.auth.buscadorReload.next();
        this.cargando = false;
    }

    async editarDocumentoPoliza(documento: DocumentoPoliza) {
        this.cargando = true;
        var res = await this.service.editarDocumentoPoliza(documento);
        if (!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        } else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.auth.buscadorReload.next();
        this.cargando = false;
    }

    abrirEditarDocumento($event) {
        const dialogRef = this.dialog.open(DialogAgregarDocumentoPolizaComponent, {
            data: {
                registro: $event,
                modo: 'detalle'
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.editarDocumentoPoliza(result);
            }
        });
    }

    async descargarDocumento(url: string) {
        this.cargando = true;
        if (url) {
            await window.open(url, "_blank");
        }
        this.cargando = false;
    }

    paramsEspecialesDocumentos() {
        var params = '';
        if(this.registro.id) params += '&CertificadoId=' + this.registro.id;
        return params;
    }

    paramsEspecialesDirecciones() {
        var params = '';
        if(this.registro.id) params += '&CertificadoId=' + this.registro.id;
        return params;
    }

    abrirNuevoDireccion(id: number) {
        const dialogRef = this.dialog.open(DialogNuevoDireccionCertificado, {
            data: {
                id: id,
                CertificadoId: this.id,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result && result.accion == 'confirmar') {
                this.auth.buscadorReload.next();
            }
        });
    }

    estaEnEmision() {
        if(!this.poliza) return false;
        if(this.modoCreacion) return true;
        if(this.polizaEstaEnEmision()) return true;
        if(this.registro.estado == 'incluido') return true;
        return false;
    }

    polizaEstaEnEmision() {
        if(!this.poliza) return false;
        if(['emision', 'traslado'].includes(this.poliza.estado)) return true;
        return false;
    }

    // * * * Cláusulas de garantía * * *
    abrirNuevoClausulaGarantia() {
      var clausula = new ClausulaGarantiaPoliza(null, null, null, null, null, null, null, null, null, 'activo', null, null, this.auth.idUsuarioActual(), this.auth.idEmpresaActual(), this.id)

      const dialogRef = this.dialog.open(DialogNuevoClausulaGarantiaPoliza, {
          data: {
              clausula: clausula,
              poliza: this.registro,
              monedas: this.monedas,
          }
      });

      dialogRef.afterClosed().subscribe(result => {
          if(result && result.accion == 'confirmar') {
              this.guardarClausulaGarantia(result.clausula);
          }
          else if(result && result.accion == 'eliminar') {
              this.eliminarClausulaGarantia(result.clausula.id);
          }
      });
  }

  async guardarClausulaGarantia(clausula: ClausulaGarantiaPoliza) {
      this.cargando = true;
      if(clausula) {
          // Enviar el registro para guardar
          var res = null;
          if(clausula.id == null || clausula.id < 0) res = await this.service.crearClausulaGarantiaPoliza(clausula);
          else res = await this.service.actualizarClausulaGarantiaPoliza(clausula);

          if(!res.error) {
              this.libraryService.crearNotificacion(res.data.mensaje, 'success');
              this.auth.buscadorReload.next();
          }
          else {
              this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
          }
      }
      this.cargando = false;
  }

  async eliminarClausulaGarantia(ClausulaGarantiaPolizaId: number) {
      this.cargando = true;
      if(ClausulaGarantiaPolizaId) {
          // Enviar el registro para guardar
          var res = await this.service.eliminarClausulaGarantiaPoliza(ClausulaGarantiaPolizaId);

          if(!res.error) {
              this.libraryService.crearNotificacion(res.data.mensaje, 'success');
              this.auth.buscadorReload.next();
          }
          else {
              this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
          }
      }
      this.cargando = false;
  }

  abrirClausulaGarantiaExistente(clausula: ClausulaGarantiaPoliza) {
      if(clausula){
          const dialogRef = this.dialog.open(DialogNuevoClausulaGarantiaPoliza, {
              data: {
                  clausula: clausula,
                  poliza: this.registro,
                  modoEdicion: this.modoEdicion,
                  monedas: this.monedas,
              },
              width: '60%'
          });

          dialogRef.afterClosed().subscribe(result => {
              if(result && result.accion == 'confirmar') {
                  this.guardarClausulaGarantia(result.clausula);
              }
              else if(result && result.accion == 'eliminar') {
                  this.eliminarClausulaGarantia(result.clausula.id);
              }
          });
      }
  }

  paramsEspecialesClausulasGarantias() {
      var params = '';
      if(this.registro.id) params += '&CertificadoId=' + this.registro.id;
      return params;
  }

  // * * * Acciones * * *
  aplicarAccion($event) {
    if($event && !this.cargando) {
        var accion = $event.accion;
        var seleccionados = $event.seleccionados;
        var ids = $event.ids;
        switch(accion.id) {
            case 'activo': {
                this.abrirEnviarActivo(ids);
                break;
            }
            case 'excluido': {
                this.abrirEnviarExcluidoConDatepicker(ids);
                break;
            }
        }
    }
  }

  // * Activo *
  abrirEnviarActivo(ids: number[]) {
    this.idsSeleccionados = ids;
    const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
        data: {
            titulo: 'Pasar a activo',
            mensaje: '¿Está seguro de que desea modificar la etapa de las cláusulas seleccionadas?',
            payload: {
                ids: ids,
            }
        }
    });

    dialogRef.afterClosed().subscribe(result => {
        if(result == 'confirmar') {
            this.enviarActivo();
        }
    });
  }

  async enviarActivo() {
    this.cargando = true;
    if(this.idsSeleccionados && this.idsSeleccionados.length > 0) {
        // Enviar el registro para guardar
        var res = await this.service.cambiarEstadoClausulaGarantia(this.idsSeleccionados, 'activo', null);
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.auth.buscadorReload.next();
            this.auth.totalesProspectosReload.next();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
    }
    this.cargando = false;
  }

  abrirEnviarExcluidoConDatepicker(ids: number[]) {
    this.idsSeleccionados = ids;
    const dialogRef = this.dialog.open(GenericDialogDatepicker, {
        data: {
            titulo: 'Pasar a excluido',
            mensaje: '¿Está seguro de que desea modificar la etapa de las cláusulas seleccionadas?',
            nombrePlaceholder: 'Fecha de baja',
            payload: {
                ids: ids,
            }
        }
    });

    dialogRef.afterClosed().subscribe(result => {
        if(result) {
            this.actualizarFechaBajaClausulaGarantia(result.fechaDatePicker);
            this.enviarExcluido();
        }
    });

  }

    actualizarFechaBajaClausulaGarantia(fecha){
        this.fechaBajaClausulaGarantia = this.libraryService.convertirFecha(fecha.toDate(), 'date', 'YYYY-MM-DD');
    }

    async enviarExcluido() {
        this.cargando = true;
        if(this.idsSeleccionados && this.idsSeleccionados.length > 0) {
            // Enviar el registro para guardar
            var res = await this.service.cambiarEstadoClausulaGarantia(this.idsSeleccionados, 'excluido', this.fechaBajaClausulaGarantia);
            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
                this.auth.totalesProspectosReload.next();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    puedeMostrarTipoBaseDeducible(idTipoBase: string) {
        let obj = this.apiURLs.tiposBasesDeducibles.find(x => x.id == idTipoBase);
        if(obj) {
            if(obj.tipoRamo == 'todos') return true;
            if(obj.tipoRamo == 'danios') return !this.service.esPersonas(this.poliza);
            if(obj.tipoRamo == 'personas') return this.service.esPersonas(this.poliza);
        }
        return false;
    }

    puedeMostrarCampoDeducible(campo: string, tipoBase: string) {
        return true;
        // if(!tipoBase) return false;
        // let obj = this.apiURLs.tiposBasesDeducibles.find(x => x.id == tipoBase);
        // if(!obj) return false;

        // if(!obj.campos || obj.campos.length == 0) return true;
        // if(obj.campos.includes(campo)) return true;
        // return false;
    }

    async obtenerRazonesPerdidas() {
        this.cargando = true;
        var res = await this.prospectosService.obtenerTodasRazonesPerdidas();
        if(!res.error) {
            this.razonesPerdidas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    paramsEspecialesCambiosEstado() {
        var params = '';
        if(this.registro.id) params += '&CertificadoId=' + this.registro.id;
        return params;
    }

    actualizarFechaBeneficiarioSegunEstado(beneficiario: BeneficiarioDependienteCertificado) {
        if(beneficiario.estado == 'activo') {
            beneficiario.fechaAltaDate = null;
            beneficiario.fechaAlta = null;
            beneficiario.fechaBajaDate = null;
            beneficiario.fechaBaja = null;
        }
    }
}
