import { Certificado } from './certificado';
import { SolicitudPoliza } from './solicitudPoliza';

export class CertificadoSolicitud {
    constructor(
        public id: number,
        public observaciones: string,
        public idsDeclaraciones: string,

        // Relaciones
        public SolicitudPolizaId: number,
        public CertificadoId: number,
        public EmpresaId: number,
    ) {}


    solicitud: SolicitudPoliza;
    certificado: Certificado;
    idsDeclaracionesArray: any[] = [];
}
