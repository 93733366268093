import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { LibraryService } from '../library.service';
import iconClose from '@iconify/icons-fa-solid/times';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, switchMap, tap, finalize } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'mat-select-autocomplete',
  templateUrl: './mat-select-autocomplete.component.html',
  styleUrls: ['./mat-select-autocomplete.component.scss']
})
export class MatSelectAutocompleteComponent implements OnInit {
    @Input() placeholder: string;
    @Input() searchPlaceholder: string = 'Buscar';
    @Input() idProp: string = 'id';
    @Input() fieldSearch: string = 'title';
    @Input() titleProp: string = 'title';
    @Input() subtitleProp: string;
    @Input() records: any[];
    @Input() disabled: boolean;
    @Input() seleccionados: any[];
    @Input() defaultRecord: any;
    @Input() url: string;
    @Input() paramsEspeciales: string;
    @Input() parser: Function;
    @Input() parserService: any;
    @Input() multiSelect: boolean = true;
    @Output() selectionChange: EventEmitter<any> = new EventEmitter();
    separatorKeysCodes: number[] = [ENTER, COMMA];
    iconClose = iconClose;
    subscriptions: Subscription;
    mostrar: boolean = true;

    recordsQuery: any[];
    query: string;
    cargando: boolean = false;
    recordsForm: FormGroup;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private service: LibraryService,
        private auth: AuthService,
        private fb: FormBuilder,
    ) { }

    ngOnInit() {
        if(this.records) this.recordsQuery = this.records.slice();
        
        // Observable
        this.recordsForm = this.fb.group({ query: null });
        this.recordsForm.get('query').valueChanges
        .pipe(
            debounceTime(300),
            tap(() => this.cargando = true),
            switchMap(value => {
                if(value && typeof value == 'string') {
                    var queryCompare = value.toLowerCase();
                    var params = this.calcularParams(queryCompare);
                    var finalURL = this.url + params;
                    return this.service.obtenerRegistrosObservable(finalURL, this.parser.bind(this.parserService), null)
                    .pipe(
                        finalize(() => this.cargando = false),
                    )
                }
                else {
                    this.cargando = false;
                    this.records = [];
                    this.recordsQuery = [];
                    return new Observable();
                }
            })
        )
        .subscribe(results => {
            if(!results.error) {
                var records = results.records;
                var registros = [];
                for (let i = 0; i < records.length; i++) {
                    const element = records[i];
                    registros.push(this.parser(element));
                }
                this.records = registros;
                this.recordsQuery = this.records.slice();
            }
        });

        this.subscriptions = new Subscription();
        this.subscriptions.add(
            this.auth.limpiarBuscador.subscribe(event => {
                this.deleteQuery();
                this.changeDetector.detectChanges();
                this.mostrar = false;
                setTimeout(() => {
                    this.mostrar = true;
                    this.changeDetector.detectChanges();
                }, 500);
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        this.changeDetector.detectChanges();
        if(changes.records && this.records){
            this.recordsQuery = this.records.slice();
            this.query = null;
            this.search();
        }
    }

    async search() {
        /*
        this.recordsQuery = [];
        this.cargando = true;
        if(this.query && typeof this.query == 'string') {
            var queryCompare = this.query.toLowerCase();
            var params = this.calcularParams(queryCompare);
            var finalURL = this.url + params;
            var res = await this.service.obtenerRegistros(finalURL, this.parser.bind(this.parserService), null);
            
            // Actualizar
            this.records = res.data.registros;
            this.recordsQuery = this.records.slice();
        }
        else {
            if(this.records) this.recordsQuery = this.records.slice();
        }
        this.cargando = false;
        */
    }

    calcularParams(queryCompare: string): string {
        var params = '?';
        params += '&limit=10';
        
        // Campos de búsqueda
        params += '&field=' + this.fieldSearch;
        params += '&value=' + queryCompare;

        if(this.paramsEspeciales) {
            params += this.paramsEspeciales;
        }

        return params;
    }

    deleteQuery() {
        this.query = '';
        this.recordsForm.setValue({ query: '' });
        this.mostrar = false;
        setTimeout(() => {
            this.mostrar = true;
        }, 300);
        //this.changeDetector.detectChanges();
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 32) {
            event.stopPropagation();
        }
    }

    agregarOpcion(autocompleteItem) {
        if(autocompleteItem && autocompleteItem.option) {
            var selectedValue = autocompleteItem.option.value;
            var item = null;
            for (let i = 0; i < this.records.length; i++) {
                var element = this.records[i];
                if(element[this.idProp] == selectedValue) {
                    item = element;
                }
            }

            if(item) {
                var index = this.service.indexOf(this.seleccionados, this.idProp, item[this.idProp]);
                if(index == -1) {
                    if(this.multiSelect || (!this.multiSelect && this.seleccionados.length == 0)) {
                        this.seleccionados.push(item);
                        this.recordsQuery = [];
                        this.records = [];
                        this.selectionChange.emit(this.seleccionados);
                        this.changeDetector.detectChanges();
                    }
                    else if(!this.multiSelect && this.seleccionados.length > 0) {
                        this.seleccionados = [item];
                        this.recordsQuery = [];
                        this.records = [];
                        this.selectionChange.emit(this.seleccionados);
                        this.changeDetector.detectChanges();
                    }
                }
            }
        }
        this.deleteQuery();
    }

    eliminarSeleccionado(index) {
        this.seleccionados.splice(index, 1);
        this.selectionChange.emit(this.seleccionados);
    }
}
