import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { SeguimientoCobro } from 'src/app/models/seguimientoCobro';
import { APIUrls } from 'src/app/api/apiUrls';
import { AplicacionDocumentoCredito } from 'src/app/models/aplicacionDocumentoCredito';
import { LibraryService } from 'src/app/library/library.service';
import { DocumentoCredito } from 'src/app/models/documentoCredito';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DocumentoCobro } from 'src/app/models/documentoCobro';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};
export interface DialogData {
    DocumentoCobroId: number,
    cobro: DocumentoCobro,
    aplicacionCredito: AplicacionDocumentoCredito,
    creditosDisponibles: DocumentoCredito[],
}

@Component({
    selector: 'dialog-nuevo-aplicacion-credito',
    templateUrl: './dialog-nuevo-aplicacion-credito.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogNuevoAplicacionCredito {
    apiURLs = new APIUrls();
    creditoSeleccionado: DocumentoCredito;
    errorAplicacion: string;

    constructor(
        public dialogRef: MatDialogRef<DialogNuevoAplicacionCredito>,
        public libraryService: LibraryService,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit(){
        this.data.aplicacionCredito.monto = this.data.cobro.primaTotal;
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close(this.data.aplicacionCredito);
    }

    registroValido(): boolean {
        var aplicacion = this.data.aplicacionCredito;
        if(!aplicacion || !this.creditoSeleccionado) return false;
        
        if(!aplicacion.monto || !aplicacion.DocumentoCreditoId || !aplicacion.DocumentoCobroId) return false;

        if(aplicacion.monto <= 0) return false;
        if(aplicacion.monto > this.creditoSeleccionado.creditoRestante) {
            this.errorAplicacion = 'El monto a aplicar es mayor que el crédito restante.';
            return false;
        }
        if(aplicacion.monto > this.data.cobro.primaTotal) {
            this.errorAplicacion = 'El monto a aplicar es mayor que la prima total.';
            return false;
        }

        this.errorAplicacion = null;
        return true;
    }

    actualizarFecha($event){
        if($event && $event.value) {
            this.data.aplicacionCredito.fechaAplicacion = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    actualizarCreditoSeleccionado(id: number) {
        var index = this.libraryService.indexOf(this.data.creditosDisponibles, 'id', id);
        if(index >= 0) {
            this.creditoSeleccionado = this.data.creditosDisponibles[index];
        }
    }
}