import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopoverService } from '../popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import icPerson from '@iconify/icons-ic/twotone-person';
import theme from '../../utils/tailwindcss';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'vex-toolbar-user',
    templateUrl: './toolbar-user.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {

    dropdownOpen: boolean;
    icPerson = icPerson;

    theme = theme;

    constructor(
        public auth: AuthService,
        private popover: PopoverService,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.auth.perfilReload.subscribe(event => {
            this.cd.detectChanges();
        })
    }

    showPopover(originRef: HTMLElement) {
        this.dropdownOpen = true;
        this.cd.markForCheck();

        const popoverRef = this.popover.open({
            content: ToolbarUserDropdownComponent,
            origin: originRef,
            offsetY: 12,
            position: [{
                    originX: 'center',
                    originY: 'top',
                    overlayX: 'center',
                    overlayY: 'bottom'
                },
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top',
                },
            ]
        });

        popoverRef.afterClosed$.subscribe(() => {
            this.dropdownOpen = false;
            this.cd.markForCheck();
        });
    }
}