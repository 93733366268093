import { Usuario } from './usuario';

export class ClausulaGarantiaPoliza {
    constructor(
        public id: number,
        public nombreUsuario: string,
        public beneficiario: string,
        public descripcion: string,
        public propietario: string,
        public valorClausula: number,
        public fecha: string,
        public fechaAlta: string,
        public fechaBaja: string,
        public estado: string,
        public PolizaId: number,
        public MonedaId: number,
        public UsuarioId: number,
        public EmpresaId: number,
        public CertificadoId: number,
    ){}

    fechaFormato: string;
    fechaDate: Date;
    fechaAltaDate: Date;
    fechaAltaFormato: string;
    fechaBajaDate: Date;
    fechaBajaFormato: string;
    usuario: Usuario;
    descripcionResumen: string;
    nombreUsuarioIngreso: string;
}
