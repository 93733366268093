import { Injectable } from '@angular/core';
import { Usuario } from '../models/usuario';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { Area } from '../models/area';

@Injectable()
export class AvisosService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    // * * * * * Editar datos * * * * *
    // Crear un nuevo cliente
    public async enviarAvisoPrueba(avisoAEnviar: number, fechaAviso: string, direcciones: string[], UsuarioSimuladoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.avisosURL + '/enviarPrueba';
            let data = {
                avisoAEnviar: avisoAEnviar,
                fechaAviso: fechaAviso,
                direcciones: direcciones,
                UsuarioSimuladoId: UsuarioSimuladoId,
            }
            const res = await this.connection.postRequest(url, data);
            return { error: null, data: { mensaje: 'Aviso de prueba enviado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }
}