import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import { CobrosService } from '../cobros.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { PlanillaCobro } from 'src/app/models/planillaCobro';
import { FormaCobroMultiple } from 'src/app/models/documentoCobro';
import iconDelete from '@iconify/icons-fa-solid/trash';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    ids: number[],
}

@Component({
    selector: 'dialog-asignar-planilla',
    templateUrl: './dialog-asignar-planilla.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogAsignarAPlanillaComponent {
    iconDelete = iconDelete;

    apiURLs = new APIUrls();
    cargando: boolean = false;
    fechaPagoDate: Date;
    fechaPago: string;
    PlanillaCobroId: number;
    planillas: PlanillaCobro[];
    multiplesFormasPagoArray: FormaCobroMultiple[];
    
    constructor(
        public dialogRef: MatDialogRef<DialogAsignarAPlanillaComponent>,
        public service: CobrosService,
        public auth: AuthService,
        public libraryService: LibraryService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit() { 
        // Arreglar estilo
        var dialog = document.getElementById("dialogInscripcionTorneo");
        var dialogContainer = null;
        if(dialog) dialogContainer = dialog.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer.className += ' dialogOverflow';

        this.fechaPagoDate = new Date();
        this.actualizarFecha({ value: { _d: this.fechaPagoDate } });
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close({
            estado: 'confirmar',
            fechaPago: this.fechaPago,
            PlanillaCobroId: this.PlanillaCobroId,
        });
    }

    async obtenerPlanillasDia() {
        this.cargando = true;
        var res = await this.service.obtenerTodosPlanillasFecha(this.fechaPago);
        if(!res.error) {
            this.planillas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    registroValido(): boolean {
        //if(!this.data.ids || this.data.ids.length == 0) return false;
        
        return true;
    }

    actualizarFecha($event){
        if($event && $event.value) {
            this.fechaPago = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
            this.obtenerPlanillasDia();
            return this.fechaPago;
        }
        return null;
    }
}