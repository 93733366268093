import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconCandadoDetalle from '@iconify/icons-fa-solid/lock';
import iconCandadoEditar from '@iconify/icons-fa-solid/lock-open';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { CobrosService } from '../cobros.service';
import { PlantillaGestionCobro } from 'src/app/models/plantillaMapaProceso copy';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-nuevo-plantilla-gestion-cobro',
    templateUrl: './nuevo-plantilla-gestion-cobro.component.html',
    styleUrls: ['./nuevo-plantilla-gestion-cobro.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class NuevoPlantillaGestionCobroComponent implements OnInit {
    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconCandadoDetalle = iconCandadoDetalle;
    iconCandadoEditar = iconCandadoEditar;
    
    // Banderas y generales
    cargando: boolean = false;
    modoEdicion: boolean;
    modoDetalle: boolean;
    modoCreacion: boolean;
    titulo: string;
    apiURLs: APIUrls = new APIUrls();
    
    // Datos
    id: number;
    registro: PlantillaGestionCobro;

    crumbs = [
        { nombre: 'Plantillas de gestión de cobro', link: '/cobros/plantillas-gestiones-cobros' },
        { nombre: 'Nueva plantilla', link: '/cobros/nuevo-plantilla-gestion-cobro' },
    ];

    botonUploadImagen = {
        name: 'subirImagen',
        icon: 'image',
        exec: this.activarInputImagen.bind(this),
    }
    botonesEditor = [
        'bold', 'italic',
        '|',
        'ul', 'ol',
        '|',
        'font', 'fontsize', 'brush', 'paragraph',
        '|',
        this.botonUploadImagen, 'table', 'link',
        '|',
        'left', 'center', 'right', 'justify',
        '|',
        'undo', 'redo',
        '|',
        'hr', 'eraser', 'fullsize'
    ]
    config = {
        language: 'es',
        buttons: this.botonesEditor,
        readonly: false,
        toolbarAdaptive: false
    };

    @ViewChild('contenido', { static: true }) contenido: any;
    @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

    constructor(
        public service: CobrosService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * * 
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.obtenerRegistro();
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        this.modoCreacion = ruta === 'nuevo-plantilla-gestion-cobro';
        this.modoEdicion = ruta === 'editar-plantilla-gestion-cobro';
        this.modoDetalle = ruta === 'detalle-plantilla-gestion-cobro';
        if(params['id']){
            this.id = parseInt(params['id']);
        }
    }

    // * * * * * Obtener datos * * * * * 
    // Obtener el registro o inicializar uno nuevo
    async obtenerRegistro() {
        this.cargando = true;
        if(this.modoCreacion) {
            // Asignar título
            this.titulo = 'Agregar plantilla de gestión de cobro';
            this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
            this.registro = new PlantillaGestionCobro(null, null, null, true, false, this.auth.idEmpresaActual());
        }
        else if(this.modoEdicion || this.modoDetalle) {
            if(this.modoEdicion) this.titulo = 'Editar plantilla de gestión de cobro';
            if(this.modoDetalle) this.titulo = 'Detalle de plantilla de gestión de cobro';
            // Obtener registro
            var res = await this.service.obtenerPlantillaGestionCobroPorId(this.id);
            if(!res.error) {
                this.registro = res.data.registro;
                this.crumbs[1].link = '/cobros/editar-plantilla-gestion-cobro/' + this.id;
            }
            else {
                this.router.navigate(['/404']);
            }
        }
        this.cargando = false;
    }

    // * * * * * Editar datos * * * * *
    // Guardar datos
    async guardar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res;
        if(this.modoCreacion) {
            res = await this.service.crearPlantillaGestionCobro(this.registro);
        } else {
            res = await this.service.actualizarPlantillaGestionCobro(this.registro);
        }
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            if(this.modoCreacion) this.router.navigate(['/cobros/editar-plantilla-gestion-cobro/' + res.data.result.result.id]);
            this.auth.comprobarAutenticacion(false);
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * * * Validaciones * * * * * 
    formularioValido() {
        if(!this.registro) return false;
        return true;
    }

    async seleccionImagen(ev, option) {
        var editor = this.contenido.editor;

        for (let file of ev.target.files) {
            let url = await this.crearYSubirDocumento(file);
            editor.selection.insertImage(url);
        }
    }
    
    activarInputImagen() {
        this.fileInput.nativeElement.click();
    }

    async crearYSubirDocumento(archivo: File) {
        this.cargando = true;
        let url;
        if (archivo) {
            var res = await this.service.guardarArchivoEnServidorPolizas(archivo);
            if (!res.error) {
                url = res.data.url;
            } else {
                this.libraryService.crearNotificacion('Ha ocurrido un error al subir su documento al servidor.', 'danger');
            }
        }
        this.cargando = false;
        return url;
    }
}