import { Component, Inject, Input } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { Agrupador } from 'src/app/models/agrupador';
import moment from 'moment';
import iconDownload from '@iconify/icons-fa-solid/download';
import { Usuario } from 'src/app/models/usuario';
import { Aseguradora } from 'src/app/models/aseguradora';
import { Poliza } from 'src/app/models/poliza';
import { Certificado } from 'src/app/models/certificado';
import { CobrosService } from '../cobros.service';
import { AseguradorasService } from 'src/app/aseguradoras/aseguradoras.service';
import { DialogPreviewDownloadDocumentComponent } from 'src/app/library/dialog-preview-download-document/dialog-preview-download-document.component';
import { RamosService } from 'src/app/ramos/ramos.service';
import { Ramo } from 'src/app/models/ramo';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
import { EmpresasService } from 'src/app/empresas/empresas.service';
import { Moneda } from 'src/app/models/moneda';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};
export interface DialogData {
    etapa: string,
}

@Component({
    selector: 'dialog-generar-reporte-comisiones',
    templateUrl: './dialog-generar-reporte-comisiones.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogGenerarReporteComisiones {
    @Input() modoGestionCobro: boolean = false;
    @Input() filtrosIniciales: any;

    apiURLs = new APIUrls();
    fechaInicioDate: Date;
    fechaInicio: string;
    fechaFinDate: Date;
    fechaFin: string;
    cargando: boolean = false;
    iconDownload = iconDownload;

    jsonConverters: JSONConverters = new JSONConverters();
    urlAutocompleteAgrupadores = this.apiURLs.baseURL + this.apiURLs.agrupadoresURL + '/search';
    aseguradoras: Aseguradora[];
    ramos: Ramo[];
    idsAseguradoras: number[] = [];
    idsRamos: number[] = [];
    monedas: Moneda[];
    MonedaId: number;
    agentes: Usuario[];
    idsAgentes: number[] = [];

    columnasAseguradoras = [
        { prop: 'codigo', name: 'Código', sortable: true, cellTemplate: null },
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'imagen', name: 'Imagen', sortable: true, cellTemplate: null },
    ];
    camposAseguradoras = [
        { nombre: 'Nombre', campo: 'nombre', tipo: 'texto', categorias: null },
        { nombre: 'Código', campo: 'codigo', tipo: 'texto', categorias: null },
    ];

    constructor(
        public dialogRef: MatDialogRef<DialogGenerarReporteComisiones>,
        public dialog: MatDialog,
        public service: CobrosService,
        public aseguradorasService: AseguradorasService,
        public ramosService: RamosService,
        public usuariosService: UsuariosService,
        public empresasService: EmpresasService,
        public libraryService: LibraryService,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit(){
        // Arreglar estilo
        var dialog = document.getElementById("dialogOverflow");
        var dialogContainer = null;
        if(dialog) dialogContainer = dialog.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer.className += ' dialogOverflow';

        this.fechaInicioDate = moment().startOf('month').toDate();
        this.actualizarFechaInicio({ value: { _d: this.fechaInicioDate } });
        this.fechaFinDate = moment().endOf('month').toDate();
        this.actualizarFechaFin({ value: { _d: this.fechaFinDate } });

        this.obtenerAseguradoras();
        this.obtenerAgentes();
        this.obtenerMonedas();
        setTimeout(() => {
            this.cargarFiltrosIniciales();
        }, 1500);
    }

    // * * * Confirmar * * *
    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close(null);
    }

    // * * * Descargar * * *
    async descargarPDF() {
        // if(this.registroValido()) {
        //     this.cargando = true;
        //     var res = await this.service.descargarPDFEstadoCuenta(
        //         this.AgrupadorId,
        //         this.idsPagadores,
        //         this.idsClientes,
        //         this.idsAseguradoras,
        //         this.idsRamos,
        //         this.idsPolizas,
        //         this.idsCertificados,
        //         this.fechaInicio,
        //         this.fechaFin,
        //         this.incluirPorCobrar,
        //         this.incluirCobrados,
        //         this.incluirNotasCredito,
        //         this.incluirNotasCreditoPendientes,
        //         this.tipoAgrupador,
        //         this.camposAMostrar,
        //         false
        //     );
            
        //     if(!res.error) {
        //         //this.confirmar();
        //     }
        //     else {
        //         this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        //     }
            
        //     this.cargando = false;
        // }
    }

    async descargarExcel() {
        if(this.registroValido()) {
            this.cargando = true;

            // Validar si seleccionó todas las aseguradoras
            let idsAseguradoras = this.idsAseguradoras;
            if(idsAseguradoras.length >= this.aseguradoras.length) idsAseguradoras = [];
            let idsAgentes = this.idsAgentes;
            if(idsAgentes.length >= this.agentes.length) idsAgentes = [];

            var res = await this.service.descargarExcelReporteComisiones(
                idsAseguradoras,
                idsAgentes,
                this.MonedaId,
                this.fechaInicio,
                this.fechaFin,
                this.data.etapa,
                false
            );
            
            if(!res.error) {
                //this.confirmar();
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            
            this.cargando = false;
        }
    }

    registroValido(): boolean {
        //if(!this.AgrupadorId) return false;
        if(!this.fechaInicio || !this.fechaFin) return false;
        return true;
    }

    // * * * Fechas * * *
    actualizarFechaInicio($event){
        if($event && $event.value) {
            this.fechaInicio = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    actualizarFechaFin($event){
        if($event && $event.value) {
            this.fechaFin = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    // * * * Aseguradoras * * *
    async obtenerAseguradoras() {
        this.cargando = true;
        var res = await this.aseguradorasService.obtenerTodasAseguradoras();
        if(!res.error) {
            this.aseguradoras = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    marcarTodasAseguradoras() {
        let desmarcar = false;
        if(this.idsAseguradoras.length >= this.aseguradoras.length - 2) {
            desmarcar = true;
        }

        this.idsAseguradoras = [];
        if(!desmarcar) {
            for (const aseguradora of this.aseguradoras) {
                this.idsAseguradoras.push(aseguradora.id);
            }
        }
    }

    marcarTodosAgentes() {
        let desmarcar = false;
        if(this.idsAgentes.length >= this.agentes.length - 2) {
            desmarcar = true;
        }

        this.idsAgentes = [];
        if(!desmarcar) {
            for (const agente of this.agentes) {
                this.idsAgentes.push(agente.id);
            }
        }
    }

    async obtenerRamos() {
        this.cargando = true;
        var res = await this.ramosService.obtenerTodosRamos();
        if(!res.error) {
            this.ramos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    marcarTodosRamos() {
        let desmarcar = false;
        if(this.idsRamos.length >= this.ramos.length - 2) {
            desmarcar = true;
        }

        this.idsRamos = [];
        if(!desmarcar) {
            for (const aseguradora of this.ramos) {
                this.idsRamos.push(aseguradora.id);
            }
        }
    }

    async visualizarEstadoCuentaPDF(){
        this.cargando = true;
        // var res = await this.service.descargarPDFEstadoCuenta(
        //     this.AgrupadorId,
        //     this.idsPagadores,
        //     this.idsClientes,
        //     this.idsAseguradoras,
        //     this.idsRamos,
        //     this.idsPolizas,
        //     this.idsCertificados,
        //     this.fechaInicio,
        //     this.fechaFin,
        //     this.incluirPorCobrar,
        //     this.incluirCobrados,
        //     this.incluirNotasCredito,
        //     this.incluirNotasCreditoPendientes,
        //     this.tipoAgrupador,
        //     this.camposAMostrar,
        //     true
        // );
        // if (res.error) this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        // this.cargando = false;

        // const fileReader = new FileReader();
        // fileReader.onload = () => {
        //     const pdfSrc = new Uint8Array(fileReader.result as ArrayBuffer);
        //     this.abrirPreviewDocumento(pdfSrc)
        // };
        // await fileReader.readAsArrayBuffer(res.data.blob);
    }

    abrirPreviewDocumento(pdfSrc) {
          const dialogRef = this.dialog.open(DialogPreviewDownloadDocumentComponent, {
              data: {
                  pdfSrc: pdfSrc,
                  titulo: 'Estado de cuenta',
              }
          });
          dialogRef.afterClosed().subscribe(async result => {
              if (result) {

              }
          });
    }

    generarJSONFiltros(): any {
        let jsonFiltros = {};
        if(this.idsAseguradoras) jsonFiltros['idsAseguradoras'] = this.idsAseguradoras;
        if(this.idsRamos) jsonFiltros['idsRamos'] = this.idsRamos;
        
        if(this.fechaInicio) jsonFiltros['fechaInicio'] = this.fechaInicio;
        if(this.fechaFin) jsonFiltros['fechaFin'] = this.fechaFin;
        
        return jsonFiltros;
    }

    cargarFiltrosIniciales() {
        if(this.filtrosIniciales) {
            if(this.filtrosIniciales.idsAseguradoras) this.idsAseguradoras = this.filtrosIniciales.idsAseguradoras;
            if(this.filtrosIniciales.idsRamos) this.idsRamos = this.filtrosIniciales.idsRamos;
            if(this.filtrosIniciales.fechaInicio) {
                this.fechaInicio = this.filtrosIniciales.fechaInicio;
                this.fechaInicioDate = new Date(this.fechaInicio);
            }
            if(this.filtrosIniciales.fechaFin) {
                this.fechaFin = this.filtrosIniciales.fechaFin;
                this.fechaFinDate = new Date(this.fechaFin);
            }
        }
    }

    async obtenerAgentes() {
        this.cargando = true;
        var res = await this.usuariosService.obtenerTodosAgentes();
        if(!res.error) {
            this.agentes = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerMonedas() {
        this.cargando = true;
        let EmpresaId = await this.auth.idEmpresaActual();
        var res = await this.empresasService.obtenerTodasMonedasEmpresa(EmpresaId);
        if(!res.error) {
            this.monedas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }
}