export class DocumentoEnvioReclamo {
    constructor(
        public id: number,
        public titulo: string,
        public descripcion: string,
        public entregado: boolean,
        public enviarAAseguradora: boolean,
        public enviarACliente: boolean,
        public devolverAAseguradora: boolean,

        // Relaciones
        public ReclamoId:         number,
        public EmpresaId:         number,
    ){}
}