import { Certificado } from './certificado';

export class IncisoHistoricoCertificado {
    constructor(
        public id: number,
        public nombre: string,
        public declarativo: boolean,
        public descripcion: string,
        public sumaAsegurada: number,
        public primaFacturada: number,
        public primaSinDependientes: number,
        public primaCon1Dependiente: number,
        public primaConMasDependientes: number,
        public primaAnualizada: number,
        public primaEnDeposito: number,
        public tipoCambio: number,
        public porcentajeTasa: number,
        public porcentajePrimaDeposito: number,
        public tarifa: number,
        public tipoTasa: string,
        public estado: string,
        public fechaVigenciaInicio: string,
        public fechaVigenciaFin: string,
        public fechaAlta: string,
        public fechaBaja: string,
        public fechaReporteAlta: string,
        public fechaReporteBaja: string,
        public CertificadoId: number,
        public EmpresaId: number,
        public RazonPerdidaId: number,
    ){}

    certificado: Certificado;
    seleccionado: boolean = false;
    // coberturasSinAsignar: Cobertura[];
    // coberturas: Cobertura[] = [];
    // idsCoberturas: number[] = [];
    // deducibles: DeducibleCertificado[] = [];
    // idsDeducibles: number[] = [];
    busquedaCoberturasSinAsignar: string;
    busquedaCoberturasAsignadas: string;
    fechaVigenciaInicioFormato: string;
    fechaVigenciaInicioDate: Date;
    fechaVigenciaFinFormato: string;
    fechaVigenciaFinDate: Date;
    fechaAltaFormato: string;
    fechaAltaDate: Date;
    fechaBajaFormato: string;
    fechaBajaDate: Date;
    fechaReporteAltaFormato: string;
    fechaReporteAltaDate: Date;
    fechaReporteBajaFormato: string;
    fechaReporteBajaDate: Date;
    nombreEstado: string;
    diasProrrateo: string;
    porcentajeProrrateo: number;
}