import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconCandadoDetalle from '@iconify/icons-fa-solid/lock';
import iconCandadoEditar from '@iconify/icons-fa-solid/lock-open';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { Cobertura } from 'src/app/models/cobertura';
import { PolizasService } from '../polizas.service';
import { Certificado } from 'src/app/models/certificado';
import { Declaracion } from 'src/app/models/declaracion';
import { Poliza } from 'src/app/models/poliza';
import { DetalleDeclaracion } from 'src/app/models/detalleDeclaracion';
import { GenericDialogConfirmacionOpciones } from 'src/app/library/generic-dialog-confirmacion-opciones/generic-dialog-confirmacion-opciones.component';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-nuevo-declaracion',
    templateUrl: './nuevo-declaracion.component.html',
    styleUrls: ['./nuevo-declaracion.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class NuevoDeclaracionComponent implements OnInit {
    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconCandadoDetalle = iconCandadoDetalle;
    iconCandadoEditar = iconCandadoEditar;

    // Banderas y generales
    cargando: boolean = false;
    modoEdicion: boolean;
    modoDetalle: boolean;
    modoCreacion: boolean;
    titulo: string;
    apiURLs: APIUrls = new APIUrls();

    // Datos
    id: number;
    CertificadoId: number;
    IncisoCertificadoId: number;
    registro: Declaracion;
    certificado: Certificado;
    poliza: Poliza;

    rutaArchivoDetalles: string;
    archivoDetalles: File;

    crumbs = [
        { nombre: 'Editar póliza', link: '/polizas/editar' },
        { nombre: 'Editar certificado', link: '/polizas/editar-certificado', queryParams: { tab: 'declaraciones' } },
        { nombre: 'Editar declaración', link: '/polizas/editar-declaracion' },
    ];

    constructor(
        public service: PolizasService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * *
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.obtenerRegistro();
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        this.modoCreacion = ruta === 'nuevo-declaracion';
        this.modoEdicion = ruta === 'editar-declaracion';
        this.modoDetalle = ruta === 'detalle-declaracion';
        if(params['id']){
            this.id = parseInt(params['id']);
        }
        if(params['CertificadoId']){
            this.CertificadoId = parseInt(params['CertificadoId']);
        }
        if(params['IncisoCertificadoId']){
            this.IncisoCertificadoId = parseInt(params['IncisoCertificadoId']);
        }
    }

    // * * * * * Obtener datos * * * * *
    // Obtener el registro o inicializar uno nuevo
    async obtenerRegistro() {
        this.cargando = true;
        if(this.modoCreacion) {
            // Asignar título
            this.titulo = 'Agregar declaración';
            this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
            // Inicializar registro
            var resCertificado = await this.service.obtenerCertificadoPorId(this.CertificadoId);
            if(!resCertificado.error) {
                this.certificado = resCertificado.data.registro;
                this.poliza = this.certificado.poliza;
                this.crumbs[0].link = '/polizas/editar/' + this.certificado.PolizaId;
                this.crumbs[1].link = '/polizas/editar-certificado/' + this.certificado.id;
                this.crumbs[2].link = '/polizas/nuevo-declaracion/' + this.certificado.id;

                // Obtener tasa por defecto
                var tasaDefault = null;
                if(this.certificado && this.certificado.incisos) {
                    for (let i = 0; i < this.certificado.incisos.length; i++) {
                        const element = this.certificado.incisos[i];
                        if(element.declarativo && element.porcentajeTasa) tasaDefault = element.porcentajeTasa;
                    }
                }

                this.registro = new Declaracion(-1, null, null, null, null, tasaDefault, null, null, null, null, null, this.CertificadoId, this.IncisoCertificadoId, this.auth.idEmpresaActual());
            }
            else {
                this.router.navigate(['/404']);
            }
        }
        else if(this.modoEdicion || this.modoDetalle) {
            if(this.modoEdicion) this.titulo = 'Editar declaración';
            if(this.modoDetalle) this.titulo = 'Detalle de declaración';
            // Obtener registro
            var res = await this.service.obtenerDeclaracionPorId(this.id);
            if(!res.error) {
                this.registro = res.data.registro;
                this.certificado = this.registro.certificado;
                this.poliza = this.certificado.poliza;
                this.CertificadoId = this.registro.CertificadoId;
                this.crumbs[0].link = '/polizas/editar/' + this.certificado.PolizaId;
                this.crumbs[1].link = '/polizas/editar-certificado/' + this.certificado.id;
                this.crumbs[2].link = '/polizas/editar-declaracion/' + this.id;
                // Asignar título
                this.titleService.setTitle(this.registro.descripcion + this.apiURLs.nombreSitioTitle);
            }
            else {
                this.router.navigate(['/404']);
            }
        }
        this.cargando = false;
    }

    // * * * * * Editar datos * * * * *
    abrirGuardar() {
        if(this.registro.hasta && !this.registro.fueDeclarado) {
            let pasado = moment(this.registro.hasta).isBefore(moment());
            if(pasado) {
                const dialogRef = this.dialog.open(GenericDialogConfirmacionOpciones, {
                    data: {
                        titulo: 'Marcar como declarada',
                        mensaje: '¿Marcar esta declaración como declarada?',
                        opciones: [
                            { id: 'no-marcar', nombre: 'No marcar' },
                            { id: 'marcar', nombre: 'Marcar como declarada' },
                        ]
                    }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if(result == 'marcar') {
                        this.registro.fueDeclarado = true;
                    }
                    
                    this.guardar();
                });
                return;
            }
        }
        this.guardar();
    }

    // Guardar datos
    async guardar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res;
        if(this.modoCreacion) {
            res = await this.service.crearDeclaracion(this.registro);
        }
        else if (this.modoEdicion) {
            res = await this.service.actualizarDeclaracion(this.registro);
        }

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            if(this.modoCreacion) {
              this.router.navigate(['/polizas/editar-certificado/' + this.registro.CertificadoId], { queryParams: { tab: 'declaraciones' } });
            }
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // Eliminar datos
    abrirEliminar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar declaración',
                mensaje: '¿Está seguro de que desea eliminar esta declaración mensual?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminar();
            }
        });
    }

    async eliminar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.eliminarDeclaracion(this.id);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.router.navigate(['/polizas/editar-certificado/' + this.CertificadoId], { queryParams: { tab: 'declaraciones' } });
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * * * Validaciones * * * * *
    formularioValido() {
        if(!this.registro) return false;
        return true;
    }

    actualizarFechaDetalle($event, index){
        if($event && $event.value) {
            this.registro.detalles[index].fechaInicioEmbarque = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    actualizarDesde($event){
        if($event && $event.value) {
            this.registro.desde = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    actualizarHasta($event){
        if($event && $event.value) {
            this.registro.hasta = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    calcularPrima() {
        if(!this.registro.ingresoDetalle) {
            if(this.registro.montoDeclarado == null || this.registro.tasa == null) {
                this.registro.primaNeta = 0;
            }
            else {
                this.registro.primaNeta = (this.registro.tasa / 100) * this.registro.montoDeclarado;
            }
        } else {
            this.registro.primaNeta = 0;
            this.registro.montoDeclarado = 0;
            if(this.registro.detalles) {
                for (const detalle of this.registro.detalles) {
                    detalle.totalDeclarado = detalle.valorEmbarque + detalle.gastosAdicionales;
                    if(detalle.tasa) detalle.primaNeta = (detalle.tasa / 100) * detalle.totalDeclarado;

                    if(detalle.primaNeta) this.registro.primaNeta += detalle.primaNeta;
                    if(detalle.totalDeclarado) this.registro.montoDeclarado += detalle.totalDeclarado;
                }
            }
        }
    }

    regresarACertificado() {
        this.router.navigate(['/polizas/editar-certificado/' + this.CertificadoId], { queryParams: { tab: 'declaraciones' } });
    }

    eliminarDetalle(index) {
        if(this.registro.detalles) {
            this.registro.detalles.splice(index, 1);
        }
    }

    agregarDetalle() {
        let detalle = new DetalleDeclaracion(null, null, null, null, null, null, null, null, null, null, null, null, null, this.registro.id, this.auth.idEmpresaActual());
        detalle.tasa = this.registro.tasa;
        // if(this.certificado.valores) {
        //     let valorTasa = this.certificado.valores.find(x => x.campo && x.campo.nombre && x.campo.nombre.trim().toLowerCase() == 'tasa');
        //     if(valorTasa && valorTasa.valor) {
        //         let tasa: any = valorTasa.valor.replace('%', '');
        //         tasa = parseFloat(tasa);
        //         detalle.tasa = tasa;
        //     }
        // }
        if(!this.registro.detalles) this.registro.detalles = [];
        this.registro.detalles.push(detalle);
    }

    async descargarPlantillaDetallesDeclaracion(){
        this.cargando = true;
        // Enviar el registro para guardar
        var res = await this.service.descargarExcelPlantillaDetallesDeclaracion('Plantilla de detalles de declaracion.xlsx');

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    seleccionarArchivoDetalleDeclaracion(event: any){
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.rutaArchivoDetalles = file.name;
            this.archivoDetalles = file;
            this.analizarArchivoExcelPagos();
        }
    }

    async analizarArchivoExcelPagos() {
        if(this.archivoDetalles) {
            this.cargando = true;
            var resLectura = await this.service.lecturaImportacionDetallesDeclaracion(this.archivoDetalles, this.auth.idEmpresaActual(), this.id);
            if(resLectura.error) {
                // Error al leer archivo
                this.libraryService.crearNotificacion(resLectura.mensaje, 'danger');
                this.cargando = false;
            }
            else {
                let registros = resLectura.resultados;
                this.registro.detalles = registros;
                this.calcularPrima();
            }
            this.cargando = false;
        }
    }

    activarSeleccionArchivoDetalleDeclaracion(){
        this.limpiarArchivoDetalleDeclaracion();
        document.getElementById('file-input-archivo-detalle').click();
    }

    limpiarArchivoDetalleDeclaracion(){
        this.rutaArchivoDetalles = null;
        this.archivoDetalles = null;
    }
}
