import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { APIUrls } from '../../api/apiUrls';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { JSONConverters } from 'src/app/models/JSONConverters';
import iconEdit from '@iconify/icons-fa-solid/edit';
import iconList from '@iconify/icons-fa-solid/list';
import iconPlus from '@iconify/icons-fa-solid/plus';
import { CobrosService } from '../cobros.service';
import { AuthService } from 'src/app/auth/auth.service';
import { LibraryService } from 'src/app/library/library.service';
import { DialogAplicarPago } from '../dialog-aplicar-pago/dialog-aplicar-pago.component';
import { MatDialog } from '@angular/material';

@Component({
    selector: 'app-planillas',
    templateUrl: './planillas.component.html',
    styleUrls: ['./planillas.component.scss']
})
export class PlanillasCobrosComponent implements OnInit {
    // Templates de celdas
    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;
    @ViewChild('cellBotones', { static: true }) cellBotones: TemplateRef<any>;
    @ViewChild('cellPlanilla', { static: true }) cellPlanilla: TemplateRef<any>;
    @ViewChild('cellMoneda', { static: true }) cellMoneda: TemplateRef<any>;
    @ViewChild('cellImagen', { static: true }) cellImagen: TemplateRef<any>;

    apiURLs = new APIUrls();
    jsonConverters = new JSONConverters();

    // Columnas de tabla
    columnas = [
        { prop: 'id', name: 'Número', sortable: true, cellTemplate: null },
        { prop: 'fechaFormato', name: 'Fecha', sortable: true, cellTemplate: null },
        { prop: 'usuario.nombreCompleto', name: 'Usuario', sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    campos = [
        { nombre: 'Número', campo: 'id', tipo: 'texto', categorias: null },
        { nombre: 'No. cheque', campo: 'numeroCheque', tipo: 'texto', categorias: null },
        { nombre: 'Fecha', campo: 'fecha', tipo: 'rango-fechas', categorias: null },
    ];

    urlBusqueda = this.apiURLs.baseURL + this.apiURLs.planillasCobrosURL + '/search';
    urlAutocompleteAseguradoras = this.apiURLs.baseURL + this.apiURLs.aseguradorasURL + '/search';

    // Filtros
    filtros = [
        // No. de póliza
        { nombre: 'Forma de pago', campo: 'formaPago', tipo: 'select-unico', valor: null, mostrar: false, categorias: this.apiURLs.formasCobroCreacion },
        // Aseguradora
        {
            nombre: 'Aseguradora', campo: 'aseguradora', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteAseguradoras, seleccionados: [], mostrar: false,
            idProp: 'id', titleProp: 'nombre', fieldSearch: 'nombre',
            parser: this.jsonConverters.aseguradoraDeJSON, parserService: this.jsonConverters,
        },
    ];

    crumbs = [
        { nombre: 'Cobros', link: '/cobros/cobrado' },
        { nombre: 'Planillas', link: '/cobros/planillas' },
    ];

    titulo: string = 'Planillas de cobro';
    mostrarTabla: boolean = false;
    iconEdit = iconEdit;
    iconList = iconList;
    iconPlus = iconPlus;
    cargando: boolean = false;

    constructor(
        public service: CobrosService,
        public auth: AuthService,
        public libraryService: LibraryService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        private dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.agregarFiltros();
        this.agregarTemplates();
        this.mostrarTabla = true;
    }

    obtenerRuta(params: Params) {
        if(this.route.snapshot.url.length > 0) {
            var ruta = this.route.snapshot.url[0].path;
        }
        this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
    }

    agregarTemplates() {
        this.columnas.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
        //this.columnas[4].cellTemplate = this.cellMoneda;
    }

    async agregarFiltros() {

    }

    calcularParamsEspeciales(): string {
        var paramsEspeciales = '';

        return paramsEspeciales;
    }
}
