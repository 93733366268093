import { AreaRamo } from './areaRamo';
import { RazonRamo } from './razonRamo';
import { TipoSiniestro } from './tipoSiniestro';

export class Ramo {
    constructor(
        public id: number,
        public nombre: string,
        public observaciones: string,
        public tipoBeneficiario: string,
        public mostrarFacturasReclamo: boolean,
        public gastosMedicos: boolean,
        public activo: boolean,
        public usarDatosVehiculos: boolean,
        public usarReclamoMonedaInternacional: boolean,
        public esconderPrimasDiferenciadasDependientes: boolean,
        public liquidacionReclamoGastosMedicosMayores: boolean,
        public codigoAnterior: string,
        public AreaRamoId: number,
        public EmpresaId: number,
    ){}

    area: AreaRamo;
    razones: RazonRamo[];
    tiposSiniestros: TipoSiniestro[];
    nombreTipoBeneficiario: string = '';
    nombreAreaRamo: string = '';
}