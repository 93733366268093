import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { LibraryService } from 'src/app/library/library.service';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconEdit from '@iconify/icons-fa-solid/edit';
import { PolizasService } from '../polizas.service';
import { Poliza } from 'src/app/models/poliza';
import { Certificado } from 'src/app/models/certificado';
import { APIUrls } from 'src/app/api/apiUrls';
import { Usuario } from 'src/app/models/usuario';
import { PageEvent } from '@angular/material';

export interface DialogData {
    modo: string,
    polizaDefault: Poliza,
}

@Component({
    selector: 'vex-dialog-buscador-global',
    templateUrl: './dialog-buscador-global.component.html',
    styleUrls: ['./dialog-buscador-global.component.scss']
})
export class DialogBuscadorGlobalComponent implements OnInit {
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconEdit = iconEdit;
    cargando: boolean = false;
    busqueda: string;
    polizasPorNumero: Poliza[];
    totalPolizasPorNumero: number;
    polizasPorCliente: Poliza[];
    certificados: Certificado[];
    certificadosPolizaDefault: Certificado[];
    clientes: Usuario[];
    apiURLs = new APIUrls();
    titulo = 'Buscador global';
    tamanoPagina: number = 15;
    tamanoPaginaPolizasNumero: number = 15;
    pagina: number = 0;
    paginaPolizasNumero: number = 0;
    selectedIndex: number = 0;
    soloActivas: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<DialogBuscadorGlobalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public service: PolizasService,
        private libraryService: LibraryService,
        private router: Router,
        public dialog: MatDialog,
        public auth: AuthService
    ) { }

    ngOnInit(): void {
        // Arreglar estilo
        var dialog = document.getElementById("dialogInscripcionTorneo");
        var dialogContainer = null;
        if(dialog) dialogContainer = dialog.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer.className += ' dialogOverflow';

        switch(this.data.modo) {
            case 'buscador-global': this.titulo = 'Buscador global'; break;
            case 'poliza-certificado': this.titulo = 'Buscador de póliza y certificado'; break;
        }

        if(this.data.polizaDefault) {
            this.obtenerCertificadosPolizaDefault();
        }
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    triggerBuscar() {
        let busquedaSearch = this.busqueda;
        setTimeout(() => {
            if (
                busquedaSearch == this.busqueda
            ) {
                this.buscar()
            }
        }, 500);
    }

    async buscar(reiniciar = false) {
        this.cargando = true;

        if(reiniciar) {
            this.pagina = 1;
            this.paginaPolizasNumero = 1;
            this.data.polizaDefault = null;
        }

        // Obtener registro
        let otrosParams = '';
        if(this.data.polizaDefault) otrosParams += '&PolizaId=' + this.data.polizaDefault.id;
        otrosParams += '&tamanoPaginaPolizasNumero=' + this.tamanoPaginaPolizasNumero;
        otrosParams += '&paginaPolizasNumero=' + this.paginaPolizasNumero;
        if(this.soloActivas) otrosParams += '&soloActivas=1';

        var res = await this.service.buscadorGlobal(this.busqueda, otrosParams);
        if(!res.error) {
            this.polizasPorNumero = res.data.polizasPorNumero;
            this.totalPolizasPorNumero = res.data.totalPolizasPorNumero;
            this.polizasPorCliente = res.data.polizasPorCliente;
            this.certificados = res.data.certificados;
            this.clientes = res.data.clientes;
        }
        else {
            
        }
        this.cargando = false;
    }

    async obtenerCertificadosPolizaDefault() {
        this.cargando = true;
        if(this.data.polizaDefault) {
            var res = await this.service.obtenerTodosCertificadosPoliza(this.data.polizaDefault.id);
            if(!res.error) {
                this.certificadosPolizaDefault = res.data.registros;
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    clickPoliza(poliza: Poliza) {
        let PolizaId = poliza.id;
        if(this.data.modo == 'buscador-global') {
            if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.ESCRITURA)) {
                this.router.navigate(['/polizas/editar/' + PolizaId]);
            }
            else if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.LECTURA)) {
                this.router.navigate(['/polizas/detalle/' + PolizaId]);
            }
            this.cerrar();
        }
        else if(this.data.modo == 'poliza-certificado') {
            this.dialogRef.close({
                poliza: poliza,
            });
        }
    }

    clickCliente(cliente: Usuario) {
        let UsuarioId = cliente.id;
        if(this.data.modo == 'buscador-global') {
            if(this.auth.tienePermisoPara(this.apiURLs.modulos.CLIENTES.id, this.apiURLs.nivelesAutorizacion.ESCRITURA)) {
                this.router.navigate(['/clientes/editar/' + UsuarioId]);
            }
            else if(this.auth.tienePermisoPara(this.apiURLs.modulos.CLIENTES.id, this.apiURLs.nivelesAutorizacion.LECTURA)) {
                this.router.navigate(['/clientes/detalle/' + UsuarioId]);
            }
            this.cerrar();
        }
        else if(this.data.modo == 'poliza-certificado') {
            this.dialogRef.close({
                cliente: cliente,
            });
        }
    }
    
    clickCertificado(certificado: Certificado) {
        let CertificadoId = certificado.id;
        if(this.data.modo == 'buscador-global') {
            if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.ESCRITURA)) {
                this.router.navigate(['/polizas/editar-certificado/' + CertificadoId]);
            }
            else if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.LECTURA)) {
                this.router.navigate(['/polizas/detalle-certificado/' + CertificadoId]);
            }
            this.cerrar();
        }
        else if(this.data.modo == 'poliza-certificado') {
            this.dialogRef.close({
                certificado: certificado,
                poliza: certificado.poliza,
            });
        }
    }

    pageUpdate($event: PageEvent) {
        this.pagina = $event.pageIndex;
        this.tamanoPagina = $event.pageSize;
        this.buscar();
    }

    pageUpdatePolizasNumero($event: PageEvent) {
        this.paginaPolizasNumero = $event.pageIndex;
        this.tamanoPaginaPolizasNumero = $event.pageSize;
        this.buscar();
    }
}
