import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { CobrosService } from 'src/app/cobros/cobros.service';
import { LibraryService } from '../library.service';

export interface DialogData {
    titulo: string,
    mensaje: string,
    errores: any[],
}

@Component({
    selector: 'generic-dialog-errores',
    templateUrl: './generic-dialog-errores.component.html',
})
export class GenericDialogErrores {
    constructor(
        public dialogRef: MatDialogRef<GenericDialogErrores>,
        public auth: AuthService,
        public service: CobrosService,
        public libraryService: LibraryService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}
    cargando = false;

    ngOnInit() {
        // Arreglar estilo
        var dialog = document.getElementById("dialogOverflow");
        var dialogContainer = null;
        if(dialog) dialogContainer = dialog.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer.className += ' dialogOverflow';
    }

    cerrar() {
        this.dialogRef.close('cerrar');
    }

    confirmar() {
        this.dialogRef.close('confirmar');
    }

    async descargarExcel() {
        this.cargando = true;
        // Enviar el registro para guardar
        var res = await this.service.descargarExcelErrores('Errores', this.data.errores);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }
}