import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { LibraryService } from 'src/app/library/library.service';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconEdit from '@iconify/icons-fa-solid/edit';
import iconSearch from '@iconify/icons-fa-solid/search';
import { DocumentoPoliza } from 'src/app/models/documentoPoliza';
import { PolizasService } from '../polizas.service';
import { DialogPreviewDocumentoComponent } from 'src/app/library/dialog-preview-documento/dialog-preview-documento.component';

export interface DialogData {
    registro: DocumentoPoliza,
    modo: string
}

@Component({
    selector: 'vex-dialog-agregar-documento-poliza',
    templateUrl: './dialog-agregar-documento-poliza.component.html',
    styleUrls: ['./dialog-agregar-documento-poliza.component.scss']
})
export class DialogAgregarDocumentoPolizaComponent implements OnInit {
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconSearch = iconSearch;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconEdit = iconEdit;
    archivoCargado:boolean = false;
    cargando: boolean = false;
    titulo:string = '';

    constructor(
        public dialogRef: MatDialogRef<DialogAgregarDocumentoPolizaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private service: PolizasService,
        private libraryService: LibraryService,
        private router: Router,
        public dialog: MatDialog,
        private auth: AuthService
    ) { }

    ngOnInit(): void {
        if(this.data.modo === 'creacion'){
            this.titulo = 'Nuevo documento';
        }
        else if(this.data.modo === 'detalle'){
            this.titulo = 'Detalle de documento';
        }
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    guardar() {
        this.dialogRef.close(this.data);
    }

    abrirEliminar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar documento',
                mensaje: '¿Está seguro de que desea eliminar este documento?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminarDocumento();
            }
        });
    }

    async eliminarDocumento(){
        this.cargando = true;
        var res = await this.service.eliminarDocumento(this.data.registro.id);
        if (!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        } else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
        this.auth.buscadorReload.next();
        this.cerrar();
    }

    async seleccionarDocumentoResultado(event: any) {
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            let file: File = fileList[0];
            var rutaArchivo = file.name;
            var auxExtension = rutaArchivo.split(".");
            var extension = auxExtension[auxExtension.length-1];
            var archivo = file;
            await this.crearYSubirDocumento(archivo, rutaArchivo, extension);
        }
    }

    activarSeleccionDocumentoResultado() {
        document.getElementById('file-input-archivo').click();
    }

    async crearYSubirDocumento(archivo, rutaArchivo, extension) {
        this.cargando = true;
        if (archivo && rutaArchivo && extension) {
            var res = await this.service.guardarArchivoEnServidorPolizas(archivo);
            if (!res.error) {
                this.data.registro.link = res.data.url;
                this.data.registro.extension = extension;
                this.archivoCargado = true;
            } else {
                this.libraryService.crearNotificacion('Ha ocurrido un error al subir su documento al servidor.', 'danger');
            }
        }
        this.cargando = false;
    }

    descargarArchivoImagen() {
        if (this.data.registro.link) {
            window.open(this.data.registro.link, "_blank");
        }
    }

    cambiarEditar(){
        this.titulo = 'Edición de documento';
        this.data.modo = 'edicion';
    }

    previsualizarArchivoURL(url: string){
        // Obtener la extensión del archivo desde la URL
        const extension = url.split('.').pop().toLowerCase();

        // Verificar si la extensión es una imagen
        if (['png', 'jpg', 'jpeg'].includes(extension)) {
            this.libraryService.openModal(url)
        } else {
            this.abrirPreviewDocumentoPDF(url);
        }
    }

    abrirPreviewDocumentoPDF(urlDocumento) {
        this.cargando = true;

        const dialogRef = this.dialog.open(DialogPreviewDocumentoComponent, {
            data: {
                urlDocumento: urlDocumento,
                titulo: 'Vista previa del documento',
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {

            }
        });
        this.cargando = false;
    }
}
