import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { Aseguradora } from '../models/aseguradora';
import * as Papa from 'papaparse';

@Injectable()
export class AseguradorasService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) {
        //Papa Promise
        Papa.parsePromise = function(file) {
            return new Promise(function(complete, error) {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete,
                    error
                });
            });
        };
    }

    async obtenerAseguradoraPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.aseguradorasURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.aseguradoraDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearAseguradora(registro: Aseguradora) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.aseguradorasURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarAseguradora(registro: Aseguradora) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.aseguradorasURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarAseguradora(AseguradoraId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.aseguradorasURL + '/id/' + AseguradoraId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Aseguradora eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Desactivar
    public async desactivarAseguradora(AseguradoraId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.aseguradorasURL + '/desactivar';
            const res = await this.connection.postRequest(url, { AseguradoraId: AseguradoraId });
            return { error: null, data: { mensaje: 'Aseguradora desactivada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Activar
    public async activarAseguradora(AseguradoraId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.aseguradorasURL + '/activar';
            const res = await this.connection.postRequest(url, { AseguradoraId: AseguradoraId });
            return { error: null, data: { mensaje: 'Aseguradora activada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Obtener datos * * * * *
    async obtenerTodasAreas() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.areasURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.areaDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las áreas.');
        }
    }

    async obtenerTodasAseguradoras() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.aseguradorasURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.aseguradoraDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las aseguradoras.');
        }
    }

    // * * * * * Importación de datos * * * * *
    async lecturaImportacionAseguradoras(archivo, EmpresaId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');
            
            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = ['nombre'];
            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;
            
            // Preparar resultados según modelo
            var resultadosFinales = [];
            resultados.forEach(element => {
                var nombre = element['nombre'];
                if(nombre) nombre = nombre.trim();
                var elementoFinal = new Aseguradora(
                    null, nombre, null, null, null,
                    null, null, null, null, null, null, null, null,
                    null, null, null, null, null, null, null, null, null,
                    true, false, EmpresaId
                );

                resultadosFinales.push(elementoFinal);
            });

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async analizarImportacionAseguradoras(registros: Aseguradora[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.aseguradorasURL + '/analisisImportacionAseguradoras';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var errores = res.errores;

            return { error: null, data: {
                mensaje: 'Importación analizada con éxito',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async subirImportacionAseguradoras(registros: Aseguradora[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.aseguradorasURL + '/subirImportacionAseguradoras';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Archivos * * * * *
    async guardarArchivoEnServidorAseguradoras(archivo: File){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.aseguradorasURL + '/uploadArchivo';
            const res = await this.connection.uploadFile(url, archivo, true);
            if(!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }
}