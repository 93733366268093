
export class ConfiguracionEmpresa {
    constructor(
        public id: number,
        public smtp: string,
        public pop: string,
        public puertoSMTP: string,
        public puertoPOP: string,
        public requiereAutenticacion: boolean,
        public diasVencimientoAcceso: number,
        public minimoCaracteresContrasena: number,
        public aplicaVencimientoContrasena: boolean,
        public incisosAplicaGastosEmision: string,
        public incisosNoProrrateo: string,
        public tiposClientesRiesgo: string,
        public paisesRiesgoAlto: string,
        public paisesRiesgoMedio: string,
        public tiposActivosPropiosRiesgo: string,
        public caracteresRiesgo: string,
        public valoresMontoAseguradoRiesgo: string,
        public mediosPagoRiesgo: string,
        public ingresosOrigenRecursosRiesgo: string,
        public rangosRiesgo: string,
        public EmpresaId: number,
    ){}

    incisosAplicaGastosEmisionArray: any[] = [];
    incisosNoProrrateoArray: any[] = [];

    tiposClientesRiesgoArray: any[] = [];
    paisesRiesgoAltoArray: any[] = [];
    paisesRiesgoMedioArray: any[] = [];
    tiposActivosPropiosRiesgoArray: any[] = [];
    caracteresRiesgoArray: any[] = [];
    valoresMontoAseguradoRiesgoArray: any[] = [];
    mediosPagoRiesgoArray: any[] = [];
    ingresosOrigenRecursosRiesgoArray: any[] = [];
    rangosRiesgoArray: any[] = [];
}