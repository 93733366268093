import { IncisoPlantillaRamo } from "./incisoPlantillaRamo";
import { Ramo } from "./ramo";

export class PlantillaRamo {
    constructor(
        public id: number,
        public nombre: string,
        public descripcion: string,
        public notasAdicionales: string,
        public eliminado: boolean,
        public activo: boolean,
        public RamoId: number,
        public EmpresaId: number,
    ){}

    incisos: IncisoPlantillaRamo[];
    ramo: Ramo;
    nombreRamo: string = '';
}
