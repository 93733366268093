import { Zona } from "./zona";

export class Municipio {
    constructor(
        public id: number,
        public nombre: string,
        public PaisId: number,
        public DepartamentoId: number,
    ){}

    zonas: Zona[];
}