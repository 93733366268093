import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import { Usuario } from '../../models/usuario';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconRefresh from '@iconify/icons-fa-solid/sync';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { Ramo } from 'src/app/models/ramo';
import { RamosService } from 'src/app/ramos/ramos.service';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
import { Chart } from 'angular-highcharts';
import { Aseguradora } from 'src/app/models/aseguradora';
import { AseguradorasService } from 'src/app/aseguradoras/aseguradoras.service';
import { CobrosService } from '../cobros.service';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-analytics-comisiones',
    templateUrl: './analytics-comisiones.component.html',
    styleUrls: ['./analytics-comisiones.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class AnalyticsComisionesComponent implements OnInit {
    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconRefresh = iconRefresh;
    
    // Banderas y generales
    cargando: boolean = false;
    titulo: string;
    apiURLs: APIUrls = new APIUrls();
    jsonConverters = new JSONConverters();
    
    // Datos
    data: any[];
    tiposGraficas = this.apiURLs.tiposGraficasComisiones;
    xAxisTipos = this.tiposGraficas.xAxis;
    yAxisTipos = this.tiposGraficas.yAxis;
    seriesTipos = this.tiposGraficas.series;
    xAxis = this.xAxisTipos[0].id;
    yAxis = this.yAxisTipos[0].id;
    series = this.seriesTipos[0].id;
    primeraGrafica: boolean = true;

    // Filtros
    ramos: Ramo[];
    idsRamos: number[] = [];
    aseguradoras: Aseguradora[];
    idsAseguradoras: number[] = [];
    agentes: Usuario[];
    idsAgentes: number[] = [];
    
    // Fechas
    fechasRapidas = this.apiURLs.fechasRapidas;
    fechaInicioDate: Date;
    fechaFinDate: Date;
    fechaInicio: string;
    fechaFin: string;
    tipoFecha: string = 'ano-actual';

    // Gráfica
    chart: Chart;
    
    crumbs = [
        { nombre: 'Comisiones', link: '/cobros/comisiones' },
    ];

    constructor(
        public service: CobrosService,
        public ramosService: RamosService,
        public aseguradorasService: AseguradorasService,
        public usuariosService: UsuariosService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * * 
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.obtenerRamos();
        await this.obtenerAseguradoras();
        await this.obtenerAgentes();
        await this.obtenerData();
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        this.titulo = 'Analytics de Comisiones';
        this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
        this.auth.simboloMonedaPorDefecto()
    }

    // * * * * * Obtener data * * * * * 
    async obtenerData() {
        this.cargando = true;
        var res = await this.service.obtenerAnalytics(this.obtenerParams());
        if(!res.error) {
            this.data = res.data.data;
            this.construirGrafica();
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    // Ramos
    async obtenerRamos() {
        this.cargando = true;
        var res = await this.ramosService.obtenerTodosRamos();
        if(!res.error) {
            this.ramos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    // Aseguradoras
    async obtenerAseguradoras() {
        this.cargando = true;
        var res = await this.aseguradorasService.obtenerTodasAseguradoras();
        if(!res.error) {
            this.aseguradoras = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    // Agentes
    async obtenerAgentes() {
        this.cargando = true;
        var res = await this.usuariosService.obtenerTodosAgentes();
        if(!res.error) {
            this.agentes = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }
    
    // * * * * * Filtros * * * * *
    actualizarFechaInicio($event){
        if($event && $event.value) {
            this.fechaInicio = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    actualizarFechaFin($event){
        if($event && $event.value) {
            this.fechaFin = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    // Actualiza el id del Agente
    actualizarAgentes(items: Usuario[]) {
        var idsUsuarios: number[] = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            idsUsuarios.push(item.id);
        }
        this.idsAgentes = idsUsuarios;
    }

    // Params
    obtenerParams(): string {
        var params = '';
        
        // Fechas
        if(this.tipoFecha) params += '&tipoFecha=' + this.tipoFecha;
        if(this.fechaInicio) params += '&start=' + this.fechaInicio;
        if(this.fechaFin) params += '&finish=' + this.fechaFin;
        
        // Filtros
        if(this.idsRamos && this.idsRamos.length > 0) params += '&idsRamos=' + this.idsRamos.toString();
        if(this.idsAgentes && this.idsAgentes.length > 0) params += '&idsAgentes=' + this.idsAgentes.toString();
        if(this.idsAseguradoras && this.idsAseguradoras.length > 0) params += '&idsAseguradoras=' + this.idsAseguradoras.toString();
        
        // Tipo de gráfica
        if(this.xAxis) params += '&xAxis=' + this.xAxis;
        if(this.series) params += '&series=' + this.series;
        if(this.yAxis) params += '&yAxis=' + this.yAxis;

        return params;
    }

    // * * * Gráfica * * *
    construirGrafica() {
        if(this.data) {
            this.chart = null;
            // Obtener el periodo mínimo y máximo, y completar a partir de allí
            var minPeriodo = { anio: 2999, mes: 2999 }, maxPeriodo = { anio: 0, mes: 0 };
            for (let i = 0; i < this.data.length; i++) {
                const element = this.data[i];
                // Validar si este periodo es mínimo o máximo
                if(element.periodo) {
                    // Dividir en año y mes
                    var partes = element.periodo.split('/');
                    var anio = null;
                    var mes = null;
                    if(partes.length > 1) {
                        anio = parseInt(partes[1]);
                        mes = parseInt(partes[0]);
                    }
                    else anio = parseInt(partes[0]);

                    // Verificar si es el mínimo periodo encontrado
                    if(anio < minPeriodo.anio){
                        minPeriodo.anio = anio;
                        if(mes) minPeriodo.mes = mes;
                    }
                    else if(anio == minPeriodo.anio && mes) {
                        if(mes < minPeriodo.mes) minPeriodo.mes = mes;
                    }

                    // Verificar si es el máximo periodo encontrado
                    if(anio > maxPeriodo.anio){
                        maxPeriodo.anio = anio;
                        if(mes) maxPeriodo.mes = mes;
                    }
                    else if(anio == maxPeriodo.anio && mes) {
                        if(mes > maxPeriodo.mes) maxPeriodo.mes = mes;
                    }

                    // Limpiar en caso de que no haya mes
                    if(minPeriodo.mes == 2999) minPeriodo.mes = null;
                    if(maxPeriodo.mes == 0) maxPeriodo.mes = null;
                }
            }
            
            // Agregar las categorías (x-Axis)
            var categorias: string[] = [];
            var periodoActual = { anio: minPeriodo.anio, mes: minPeriodo.mes };
            do {
                var nombreCategoria = '';
                if(periodoActual.mes) nombreCategoria += periodoActual.mes;
                if(nombreCategoria != '') nombreCategoria += '/';
                nombreCategoria += periodoActual.anio;
                if(periodoActual.mes) {
                    periodoActual.mes++;
                    if(periodoActual.mes > 12) {
                        periodoActual.mes = 1;
                        periodoActual.anio++;
                    }
                }
                else periodoActual.anio++;
                categorias.push(nombreCategoria);
            } while(
                (!periodoActual.mes && periodoActual.anio <= maxPeriodo.anio) || 
                (periodoActual.mes && (
                    (periodoActual.anio < maxPeriodo.anio) ||
                    (periodoActual.anio == maxPeriodo.anio && periodoActual.mes <= maxPeriodo.mes)
                ))
            );

            // Sobreescribir categorías para Todo
            if(this.xAxis == 'todo') categorias = ['Todo'];
            
            // Agregar los valores (y-Axis)
            var series = [];
            for (let i = 0; i < this.data.length; i++) {
                const element = this.data[i];
                // Verificar si está dentro de las series existentes
                var indexSerie = this.libraryService.indexOf(series, 'id', element.serie);
                if(indexSerie == -1) {
                    // Nueva serie, agregarla con valores por defecto cero
                    var valores = [];
                    for (let j = 0; j < categorias.length; j++) valores.push(0);
                    var nombreSerie = this.nombreDeSerie(element.serie);
                    var nuevaSerie = { id: element.serie, name: nombreSerie, data: valores };
                    series.push(nuevaSerie);
                    indexSerie = series.length - 1;
                }

                // Agregar el total al valor correspondiente
                var indexCategoria = categorias.indexOf(element.periodo);
                if(indexCategoria != -1) {
                    series[indexSerie].data[indexCategoria] = element.total;
                }
            }

            // Encontrar nombre de y-Axis
            var yAxisNombre = '';
            switch(this.yAxis) {
                case 'comision-agente': {
                    yAxisNombre = 'Comisión de agente'
                    break;
                }
                case 'comision-empresa': {
                    yAxisNombre = 'Comisión de empresa'
                    break;
                }
                case 'comision-productor-externo': {
                    yAxisNombre = 'Comisión de productor externo'
                    break;
                }
                case 'prima-neta': {
                    yAxisNombre = 'Prima neta estimada'
                    break;
                }
            }
            
            // Construir elemento de Chart
            this.chart = new Chart({
                chart: {
                    type: 'column'
                },
                title: {
                    text: 'Reporte de comisiones'
                },
                xAxis: {
                    categories: categorias,
                },
                series: series,
                yAxis: {
                    min: 0,
                    title: {
                        text: yAxisNombre,
                    }
                }
            });
            
            if(this.primeraGrafica) {
                this.primeraGrafica = false;
                this.obtenerData();
            }
        }
    }

    nombreDeSerie(id: string) {
        if(id) {
            switch(this.series) {
                case 'agente':
                case 'ramo':
                default: {
                    return id;
                }
            }
        }
    }
}
