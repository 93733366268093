import { Usuario } from './usuario';

export class PagadorPoliza {
    constructor(
        public id: number,
        public nombre: string,
        public porcentaje: number,
        public ClienteId: number,
        public PolizaId: number,
    ){}

    cliente: Usuario;
    clientes: Usuario[] = [];
}