export class DocumentoMensajeria {
    constructor(
        public id:              number,
        public nombre:          string,
        public descripcion:     string,
        public extension:       string,
        public link:            string,

        // Relaciones
        public MensajeriaId:         number,
        public EmpresaId:         number,
    ){}
}