import { APIUrls } from '../api/apiUrls';

export class Area {
    constructor(
        public id: number,
        public nombre: string,
        public descripcion: string,
        public clientes: string,
        public polizas: string,
        public prospectos: string,
        public comisiones: string,
        public reclamos: string,
        public emergencias: string,
        public cobros: string,
        public controlCalidad: string,
        public mensajeria: string,
        public mapas: string,
        public plantillasRamos: string,
        public clientesAnalytics: string,
        public polizasAnalytics: string,
        public prospectosAnalytics: string,
        public comisionesAnalytics: string,
        public reclamosAnalytics: string,
        public emergenciasAnalytics: string,
        public cobrosAnalytics: string,
        public controlCalidadAnalytics: string,
        public mensajeriaAnalytics: string,
        public mapasAnalytics: string,
        public analytics: string,
        public empresas: string,
        public datosGenerales: string,
        public paisesMonedas: string,
        public areasAccesos: string,
        public usuarios: string,
        public aseguradoras: string,
        public ramos: string,
        public formasCobro: string,
        public estatusCobro: string,
        public tiposGestion: string,
        public plantillasMapas: string,
        public matrizRiesgo: string,
        public usuariosExternos: string,
        public bitacora: string,
        public homepage: string,
        public cliente: boolean,
        public mostrarEjecutivos: boolean,
        public EmpresaId: number,
    ){}

    public tienePermisoPara(modulo: string, nivel: string) {
        var apiURLs = new APIUrls();
        var nivelesAutorizacion = apiURLs.nivelesAutorizacion;
        var nivelAutorizado = this[modulo];
        // No tiene nivel de autorización
        if(!nivelAutorizado || (nivelAutorizado == nivelesAutorizacion.NINGUNO && nivel != nivelesAutorizacion.PROPIO)) return false;
        // Comprobar los otros niveles
        switch(nivel) {
            case nivelesAutorizacion.LECTURA: {
                if(nivelAutorizado == nivelesAutorizacion.LECTURA || nivelAutorizado == nivelesAutorizacion.ESCRITURA || nivelesAutorizacion.ESCRITURA_PROPIA || nivelAutorizado == nivelesAutorizacion.ADMINISTRADOR) return true;
                break;
            }
            case nivelesAutorizacion.ESCRITURA: {
                if(nivelAutorizado == nivelesAutorizacion.ESCRITURA || nivelesAutorizacion.ESCRITURA_PROPIA || nivelAutorizado == nivelesAutorizacion.ADMINISTRADOR) return true;
                break;
            }
            case nivelesAutorizacion.ESCRITURA_PROPIA: {
                if(nivelAutorizado == nivelesAutorizacion.ESCRITURA || nivelesAutorizacion.ESCRITURA_PROPIA || nivelAutorizado == nivelesAutorizacion.ADMINISTRADOR) return true;
                break;
            }
            case nivelesAutorizacion.LECTURA_PROPIA: {
                if(nivelAutorizado == nivelesAutorizacion.LECTURA || nivelAutorizado == nivelesAutorizacion.ESCRITURA || nivelesAutorizacion.ESCRITURA_PROPIA || nivelAutorizado == nivelesAutorizacion.LECTURA_PROPIA || nivelAutorizado == nivelesAutorizacion.ADMINISTRADOR) return true;
                break;
            }
            case nivelesAutorizacion.ADMINISTRADOR: {
                if(nivelAutorizado == nivelesAutorizacion.ADMINISTRADOR) return true;
                break;
            }
            case nivelesAutorizacion.PROPIO: {
                return true;
            }
        }
        return false;
    }

    public tienePermisoExactoPara(modulo: string, nivel: string) {
        var apiURLs = new APIUrls();
        var nivelesAutorizacion = apiURLs.nivelesAutorizacion;
        var nivelAutorizado = this[modulo];
        // No tiene nivel de autorización
        if(!nivelAutorizado || (nivelAutorizado == nivelesAutorizacion.NINGUNO && nivel != nivelesAutorizacion.PROPIO)) return false;
        // Comprobar los otros niveles
        switch(nivel) {
            case nivelesAutorizacion.LECTURA: {
                if(nivelAutorizado == nivelesAutorizacion.LECTURA) return true;
                break;
            }
            case nivelesAutorizacion.ESCRITURA: {
                if(nivelAutorizado == nivelesAutorizacion.ESCRITURA) return true;
                break;
            }
            case nivelesAutorizacion.ESCRITURA_PROPIA: {
                if(nivelesAutorizacion.ESCRITURA_PROPIA) return true;
                break;
            }
            case nivelesAutorizacion.LECTURA_PROPIA: {
                if(nivelAutorizado == nivelesAutorizacion.LECTURA_PROPIA) return true;
                break;
            }
            case nivelesAutorizacion.ADMINISTRADOR: {
                if(nivelAutorizado == nivelesAutorizacion.ADMINISTRADOR) return true;
                break;
            }
            case nivelesAutorizacion.PROPIO: {
                return true;
            }
        }
        return false;
    }
}