import { Component, Inject } from '@angular/core';
import { MAT_DATE_FORMATS, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { SeguimientoCobro } from 'src/app/models/seguimientoCobro';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    seguimiento: SeguimientoCobro,
}

@Component({
    selector: 'dialog-nuevo-seguimiento-cobro',
    templateUrl: './dialog-nuevo-seguimiento-cobro.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogNuevoSeguimientoCobro {
    apiURLs = new APIUrls();
    modoCreacion: boolean = true;
    modoDetalle: boolean = false;
    seguimiento: SeguimientoCobro;

    constructor(
        public dialogRef: MatDialogRef<DialogNuevoSeguimientoCobro>,
        public service: AuthService,
        public auth: AuthService,
        private libraryService: LibraryService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit() {
        this.seguimiento = this.data.seguimiento;
        this.modoCreacion = this.seguimiento.id == null;
        this.modoDetalle = !this.modoCreacion;
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close(this.seguimiento);
    }

    registroValido(): boolean {
        var seguimiento = this.seguimiento;
        if(!seguimiento) return false;
        if(!seguimiento.observaciones || seguimiento.observaciones.trim() == '') return false;
        
        return true;
    }

    actualizarFecha($event, campo){
        if($event && $event.value) {
            this.seguimiento[campo] = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }
}