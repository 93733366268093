import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconAnalizar from '@iconify/icons-ic/twotone-search';
import { MatDialog, PageEvent } from '@angular/material';
import * as _moment from 'moment';
import { GenericDialogErrores } from 'src/app/library/generic-dialog-errores/generic-dialog-errores.component';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
import { PolizasService } from '../polizas.service';
import { RamosService } from 'src/app/ramos/ramos.service';
import { AseguradorasService } from 'src/app/aseguradoras/aseguradoras.service';
import { ClientesService } from 'src/app/clientes/clientes.service';
import { EmpresasService } from 'src/app/empresas/empresas.service';
import { ClausulaGarantiaPoliza } from 'src/app/models/clausulaGarantiaPoliza';
import { Poliza } from 'src/app/models/poliza';
import { Moneda } from 'src/app/models/moneda';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
  selector: 'vex-importacion-clausulas',
  templateUrl: './importacion-clausulas.component.html',
  styleUrls: ['./importacion-clausulas.component.scss']
})
export class ImportacionClausulasComponent implements OnInit {

    // Íconos
    iconBack = iconBack;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconAnalizar = iconAnalizar;

    // Banderas y generales
    cargando: boolean = false;
    titulo: string = 'Importación de clausulas por plantilla';
    apiURLs: APIUrls = new APIUrls();

    // Generales importación
    rutaArchivo: string;
    archivo: File;
    registros: ClausulaGarantiaPoliza[];
    erroresLectura: any[];
    analisis = false;
    tamanoPagina: number = 15;
    pagina: number = 0;
    selectedIndex: number = 0;

    // Resultado de análisis
    registrosNuevos: number;
    registrosEditados: number;
    erroresAnalisis: any[];

    // Específicos
    PolizaId: number;
    TipoCertificadoId: number;
    poliza: Poliza;
    monedas: Moneda[];

    crumbs = [
        { nombre: 'Póliza', link: '/polizas/detalle/' },
        { nombre: 'Importación de clausulas por plantilla', link: '/polizas/importacion-clausulas/' },
    ];

    constructor(
        public service: PolizasService,
        public empresasService: EmpresasService,
        public usuariosService: UsuariosService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * *
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        this.titleService.setTitle(this.titulo);
        await this.obtenerMonedas();
        await this.obtenerRegistro();
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;

        if(params['PolizaId']){
            this.PolizaId = parseInt(params['PolizaId']);
            this.crumbs[0].link = '/polizas/detalle/' + this.PolizaId;
            this.crumbs[1].link = '/polizas/importacion-clausulas/' + this.PolizaId;
        }
    }

    async obtenerRegistro() {
        this.cargando = true;
        // Asignar título
        this.titulo = 'Importar certificados de clausula - ';
        this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
        // Inicializar registro
        var resPoliza = await this.service.obtenerPolizaPorId(this.PolizaId, false);
        if(!resPoliza.error) {
            this.poliza = resPoliza.data.registro;
            if(!this.poliza) this.router.navigate(['/404']);
            this.titulo += this.poliza.nombrePoliza;
        }
        else {
            this.router.navigate(['/404']);
        }
        this.cargando = false;
    }

    // * * * * * Validaciones * * * * *
    formularioValido() {
        return true;
    }

    // * * * * * Selccionar archivo * * * * *
    seleccionarArchivo(event: any){
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.rutaArchivo = file.name;
            this.archivo = file;
            this.analisis = false;
            this.analizarArchivoExcel();
        }
    }

    activarSeleccionArchivo(){
        document.getElementById('file-input-archivo').click();
    }

    limpiarArchivo(){
        this.rutaArchivo = null;
        this.archivo = null;
        this.analisis = false;
        this.erroresLectura = null;
        this.registros = null;
        this.pagina = 0;
    }

    // * * * * * Análisis de archivo * * * * *
    async analizarArchivoExcel() {
        if(this.archivo) {
            this.cargando = true;
            var resLectura = await this.service.lecturaImportacionClausulasPlantilla(this.archivo, this.PolizaId, this.auth.idEmpresaActual(), this.monedas);
            if(resLectura.error) {
                // Error al leer archivo
                this.libraryService.crearNotificacion(resLectura.mensaje, 'danger');
                throw new Error();
            }
            else {
                this.erroresLectura = resLectura.errores;
                this.registros = resLectura.resultados;
                this.libraryService.crearNotificacion(resLectura.mensaje, 'success');
            }
            this.cargando = false;
        }
    }

    async analizarRegistros(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.analizarImportacionClausulasPlantilla(this.registros, this.PolizaId, this.auth.idEmpresaActual());

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.analisis = true;
            this.registrosNuevos = res.data.registrosNuevos;
            this.registrosEditados = res.data.registrosEditados;
            this.erroresAnalisis = res.data.errores;
            this.selectedIndex = 1;
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            this.analisis = false;
        }
        this.cargando = false;
    }

    async subirRegistros(){
        try {
            this.cargando = true;
            if(!this.analisis) throw new Error('Debe realizar analizar la importación antes de poder subirla.');
            if(!this.registros || this.registros.length == 0) throw new Error('No hay datos para subir.');

            // Enviar el registro para guardar
            var res = await this.service.subirImportacionClausulasPlantilla(this.registros, this.PolizaId, this.auth.idEmpresaActual());

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.router.navigate(['/polizas/detalle/' + this.PolizaId]);
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            this.cargando = false;
        } catch(error) {
            this.cargando = false;
            this.libraryService.crearNotificacion(error.message, 'danger');
        }
    }

    abrirDialogErrores() {
        const dialogRef = this.dialog.open(GenericDialogErrores, {
            data: {
                titulo: 'Errores de lectura de archivo CSV',
                errores: this.erroresLectura,
            }
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }

    // * * * * * Tabla * * * * *
    eliminarRegistro(index) {
        if(this.registros) {
            this.registros.splice(index, 1);
        }
    }

    pageUpdate($event: PageEvent) {
        this.pagina = $event.pageIndex;
        this.tamanoPagina = $event.pageSize;
    }

    async obtenerMonedas() {
        this.cargando = true;
        let EmpresaId = await this.auth.idEmpresaActual();
        var res = await this.empresasService.obtenerTodasMonedasEmpresa(EmpresaId);
        if(!res.error) {
            this.monedas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }
}
