export class PlantillaSolicitud {
    constructor(
        public id: number,
        public nombre: string,
        public tipo: string,
        public tipoUso: string,
        public descripcion: string,
        public titulo: string,
        public encabezados: string,
        public secciones: string,
        public aseguradora: boolean,
        public usaCertificados: boolean,
        public soloIncluidos: boolean,
        public soloExcluidos: boolean,
        public ignorarExcluidos: boolean,
        public seleccionarDeclaraciones: boolean,
        public activo: boolean,
        public eliminado: boolean,
        public idsPlantillasMapas: string,
        public idsRamos: string,
        public aplicaPolizas: boolean,
        public aplicaReclamos: boolean,
        public aplicaCobros: boolean,
        public aplicaClientes: boolean,
        public plantillaEspecial: string,
        public usarEnEnvioAseguradoraReclamos: boolean,
        public usarEnEnvioClienteReclamos: boolean,
        public esconderMontosTotales: boolean,
        public esconderAclaracionesPoliza: boolean,
        public ignorarNotasAdicionalesSolicitud: boolean,
        public usarNumeroEmision: boolean,
        public esconderPrimasSumas: boolean,
        public TipoReclamoId: number,
        public EmpresaId: number,
    ){}

    modelSecciones: Seccion[] = [];
    meta: any = {};
    nombreTipo: string;
    nombreTipoUso: string;
    idsPlantillasMapasArray: any[] = [];
    idsRamosArray: any[] = [];
}

export class Seccion {
    constructor(
        // Campos
        public numero:   number = null,
        public nombre:   string = null,
        public tipo:     string = null,
        public opciones: string[] = [],
        public campos:   CampoSeccion[] = [],
        public texto:    string = null,
        // Resultados
        public valor:    string = null,
        public id:       number = null,
        public objeto:   any = null,
        public camposResultados:   any[] = null,
    ){}
}

export class CampoSeccion {
    constructor(
        // Formulario
        public numero:   number = null,
        public nombre:   string = null,
        public tipo:     string = null,
        public opciones: string[] = [],
        // Resultados
        public valor:    string = null,
        public id:       number = null,
        public objeto:   any = null,
    ){}
}