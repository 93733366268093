export class Agrupador {
    constructor(
        public id: number,
        public nombre: string,
        public descripcion: string,
        public linea: string,
        public EmpresaId: number,
    ){}

    inactivo: boolean;
    nombreLinea: string;
}