import { Aseguradora } from './aseguradora';
import { Ramo } from './ramo';
import { Moneda } from './moneda';
import { Usuario } from './usuario';
import { Producto } from './producto';
import { DocumentoPoliza } from './documentoPoliza';
import { Agrupador } from './agrupador';
import { PorcentajeCoaseguros } from './porcentajeCoaseguros';
import { CambioEstadoPoliza } from './cambioEstadoPoliza';
import { Certificado } from './certificado';
import { PagadorPoliza } from './pagadorPoliza';
import { DocumentoCobro } from './documentoCobro';
import { RevisionPoliza } from './revisionPoliza';
import { Empresa } from './empresa';
import { RazonPerdida } from './razonPerdida';
import { Cobrador } from './cobrador';
import { AclaracionPoliza } from './aclaracionPoliza';

export class Poliza {
    constructor(
        public id: number,
        public numero: string,
        public numeroEmision: string,
        public aniosVigencia: number,
        public notasAdicionales: string,
        public tipo: string,
        public resguardo: boolean,
        public fechaIngreso: string,
        public fechaResguardo: string,
        public fechaVigenciaInicio: string,
        public fechaVigenciaFin: string,
        public horasVigencia: string,
        public caracter: string,
        public numeroPagos: number,
        public frecuenciaPago: string,
        public formaCobro: string,
        public pagosIguales: boolean,
        public estado: string,
        public borrador: boolean,
        public cobrosGenerados: boolean,
        public sumaAsegurada: number,
        public primaNeta: number,
        public tipoCambio: number,
        public ultimaVisualizacion: string,
        public estadoControlCalidad: string,
        public maximoVitalicio: boolean,
        public polizaRegional: boolean,
        public conDevolucion: boolean,
        public pagoDirecto: string,
        public polizaVidaTermino: boolean,
        public fechaFinTerminos: string,
        public facturasAnticipadas: boolean,
        public AseguradoraId: number,
        public AgrupadorId: number,
        public MonedaId: number,
        public RamoId: number,
        public ProductoId: number,
        public UsuarioId: number,
        public AgenteId: number,
        public ContactoAtencionId: number,
        public VendedorId: number,
        public EjecutivoId: number,
        public ClienteId: number,
        public CobradorId: number,
        public RazonPerdidaId: number,
        public EmpresaId: number,
        public PolizaVigenteId: number,
        public EmpresaProductoraId: number,
        public enProcesoRenovacion: boolean,
        public UsuarioRenovadorId: number,
        public fechaRenovacion: string,
    ){}

    ultimaVisualizacionFormato: string;
    fechaIngresoFormato: string;
    fechaIngresoDate: Date;
    diasIngreso: number = 0;
    fechaResguardoFormato: string;
    fechaResguardoDate: Date;
    fechaVigenciaInicioFormato: string;
    fechaVigenciaInicioDate: Date;
    fechaVigenciaFinFormato: string;
    fechaVigenciaFinDate: Date;
    fechaVigenciaInicioHace2AniosDate: Date;
    fechaFinTerminosFormato: string;
    fechaFinTerminosDate: Date;
    fechaRenovacionFormato: string;
    fechaRenovacionDate: Date;
    aseguradora: Aseguradora;
    ramo: Ramo;
    moneda: Moneda;
    razonPerdida: RazonPerdida;
    usuario: Usuario;
    agente: Usuario;
    usuarioRenovador: Usuario;
    contactoAtencion: Usuario;
    vendedor: Usuario;
    ejecutivo: Usuario;
    empresaProductora: Empresa;
    cliente: Usuario;
    cobrador: Cobrador;
    producto: Producto;
    agrupador: Agrupador;
    documentos: DocumentoPoliza[];
    documentosCobros: DocumentoCobro[];
    aclaraciones: AclaracionPoliza[];
    porcentajesCoaseguros: PorcentajeCoaseguros[];
    cambiosEstado: CambioEstadoPoliza[];
    certificados: Certificado[];
    pagadores: PagadorPoliza[];
    // Nombres para mostrar
    estadoControlCalidadNombre: string = '';
    nombreUsuarioRenovador: string = '';
    nombreAgente: string = '';
    nombreContactoAtencion: string = '';
    nombreUsuario: string = '';
    nombreEjecutivo: string = '';
    nombreVendedor: string = '';
    nombreCliente: string = '';
    nombreEmpresaProductora: string = '';
    nombreCobrador: string = '';
    nombreProducto: string = '';
    nombreAgrupador: string = '';
    nombreAseguradora: string = '';
    nombreRamo: string = '';
    nombreFrecuenciaPagos: string = '';
    nombreEstado: string = '';
    nombreTipo: string = '';
    nombreCaracter: string = '';
    nombreMoneda: string = '';
    nombreFormaCobro: string = '';
    nombrePoliza: string = '';
    nombrePolizaResumido: string = '';
    // Control de calidad
    revision: RevisionPoliza;
    deducibleString: string = '';
    totalPagadoReclamos: number;
    textoDependientesRelacionados: string = '';
}
