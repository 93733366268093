import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import moment from 'moment';
import { PolizasService } from '../polizas.service';
import iconDelete from '@iconify/icons-fa-solid/trash';
import { Certificado } from 'src/app/models/certificado';
import { Router } from '@angular/router';
import { PlantillaRamo } from 'src/app/models/plantillaRamo';
import { Poliza } from 'src/app/models/poliza';
import { RamosService } from 'src/app/ramos/ramos.service';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { Inciso } from 'src/app/models/inciso';

export interface DialogData {
    RamoId: number,
}

@Component({
    selector: 'dialog-copiar-inciso-ramo',
    templateUrl: './dialog-copiar-inciso-ramo.component.html',
})
export class DialogCopiarIncisoRamo {
    apiURLs = new APIUrls();
    incisos: Inciso[] = [];
    IncisoId: number;
    cargando: boolean;
    jsonConverters: JSONConverters = new JSONConverters();
    
    iconDelete = iconDelete;

    constructor(
        public dialogRef: MatDialogRef<DialogCopiarIncisoRamo>,
        public polizasService: PolizasService,
        public ramosService: RamosService,
        public auth: AuthService,
        public libraryService: LibraryService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
    }
    
    ngOnInit() {
        this.obtenerIncisos();
    }

    async obtenerIncisos() {
        this.cargando = true;
        var res = await this.ramosService.obtenerTodosIncisosRamo(this.data.RamoId);
        if (!res.error) {
            this.incisos = res.data.registros;
        } else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        let inciso = this.incisos.find(x => x.id == this.IncisoId);
        this.dialogRef.close(inciso);
    }
}
