import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { CobrosService } from '../cobros.service';
import { DocumentoCobro } from 'src/app/models/documentoCobro';

export interface DialogData {
    ids: number[],
    formaPago: string,
    numeroFactura: string,
    banco: string,
    numeroCheque: string,
    observaciones: string,
    generarPlanilla: boolean,
}

@Component({
    selector: 'dialog-aplicar-pago',
    templateUrl: './dialog-aplicar-pago.component.html',
})
export class DialogAplicarPago {
    apiURLs = new APIUrls();
    formasCobro = this.apiURLs.formasCobroCreacion;
    cargandoCreditos: boolean = false;
    creditos: any;
    
    constructor(
        public dialogRef: MatDialogRef<DialogAplicarPago>,
        public service: CobrosService,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit() { 
        
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close(this.data);
    }

    registroValido(): boolean {
        if(!this.data.ids || this.data.ids.length == 0) return false;
        if(!this.data.formaPago) return false;
        
        return true;
    }
}