import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardPermisos } from '../auth/auth-guard-permisos';
import { APIUrls } from '../api/apiUrls';
import { PlantillasMapasComponent } from './plantillas-mapas/plantillas-mapas.component';
import { NuevoPlantillaMapaProcesoComponent } from './nuevo-plantilla-mapa-proceso/nuevo-plantilla-mapa-proceso.component';
import { MapasProcesosComponent } from './mapas-procesos/mapas-procesos.component';
import { DetalleMapaProcesoComponent } from './detalle-mapa-proceso/detalle-mapa-proceso.component';
import { AnalyticsMapasProcesosComponent } from './analytics-mapas-procesos/analytics-mapas-procesos.component';
import { ContenedorAnalyticsMapasProcesosComponent } from './analytics-mapas-procesos/contenedor-analytics-mapas-procesos.component';
import { ReporteMapasComponent } from './reporte-mapas/reporte-mapas.component';

var apiURLs = new APIUrls();
const routes: Routes = [
    {
        path: 'plantillas',
        component: PlantillasMapasComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.DATOS_GENERALES.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'pendientes',
        component: MapasProcesosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.MAPAS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'todos',
        component: MapasProcesosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.MAPAS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'general',
        component: MapasProcesosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.MAPAS.id, nivel: apiURLs.nivelesAutorizacion.ADMINISTRADOR }
    },
    {
        path: 'creados',
        component: MapasProcesosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.MAPAS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'nuevo-plantilla',
        component: NuevoPlantillaMapaProcesoComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.DATOS_GENERALES.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'editar-plantilla/:id',
        component: NuevoPlantillaMapaProcesoComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.DATOS_GENERALES.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'detalle-mapa/:id',
        component: DetalleMapaProcesoComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.MAPAS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'analytics',
        component: ContenedorAnalyticsMapasProcesosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.MAPAS_ANALYTICS.id, nivel: apiURLs.nivelesAutorizacion.PROPIO }
    },
    {
        path: 'reporte-solicitudes',
        component: ReporteMapasComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.MAPAS_ANALYTICS.id, nivel: apiURLs.nivelesAutorizacion.PROPIO }
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MapasProcesosRoutingModule {
}
