import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { SeguimientoCobro } from 'src/app/models/seguimientoCobro';
import { APIUrls } from 'src/app/api/apiUrls';

export interface DialogData {
    seguimiento: SeguimientoCobro,
}

@Component({
    selector: 'dialog-nuevo-seguimiento',
    templateUrl: './dialog-nuevo-seguimiento.component.html',
})
export class DialogNuevoSeguimientoCobroOld {
    apiURLs = new APIUrls();
    
    constructor(
        public dialogRef: MatDialogRef<DialogNuevoSeguimientoCobroOld>,
        public service: AuthService,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close(this.data.seguimiento);
    }

    registroValido(): boolean {
        var seguimiento = this.data.seguimiento;
        if(!seguimiento) return false;
        if(!seguimiento.observaciones || seguimiento.observaciones.trim() == '') return false;
        
        return true;
    }
}