import { PlantillaGestionCobro } from "./plantillaMapaProceso copy";
import { Usuario } from "./usuario";

export class GestionCobro {
    constructor(
        public id: number,
        public asunto: string,
        public tipo: string,
        public tipoAdjunto: string,
        public contenido: string,
        public filtros: string,
        public correos: string,
        public fecha: string,
        public enviado: boolean,
        public archivoEnviado: string,
        public archivoEnviado2: string,
        public idsFormulariosClientes: string,
        public PlantillaGestionCobroId: number,
        public UsuarioId: number,
        public EmpresaId: number,
    ){}
    
    plantillaGestionCobro: PlantillaGestionCobro;
    usuario: Usuario;
    idsFormulariosClientesArray: number[] = [];
    correosArray: string[] = [];
    nombreUsuario: string;
    nombrePlantilla: string;
    nombreTipo: string;
    fechaFormato: string;
    fechaDate: Date;
    filtrosJSON: any;
}