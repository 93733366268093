import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check-circle';
import iconUser from '@iconify/icons-fa-solid/user';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconRight from '@iconify/icons-fa-solid/arrow-right';
import iconObservaciones from '@iconify/icons-fa-solid/edit';
import iconDuplicar from '@iconify/icons-fa-solid/copy';
import iconNoIniciado from '@iconify/icons-fa-solid/pause-circle';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { RamosService } from 'src/app/ramos/ramos.service';
import { MapasProcesosService } from '../mapas-procesos.service';
import { MapaProceso } from 'src/app/models/mapaProceso';
import { PasoMapaProceso } from 'src/app/models/pasoMapaProceso';
import { DialogAsignarPasoMapaProceso } from '../dialog-asignar-paso-mapa/dialog-asignar-paso-mapa.component';
import { DialogObservacionesPasoMapaProceso } from '../dialog-observaciones-paso-mapa/dialog-observaciones-paso-mapa.component';
import { GenericDialogSeleccionarOpcion } from 'src/app/library/generic-dialog-seleccionar-opcion/generic-dialog-seleccionar-opcion.component';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-detalle-mapa-proceso',
    templateUrl: './detalle-mapa-proceso.component.html',
    styleUrls: ['./detalle-mapa-proceso.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DetalleMapaProcesoComponent implements OnInit {
    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconRight = iconRight;
    iconUser = iconUser;
    iconObservaciones = iconObservaciones;
    iconDuplicar = iconDuplicar;
    iconNoIniciado = iconNoIniciado;
    
    // Banderas y generales
    cargando: boolean = false;
    titulo: string;
    apiURLs: APIUrls = new APIUrls();
    
    // Datos
    id: number;
    registro: MapaProceso;
    porcentajeCompletado: number;

    crumbs = [
        { nombre: 'Mapas de proceso', link: '/mapas-procesos/todos' },
        { nombre: 'Detalle de mapa', link: '/mapas-procesos/detalle-mapa' },
    ];

    constructor(
        public service: MapasProcesosService,
        public ramosService: RamosService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * * 
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        this.obtenerRegistro();
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        if(params['id']){
            this.id = parseInt(params['id']);
            this.crumbs[1].link = '/mapas-procesos/detalle-mapa/' + this.id;
        }
    }

    // * * * * * Obtener datos * * * * * 
    // Obtener el registro o inicializar uno nuevo
    async obtenerRegistro() {
        this.cargando = true;
        
        // Obtener registro
        var res = await this.service.obtenerMapaProcesoPorId(this.id);
        if(!res.error) {
            this.registro = res.data.registro;
            this.titulo = this.registro.nombreMapa;
            this.calcularPorcentajeCompletado();
            // Asignar título
            this.titleService.setTitle(this.registro.nombreMapa + this.apiURLs.nombreSitioTitle);
        }
        else {
            this.router.navigate(['/404']);
        }
        
        this.cargando = false;
    }

    // * * * * * Editar datos * * * * *
    abrirAsignarPasoMapaProceso(index: number) {
        var paso = this.registro.pasos[index];
        const dialogRef = this.dialog.open(DialogAsignarPasoMapaProceso, {
            data: {
                PasoMapaProcesoId: paso.id,
                MapaProcesoId: this.id,
                UsuarioResponsableId: paso.UsuarioResponsableId,
                usuario: paso.usuarioResponsable,
            }
        });

        dialogRef.afterClosed().subscribe(async result => {
            if(result) {
                this.marcarAceptado(index);
                //this.obtenerRegistro();
            }
        });
    }

    abrirObservacionesPasoMapaProceso(index: number) {
        var paso = this.registro.pasos[index];
        const dialogRef = this.dialog.open(DialogObservacionesPasoMapaProceso, {
            data: {
                PasoMapaProcesoId: paso.id,
                MapaProcesoId: this.id,
                observaciones: paso.observaciones,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.ngOnInit();
            }
        });
    }

    abrirAceptado(index: number) {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Marcar como aceptado',
                mensaje: '¿Está seguro de que desea marcar este paso como aceptado?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.marcarAceptado(index);
            }
        });
    }

    puedeMarcarAceptado(index: number) {
        var pasoActual = this.registro.pasos[index];
        var todosTerminados = true;
        for (let i = 0; i < index; i++) {
            const paso = this.registro.pasos[i];
            if(paso.tipoPaso == 'obligatorio' && !paso.completado) todosTerminados = false;
        }
        return todosTerminados;
    }

    calcularPorcentajeCompletado() {
        this.porcentajeCompletado = 0;
        if(this.registro && this.registro.pasos) {
            var completados = 0;
            this.registro.pasos.forEach(paso => {
                if(paso.completado) completados++;
            });
            this.porcentajeCompletado = (completados / this.registro.pasos.length) * 100;
        }
    }

    async marcarAceptado(index: number){
        this.cargando = true;

        // Enviar el registro para guardar
        var paso = this.registro.pasos[index];
        var res = await this.service.marcarAceptadoPasoMapaProceso(paso.id, this.id);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.obtenerRegistro();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    async guardarPuntuacionPaso(paso: PasoMapaProceso){
        this.cargando = true;
        try {
            let res = await this.service.guardarPuntuacionPaso(paso.puntuacion, paso.id);
            
            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            this.cargando = false;
        } catch(error) {
            this.cargando = false;
        }
    }

    async guardarNumeroAseguradora(){
        this.cargando = true;
        try {
            let res = await this.service.guardarNumeroAseguradora(this.registro.numeroAseguradora, this.id);
            
            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            this.cargando = false;
        } catch(error) {
            this.cargando = false;
        }
    }

    abrirCompletado(index: number) {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Marcar como completado',
                mensaje: '¿Está seguro de que desea marcar este paso como completado?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.marcarCompletado(index);
            }
        });
    }

    async marcarCompletado(index: number){
        this.cargando = true;

        // Enviar el registro para guardar
        var paso = this.registro.pasos[index];
        var res = await this.service.marcarCompletadoPasoMapaProceso(paso.id, this.id);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    abrirRevertirCompletado(index: number) {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Revertir como completado',
                mensaje: '¿Está seguro de que desea revertir este paso como completado?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.revertirCompletado(index);
            }
        });
    }

    async revertirCompletado(index: number){
        this.cargando = true;

        // Enviar el registro para guardar
        var paso = this.registro.pasos[index];
        var res = await this.service.revertirCompletadoPasoMapaProceso(paso.id, this.id);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    abrirDuplicar(index: number) {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Duplicar paso',
                mensaje: '¿Está seguro de que desea duplicar este paso?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.duplicarPaso(index);
            }
        });
    }

    async duplicarPaso(index: number){
        this.cargando = true;

        // Enviar el registro para guardar
        var paso = this.registro.pasos[index];
        var res = await this.service.duplicarPasoMapaProceso(paso.id, this.id);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // Eliminar datos
    abrirEliminar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar mapa de proceso',
                mensaje: '¿Está seguro de que desea eliminar este mapa? Esta acción no se puede deshacer.',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminar();
            }
        });
    }

    async eliminar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.eliminarMapaProceso(this.id);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.router.navigate(['/mapas-procesos/todos']);
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // Cancelar mapa
    abrirCancelar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Cancelar mapa de proceso',
                mensaje: '¿Está seguro de que desea cancelar este mapa?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.cancelar();
            }
        });
    }

    async cancelar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.cancelarMapaProceso(this.id);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * * * Validaciones * * * * * 
    formularioValido() {
        if(!this.registro) return false;
        return true;
    }

    abrirDialogAgregarPaso() {
        if(this.registro.plantilla.pasos) {
            let opciones = [];
            for (const el of this.registro.plantilla.pasos) {
                opciones.push({ id: el.id, nombre: el.nombreTipo });
            }
            const dialogRef = this.dialog.open(GenericDialogSeleccionarOpcion, {
                data: {
                    titulo: 'Agregar paso al mapa',
                    mensaje: '¿Qué paso desea agregar?',
                    opciones: opciones,
                }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if(result) {
                    let pasoOriginal = this.registro.plantilla.pasos.find(paso => paso.id == result);
                }
            });
        }
    }
}
