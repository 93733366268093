import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { Usuario } from 'src/app/models/usuario';
import { APIUrls } from 'src/app/api/apiUrls';
import { Agrupador } from 'src/app/models/agrupador';
import * as _moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { LibraryService } from 'src/app/library/library.service';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconView from '@iconify/icons-fa-solid/info-circle';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import { PolizasService } from 'src/app/polizas/polizas.service';
import { Poliza } from 'src/app/models/poliza';
import { PlantillaMapaProceso } from 'src/app/models/plantillaMapaProceso';
import { Router } from '@angular/router';
import { MapasProcesosService } from 'src/app/mapas-procesos/mapas-procesos.service';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { Certificado } from 'src/app/models/certificado';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    PolizaId: number,
    poliza: Poliza,
}

@Component({
    selector: 'dialog-nuevo-mapa-proceso',
    templateUrl: './dialog-nuevo-mapa-proceso.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogNuevoMapaProceso {
    apiURLs = new APIUrls();
    jsonConverters = new JSONConverters();

    // URLs
    urlAutocompletePolizas = this.apiURLs.baseURL + this.apiURLs.polizasURL + '/search';
    urlAutocompleteCertificados = this.apiURLs.baseURL + this.apiURLs.certificadosURL + '/search';

    columnasPolizas = [
        { prop: 'numero', name: 'Número', sortable: true, cellTemplate: null },
        { prop: 'cliente.nombreCompleto', name: 'Cliente', sortable: true, cellTemplate: null },
        { prop: 'aseguradora.nombre', name: 'Aseguradora', sortable: true, cellTemplate: null },
        { prop: 'ramo.nombre', name: 'Ramo', sortable: true, cellTemplate: null },
        { prop: 'producto.nombre', name: 'Producto', sortable: true, cellTemplate: null },
        { prop: 'nombreEstado', name: 'Estado', sortable: false, cellTemplate: null },
    ];
    camposPolizas = [
        { nombre: 'Número de póliza', campo: 'numero', tipo: 'texto', categorias: null },
        { nombre: 'ID interno', campo: 'id', tipo: 'texto', categorias: null },
    ];

    columnasCertificados = [
        { prop: 'numero', name: 'Número', sortable: true, cellTemplate: null },
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'nombresPagadores', name: 'Pagadores', sortable: true, cellTemplate: null },
        { prop: 'tipoCertificado.nombre', name: 'Tipo', sortable: true, cellTemplate: null },
        { prop: 'sumaAsegurada', name: 'Suma asegurada', sortable: true, cellTemplate: null },
    ];
    camposCertificados = [
        { nombre: 'General', campo: 'nombre', tipo: 'texto', categorias: null },
        { nombre: 'ID', campo: 'id', tipo: 'texto', categorias: null },
    ];

    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconView = iconView;
    iconCheck = iconCheck;
    iconClose = iconClose;
    iconPlus = iconPlus;

    // Datos
    cargando: boolean = false;
    PolizaId: number;
    poliza: Poliza;
    polizas: Poliza[] = [];
    idsCertificados: number[] = [];
    certificados: PlantillaMapaProceso[] = [];
    PlantillaMapaProcesoId: number;
    plantillaSeleccionada: PlantillaMapaProceso;
    plantillas: PlantillaMapaProceso[] = [];
    observaciones: string;

    constructor(
        public dialogRef: MatDialogRef<DialogNuevoMapaProceso>,
        public service: AuthService,
        public mapasProcesosService: MapasProcesosService,
        public polizasService: PolizasService,
        public libraryService: LibraryService,
        public auth: AuthService,
        public router: Router,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit(){
        this.PolizaId = this.data.PolizaId;
        this.poliza = this.data.poliza;
        if(this.poliza) {
            this.polizas = [this.poliza];
            this.obtenerPlantillas();
        }
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    async confirmar() {
        try {
            this.cargando = true;
            
            let res = await this.mapasProcesosService.crearMapaProceso(this.PlantillaMapaProcesoId, this.PolizaId, this.idsCertificados, this.observaciones);
            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.router.navigate(['/mapas-procesos/detalle-mapa/' + res.data.result.MapaProcesoId]);
                this.auth.buscadorReload.next();
                this.dialogRef.close();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
    
            this.cargando = false;
        }
        catch(error) {
            this.cargando = false;
        }
    }

    registroValido(): boolean {
        if(!this.PolizaId) return false;
        if(!this.PlantillaMapaProcesoId) return false;
        return true;
    }

    // * * * * * Actualizaciones de controles * * * * *
    actualizarPoliza(items: Poliza[]) {
        if(items && items.length > 0) {
            if(this.PolizaId != items[0].id) {
                this.idsCertificados = [];
                this.certificados = [];
            }
            this.PolizaId = items[0].id;
            this.poliza = items[0];
            this.obtenerPlantillas();
        }
        else this.PolizaId = null;
    }

    actualizarCertificados(items: Certificado[]) {
        this.idsCertificados = [];
        items.forEach(item => {
            this.idsCertificados.push(item.id);
            
        });
    }

    calcularParamsEspecialesCertificados(){
        var params = '';
        if(this.PolizaId) {
            params += '&PolizaId=' + this.PolizaId;
        }
        return params;
    }

    async obtenerPlantillas() {
        this.cargando = true;
        if(this.poliza) {
            var res = await this.mapasProcesosService.obtenerTodasPlantillasRamo(this.poliza.RamoId);
            if(!res.error) {
                this.plantillas = res.data.registros;
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        else {
            this.plantillas = [];
            this.PlantillaMapaProcesoId = null;
        }
        this.cargando = false;
    }
}