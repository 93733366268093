import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconCandadoDetalle from '@iconify/icons-fa-solid/lock';
import iconCandadoEditar from '@iconify/icons-fa-solid/lock-open';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { CobrosService } from '../cobros.service';
import { Usuario } from 'src/app/models/usuario';
import { Cobrador } from 'src/app/models/cobrador';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-nuevo-cobrador',
    templateUrl: './nuevo-cobrador.component.html',
    styleUrls: ['./nuevo-cobrador.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class NuevoCobradorComponent implements OnInit {
    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconCandadoDetalle = iconCandadoDetalle;
    iconCandadoEditar = iconCandadoEditar;
    
    // Banderas y generales
    cargando: boolean = false;
    modoEdicion: boolean;
    modoDetalle: boolean;
    modoCreacion: boolean;
    titulo: string;
    apiURLs: APIUrls = new APIUrls();
    
    // Datos
    id: number;
    registro: Cobrador;

    crumbs = [
        { nombre: 'Cobradores', link: '/cobros/cobradores' },
        { nombre: 'Nuevo cobrador', link: '/cobros/nuevo-cobrador' },
    ];

    constructor(
        public service: CobrosService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * * 
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.obtenerRegistro();
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        this.modoCreacion = ruta === 'nuevo-cobrador';
        this.modoEdicion = ruta === 'editar-cobrador';
        this.modoDetalle = ruta === 'detalle-cobrador';
        if(params['id']){
            this.id = parseInt(params['id']);
        }
    }

    // * * * * * Obtener datos * * * * * 
    // Obtener el registro o inicializar uno nuevo
    async obtenerRegistro() {
        this.cargando = true;
        if(this.modoCreacion) {
            // Asignar título
            this.titulo = 'Agregar cobrador';
            this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
            this.registro = new Cobrador(null, null, null, null, null, null, null, null, null, null, true, false, this.auth.idEmpresaActual());
        }
        else if(this.modoEdicion || this.modoDetalle) {
            if(this.modoEdicion) this.titulo = 'Editar cobrador';
            if(this.modoDetalle) this.titulo = 'Detalle de cobrador';
            // Obtener registro
            var res = await this.service.obtenerCobradorPorId(this.id);
            if(!res.error) {
                this.registro = res.data.registro;
                this.crumbs[1].link = '/cobros/editar-cobrador/' + this.id;
            }
            else {
                this.router.navigate(['/404']);
            }
        }
        this.cargando = false;
    }

    // * * * * * Editar datos * * * * *
    // Guardar datos
    async guardar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res;
        if(this.modoCreacion) {
            res = await this.service.crearCobrador(this.registro);
        }
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            if(this.modoCreacion) this.router.navigate(['/cobros/editar-cobrador/' + res.data.result.result.id]);
            this.auth.comprobarAutenticacion(false);
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * * * Validaciones * * * * * 
    formularioValido() {
        if(!this.registro) return false;
        return true;
    }
}