import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibraryModule } from '../library/library.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule, MatOptionModule, MatIconModule, MatSelectModule, MatButtonModule, MatProgressBarModule, MatCheckboxModule, MatDatepickerModule, MatExpansionModule, MatTooltipModule, MatTabsModule } from '@angular/material';
import { IconModule } from '@visurel/iconify-angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxCurrencyModule } from "ngx-currency";
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { PlantillasMapasComponent } from './plantillas-mapas/plantillas-mapas.component';
import { MapasProcesosRoutingModule } from './mapas-procesos-routing.module';
import { NuevoPlantillaMapaProcesoComponent } from './nuevo-plantilla-mapa-proceso/nuevo-plantilla-mapa-proceso.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DialogNuevoMapaProceso } from './dialog-nuevo-mapa-proceso/dialog-nuevo-mapa-proceso.component';
import { MapasProcesosComponent } from './mapas-procesos/mapas-procesos.component';
import { DetalleMapaProcesoComponent } from './detalle-mapa-proceso/detalle-mapa-proceso.component';
import { DialogAsignarPasoMapaProceso } from './dialog-asignar-paso-mapa/dialog-asignar-paso-mapa.component';
import { DialogObservacionesPasoMapaProceso } from './dialog-observaciones-paso-mapa/dialog-observaciones-paso-mapa.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PageLayoutModule } from '../../@vex/components/page-layout/page-layout.module';
import { AnalyticsMapasProcesosComponent } from './analytics-mapas-procesos/analytics-mapas-procesos.component';
import { ContenedorAnalyticsMapasProcesosComponent } from './analytics-mapas-procesos/contenedor-analytics-mapas-procesos.component';
import { ChartModule } from 'angular-highcharts';
import { DialogReporteMapasProcesos } from './dialog-reporte-mapas/dialog-reporte-mapas.component';
import { ReporteMapasComponent } from './reporte-mapas/reporte-mapas.component';

@NgModule({
    imports: [
        CommonModule,
        MapasProcesosRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        LibraryModule,
        NgxDatatableModule,
        NgxCurrencyModule,
        BreadcrumbsModule,
        SecondaryToolbarModule,
        // Material
        MatInputModule,
        MatOptionModule,
        MatIconModule,
        MatSelectModule,
        MatButtonModule,
        MatProgressBarModule,
        MatDatepickerModule,
        IconModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatTooltipModule,
        MatTabsModule,
        DragDropModule,
        FlexLayoutModule,
        PageLayoutModule,
        ChartModule,
    ],
    declarations: [
        PlantillasMapasComponent,
        NuevoPlantillaMapaProcesoComponent,
        DialogNuevoMapaProceso,
        MapasProcesosComponent,
        DetalleMapaProcesoComponent,
        DialogAsignarPasoMapaProceso,
        DialogObservacionesPasoMapaProceso,
        AnalyticsMapasProcesosComponent,
        ContenedorAnalyticsMapasProcesosComponent,
        DialogReporteMapasProcesos,
        ReporteMapasComponent,
    ],
    entryComponents: [
        DialogReporteMapasProcesos,
        DialogNuevoMapaProceso,
        DialogAsignarPasoMapaProceso,
        DialogObservacionesPasoMapaProceso,
    ],
    exports: [
        DialogNuevoMapaProceso,
        DialogAsignarPasoMapaProceso,
        DialogObservacionesPasoMapaProceso,
        AnalyticsMapasProcesosComponent,
    ]
})
export class MapasProcesosModule {
}
