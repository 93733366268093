import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Error404Component } from './error-404/error-404.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule, MatIconModule, MatSnackBarModule, MatTooltipModule, MatButtonModule, MatCheckboxModule, MatProgressBarModule } from '@angular/material';
import { IconModule } from '@visurel/iconify-angular';
import { RecuperarContrasenaComponent } from './recuperar-contrasena/recuperar-contrasena.component';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        LoginRoutingModule,
        FlexLayoutModule,
        // Material
        MatInputModule,
        MatIconModule,
        MatSnackBarModule,
        IconModule,
        MatTooltipModule,
        MatButtonModule,
        MatCheckboxModule,
        MatProgressBarModule,
    ],
    declarations: [
        LoginComponent,
        Error404Component,
        RecuperarContrasenaComponent,
    ],
    providers: [
        
    ]
})
export class LoginModule {
}