import { Poliza } from './poliza';
import { Usuario } from './usuario';
import { CorreccionControlCalidad } from './correccionControlCalidad';

export class RevisionPoliza {
    constructor(
        public id: number,
        public fecha: string,
        public totalDatosIncorrectos: number,
        public totalDatosCorrectos: number,
        public totalDatos: number,
        public puntuacion: number,
        public terminada: boolean,
        public corregida: boolean,
        public observaciones: string,
        public PolizaId: number,
        public UsuarioId: number,
        public EmpresaId: number,
    ){}

    fechaFormato: string;
    fechaDate: Date;
    nombreUsuario: string;
    usuario: Usuario;
    poliza: Poliza;
    correcciones: CorreccionControlCalidad[];
}