import { Area } from './area';
import { Empresa } from './empresa';
import { InformacionCliente } from './informacionCliente';
import { ContactoUsuario } from './contactoUsuario';
import { DocumentoUsuario } from './documentoUsuario';
import { Agrupador } from './agrupador';
import { TarjetaCliente } from './tarjetaCliente';
import { DireccionCliente } from './direccionCliente';

export class Usuario {
    constructor(
        public id: number,
        public codigo: string,
        public nombre: string,
        public apellido: string,
        public nombreEmpresa: string,
        public puesto: string,
        public correo1: string,
        public correo2: string,
        public telefono1: string,
        public telefono2: string,
        public direccion: string,
        public activo: boolean,
        public isDeleted: boolean,
        public esCliente: boolean,
        public esAdministradorAgrupador: boolean,
        public esAccesoACliente: boolean,
        public esVendedor: boolean,
        public esEjecutivo: boolean,
        public esAgente: boolean,
        public suspendido: boolean,
        public fechaNacimiento: string,
        public ultimaVisualizacion: string,
        public AgrupadorId: number,
        public InformacionClienteId: number,
        public imagen: string,
        public portada: string,
        public EmpresaId: number,
    ){}
    
    public correoCopiaSolicitudes: string;
    public AreaId: number;
    public meta: any;
    public nombreCompleto: string;
    public nombreCompletoConId: string;
    public password: string;
    public area: Area;
    public nombreArea: string;
    public empresa: Empresa;
    public empresas: Empresa[];
    public contactos: ContactoUsuario[];
    public agentesIds = [];
    public ejecutivosIds = [];
    public agentes: Usuario[];
    public ejecutivos: Usuario[];
    public documentos: DocumentoUsuario[];
    public informacionCliente: InformacionCliente;
    public fechaNacimientoFormato: string;
    public fechaNacimientoDate: Date;
    public ultimaVisualizacionFormato: string;
    public porcentajeComision: number;
    public porcentajeComision2: number;
    public metaMensual: number;
    public metaTrimestral: number;
    public metaSemestral: number;
    public metaAnual: number;
    public idsAgentesCartera: string;
    public idsAgentesCarteraArray: number[] = [];
    public idsVendedoresCartera: string;
    public idsVendedoresCarteraArray: number[] = [];
    public agrupador: Agrupador;
    public nombreAgrupador: string;
    public tarjetas: TarjetaCliente[] = [];
    public nitCliente: string;
    public direcciones: DireccionCliente[] = [];
}