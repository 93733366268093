import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { APIUrls } from '../../api/apiUrls';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { JSONConverters } from 'src/app/models/JSONConverters';
import iconEdit from '@iconify/icons-fa-solid/edit';
import iconList from '@iconify/icons-fa-solid/list';
import { CobrosService } from '../cobros.service';
import { AuthService } from 'src/app/auth/auth.service';
import { LibraryService } from 'src/app/library/library.service';
import { MatDialog } from '@angular/material';

@Component({
    selector: 'app-creditos',
    templateUrl: './creditos.component.html',
    styleUrls: ['./creditos.component.scss']
})
export class CreditosComponent implements OnInit {
    // Templates de celdas
    @ViewChild('cellBotones', { static: true }) cellBotones: TemplateRef<any>;
    @ViewChild('cellPlanilla', { static: true }) cellPlanilla: TemplateRef<any>;
    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;
    @ViewChild('cellMoneda', { static: true }) cellMoneda: TemplateRef<any>;
    @ViewChild('cellImagen', { static: true }) cellImagen: TemplateRef<any>;
    @ViewChild('cellEstado', { static: true }) cellEstado: TemplateRef<any>;

    apiURLs = new APIUrls();
    jsonConverters = new JSONConverters();
    
    // Columnas de tabla
    columnas = [];
    columnasNotasDevolucion = [
        { name: '', sortable: false, checkboxable: true, headerCheckboxable: true, cellTemplate: null },
        { prop: 'pagador.nombreCompleto', name: 'Pagador', sortable: true, cellTemplate: null },
        { prop: 'poliza.numero', name: 'No. póliza', sortable: true, cellTemplate: null },
        { prop: 'poliza.nombreAseguradora', name: 'Aseguradora', sortable: true, cellTemplate: null },
        { prop: 'fechaEmisionFormato', name: 'Emisión', sortable: true, cellTemplate: null },
        { prop: 'numeroRequisito', name: 'No. doc.', sortable: true, cellTemplate: null },
        { prop: 'numeroRecibo', name: 'No. recibo', sortable: true, cellTemplate: null },
        { prop: 'metaCreditoTotal', name: 'Crédito total', sortable: true, cellTemplate: null },
        { prop: 'metaCreditoRestante', name: 'Crédito pendiente', sortable: true, cellTemplate: null },
        { prop: 'razonCredito', name: 'Razón de crédito', sortable: true, cellTemplate: null },
        { prop: 'nombreEstado', name: 'Estado', sortable: true, cellTemplate: null },
    ];
    
    // Campos para buscador
    campos = [
        { nombre: 'Número de requerimiento', campo: 'numeroRequisito', tipo: 'texto', categorias: null },
        { nombre: 'Número de documento', campo: 'numeroCheque', tipo: 'texto', categorias: null },
        { nombre: 'ID interno', campo: 'id', tipo: 'texto', categorias: null },
        { nombre: 'Fecha de emisión', campo: 'fechaEmision', tipo: 'rango-fechas', categorias: null },
    ];

    urlBusqueda = this.apiURLs.baseURL + this.apiURLs.documentosCreditosURL + '/search';
    urlAutocompleteClientes = this.apiURLs.baseURL + this.apiURLs.informacionClientesURL + '/search';
    urlAutocompleteAgrupadores = this.apiURLs.baseURL + this.apiURLs.agrupadoresURL + '/search';
    urlAutocompleteAseguradoras = this.apiURLs.baseURL + this.apiURLs.aseguradorasURL + '/search';

    // Filtros
    filtros = [
        // No. de póliza
        { nombre: 'No. póliza', campo: 'numeroPoliza', tipo: 'texto', valor: null, mostrar: true, categorias: null},
        // Cliente
        {
            nombre: 'Pagador', campo: 'pagador', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteClientes, seleccionados: [], mostrar: true,
            idProp: 'id', titleProp: 'nombreCompleto', fieldSearch: 'general',
            parser: this.jsonConverters.usuarioDeJSON, parserService: this.jsonConverters,
        },
        // Agrupador
        {
            nombre: 'Agrupador', campo: 'agrupador', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteAgrupadores, seleccionados: [], mostrar: false,
            idProp: 'id', titleProp: 'nombre', fieldSearch: 'nombre',
            parser: this.jsonConverters.agrupadorDeJSON, parserService: this.jsonConverters,
        },
        // Aseguradora
        {
            nombre: 'Aseguradora', campo: 'aseguradora', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteAseguradoras, seleccionados: [], mostrar: false,
            idProp: 'id', titleProp: 'nombre', fieldSearch: 'nombre',
            parser: this.jsonConverters.aseguradoraDeJSON, parserService: this.jsonConverters,
        },
        { nombre: 'Estado', campo: 'estado', tipo: 'select-unico', valor: null, mostrar: false, categorias: this.apiURLs.estadosCreditos},
    ];

    crumbs = [
        { nombre: 'Créditos', link: '/cobros/notas-devolucion' },
    ];

    titulo: string = 'Créditos';
    mostrarTabla: boolean = false;
    iconEdit = iconEdit;
    iconList = iconList;
    etapa: string = this.apiURLs.etapasCreditos[0].id;
    cargando: boolean = false;
    idsSeleccionados: number[];
    modo: string;

    constructor(
        public service: CobrosService,
        public auth: AuthService,
        public libraryService: LibraryService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        private dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.agregarFiltros();
        this.agregarTemplates();
        this.mostrarTabla = true;
    }

    obtenerRuta(params: Params) {
        if(this.route.snapshot.url.length > 0) {
            var ruta = this.route.snapshot.url[0].path;
            this.modo = ruta;
            switch(ruta) {
                case 'creditos-todos': {
                    this.etapa = 'todos';
                    this.titulo = 'Todos los documentos de crédito';
                    this.columnas = this.columnasNotasDevolucion;
                    this.crumbs[0].link = '/cobros/creditos-todos/';
                    break;
                }
                case 'creditos-por-aplicar': {
                    this.etapa = 'por-aplicar';
                    this.titulo = 'Notas de devolución por aplicar';
                    this.columnas = this.columnasNotasDevolucion;
                    this.crumbs[0].link = '/cobros/creditos-por-aplicar/';
                    // Eliminar columna con el name Estado
                    let indexColumnaEstado = this.columnas.findIndex(columna => columna.name == 'Estado');
                    this.columnas.splice(indexColumnaEstado, 1);
                    break;
                }
                case 'creditos-aplicados': {
                    this.etapa = 'aplicados';
                    this.titulo = 'Notas de devolución aplicadas';
                    this.columnas = this.columnasNotasDevolucion;
                    this.crumbs[0].link = '/cobros/creditos-aplicados/';
                    // Eliminar columna con el name Estado
                    let indexColumnaEstado = this.columnas.findIndex(columna => columna.name == 'Estado');
                    this.columnas.splice(indexColumnaEstado, 1);
                    break;
                }
                case 'creditos-notas-devolucion': {
                    this.etapa = 'nota-devolucion';
                    this.titulo = 'Notas de devolución';
                    this.columnas = this.columnasNotasDevolucion;
                    this.crumbs[0].link = '/cobros/creditos-notas-devolucion/';
                    break;
                }
                case 'creditos-primas-deposito': {
                    this.etapa = 'prima-deposito';
                    this.titulo = 'Primas en depósito';
                    this.columnas = this.columnasNotasDevolucion;
                    this.crumbs[0].link = '/cobros/creditos-primas-deposito/';
                    break;
                }
                case 'creditos-pagados': {
                    this.etapa = 'pagado';
                    this.titulo = 'Créditos pagados';
                    this.columnas = this.columnasNotasDevolucion;
                    this.crumbs[0].link = '/cobros/creditos-pagados/';
                    break;
                }
            }
            this.crumbs[0].nombre = this.titulo;
        }
        this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
    }

    agregarTemplates() {
        this.columnas.forEach(columna => {
            if(columna.name == 'Estado') columna.cellTemplate = this.cellEstado;
            else if(columna.name == 'Crédito total') columna.cellTemplate = this.cellMoneda;
            else if(columna.name == 'Crédito pendiente') columna.cellTemplate = this.cellMoneda;
            else columna.cellTemplate = this.cellNormal;
        });
    }

    async agregarFiltros() {
        
    }

    rutaNuevo() {
        if(this.modo == 'creditos-notas-devolucion') return '/cobros/nuevo-nota-devolucion'
        else if (this.modo == 'creditos-primas-deposito') return '/cobros/nuevo-prima-deposito'
        else return null;
    }

    calcularParamsEspeciales(): string {
        var paramsEspeciales = '';
        
        if(this.etapa == 'pagado') {
            paramsEspeciales += '&pagado=1';
        }
        else if(this.etapa == 'por-aplicar') {
            paramsEspeciales += '&estado=pendiente';
            paramsEspeciales += '&etapa=nota-devolucion';
        }
        else if(this.etapa == 'aplicados') {
            paramsEspeciales += '&estado=aplicado';
            paramsEspeciales += '&etapa=nota-devolucion';
        }
        else if(this.etapa != 'todos') {
            paramsEspeciales += '&etapa=' + this.etapa;
        }

        if(this.auth.esAccesoACliente()) {
            paramsEspeciales += '&accesoACliente=1';
        }

        return paramsEspeciales;
    }
}
