import { Certificado } from './certificado';
import { Reclamo } from './reclamo';
import { BeneficiarioDependienteCertificado } from './beneficiarioDependienteCertificado';
import { CoberturaCertificadoReclamo } from './coberturaCertificadoReclamo';
import { CoberturaIncisoCertificado } from './coberturaIncisoCertificado';
import { DeducibleCertificado } from './deducibleCertificado';
import { IncisoCertificado } from './incisoCertificado';
import { DeducibleMonedaCertificado } from './deducibleMonedaCertificado';

export class CertificadoReclamo {
    constructor(
        public id: number,
        public montoDeducible: number,
        public observaciones: string,
        public montoReclamado: number,
        public montoReserva: number,
        public impuesto: number,
        public timbres: number,
        public coaseguros: number,
        public gastosNoElegibles: number,
        public gastosCubiertos: number,
        public montoNoPagado: number,
        public montoPagado: number,
        public depreciacion: number,
        public tipoPago: string,
        public numeroCheque: string,
        public reclamoReportadoDirecto: boolean,
        public pagoDirectoTaller: boolean,
        public reclamoTitular: boolean,
        public ReclamoId: number,
        public CertificadoId: number,
        public BeneficiarioDependienteCertificadoId: number,
        public IncisoCertificadoId: number,
        public DeducibleCertificadoId: number,
        public DeducibleMonedaCertificadoId: number,
        public EmpresaId: number,
    ){}

    aplicarCoaseguros: boolean = false;
    auxCertificados: Certificado[] = [];
    certificado: Certificado;
    nombreCertificado: string;
    montoAcumulado: number;
    reclamo: Reclamo;
    beneficiarioDependiente: BeneficiarioDependienteCertificado;
    idsCoberturasIncisos: number[] = [];
    deducible: DeducibleCertificado;
    coberturas: CoberturaCertificadoReclamo[] = [];
    auxBeneficiarios: BeneficiarioDependienteCertificado[];
    auxIncisos: IncisoCertificado[];
    auxCoberturas: CoberturaIncisoCertificado[] = [];
    auxDeducibles: DeducibleCertificado[] = [];
    auxDeduciblesMoneda: DeducibleMonedaCertificado[] = [];
    // Deducible
    totalDeducible: number;
    totalPagado: number;
    deducibleRestante: number;
}