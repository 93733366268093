import { CoberturaIncisoCertificado } from './coberturaIncisoCertificado';
import { IncisoCertificado } from './incisoCertificado';
import { Usuario } from './usuario';

export class CambioEstadoCertificado {
    constructor(
        public id: number,
        public fecha: string,
        public estadoAnterior: string,
        public estadoSiguiente: string,
        public UsuarioId: number,
        public CertificadoId: number,
        public IncisoCertificadoId: number,
        public CoberturaIncisoCertificadoId: number,
        public EmpresaId: number,
    ){}

    usuario: Usuario;
    fechaFormato: string;
    nombreEstadoAnterior: string;
    nombreEstadoSiguiente: string;
    nombreUsuario: string;
    inciso: IncisoCertificado;
    nombreInciso: string;
    cobertura: CoberturaIncisoCertificado;
    nombreCobertura: string;
}