import { Usuario } from './usuario';

export class SeguimientoProspecto {
    constructor(
        public id: number,
        public numero: number,
        public observaciones: string,
        public fecha: string,
        public fechaSeguimiento: string,
        public ProspectoId: number,
        public UsuarioId: number,
        public EmpresaId: number,
    ){}

    fechaFormato: string;
    fechaDate: Date;
    fechaSeguimientoFormato: string;
    fechaSeguimientoDate: Date;
    usuario: Usuario;
    nombreUsuario: string;
    observacionesResumen: string;
}
