export class ComisionPolizaInciso {
    constructor(
        public id: number,
        public nombreInciso: string,
        public primaNeta: number,
        public porcentajeComision: number,
        public porcentajeComisionRepartida: number,
        public comision: number,
        public comisionPorPago: number,
        public ProductoId: number,
        public AseguradoraId: number,
        public PolizaId: number,
        public IncisoId: number,
        public ComisionAseguradoraProductoId: number,
        public EmpresaId: number,
    ){}
}