import { Agrupador } from "./agrupador";
import { Certificado } from "./certificado";
import { Poliza } from "./poliza";
import { Usuario } from "./usuario";

export class PermisoAccesoCliente {
    constructor(
        public id: number,
        public correo: string,
        public tipo: string,
        public UsuarioId: any,
        public ClienteId: any,
        public PolizaId: any,
        public CertificadoId: any,
        public AgrupadorId: number,
        public EmpresaId: number,
    ){}

    nombreTipo: string;
    cliente: Usuario;
    nombreCliente: string;
    poliza: Poliza;
    nombrePoliza: string;
    certificado: Certificado;
    nombreCertificado: string;
    agrupador: Agrupador;
    nombreAgrupador: string;
    usuario: Usuario;
    nombreUsuario: string;

    polizasCliente: Poliza[];
    certificadosPoliza: Certificado[];
}