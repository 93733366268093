import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { APIUrls } from 'src/app/api/apiUrls';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'vex-recuperar-contrasena',
  templateUrl: './recuperar-contrasena.component.html',
  styleUrls: ['./recuperar-contrasena.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        fadeInUp400ms
    ]
})
export class RecuperarContrasenaComponent implements OnInit {

    username: string;
    cargando: boolean = false;
    password: string;
    codigoSeguridad: string;
    correoEnviado: boolean = false;
    fondoLogin: string = '"/assets/img/prointel-fondo-login.jpg"';
    inputType = 'password';
    visible = false;
    form: FormGroup;
    minimoContrasena: number = 8;
    icVisibility = icVisibility;
    icVisibilityOff = icVisibilityOff;

    constructor(
        private router: Router,
        private usuariosService: UsuariosService,
        private cd: ChangeDetectorRef,
        private snackbar: MatSnackBar,
        private fb: FormBuilder,
        public auth: AuthService,
    ) { }

    ngOnInit(): void {
        this.form = this.fb.group({
            username: ['', Validators.required],
            codigoSeguridad: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.obtenerMinimoContrasena();
    }

    toggleVisibility() {
        if (this.visible) {
            this.inputType = 'password';
            this.visible = false;
            this.cd.markForCheck();
        } else {
            this.inputType = 'text';
            this.visible = true;
            this.cd.markForCheck();
        }
    }

    async solicitarRecuperacion() {
        this.cargando = true;
        const res = await this.usuariosService.enviarCorreoRestaurarContrasena(this.username);
        if (res.error) {
            // Mostrar notificación de error
            this.snackbar.open(res.error.mensajeError, 'OK', {
                duration: 10000
            });
        }
        else {
            this.correoEnviado = true;
            this.password = null;
            this.snackbar.open(res.data.mensaje, 'OK', {
                duration: 10000
            });
        }
        this.cargando = false;
        this.cd.detectChanges();
    }
    async recuperarCodigoSeguridad() {
        this.cargando = true;
        const res = await this.usuariosService.recuperarPasswordCodigoSeguridad(this.username, this.codigoSeguridad, this.password);
        if (res.error) {
            // Mostrar notificación de error
            this.snackbar.open(res.error.mensajeError, 'OK', {
                duration: 10000
            });
        }
        else {
            this.snackbar.open(res.data.mensaje, 'OK', {
                duration: 10000
            });

            var loginRes = await this.auth.login(this.username, this.password);
            if (loginRes.error) {
                // Mostrar notificación de error
                this.snackbar.open(loginRes.mensaje, 'OK', {
                    duration: 10000
                });
            }
            else {
                this.router.navigate(['/']);
            }
        }
        this.cargando = false;
        this.cd.detectChanges();
    }
    
    validForm() {
        if (!this.username) return false;
        return true;
    }

    async obtenerMinimoContrasena() {
        var configuracion = this.auth.configuracionEmpresaActual();
        if(configuracion) {
            this.minimoContrasena = configuracion.minimoCaracteresContrasena;
            if(!this.minimoContrasena) this.minimoContrasena = 8;
        }
    }
}
