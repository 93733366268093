import { Component, OnInit, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconEdit from '@iconify/icons-fa-solid/edit';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconGenerate from '@iconify/icons-fa-solid/money-bill';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconUndo from '@iconify/icons-fa-solid/undo';
import iconDelete from '@iconify/icons-fa-solid/trash';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { CobrosService } from 'src/app/cobros/cobros.service';
import { DocumentoCredito } from 'src/app/models/documentoCredito';
import { DialogAplicarPagoCredito } from '../dialog-aplicar-pago-credito/dialog-aplicar-pago-credito.component';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { AplicacionDocumentoCredito } from 'src/app/models/aplicacionDocumentoCredito';
import { DialogNuevoAplicacionCreditoCheque } from '../dialog-nuevo-aplicacion-credito-cheque/dialog-nuevo-aplicacion-credito-cheque.component';
import { Poliza } from 'src/app/models/poliza';
import { JSONConverters } from 'src/app/models/JSONConverters';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-detalle-credito',
    templateUrl: './detalle-credito.component.html',
    styleUrls: ['./detalle-credito.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DetalleCreditoComponent implements OnInit {
    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;
    @ViewChild('cellMonto', { static: true }) cellMonto: TemplateRef<any>;
    @ViewChild('cellAcciones', { static: true }) cellAcciones: TemplateRef<any>;
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    innerWidth: any;
    crumbs = [
        { nombre: 'Créditos', link: '/cobros/creditos-notas-devolucion' },
        { nombre: 'Detalle de crédito', link: '/cobros/detalle-credito/' },
    ];
    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconEdit = iconEdit;
    iconDownload = iconDownload;
    iconGenerate = iconGenerate;
    iconPlus = iconPlus;
    iconCheck = iconCheck;
    iconUndo = iconUndo;
    iconDelete = iconDelete;
    
    // Banderas y generales
    cargando: boolean = true;
    cargandoCreditos: boolean = false;
    titulo: string;
    apiURLs: APIUrls = new APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    
    // Datos
    id: number;
    registro: DocumentoCredito;
    nombreTipoCredito: string;

    // Aplicaciones créditos
    urlBusquedaAplicaciones = this.apiURLs.baseURL + this.apiURLs.documentosCreditosURL + '/searchAplicaciones';
    camposAplicaciones = [
        { nombre: 'Fecha de aplicación', campo: 'fechaAplicacion', tipo: 'rango-fechas', categorias: null },
        { nombre: 'ID interno', campo: 'id', tipo: 'texto', categorias: null },
        { nombre: 'ID Documento Cobro', campo: 'DocumentoCobroId', tipo: 'texto', categorias: null },
    ];
    columnasAplicaciones = [
        { name: '', sortable: false, checkboxable: true, headerCheckboxable: true, cellTemplate: null },
        { prop: 'id', name: 'ID', sortable: true, cellTemplate: null },
        { prop: 'fechaAplicacionFormato', name: 'Aplicación', sortable: true, cellTemplate: null },
        { prop: 'tipoNombre', name: 'Tipo', sortable: true, cellTemplate: null },
        { prop: 'banco', name: 'Banco', sortable: true, cellTemplate: null },
        { prop: 'numeroCheque', name: 'No. cheque', sortable: true, cellTemplate: null },
        { prop: 'meta', name: 'Monto', sortable: true, cellTemplate: null },
        { prop: 'DocumentoCobroId', name: 'ID Doc. de cobro', sortable: true, cellTemplate: null },
        { prop: 'observaciones', name: 'Observaciones', sortable: true, cellTemplate: null },
    ];
    filtrosAplicaciones = [];

    accionesAplicaciones = [
        { id: 'revertir', nombre: 'Revertir aplicación' },
    ]

    constructor(
        public service: CobrosService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * * 
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.agregarTemplatesAplicaciones();
        this.obtenerRuta(params);
        await this.obtenerRegistro();
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        if(params['id']){
            this.id = parseInt(params['id']);
        }
        if(params['id']){
            this.id = parseInt(params['id']);
            this.crumbs[1].link = '/cobros/detalle-credito/' + this.id;
        }
    }

    agregarTemplatesAplicaciones() {
        this.columnasAplicaciones.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
        this.columnasAplicaciones[6].cellTemplate = this.cellMonto;
    }

    // * * * * * Obtener datos * * * * * 
    // Obtener el registro o inicializar uno nuevo
    async obtenerRegistro() {
        this.cargando = true;
        this.titulo = 'Detalle de crédito';
        // Obtener registro
        var res = await this.service.obtenerDocumentoCreditoPorId(this.id);
        if(!res.error) {
            this.registro = res.data.registro;
            this.nombreTipoCredito = this.registro.etapa == 'prima-deposito' ? 'prima en depósito' : 'nota de devolución';
            this.titulo = 'Detalle de ' + this.nombreTipoCredito;
            // Asignar título
            this.titleService.setTitle('Documento de crédito #' + this.registro.id + this.apiURLs.nombreSitioTitle);
        }
        else {
            this.router.navigate(['/404']);
        }
        this.cargando = false;
    }

    // * * * Cambios de estado * * *
    abrirCambiarPagado() {
        const dialogRef = this.dialog.open(DialogAplicarPagoCredito, {
            data: {
                formaPago: this.apiURLs.formasCobroCreacion[0].id,
                banco: null,
                numeroCheque: null,
                observaciones: null,
                fecha: null,
                fechaDate: null,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result != null) {
                this.cambiarPagado(result);
            }
        });
    }

    async cambiarPagado(data: any) {
        this.cargando = true;
        if(data) {
            // Enviar el registro para guardar
            var res = await this.service.pasarACreditoPagado(this.id, data.formaPago, data.fecha, data.banco, data.numeroCheque, data.observaciones, this.auth.idUsuarioActual());
            
            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.ngOnInit();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    abrirRegresarNotaDevolucion() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Marcar como pendiente de pago',
                mensaje: '¿Está seguro de que desea regresar documento de crédito a nota de devolución?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.regresarNotaDevolucion();
            }
        });
    }

    async regresarNotaDevolucion() {
        this.cargando = true;
        if(this.id) {
            // Enviar el registro para guardar
            var res = await this.service.regresarANotaDevolucion(this.id, this.auth.idUsuarioActual());
            
            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.ngOnInit();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    onActivateAplicacion(aplicacion: AplicacionDocumentoCredito) {
        if(aplicacion.DocumentoCobroId) this.router.navigate([ '/cobros/detalle/' + aplicacion.DocumentoCobroId ]);
    }

    getRowClass(row) {
        return {
            'hoverRow': true
        };
    }

    modoResponsive() {
        if(!this.innerWidth) return false;
        return this.innerWidth <= 768;
    }

    // * * * Aplicaciones de crédito * * *
    abrirNuevoAplicacionCreditoCheque() {
        var aplicacionCredito = new AplicacionDocumentoCredito(null, 'cheque', null, null, null, null, null, null, this.id, this.auth.idUsuarioActual(), this.registro.MonedaId, this.auth.idEmpresaActual(), null);
        aplicacionCredito.moneda  = this.registro.moneda;
        const dialogRef = this.dialog.open(DialogNuevoAplicacionCreditoCheque, {
            data: {
                aplicacionCredito: aplicacionCredito,
                DocumentoCreditoId: this.id,
                credito: this.registro,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result != null) {
                this.guardarAplicacionCredito(result);
            }
        });
    }

    async guardarAplicacionCredito(nuevoAplicacionCredito: AplicacionDocumentoCredito) {
        this.cargandoCreditos = true;
        if(nuevoAplicacionCredito) {
            // Enviar el registro para guardar
            var res = await this.service.crearAplicacionCreditoCheque(nuevoAplicacionCredito);
            
            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.ngOnInit();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargandoCreditos = false;
    }

    clickPoliza(poliza: Poliza) {
        let PolizaId = poliza.id; 
        if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.ESCRITURA)) {
            this.router.navigate(['/polizas/editar/' + PolizaId]);
        }
        else if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.LECTURA)) {
            this.router.navigate(['/polizas/detalle/' + PolizaId]);
        }
    }

    calcularParamsEspecialesAplicaciones(): string {
        var paramsEspeciales = '';
        paramsEspeciales += '&DocumentoCreditoId=' + this.id;
        return paramsEspeciales;
    }

    abrirEliminar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar documento de crédito',
                mensaje: '¿Está seguro de que desea eliminar este documento de crédito?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminar();
            }
        });
    }

    async eliminar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.eliminarDocumentoCredito(this.id);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.router.navigate(['/cobros/creditos-notas-devolucion/']);
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * Acciones * * *
    aplicarAccion($event) {
        if($event && !this.cargando) {
            var accion = $event.accion;
            var seleccionados = $event.seleccionados;

            var ids = $event.ids;
            switch(accion.id) {
                case 'revertir': {
                    this.abrirRevertir(ids, seleccionados);
                    break;
                }
            }
        }
    }

    abrirRevertir(ids: number[], aplicaciones: AplicacionDocumentoCredito[]) {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Revertir aplicación de crédito',
                mensaje: '¿Está seguro de que desea revertir las aplicaciones seleccionadas?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.revertirAplicaciones(ids);
            }
        });
    }

    async revertirAplicaciones(ids: number[], ) {
        this.cargando = true;
        if(ids && ids.length > 0) {
            // Enviar el registro para guardar
            var res = await this.service.revertirAplicacionesCreditos(ids);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }
}
