import { ImpuestoEmpresa } from './impuestoEmpresa';

export class ImpuestoDocumentoCredito {
    constructor(
        public id: number,
        public nombre: string,
        public porcentaje: number,
        public monto: number,
        public MonedaId: number,
        public ImpuestoEmpresaId: number,
        public DocumentoCreditoId: number,
    ){}

    impuesto: ImpuestoEmpresa;

    clone() {
        var nuevoRegistro = new ImpuestoDocumentoCredito(
            null,
            this.nombre,
            this.porcentaje,
            this.monto,
            this.MonedaId,
            this.ImpuestoEmpresaId,
            this.DocumentoCreditoId,
        );
        nuevoRegistro.impuesto = this.impuesto;
        return nuevoRegistro;
    }
}