import { Ramo } from './ramo';
import { PlantillaPasoMapaProceso } from './plantillaPasoMapaProceso';

export class PlantillaMapaProceso {
    constructor(
        public id: number,
        public nombre: string,
        public duracionDias: number,
        public activo: boolean,
        public isDeleted: boolean,
        public EmpresaId: number,
    ){}

    meta: any = {};
    ramos: Ramo[];
    pasos: PlantillaPasoMapaProceso[];
    idsRamos: number[];
    nombreRamos: string;
}