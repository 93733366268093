import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import moment from 'moment';
import { PolizasService } from '../polizas.service';
import iconDescargar from '@iconify/icons-ic/twotone-cloud-download';
import { Router } from '@angular/router';
import { SolicitudesService } from 'src/app/solicitudes/solicitudes.service';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Poliza } from 'src/app/models/poliza';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    poliza: Poliza,
}

@Component({
    selector: 'dialog-descarga-facturaciones',
    templateUrl: './dialog-descarga-facturaciones.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogDescargaFacturacionesComponent {
    apiURLs = new APIUrls();
    cargando: boolean;
    mes: number;
    anio: number = new Date().getFullYear();
    diaCorte: number;
    diaEfectivo: number;
    jsonConverters: JSONConverters = new JSONConverters();
    iconDescargar = iconDescargar;
    incluirGastosEmision: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<DialogDescargaFacturacionesComponent>,
        public servicePoliza: PolizasService,
        public solicitudesService: SolicitudesService,
        public auth: AuthService,
        public libraryService: LibraryService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
    }
    
    ngOnInit() {
        this.mes = new Date().getMonth() + 1;
        this.diaCorte = this.data.poliza.aseguradora.diaCorteReporteAltasBajasColectivosPersonas;
        this.diaEfectivo = this.data.poliza.aseguradora.diaCorteEfectivoAltasBajasColectivosPersonas;
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close({});
    }

    registroValido(): boolean {
        if(!this.anio) return false;
        if(!this.mes) return false;
        if(!this.diaCorte) return false;
        if(!this.diaEfectivo) return false;
        return true;
    }

    async descargarFacturaciones() {
        this.cargando = true;
        if (this.registroValido()) {
            const nombreArchivo = `Descarga de facturaciones póliza #${this.data.poliza.numero}`;
            let resArchivo = await this.servicePoliza.descargarExcelFacturacionesPoliza(nombreArchivo, this.data.poliza.id, this.mes, this.anio, this.diaCorte, this.diaEfectivo, this.incluirGastosEmision);
            if(resArchivo.error) {
                this.libraryService.crearNotificacion(resArchivo.error.mensajeError, 'error');
            }
            else {
                this.libraryService.crearNotificacion(resArchivo.data.mensaje, 'success',);
            }
            // this.cerrar();
        }
        this.cargando = false;
    }
}
