import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';

export interface DialogData {
    titulo: string,
    mensaje: string,
    payload: any,
    opciones: any[],
}

@Component({
    selector: 'generic-dialog-seleccionar-opcion',
    templateUrl: './generic-dialog-seleccionar-opcion.component.html',
})
export class GenericDialogSeleccionarOpcion {
    constructor(
        public dialogRef: MatDialogRef<GenericDialogSeleccionarOpcion>,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar(id: string) {
        this.dialogRef.close(id);
    }
}