import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { Prospecto } from '../models/prospecto';
import { RazonPerdida } from '../models/razonPerdida';
import { SeguimientoProspecto } from '../models/seguimientoProspecto';
import * as FileSaver from 'file-saver';
import { Ocupacion } from '../models/ocupacion';

@Injectable()
export class ProspectosService {
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    // * * * * * Prospectos * * * * *
    async obtenerProspectoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.prospectosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.prospectoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearProspecto(registro: Prospecto) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.prospectosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async crearProspectoNoAuth(registro: Prospecto) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.prospectosURL + '/noAuth';
            const res = await this.connection.postRequest(url, registro, false);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarProspecto(registro: Prospecto) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.prospectosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarProspecto(ProspectoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.prospectosURL + '/id/' + ProspectoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Prospecto eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Razones de pérdida * * * * *
    async obtenerRazonPerdidaPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.razonesPerdidasURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.razonPerdidaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearRazonPerdida(registro: RazonPerdida) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.razonesPerdidasURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarRazonPerdida(registro: RazonPerdida) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.razonesPerdidasURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarRazonPerdida(RazonPerdidaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.razonesPerdidasURL + '/id/' + RazonPerdidaId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Razón de pérdida eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Razones pérdidas
    async obtenerTodasRazonesPerdidas() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.razonesPerdidasURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.razonPerdidaDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las razones de pérdida.');
        }
    }

    // * * * * * Ocupaciones * * * * *
    async obtenerOcupacionPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ocupacionesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.ocupacionDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearOcupacion(registro: Ocupacion) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ocupacionesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Actualizar
    public async actualizarOcupacion(registro: Ocupacion) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ocupacionesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarOcupacion(OcupacionId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ocupacionesURL + '/id/' + OcupacionId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Ocupación eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Razones pérdidas
    async obtenerTodasOcupaciones() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ocupacionesURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.ocupacionDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las razones de pérdida.');
        }
    }

    // * * * * * Acciones * * * * *
    public async pasarANuevo(ids: number[], UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.prospectosURL + '/pasarANuevo';
            const res = await this.connection.postRequest(url, { ids: ids, UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Prospectos actualizados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async pasarACotizacion(ids: number[], UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.prospectosURL + '/pasarACotizacion';
            const res = await this.connection.postRequest(url, { ids: ids, UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Prospectos actualizados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async pasarANegociacion(ids: number[], UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.prospectosURL + '/pasarANegociacion';
            const res = await this.connection.postRequest(url, { ids: ids, UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Prospectos actualizados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async pasarAPerdido(ids: number[], RazonPerdidaId: number, UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.prospectosURL + '/pasarAPerdido';
            const res = await this.connection.postRequest(url, { ids: ids, RazonPerdidaId: RazonPerdidaId, UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Prospectos actualizados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async pasarAGanado(ids: number[], EjecutivoId: number, Ejecutivo2Id: number, Ejecutivo3Id: number, UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.prospectosURL + '/pasarAGanado';
            const res = await this.connection.postRequest(url, {
                ids: ids,
                EjecutivoId: EjecutivoId,
                Ejecutivo2Id: Ejecutivo2Id,
                Ejecutivo3Id: Ejecutivo3Id,
                UsuarioId: UsuarioId
            });
            return { error: null, data: { mensaje: 'Prospectos actualizados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async posponer(ids: number[], fechaContacto: string, UsuarioId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.prospectosURL + '/posponer';
            const res = await this.connection.postRequest(url, { ids: ids, fechaContacto: fechaContacto, UsuarioId: UsuarioId });
            return { error: null, data: { mensaje: 'Prospectos actualizados con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Analytics * * * * *
    async obtenerAnalytics(params: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.prospectosURL + '/analytics';
            url += '?' + params;
            var json = await this.connection.getRequest(url);
            return { error: null, data: { data: json.results } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los analytics para este módulo.');
        }
    }

    public async descargarExcelAnalytics(params: string) {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.prospectosURL + '/analyticsExcel';
            url += '?' + params;
            
            const res = await this.connection.getDownloadRequest(url);
            
            // Descargar archivo
            var filename = `Prospectos.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Archivo descargado con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * Seguimientos * * *
    public async crearSeguimientoProspecto(registro: SeguimientoProspecto) {
      try {
          var url = this.apiUrls.baseURL + this.apiUrls.seguimientosProspectosURL;
          const res = await this.connection.postRequest(url, registro);
          return { error: null, data: { mensaje: 'Seguimiento creado con éxito', result: res } };
      } catch (error) {
          return this.connection.obtenerMensajeError(error.status, error.error.message);
      }
  }

  public async actualizarSeguimientoProspecto(registro: SeguimientoProspecto) {
      try {
          var url = this.apiUrls.baseURL + this.apiUrls.seguimientosProspectosURL;
          const res = await this.connection.putRequest(url, registro);
          return { error: null, data: { mensaje: 'Seguimiento actualizado con éxito', result: res } };
      } catch (error) {
          return this.connection.obtenerMensajeError(error.status, error.error.message);
      }
  }

  public async eliminarSeguimientoProspecto(SeguimientoProspectoId: number) {
      try {
          var url = this.apiUrls.baseURL + this.apiUrls.seguimientosProspectosURL + '/id/' + SeguimientoProspectoId;
          const res = await this.connection.deleteRequest(url);
          return { error: null, data: { mensaje: 'Seguimiento eliminado con éxito', result: res } };
      } catch (error) {
          return this.connection.obtenerMensajeError(error.status, null);
      }
  }
}
