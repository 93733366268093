import { Usuario } from "./usuario";

export class EnvioSolicitud {
    constructor(
        public id:              number,
        public destinatarios: string,
        public asunto: string,
        public de: string,
        public fecha: string,
        public observaciones: string,

        // Relaciones
        public SolicitudPolizaId:         number,
        public UsuarioId:         number,
        public EmpresaId:         number,
    ){}

    fechaFormato: string;
    usuario: Usuario;
    nombreUsuario: string;
    destinatariosArray: string[];
}