import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibraryModule } from '../library/library.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule, MatOptionModule, MatIconModule, MatSelectModule, MatButtonModule, MatProgressBarModule, MatCheckboxModule, MatDatepickerModule, MatExpansionModule, MatTabsModule, MatChipsModule, MatPaginatorModule, MatCardModule, MatBottomSheetModule, MatListModule, MatRadioModule, MatAutocompleteModule } from '@angular/material';
import { IconModule } from '@visurel/iconify-angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxCurrencyModule } from "ngx-currency";
import { PolizasComponent } from './polizas/polizas.component';
import { BottomSheetPolizaComponent, NuevoPolizaComponent } from './nuevo-poliza/nuevo-poliza.component';
import { PolizasRoutingModule } from './polizas-routing.module';
import { NuevoCertificadoComponent } from './nuevo-certificado/nuevo-certificado.component';
import { NuevoDeclaracionComponent } from './nuevo-declaracion/nuevo-declaracion.component';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { DialogNuevoCliente } from './dialog-nuevo-cliente/dialog-nuevo-cliente.component';
import { MapasProcesosModule } from '../mapas-procesos/mapas-procesos.module';
import { DialogNuevaSolicitud } from './dialog-nueva-solicitud/dialog-nueva-solicitud.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PageLayoutModule } from '../../@vex/components/page-layout/page-layout.module';
import { ImportacionPolizasComponent } from './importacion-polizas/importacion-polizas.component';
import { DialogAgregarDocumentoPolizaComponent } from './dialog-agregar-documento-poliza/dialog-agregar-documento-poliza.component';
import { DialogAplicarPlantillaCertificados } from './dialog-aplicar-plantilla-certificados/dialog-aplicar-plantilla-certificados.component';
import { DialogDescargarPlantillaCertificados } from './dialog-descargar-plantilla-certificado/dialog-descargar-plantilla-certificado.component';
import { ImportacionCertificadosPlantillaComponent } from './importacion-certificados-plantilla/importacion-certificados-plantilla.component';
import { ImportacionCertificadosTiposCamposComponent } from './importacion-certificados-tipos-campos/importacion-certificados-tipos-campos.component';
import { ImportacionCertificadosDependientesBeneficiariosComponent } from './importacion-certificados-dependientes-beneficiarios/importacion-certificados-dependientes-beneficiarios.component';
import { DialogDescargaFacturacionesComponent } from './dialog-descarga-facturaciones/dialog-descarga-facturaciones.component';
import { DialogBuscadorGlobalComponent } from './dialog-buscador-global/dialog-buscador-global.component';
import { DialogCertificadosPagador } from './dialog-certificados-pagador/dialog-certificados-pagador.component';
import { SolicitudesPolizasComponent } from './solicitudes-polizas/solicitudes-polizas.component';
import { DialogDatosCertificado } from './dialog-datos-certificado/dialog-datos-certificado.component';
import { DialogCancelarPolizaComponent } from './dialog-cancelar-poliza/dialog-cancelar-poliza.component';
import { DialogNuevaMensajeria } from './dialog-nueva-mensajeria/dialog-nueva-mensajeria.component';
import { AnalyticsPolizasComponent } from './analytics-polizas/analytics-polizas.component';
import { ContenedorAnalyticsPolizasComponent } from './analytics-polizas/contenedor-analytics-polizas.component';
import { ChartModule } from 'angular-highcharts';
import { DialogGenerarEstadoCuenta } from '../cobros/dialog-generar-estado-cuenta/dialog-generar-estado-cuenta.component';
import { CobrosModule } from '../cobros/cobros.module';
import { AnalyticsSolicitudesComponent } from './analytics-solicitudes/analytics-solicitudes.component';
import { ContenedorAnalyticsSolicitudesComponent } from './analytics-solicitudes/contenedor-analytics-solicitudes.component';
import { DialogCopiarIncisoRamo } from './dialog-copiar-inciso-ramo/dialog-copiar-inciso-ramo.component';
import { DialogNuevoAclaracionPoliza } from './dialog-nuevo-aclaracion-poliza/dialog-nuevo-aclaracion-poliza.component';
import { DialogAgregarMultiplesDocumentosPolizaComponent } from './dialog-agregar-multiples-documentos/dialog-agregar-multiples-documentos.component';
import { DialogNuevoClausulaGarantiaPoliza } from './dialog-nuevo-clausula-garantia-poliza/dialog-nuevo-clausula-garantia-poliza.component';
import { ImportacionClausulasComponent } from './importacion-clausulas/importacion-clausulas.component';
import { DialogEnviarSolicitudComponent } from './dialog-enviar-solicitud/dialog-enviar-solicitud.component';
import { DialogBuscadorV2GlobalComponent } from './dialog-buscador-v2-global/dialog-buscador-v2-global.component';
import { DialogNuevoDireccionCertificado } from './dialog-nuevo-direccion-certificado/dialog-nuevo-direccion-certificado.component';
import { DialogAgregarDocumentosExpedienteComponent } from './dialog-agregar-documentos-expediente/dialog-agregar-documentos-expediente.component';
import { RenovacionMasivaPolizasComponent } from './renovacion-masiva-polizas/renovacion-masiva-polizas.component';

@NgModule({
    imports: [
        CommonModule,
        PolizasRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        LibraryModule,
        NgxDatatableModule,
        NgxCurrencyModule,
        BreadcrumbsModule,
        SecondaryToolbarModule,
        MapasProcesosModule,
        // Material
        MatInputModule,
        MatOptionModule,
        MatIconModule,
        MatSelectModule,
        MatButtonModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        IconModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatTabsModule,
        MatChipsModule,
        MatDialogModule,
        FlexLayoutModule,
        PageLayoutModule,
        MatPaginatorModule,
        MatCardModule,
        MatBottomSheetModule,
        MatListModule,
        MatRadioModule,
        ChartModule,
        CobrosModule,
    ],
    declarations: [
        PolizasComponent,
        NuevoPolizaComponent,
        NuevoCertificadoComponent,
        NuevoDeclaracionComponent,
        DialogNuevoCliente,
        DialogNuevaSolicitud,
        ImportacionPolizasComponent,
        ImportacionCertificadosPlantillaComponent,
        ImportacionCertificadosTiposCamposComponent,
        ImportacionCertificadosDependientesBeneficiariosComponent,
        DialogAgregarDocumentoPolizaComponent,
        DialogAplicarPlantillaCertificados,
        DialogDescargarPlantillaCertificados,
        DialogDescargaFacturacionesComponent,
        DialogBuscadorGlobalComponent,
        DialogCertificadosPagador,
        SolicitudesPolizasComponent,
        DialogDatosCertificado,
        DialogCancelarPolizaComponent,
        BottomSheetPolizaComponent,
        DialogNuevaMensajeria,
        AnalyticsPolizasComponent,
        ContenedorAnalyticsPolizasComponent,
        AnalyticsSolicitudesComponent,
        ContenedorAnalyticsSolicitudesComponent,
        DialogCopiarIncisoRamo,
        DialogNuevoAclaracionPoliza,
        DialogNuevoClausulaGarantiaPoliza,
        DialogAgregarMultiplesDocumentosPolizaComponent,
        ImportacionClausulasComponent,
        DialogEnviarSolicitudComponent,
        DialogBuscadorV2GlobalComponent,
        DialogNuevoDireccionCertificado,
        DialogAgregarDocumentosExpedienteComponent,
        RenovacionMasivaPolizasComponent,
    ],
    entryComponents: [
        DialogNuevoCliente,
        DialogNuevaSolicitud,
        DialogAgregarDocumentoPolizaComponent,
        DialogAplicarPlantillaCertificados,
        DialogDescargarPlantillaCertificados,
        DialogDescargaFacturacionesComponent,
        DialogBuscadorGlobalComponent,
        DialogCertificadosPagador,
        DialogDatosCertificado,
        DialogCancelarPolizaComponent,
        BottomSheetPolizaComponent,
        DialogNuevaMensajeria,
        DialogGenerarEstadoCuenta,
        DialogCopiarIncisoRamo,
        DialogNuevoAclaracionPoliza,
        DialogNuevoClausulaGarantiaPoliza,
        DialogAgregarMultiplesDocumentosPolizaComponent,
        DialogEnviarSolicitudComponent,
        DialogBuscadorV2GlobalComponent,
        DialogNuevoDireccionCertificado,
        DialogAgregarDocumentosExpedienteComponent,
    ],
    exports: [
        DialogBuscadorGlobalComponent,
        DialogDatosCertificado,
        DialogCancelarPolizaComponent,
        DialogBuscadorV2GlobalComponent,
        AnalyticsPolizasComponent,
        AnalyticsSolicitudesComponent,
    ]
})
export class PolizasModule {
}
