import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconAnalizar from '@iconify/icons-ic/twotone-search';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog, PageEvent } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { Usuario } from 'src/app/models/usuario';
import { GenericDialogErrores } from 'src/app/library/generic-dialog-errores/generic-dialog-errores.component';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
import { ClientesService } from 'src/app/clientes/clientes.service';
import { DocumentoCobro } from 'src/app/models/documentoCobro';
import { Poliza } from 'src/app/models/poliza';
import { PolizasService } from 'src/app/polizas/polizas.service';
import { Aseguradora } from 'src/app/models/aseguradora';
import { Ramo } from 'src/app/models/ramo';
import { Producto } from 'src/app/models/producto';
import { AseguradorasService } from 'src/app/aseguradoras/aseguradoras.service';
import { RamosService } from 'src/app/ramos/ramos.service';
import { TipoCertificado } from 'src/app/models/tipoCertificado';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-importacion-polizas',
    templateUrl: './importacion-polizas.component.html',
    styleUrls: ['./importacion-polizas.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class ImportacionPolizasComponent implements OnInit {
    // Íconos
    iconBack = iconBack;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconAnalizar = iconAnalizar;
    
    // Banderas y generales
    cargando: boolean = false;
    titulo: string = 'Importación de pólizas';
    apiURLs: APIUrls = new APIUrls();

    // Generales importación
    rutaArchivo: string;
    archivo: File;
    registros: any[];
    erroresLectura: any[];
    analisis = false;
    tamanoPagina: number = 15;
    pagina: number = 0;
    selectedIndex: number = 0;

    // Resultado de análisis
    registrosNuevos: any[];
    erroresAnalisis: any[];

    // Específicos
    AseguradoraId: number;
    RamoId: number;
    ProductoId: number;
    TipoCertificadoId: number;
    aseguradoras: Aseguradora[];
    ramos: Ramo[];
    productos: Producto[];
    tiposCertificados: TipoCertificado[];

    crumbs = [
        { nombre: 'Pólizas', link: '/polizas/' },
        { nombre: 'Importación de pólizas', link: '/polizas/importacion-polizas' },
    ];

    constructor(
        public clientesService: ClientesService,
        public service: PolizasService,
        public aseguradorasService: AseguradorasService,
        public usuariosService: UsuariosService,
        public ramosService: RamosService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * * 
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        this.titleService.setTitle(this.titulo);
        this.obtenerAseguradoras();
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
    }

    // * * * * * Validaciones * * * * * 
    formularioValido() {
        if(!this.AseguradoraId || !this.RamoId || !this.ProductoId || !this.TipoCertificadoId) return false;
        if(!this.registrosNuevos || this.registrosNuevos.length == 0) return false;
        return true;
    }

    // * * * * * Selccionar archivo * * * * *
    seleccionarArchivo(event: any){
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.rutaArchivo = file.name;
            this.archivo = file;
            this.analisis = false;
            this.analizarArchivoExcel();
        }
    }

    activarSeleccionArchivo(){
        document.getElementById('file-input-archivo').click();
    }

    limpiarArchivo(){
        this.rutaArchivo = null;
        this.archivo = null;
        this.analisis = false;
        this.erroresLectura = null;
        this.registros = null;
        this.pagina = 0;
    }

    // * * * * * Análisis de archivo * * * * *
    async analizarArchivoExcel() {
        if(this.archivo) {
            this.cargando = true;
            let tipoCertificado = this.tiposCertificados.find(x => x.id == this.TipoCertificadoId);
            var resLectura = await this.service.lecturaImportacionPolizas(this.archivo, tipoCertificado.campos, this.auth.idEmpresaActual(), this.auth.idUsuarioActual());
            if(resLectura.error) {
                // Error al leer archivo
                this.libraryService.crearNotificacion(resLectura.mensaje, 'danger');
                throw new Error();
            }
            else {
                this.erroresLectura = resLectura.errores;
                this.registros = resLectura.resultados;
                this.libraryService.crearNotificacion(resLectura.mensaje, 'success');
            }
            this.cargando = false;
        }
    }

    async analizarRegistros(){
        this.cargando = true;

        var res = await this.service.analizarImportacionPolizas(this.registros, this.auth.idEmpresaActual(), this.AseguradoraId, this.RamoId, this.ProductoId, this.TipoCertificadoId);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.analisis = true;
            this.registrosNuevos = res.data.registrosNuevos;
            this.erroresAnalisis = res.data.errores;
            this.selectedIndex = 1;
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            this.analisis = false;
        }

        this.cargando = false;
    }

    async subirRegistros(){
        try {
            this.cargando = true;
            if(!this.analisis) throw new Error('Debe realizar analizar la importación antes de poder subirla.');
            if(!this.registrosNuevos || this.registrosNuevos.length == 0) throw new Error('No hay datos para subir.');
    
            // Enviar el registro para guardar
            this.service.subirImportacionPolizas(this.registrosNuevos, this.auth.idEmpresaActual());
            this.libraryService.crearNotificacionGrande('La carga masiva de pólizas se realizará en segundo plano y recibirá un correo electrónico al finalizar.', 'success');
            
            this.cargando = false;
        } catch(error) {
            this.cargando = false;
            this.libraryService.crearNotificacion(error.message, 'danger');
        }
    }

    abrirDialogErrores() {
        const dialogRef = this.dialog.open(GenericDialogErrores, {
            data: {
                titulo: 'Errores de lectura de archivo CSV',
                errores: this.erroresLectura,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            
        });
    }

    // * * * * * Tabla * * * * *
    eliminarRegistro(index) {
        if(this.registros) {
            this.registros.splice(index, 1);
        }
    }

    pageUpdate($event: PageEvent) {
        this.pagina = $event.pageIndex;
        this.tamanoPagina = $event.pageSize;
    }

    async descargarPlantilla(){
        this.cargando = true;
        
        var res = await this.service.descargarExcelPlantillaPolizas('Plantilla de pólizas', this.AseguradoraId, this.RamoId, this.ProductoId, this.TipoCertificadoId);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }

        this.cargando = false;
    }

    async obtenerAseguradoras() {
        this.cargando = true;
        var res = await this.aseguradorasService.obtenerTodasAseguradoras();
        if(!res.error) {
            this.aseguradoras = res.data.registros;
            for (let aseguradora of this.aseguradoras) {
                aseguradora.ramos = aseguradora.ramos.filter(x => x.activo);
            }

            this.obtenerRamos();
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerRamos() {
        this.cargando = true;
        if(this.AseguradoraId && this.aseguradoras) {
            var index = this.libraryService.indexOf(this.aseguradoras, 'id', this.AseguradoraId);
            var aseguradora = this.aseguradoras[index];
            this.ramos = aseguradora.ramos;
            // Asignar ramo por defecto
            if(this.RamoId) {
                var indexRamoActual = this.libraryService.indexOf(this.ramos, 'id', this.RamoId);
                if(indexRamoActual == -1 && this.ramos.length > 0) this.RamoId = this.ramos[0].id;
            }
            this.ramos = this.ramos.sort((a, b) => {
                if(a.nombre < b.nombre) return -1;
                if(a.nombre > b.nombre) return 1;
                return 0;
            });
            //else if(this.ramos.length > 0 && !this.registro.RamoId) this.registro.RamoId = this.ramos[0].id;
            this.obtenerProductosRamo();
        }
        this.cargando = false;
    }

    async obtenerProductosRamo() {
        this.cargando = true;
        if(this.RamoId) {
            this.obtenerTiposCertificados();
            
            var res = await this.ramosService.obtenerTodosProductosRamo(this.RamoId, this.AseguradoraId);
            if(!res.error) {
                this.productos = res.data.registros;
                this.productos = this.productos.sort((a, b) => {
                    if(a.nombre < b.nombre) return -1;
                    if(a.nombre > b.nombre) return 1;
                    return 0;
                });
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    async obtenerTiposCertificados() {
        this.cargando = true;
        if(this.RamoId) {
            var res = await this.service.obtenerTodosTiposCertificadosRamo(this.RamoId);
            if(!res.error) {
                this.tiposCertificados = res.data.registros;
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }
}
