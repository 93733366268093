import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { APIUrls } from '../../api/apiUrls';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { JSONConverters } from 'src/app/models/JSONConverters';
import iconEdit from '@iconify/icons-fa-solid/edit';
import { CobrosService } from '../cobros.service';
import { LibraryService } from 'src/app/library/library.service';

@Component({
    selector: 'app-gestiones-cobros',
    templateUrl: './gestiones-cobros.component.html',
    styleUrls: ['./gestiones-cobros.component.scss']
})
export class GestionesCobrosComponent implements OnInit {
    // Templates de celdas
    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;
    @ViewChild('cellImagen', { static: true }) cellImagen: TemplateRef<any>;
    apiURLs = new APIUrls();
    urlAutocompleteEjecutivos = this.apiURLs.baseURL + this.apiURLs.usuariosURL + '/autocompleteEjecutivos';
    jsonConverters = new JSONConverters();
    urlBusqueda = this.apiURLs.baseURL + this.apiURLs.gestionesCobrosURL + '/search';
    titulo: string = 'Gestiones de cobro';
    mostrarTabla: boolean = false;
    iconEdit = iconEdit;

    // Columnas de tabla
    columnas = [
        { prop: 'asunto', name: 'Asunto', sortable: true, cellTemplate: null },
        { prop: 'fechaFormato', name: 'Fecha', sortable: true, cellTemplate: null },
        { prop: 'nombreTipo', name: 'Tipo', sortable: true, cellTemplate: null },
        { prop: 'correos', name: 'Correos', sortable: true, cellTemplate: null },
        { prop: 'nombrePlantilla', name: 'Plantilla', sortable: true, cellTemplate: null },
        { prop: 'nombreUsuario', name: 'Usuario', sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    campos = [
        { nombre: 'Asunto', campo: 'asunto', tipo: 'texto', categorias: null },
        { nombre: 'Fecha', campo: 'fecha', tipo: 'rango-fechas', categorias: null },
        { nombre: 'Correos', campo: 'correos', tipo: 'texto', categorias: null },
    ];

    // Filtros
    filtros = [
        // Tipo
        {
            nombre: 'Tipo', campo: 'tipos', tipo: 'select-multiple',
            mostrar: false,
            categorias: this.apiURLs.tiposGestionesCobros,
        },
        { nombre: 'Plantilla', campo: 'PlantillaGestionCobroId', tipo: 'select-unico', valor: null, mostrar: false, categorias: []},
        // Asesor
        {
            nombre: 'Asesor', campo: 'UsuarioId', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteEjecutivos, seleccionados: [], mostrar: false,
            idProp: 'id', titleProp: 'nombreCompleto', fieldSearch: 'general',
            parser: this.jsonConverters.usuarioDeJSON, parserService: this.jsonConverters,
        },
    ];

    crumbs = [
        { nombre: 'Gestiones de cobro', link: '/cobros/gestiones-cobros' },
    ];

    constructor(
        public service: CobrosService,
        public libraryService: LibraryService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.obtenerPlantillas();
        this.agregarTemplates();
        this.mostrarTabla = true;
    }

    obtenerRuta(params: Params) {
        this.titulo = 'Gestiones de cobro';
        if(this.route.snapshot.url.length > 0) {
            var ruta = this.route.snapshot.url[0].path;
        }
        this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
    }

    agregarTemplates() {
        this.columnas.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
    }

    calcularParamsEspeciales(): string {
        var paramsEspeciales = '';
        
        return paramsEspeciales;
    }

    async obtenerPlantillas() {
        var res = await this.service.obtenerTodosPlantillasGestionesCobros();
        if(!res.error) {
            this.filtros[1].categorias = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
    }
}
