import { ClausulaGarantiaPoliza } from "./clausulaGarantiaPoliza";
import { Usuario } from "./usuario";

export class BitacoraClausulaGarantia {
    constructor(
        public id: number,
        public estadoAnterior: string,
        public estadoNuevo: string,
        public fecha: string,
        public ClausulaGarantiaPolizaId: number,
        public UsuarioId: number,
    ){}

    fechaFormato: string;
    fechaDate: Date;

    clausulaGarantiaPoliza: ClausulaGarantiaPoliza;
    usuario: Usuario;
}
