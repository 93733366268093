import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { NuevoUsuarioComponent } from './nuevo-usuario/nuevo-usuario.component';
import { AuthGuardPermisos } from '../auth/auth-guard-permisos';
import { APIUrls } from '../api/apiUrls';
import { PerfilComponent } from './perfil/perfil.component';
import { ImportacionEjecutivosAgentesComponent } from './importacion-ejecutivos-agentes/importacion-ejecutivos-agentes.component';

var apiURLs = new APIUrls();
const routes: Routes = [
    {
        path: '',
        component: UsuariosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.USUARIOS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'acceso-a-clientes',
        component: UsuariosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.ACCESO_CLIENTES.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'perfil',
        component: PerfilComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.USUARIOS.id, nivel: apiURLs.nivelesAutorizacion.PROPIO }
    },
    {
        path: 'nuevo',
        component: NuevoUsuarioComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.USUARIOS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'nuevo-acceso-cliente',
        component: NuevoUsuarioComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.ACCESO_CLIENTES.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'editar/:id',
        component: NuevoUsuarioComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.USUARIOS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'importacion-ejecutivos-agentes',
        component: ImportacionEjecutivosAgentesComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.USUARIOS.id, nivel: apiURLs.nivelesAutorizacion.ADMINISTRADOR }
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UsuariosRoutingModule {
}
