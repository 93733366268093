import { Component, Inject, Input } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { Agrupador } from 'src/app/models/agrupador';
import moment from 'moment';
import iconDownload from '@iconify/icons-fa-solid/download';
import { Usuario } from 'src/app/models/usuario';
import { Aseguradora } from 'src/app/models/aseguradora';
import { Poliza } from 'src/app/models/poliza';
import { Certificado } from 'src/app/models/certificado';
import { CobrosService } from '../cobros.service';
import { AseguradorasService } from 'src/app/aseguradoras/aseguradoras.service';
import { DialogPreviewDownloadDocumentComponent } from 'src/app/library/dialog-preview-download-document/dialog-preview-download-document.component';
import { RamosService } from 'src/app/ramos/ramos.service';
import { Ramo } from 'src/app/models/ramo';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};
export interface DialogData {
    polizaDefault: Poliza,
    pagadorDefault: Usuario,
    incluirCobrados: boolean,
}

@Component({
    selector: 'dialog-generar-estado-cuenta',
    templateUrl: './dialog-generar-estado-cuenta.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogGenerarEstadoCuenta {
    @Input() modoGestionCobro: boolean = false;
    @Input() filtrosIniciales: any;

    apiURLs = new APIUrls();
    fechaInicioDate: Date;
    fechaInicio: string;
    fechaFinDate: Date;
    fechaFin: string;
    cargando: boolean = false;
    incluirCobrados: boolean = true;
    incluirPorCobrar: boolean = true;
    incluirNotasCredito: boolean = false;
    incluirNotasCreditoPendientes: boolean = false;
    tipoAgrupador: string = 'poliza';
    camposAMostrar: string[] = this.apiURLs.camposEstadoDeCuenta.map(campo => campo.id);

    iconDownload = iconDownload;

    jsonConverters: JSONConverters = new JSONConverters();
    urlAutocompleteAgrupadores = this.apiURLs.baseURL + this.apiURLs.agrupadoresURL + '/search';
    agrupadores: Agrupador[] = [];
    AgrupadorId: number;
    aseguradoras: Aseguradora[];
    ramos: Ramo[];

    columnasAgrupadores = [
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'descripcion', name: 'Descripción', sortable: true, cellTemplate: null },
    ];
    camposAgrupadores = [
        { nombre: 'Nombre', campo: 'nombre', tipo: 'texto', categorias: null },
    ];

    columnasClientes = [
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'apellido', name: 'Apellido', sortable: true, cellTemplate: null },
        { prop: 'nombreAgrupador', name: 'Agrupador', sortable: true, cellTemplate: null },
        { prop: 'telefono1', name: 'Teléfono', sortable: true, cellTemplate: null },
        { prop: 'correo1', name: 'Correo electrónico', sortable: true, cellTemplate: null },
    ];
    camposClientes = [
        { nombre: 'Nombre', campo: 'general', tipo: 'texto', categorias: null },
    ];
    urlAutocompleteClientes = this.apiURLs.baseURL + this.apiURLs.informacionClientesURL + '/search';
    pagadores: Usuario[] = [];
    idsPagadores: number[] = [];
    clientes: Usuario[] = [];
    idsClientes: number[] = [];

    idsAseguradoras: number[] = [];
    idsRamos: number[] = [];

    columnasAseguradoras = [
        { prop: 'codigo', name: 'Código', sortable: true, cellTemplate: null },
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'imagen', name: 'Imagen', sortable: true, cellTemplate: null },
    ];
    camposAseguradoras = [
        { nombre: 'Nombre', campo: 'nombre', tipo: 'texto', categorias: null },
        { nombre: 'Código', campo: 'codigo', tipo: 'texto', categorias: null },
    ];

    urlAutocompletePolizas = this.apiURLs.baseURL + this.apiURLs.polizasURL + '/search';
    polizas: Poliza[] = [];
    idsPolizas: number[] = [];
    posiblesEstadosPolizas = [
        { nombre: 'Emisión', color: '#0b60b5' },
        { nombre: 'Nueva', color: '#52b50b' },
        { nombre: 'Traslado', color: '#853b07' },
        { nombre: 'Renovada', color: '#000000' },
        { nombre: 'Histórica', color: '#878787' },
        { nombre: 'Cancelada', color: '#d40000' },
    ];

    columnasPolizas = [
        { prop: 'numero', name: 'Número', sortable: true, cellTemplate: null },
        { prop: 'cliente.nombreCompleto', name: 'Cliente', sortable: true, cellTemplate: null },
        { prop: 'aseguradora.nombre', name: 'Aseguradora', sortable: true, cellTemplate: null },
        { prop: 'ramo.nombre', name: 'Ramo', sortable: true, cellTemplate: null },
        { prop: 'producto.nombre', name: 'Producto', sortable: true, cellTemplate: null },
        { prop: 'nombreEstado', name: 'Estado', sortable: false, cellTemplate: null },
    ];
    camposPolizas = [
        { nombre: 'Número de póliza', campo: 'numero', tipo: 'texto', categorias: null },
        { nombre: 'ID interno', campo: 'id', tipo: 'texto', categorias: null },
    ];

    urlAutocompleteCertificados = this.apiURLs.baseURL + this.apiURLs.certificadosURL + '/search';
    certificados: Certificado[] = [];
    idsCertificados: number[] = [];

    constructor(
        public dialogRef: MatDialogRef<DialogGenerarEstadoCuenta>,
        public dialog: MatDialog,
        public service: CobrosService,
        public aseguradorasService: AseguradorasService,
        public ramosService: RamosService,
        public libraryService: LibraryService,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit(){
        // Arreglar estilo
        var dialog = document.getElementById("dialogOverflow");
        var dialogContainer = null;
        if(dialog) dialogContainer = dialog.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer.className += ' dialogOverflow';

        if(this.data.polizaDefault) {
            this.polizas = [this.data.polizaDefault];
            this.actualizarPolizas(this.polizas);
        }
        else {
            this.fechaInicioDate = moment().startOf('month').toDate();
            this.actualizarFechaInicio({ value: { _d: this.fechaInicioDate } });
            this.fechaFinDate = moment().endOf('month').toDate();
            this.actualizarFechaFin({ value: { _d: this.fechaFinDate } });
        }

        if(this.data.pagadorDefault) {
            this.pagadores = [this.data.pagadorDefault];
            this.actualizarPagadores(this.pagadores);
        }
        
        if(this.data.incluirCobrados == false || this.data.incluirCobrados == true) {
            this.incluirCobrados = this.data.incluirCobrados;
        }

        this.obtenerAseguradoras();
        this.obtenerRamos();
        setTimeout(() => {
            this.cargarFiltrosIniciales();
        }, 1500);
    }

    // * * * Confirmar * * *
    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close(null);
    }

    // * * * Descargar * * *
    async descargarPDF() {
        if(this.registroValido()) {
            this.cargando = true;
            var res = await this.service.descargarPDFEstadoCuenta(
                this.AgrupadorId,
                this.idsPagadores,
                this.idsClientes,
                this.idsAseguradoras,
                this.idsRamos,
                this.idsPolizas,
                this.idsCertificados,
                this.fechaInicio,
                this.fechaFin,
                this.incluirPorCobrar,
                this.incluirCobrados,
                this.incluirNotasCredito,
                this.incluirNotasCreditoPendientes,
                this.tipoAgrupador,
                this.camposAMostrar,
                false
            );
            
            if(!res.error) {
                //this.confirmar();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            
            this.cargando = false;
        }
    }

    async descargarExcel() {
        if(this.registroValido()) {
            this.cargando = true;
            var res = await this.service.descargarExcelEstadoCuenta(
                this.AgrupadorId,
                this.idsPagadores,
                this.idsClientes,
                this.idsAseguradoras,
                this.idsRamos,
                this.idsPolizas,
                this.idsCertificados,
                this.fechaInicio,
                this.fechaFin,
                this.incluirPorCobrar,
                this.incluirCobrados,
                this.incluirNotasCredito,
                this.incluirNotasCreditoPendientes,
                this.tipoAgrupador,
                this.camposAMostrar,
                false
            );
            
            if(!res.error) {
                //this.confirmar();
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            
            this.cargando = false;
        }
    }

    registroValido(): boolean {
        //if(!this.AgrupadorId) return false;
        if(
            (!this.idsPagadores || this.idsPagadores.length == 0) &&
            (!this.idsPolizas || this.idsPolizas.length == 0) &&
            (!this.idsClientes || this.idsClientes.length == 0) &&
            !this.AgrupadorId
        ) return false;
        if(!this.incluirCobrados && !this.incluirPorCobrar && !this.incluirNotasCredito && !this.incluirNotasCreditoPendientes) return false;
        if(!this.tipoAgrupador) return false;
        if(!this.camposAMostrar || this.camposAMostrar.length == 0) return false;
        return true;
    }

    // * * * Fechas * * *
    actualizarFechaInicio($event){
        if($event && $event.value) {
            this.fechaInicio = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    actualizarFechaFin($event){
        if($event && $event.value) {
            this.fechaFin = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    // * * * Agrupador * * *
    actualizarAgrupador(items: Agrupador[]) {
        var AgrupadorId = null;
        this.agrupadores = items;
        if(items && items.length > 0) {
            AgrupadorId = items[0].id;
        }
        if(AgrupadorId != this.AgrupadorId) {
            this.pagadores = [];
            this.actualizarPagadores(this.pagadores);
        }
        this.AgrupadorId = AgrupadorId;
    }

    // * * * Pagadores * * *
    actualizarPagadores(items: Usuario[]) {
        this.pagadores = items;
        this.idsPagadores = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            this.idsPagadores.push(item.id);
        }
    }

    actualizarClientes(items: Usuario[]) {
        this.clientes = items;
        this.idsClientes = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            this.idsClientes.push(item.id);
        }
    }

    calcularParamsEspecialesPagadores(){
        var params = '';
        if(this.AgrupadorId) {
            params += '&agrupador=' + this.AgrupadorId;
        }

        if(this.idsPolizas) {
            params += '&idsPolizas=' + this.idsPolizas.toString();
        }

        return params;
    }

    // * * * Aseguradoras * * *
    actualizarAseguradoras() {
        this.actualizarPolizas(this.polizas);
    }

    // * * * Pólizas * * *
    actualizarPolizas(items: Poliza[]) {
        this.idsPolizas = [];
        this.polizas = items;
        if(items && items.length > 0) {
            for (const item of items) {
                this.idsPolizas.push(item.id);
            }
            // Poner fechas de vigencia
            if(items.length == 1) {
                this.fechaInicioDate = items[0].fechaVigenciaInicioDate;
                this.actualizarFechaInicio({ value: { _d: this.fechaInicioDate } });
                this.fechaFinDate = items[0].fechaVigenciaFinDate;
                this.actualizarFechaFin({ value: { _d: this.fechaFinDate } });
            }
        }
    }

    calcularParamsEspecialesPolizas(){
        var params = '';
        if(this.idsAseguradoras && this.idsAseguradoras.length > 0) params += '&idsAseguradoras=' + this.idsAseguradoras.toString();
        if(this.idsRamos && this.idsRamos.length > 0) params += '&idsRamos=' + this.idsRamos.toString();
        if(this.AgrupadorId) params += '&agrupador=' + this.AgrupadorId;
        if(this.idsClientes && this.idsClientes.length > 0) params += '&idsPagadores=' + this.idsClientes;
        return params;
    }

    // * * * Certificados * * *
    actualizarCertificados(items: Poliza[]) {
        this.idsCertificados = [];
        if(items && items.length > 0) {
            this.idsCertificados.push(items[0].id);
        }
    }

    calcularParamsEspecialesCertificados(){
        var params = '';
        if(this.idsPagadores) {
            params += '&idsPagadores=' + this.idsPagadores.toString();
        }
        if(this.idsPolizas) {
            params += '&idsPolizas=' + this.idsPolizas.toString();
        }
        return params;
    }

    async obtenerAseguradoras() {
        this.cargando = true;
        var res = await this.aseguradorasService.obtenerTodasAseguradoras();
        if(!res.error) {
            this.aseguradoras = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    marcarTodasAseguradoras() {
        let desmarcar = false;
        if(this.idsAseguradoras.length >= this.aseguradoras.length - 2) {
            desmarcar = true;
        }

        this.idsAseguradoras = [];
        if(!desmarcar) {
            for (const aseguradora of this.aseguradoras) {
                this.idsAseguradoras.push(aseguradora.id);
            }
        }
    }

    async obtenerRamos() {
        this.cargando = true;
        var res = await this.ramosService.obtenerTodosRamos();
        if(!res.error) {
            this.ramos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    marcarTodosRamos() {
        let desmarcar = false;
        if(this.idsRamos.length >= this.ramos.length - 2) {
            desmarcar = true;
        }

        this.idsRamos = [];
        if(!desmarcar) {
            for (const aseguradora of this.ramos) {
                this.idsRamos.push(aseguradora.id);
            }
        }
    }

    async visualizarEstadoCuentaPDF(){
        this.cargando = true;
        var res = await this.service.descargarPDFEstadoCuenta(
            this.AgrupadorId,
            this.idsPagadores,
            this.idsClientes,
            this.idsAseguradoras,
            this.idsRamos,
            this.idsPolizas,
            this.idsCertificados,
            this.fechaInicio,
            this.fechaFin,
            this.incluirPorCobrar,
            this.incluirCobrados,
            this.incluirNotasCredito,
            this.incluirNotasCreditoPendientes,
            this.tipoAgrupador,
            this.camposAMostrar,
            true
        );
        if (res.error) this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;

        const fileReader = new FileReader();
        fileReader.onload = () => {
            const pdfSrc = new Uint8Array(fileReader.result as ArrayBuffer);
            this.abrirPreviewDocumento(pdfSrc)
        };
        await fileReader.readAsArrayBuffer(res.data.blob);

    }

    abrirPreviewDocumento(pdfSrc) {
          const dialogRef = this.dialog.open(DialogPreviewDownloadDocumentComponent, {
              data: {
                  pdfSrc: pdfSrc,
                  titulo: 'Estado de cuenta',
              }
          });
          dialogRef.afterClosed().subscribe(async result => {
              if (result) {

              }
          });
    }

    generarJSONFiltros(): any {
        let jsonFiltros = {};
        if(this.AgrupadorId) jsonFiltros['AgrupadorId'] = this.AgrupadorId;
        if(this.agrupadores) jsonFiltros['agrupadores'] = this.agrupadores;

        if(this.idsPagadores) jsonFiltros['idsPagadores'] = this.idsPagadores;
        if(this.pagadores) jsonFiltros['pagadores'] = this.pagadores;

        if(this.idsPolizas) jsonFiltros['idsPolizas'] = this.idsPolizas;
        if(this.polizas) jsonFiltros['polizas'] = this.polizas;
        
        if(this.idsClientes) jsonFiltros['idsClientes'] = this.idsClientes;
        if(this.clientes) jsonFiltros['clientes'] = this.clientes;
        
        if(this.idsAseguradoras) jsonFiltros['idsAseguradoras'] = this.idsAseguradoras;
        if(this.idsRamos) jsonFiltros['idsRamos'] = this.idsRamos;
        
        if(this.idsCertificados) jsonFiltros['idsCertificados'] = this.idsCertificados;
        if(this.certificados) jsonFiltros['certificados'] = this.certificados;

        if(this.fechaInicio) jsonFiltros['fechaInicio'] = this.fechaInicio;
        if(this.fechaFin) jsonFiltros['fechaFin'] = this.fechaFin;

        if(this.incluirPorCobrar) jsonFiltros['incluirPorCobrar'] = this.incluirPorCobrar;
        if(this.incluirCobrados) jsonFiltros['incluirCobrados'] = this.incluirCobrados;
        if(this.incluirNotasCredito) jsonFiltros['incluirNotasCredito'] = this.incluirNotasCredito;
        if(this.incluirNotasCreditoPendientes) jsonFiltros['incluirNotasCreditoPendientes'] = this.incluirNotasCreditoPendientes;
        if(this.tipoAgrupador) jsonFiltros['tipoAgrupador'] = this.tipoAgrupador;
        if(this.camposAMostrar) jsonFiltros['camposAMostrar'] = this.camposAMostrar;
        
        return jsonFiltros;
    }

    cargarFiltrosIniciales() {
        if(this.filtrosIniciales) {
            if(this.filtrosIniciales.AgrupadorId) this.AgrupadorId = this.filtrosIniciales.AgrupadorId;
            if(this.filtrosIniciales.agrupadores) this.agrupadores = this.filtrosIniciales.agrupadores;
            if(this.filtrosIniciales.idsPagadores) this.idsPagadores = this.filtrosIniciales.idsPagadores;
            if(this.filtrosIniciales.pagadores) this.pagadores = this.filtrosIniciales.pagadores;
            if(this.filtrosIniciales.idsPolizas) this.idsPolizas = this.filtrosIniciales.idsPolizas;
            if(this.filtrosIniciales.polizas) this.polizas = this.filtrosIniciales.polizas;
            if(this.filtrosIniciales.idsClientes) this.idsClientes = this.filtrosIniciales.idsClientes;
            if(this.filtrosIniciales.clientes) this.clientes = this.filtrosIniciales.clientes;
            if(this.filtrosIniciales.idsAseguradoras) this.idsAseguradoras = this.filtrosIniciales.idsAseguradoras;
            if(this.filtrosIniciales.idsRamos) this.idsRamos = this.filtrosIniciales.idsRamos;
            if(this.filtrosIniciales.idsCertificados) this.idsCertificados = this.filtrosIniciales.idsCertificados;
            if(this.filtrosIniciales.certificados) this.certificados = this.filtrosIniciales.certificados;
            if(this.filtrosIniciales.fechaInicio) {
                this.fechaInicio = this.filtrosIniciales.fechaInicio;
                this.fechaInicioDate = new Date(this.fechaInicio);
            }
            if(this.filtrosIniciales.fechaFin) {
                this.fechaFin = this.filtrosIniciales.fechaFin;
                this.fechaFinDate = new Date(this.fechaFin);
            }
            if(this.filtrosIniciales.incluirPorCobrar) this.incluirPorCobrar = this.filtrosIniciales.incluirPorCobrar;
            if(this.filtrosIniciales.incluirCobrados) this.incluirCobrados = this.filtrosIniciales.incluirCobrados;
            if(this.filtrosIniciales.incluirNotasCredito) this.incluirNotasCredito = this.filtrosIniciales.incluirNotasCredito;
            if(this.filtrosIniciales.incluirNotasCreditoPendientes) this.incluirNotasCreditoPendientes = this.filtrosIniciales.incluirNotasCreditoPendientes;
            if(this.filtrosIniciales.tipoAgrupador) this.tipoAgrupador = this.filtrosIniciales.tipoAgrupador;
            if(this.filtrosIniciales.camposAMostrar) this.camposAMostrar = this.filtrosIniciales.camposAMostrar;
        }
    }
}