import { Cobertura } from './cobertura';

export class CoberturaIncisoCertificado {
    constructor(
        public id: number,
        public IncisoCertificadoId: number,
        public CoberturaId: number,
    ){}

    cobertura: Cobertura;
    nombreCobertura: string;
}