import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconCandadoDetalle from '@iconify/icons-fa-solid/lock';
import iconCandadoEditar from '@iconify/icons-fa-solid/lock-open';
import iconEmail from '@iconify/icons-fa-solid/envelope';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog, MatDialogRef } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { CobrosService } from '../cobros.service';
import { GestionCobro } from 'src/app/models/gestionCobro';
import { PlantillaGestionCobro } from 'src/app/models/plantillaMapaProceso copy';
import { DialogGenerarEstadoCuenta } from '../dialog-generar-estado-cuenta/dialog-generar-estado-cuenta.component';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { EmpresasService } from 'src/app/empresas/empresas.service';
import { FormularioCliente } from 'src/app/models/formularioCliente';
import { DialogSeleccionarContactosClienteComponent } from '../dialog-seleccionar-contactos/dialog-seleccionar-contactos.component';
import { ContactoUsuario } from 'src/app/models/contactoUsuario';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { DialogAgregarDocumentoEnvioGestionCobroComponent } from '../dialog-agregar-documento-envio-gestion-cobro/dialog-agregar-documento-envio-gestion-cobro.component';
import { DocumentoEnvioGestionCobro } from 'src/app/models/documentoEnvioGestionCobro';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-nuevo-gestion-cobro',
    templateUrl: './nuevo-gestion-cobro.component.html',
    styleUrls: ['./nuevo-gestion-cobro.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class NuevoGestionCobroComponent implements OnInit {
    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconCandadoDetalle = iconCandadoDetalle;
    iconCandadoEditar = iconCandadoEditar;
    iconEmail = iconEmail;

    // Banderas y generales
    cargando: boolean = false;
    modoEdicion: boolean;
    modoDetalle: boolean;
    modoCreacion: boolean;
    titulo: string;
    apiURLs: APIUrls = new APIUrls();
    jsonConverters = new JSONConverters();

    // Datos
    id: number;
    registro: GestionCobro;
    plantillas: PlantillaGestionCobro[];
    formularios: FormularioCliente[];

    nuevoCorreo: string;
    separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

    @ViewChild('contenido', { static: true }) contenido: any;
    @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
    @ViewChild('filtrosEstadoCuenta', {static: false}) filtrosEstadoCuenta: DialogGenerarEstadoCuenta;

    crumbs = [
        { nombre: 'Gestiones de cobro', link: '/cobros/gestiones-cobros' },
        { nombre: 'Nueva gestión de cobro', link: '/cobros/nuevo-gestion-cobro' },
    ];

    botonUploadImagen = {
        name: 'subirImagen',
        icon: 'image',
        exec: this.activarInputImagen.bind(this),
    }
    botonesEditor = [
        'bold', 'italic',
        '|',
        'ul', 'ol',
        '|',
        'font', 'fontsize', 'brush', 'paragraph',
        '|',
        this.botonUploadImagen, 'table', 'link',
        '|',
        'left', 'center', 'right', 'justify',
        '|',
        'undo', 'redo',
        '|',
        'hr', 'eraser', 'fullsize'
    ]
    config = {
        language: 'es',
        buttons: this.botonesEditor,
        readonly: false,
        toolbarAdaptive: false
    };

    // * * * Documentos Envío Gestion cobro * * *
    columnasDocumentosEnvioGestionCobro = [
        { prop: 'titulo', name: 'Título', sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    camposDocumentosEnvioGestionCobro = [
        { nombre: 'Título'      , campo: 'titulo'       , tipo: 'texto', categorias: null },
    ];
    filtrosDocumentosEnvioGestionCobro = [];
    urlBusquedaDocumentosEnvioGestionCobro = this.apiURLs.baseURL + this.apiURLs.documentosEnviosGestionesCobrosURL + '/search';

    constructor(
        public service: CobrosService,
        public empresasService: EmpresasService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * *
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        this.obtenerPlantillas();
        this.obtenerFormulariosClientes();
        await this.obtenerRegistro();
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        this.modoCreacion = ruta === 'nuevo-gestion-cobro';
        this.modoEdicion = ruta === 'editar-gestion-cobro';
        this.modoDetalle = ruta === 'detalle-gestion-cobro';
        if(params['id']){
            this.id = parseInt(params['id']);
        }
    }

    // * * * * * Obtener datos * * * * *
    // Obtener el registro o inicializar uno nuevo
    async obtenerRegistro() {
        this.cargando = true;
        if(this.modoCreacion) {
            // Asignar título
            this.titulo = 'Agregar gestión de cobro';
            this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
            this.registro = new GestionCobro(null, null, null, null, null, null, null, null, false, null, null, null, null, this.auth.idUsuarioActual(), this.auth.idEmpresaActual());
            this.titleService.setTitle('Nueva gestión de cobro' + this.apiURLs.nombreSitioTitle);
        }
        else if(this.modoEdicion || this.modoDetalle) {
            if(this.modoEdicion) this.titulo = 'Editar gestión de cobro';
            if(this.modoDetalle) this.titulo = 'Detalle de gestión de cobro';
            // Obtener registro
            var res = await this.service.obtenerGestionCobroPorId(this.id);
            if(!res.error) {
                this.registro = res.data.registro;
                this.crumbs[1].link = '/cobros/editar-gestion-cobro/' + this.id;
                this.titleService.setTitle('Gestión de cobro #' + this.registro.id + this.apiURLs.nombreSitioTitle);
            }
            else {
                this.router.navigate(['/404']);
            }
        }
        this.cargando = false;
    }

    // * * * * * Editar datos * * * * *
    // Guardar datos
    async guardar(){
        this.cargando = true;

        let filtros = this.filtrosEstadoCuenta.generarJSONFiltros();
        this.registro.filtrosJSON == filtros;
        this.registro.filtros = filtros ? JSON.stringify(filtros) : null;
        this.registro.correos = this.registro.correosArray ? this.registro.correosArray.toString() : null;
        if(this.registro.idsFormulariosClientesArray && this.registro.idsFormulariosClientesArray.length > 0) this.registro.idsFormulariosClientes = this.registro.idsFormulariosClientesArray.toString();
        else this.registro.idsFormulariosClientes = null;

        // Enviar el registro para guardar
        var res;
        if(this.modoCreacion) {
            res = await this.service.crearGestionCobro(this.registro);
        }
        else {
            res = await this.service.actualizarGestionCobro(this.registro);
        }

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            if(this.modoCreacion) this.router.navigate(['/cobros/editar-gestion-cobro/' + res.data.result.result.id]);
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * * * Validaciones * * * * *
    formularioValido() {
        if(!this.registro) return false;

        let paramsValidos = this.filtrosEstadoCuenta ? this.filtrosEstadoCuenta.registroValido() : false;
        if(!paramsValidos) return false;

        // if(!this.registro.correosArray || this.registro.correosArray.length == 0) return false;

        return true;
    }

    async seleccionImagen(ev, option) {
        var editor = this.contenido.editor;

        for (let file of ev.target.files) {
            let url = await this.crearYSubirDocumento(file);
            editor.selection.insertImage(url);
        }
    }

    activarInputImagen() {
        this.fileInput.nativeElement.click();
    }

    async crearYSubirDocumento(archivo: File) {
        this.cargando = true;
        let url;
        if (archivo) {
            var res = await this.service.guardarArchivoEnServidorPolizas(archivo);
            if (!res.error) {
                url = res.data.url;
            } else {
                this.libraryService.crearNotificacion('Ha ocurrido un error al subir su documento al servidor.', 'danger');
            }
        }
        this.cargando = false;
        return url;
    }

    async obtenerPlantillas() {
        var res = await this.service.obtenerTodosPlantillasGestionesCobros();
        if(!res.error) {
            this.plantillas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
    }

    abrirDialogContactos() {
        let jsonFiltros = this.filtrosEstadoCuenta.generarJSONFiltros();
        this.dialog.open(DialogSeleccionarContactosClienteComponent, {
            data: {
                idsPolizas: jsonFiltros.idsPolizas,
                idsClientes: jsonFiltros.idsClientes,
                idsPagadores: jsonFiltros.idsPagadores,
            }
        }).afterClosed().subscribe(result => {
            if(result) {
                if(!this.registro.correosArray) this.registro.correosArray = [];
                let contactosSeleccionados: ContactoUsuario[] = result;
                for (const el of contactosSeleccionados) {
                    if(!this.registro.correosArray.includes(el.correo1)) this.registro.correosArray.push(el.correo1);
                }
            }
        });
    }

    async obtenerFormulariosClientes() {
        var res = await this.empresasService.obtenerTodosFormulariosClientes();
        if(!res.error) {
            let formularios = res.data.registros;
            //Filtrar solo aquellos que contengan en su nombre "cobro"
            this.formularios = formularios.filter(formulario => formulario.nombre.toLowerCase().includes('cobro'));
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
    }

    actualizarContenidoPlantilla() {
        let plantillaSeleccionada = this.plantillas.find(plantilla => plantilla.id == this.registro.PlantillaGestionCobroId);
        this.registro.contenido = plantillaSeleccionada.contenido;
    }

    addCorreo(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        // Add our fruit
        if ((value || '').trim()) {
            if(this.libraryService.validateEmail(value)){
                if(!this.registro.correosArray) this.registro.correosArray = [];
                this.registro.correosArray.push(value);
            } else {
                this.libraryService.crearNotificacion('El correo ingresado no tiene un formato valido, por favor ingrese nuevamente el correo.', 'danger');
            }
        }
        // Reset the input value
        if (input) {
            this.nuevoCorreo = null;
        }
    }

    removeCorreo(correo: string): void {
        const index = this.registro.correosArray.indexOf(correo);
        if (index >= 0) {
            this.registro.correosArray.splice(index, 1);
        }
    }

    async preguntarEnviarCorreo() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Enviar por correo',
                mensaje: '¿Está seguro de que desea enviar esta gestión por correo?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.enviarCorreo();
            }
        });
    }

    async enviarCorreo() {
        await this.guardar();
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.enviarCorreoGestionCobro(this.id);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    paramsEspecialesDocumentosEnvios() {
        var params = '';
        if(this.registro.id) params += '&GestionCobroId=' + this.registro.id;
        return params;
    }

    abrirSubirDocumento() {
        var document = new DocumentoEnvioGestionCobro(-1, null, null, null, null, false, false, null, this.id, this.auth.idEmpresaActual());
        const dialogRef = this.dialog.open(DialogAgregarDocumentoEnvioGestionCobroComponent, {
            data: {
                registro: document,
                modo: 'creacion'
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.guardarDocumentoGestionCobro(result);
            }
        });
    }

    async guardarDocumentoGestionCobro(documento: any) {
        this.cargando = true;
        var res = await this.service.crearDocumentoEnvioGestionCobro(documento);
        if (!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        } else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.auth.buscadorReload.next();
        this.cargando = false;
    }

    async editarDocumentoGestionCobro(documento: DocumentoEnvioGestionCobro) {
        this.cargando = true;
        var res = await this.service.actualizarDocumentoEnvioGestionCobro(documento);
        if (!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        } else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.auth.buscadorReload.next();
        this.cargando = false;
    }

    abrirEditarDocumento($event) {
        const dialogRef = this.dialog.open(DialogAgregarDocumentoEnvioGestionCobroComponent, {
            data: {
                registro: $event,
                modo: 'edicion'
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.editarDocumentoGestionCobro(result);
            }
        });
    }
}
