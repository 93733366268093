import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { APIUrls } from '../../api/apiUrls';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { JSONConverters } from 'src/app/models/JSONConverters';
import iconEdit from '@iconify/icons-fa-solid/edit';
import iconList from '@iconify/icons-fa-solid/list';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconClear from '@iconify/icons-fa-solid/times';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconTarjeta from '@iconify/icons-fa-solid/credit-card';
import { CobrosService } from '../cobros.service';
import { AuthService } from 'src/app/auth/auth.service';
import { LibraryService } from 'src/app/library/library.service';
import { MatDialog } from '@angular/material';
import { PolizasService } from 'src/app/polizas/polizas.service';

@Component({
    selector: 'app-bancos',
    templateUrl: './bancos.component.html',
    styleUrls: ['./bancos.component.scss']
})
export class BancosComponent implements OnInit {
    // Templates de celdas
    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;

    apiURLs = new APIUrls();
    jsonConverters = new JSONConverters();

    // Columnas de tabla
    columnas = [];
    columnasDefault = [
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    campos = [
        { nombre: 'Nombre', campo: 'nombre', tipo: 'texto', categorias: null },
    ];
    acciones: any[] = [];

    urlBusqueda = this.apiURLs.baseURL + this.apiURLs.bancosURL + '/search';

    // Filtros
    filtros = [
    ];

    crumbs = [
        { nombre: 'Bancos', link: '/cobros/bancos' },
    ];

    titulo: string = 'Bancos';
    mostrarTabla: boolean = false;
    iconEdit = iconEdit;
    iconList = iconList;
    iconPlus = iconPlus;
    iconClear = iconClear;
    iconUpload = iconUpload;
    iconDownload = iconDownload;
    iconTarjeta = iconTarjeta;
    etapa: string = this.apiURLs.etapasCobros[0].id;
    cargando: boolean = false;
    idsSeleccionados: number[];
    paramsUsados: string;

    constructor(
        public service: CobrosService,
        public polizasService: PolizasService,
        public auth: AuthService,
        public libraryService: LibraryService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        private dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });

        this.auth.updateParams.subscribe(event => {
            this.paramsUsados = event;
        })
    }

    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.agregarFiltros();
        this.agregarTemplates();
        this.mostrarTabla = true;
    }

    obtenerRuta(params: Params) {
        if(this.route.snapshot.url.length > 0) {
            var ruta = this.route.snapshot.url[0].path;
            this.titulo = 'Todos los Bancos';
            this.crumbs[0].nombre = this.titulo;
            this.crumbs[0].link = '/bancos/' + this.etapa;
        }
        this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
    }

    agregarTemplates() {
        this.columnas = this.columnasDefault;
        this.columnas.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
    }

    async agregarFiltros() {

    }

    calcularParamsEspeciales(): string {
        var paramsEspeciales = '';

        paramsEspeciales += '&EmpresaId=' + this.auth.idEmpresaActual();

        return paramsEspeciales;
    }
}
