export class ComisionAseguradoraProducto {
    constructor(
        public id: number,
        public ano: number,
        public comisionRecibida: number,
        public comisionRepartida: number,
        public tipoCambio: number,
        public ProductoId: number,
        public AseguradoraId: number,
        public IncisoId: number,
    ){}
}