import { Usuario } from "./usuario";

export class BitacoraComisionInciso {
    constructor(
        public id: number,
        public descripcion: string,
        public fecha: string,
        public IncisoId: number,
        public UsuarioId: number,
    ){}

    usuario: Usuario;
    fechaDate: Date;
    fechaFormato: string;
}