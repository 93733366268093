
export class ImpuestoEmpresa {
    constructor(
        public id: number,
        public nombre: string,
        public tipo: string,
        public porcentaje: number,
        public montoFijo: number,
        public EmpresaId: number,
    ){}
}