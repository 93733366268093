import { Usuario } from './usuario';
import { Poliza } from './poliza';
import { Moneda } from './moneda';
import { AplicacionDocumentoCredito } from './aplicacionDocumentoCredito';
import { GastoProductoDocumentoCredito } from './gastoProductoDocumentoCredito';
import { ImpuestoDocumentoCredito } from './impuestoDocumentoCredito';

export class DocumentoCredito {
    constructor(
        public id: number,
        public numeroRequisito: string,
        public numeroRecibo: string,
        public etapa: string,
        public fechaIngreso: string,
        public fechaEmision: string,
        public fechaExpiracionIVA: string,
        public primaNeta: number,
        public totalGastos: number,
        public totalImpuestos: number,
        public totalFraccionamiento: number,
        public totalEmision: number,
        public creditoTotal: number,
        public creditoUtilizado: number,
        public creditoRestante: number,
        public razonCredito: string,
        public observaciones: string,
        public formaPago: string,
        public banco: string,
        public numeroCheque: string,
        public fechaCredito: string,
        public pagado: boolean,
        public eliminado: boolean,
        public PagadorId: number,
        public UsuarioId: number,
        public PolizaId: number,
        public MonedaId: number,
        public EmpresaId: number,
    ){}

    fechaIngresoFormato: string;
    fechaIngresoDate: Date;
    fechaEmisionFormato: string;
    fechaEmisionDate: Date;
    fechaCreditoFormato: string;
    fechaCreditoDate: Date;
    fechaExpiracionIVAFormato: string;
    fechaExpiracionIVADate: Date;
    nombreEstado: string;
    colorEstado: string;
    pagador: Usuario;
    usuario: Usuario;
    poliza: Poliza;
    moneda: Moneda;
    metaCreditoTotal: any = {};
    metaCreditoUtilizado: any = {};
    metaCreditoRestante: any = {};
    nombreFormaPago: string;
    nombreEtapa: string;
    aplicacionesCreditos: AplicacionDocumentoCredito[];
    gastos: GastoProductoDocumentoCredito[];
    impuestos: ImpuestoDocumentoCredito[];
}