import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import { UsuariosService } from '../usuarios.service';
import { Usuario } from '../../models/usuario';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconSync from '@iconify/icons-fa-solid/sync';

@Component({
    selector: 'app-perfil-usuario',
    templateUrl: './perfil.component.html',
    styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconSync = iconSync;

    registro: Usuario;
    id: number;
    titulo: string;
    apiURLs: APIUrls = new APIUrls();
    cargando: boolean = false;
    
    // Archivos 
    rutaArchivoImagen: string;
    archivoImagen: File;
    modoSubirImagen: boolean = true;
    
    nuevoPassword: string;
    confirmarPassword: string;
    minimoContrasena: number = 8;

    constructor(
        private service: UsuariosService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title
    ) { }

    // * * * Inicializar componente * * *
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.obtenerRegistro();
        this.obtenerMinimoContrasena();
    }

    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        this.id = this.auth.idUsuarioActual();
    }

    // * * * Obtener datos * * * 
    // Obtener usuario
    async obtenerRegistro() {
        this.titulo = 'Perfil de usuario';
        this.cargando = true;
        var res = await this.service.obtenerUsuarioPerfil();
        if(!res.error) {
            this.registro = res.data.registro;
            this.titleService.setTitle(this.registro.nombreCompleto + this.apiURLs.nombreSitioTitle);
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * Editar registro * * *
    // Guardar perfil
    async guardar(){
        this.cargando = true;
        
        // Subir imagen
        if(this.modoSubirImagen && this.archivoImagen) {
            var resUpload = await this.service.guardarArchivoEnServidorUsuarios(this.archivoImagen);
            if(resUpload.error){
                this.libraryService.crearNotificacion(resUpload.error.mensajeError, 'danger');
                throw new Error();
            }
            else {
                var url = resUpload.data.url;
                this.registro.imagen = url;
            }
        }

        var res;
        res = await this.service.actualizarPerfil(this.registro);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            await this.auth.comprobarAutenticacion(false);
            this.auth.perfilReload.next();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // Reiniciar contraseña
    async reiniciarContrasena(){
        if(this.validFormPassword()) {
            this.cargando = true;
            var res = await this.service.reiniciarPassword(this.nuevoPassword, this.id, true);
            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            this.cargando = false;
        }
    }

    // * * * Validaciones * * * 
    validFormPassword(): boolean {
        if(!this.nuevoPassword || !this.confirmarPassword) return false;
        if(this.nuevoPassword.trim() == '' || this.confirmarPassword.trim() == '') return false;
        if(this.nuevoPassword.length < this.minimoContrasena) return false;
        return this.nuevoPassword == this.confirmarPassword;
    }

    formularioValido() {
        if(!this.registro) return false;
        return true;
    }

    // * * * * * Archivos * * * * *
    // Imagen
    seleccionarImagen(event: any){
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.rutaArchivoImagen = file.name;
            this.archivoImagen = file;
        }
    }

    activarSeleccionImagen(){
        document.getElementById('file-input-imagen').click();
    }

    limpiarArchivoImagen(){
        this.rutaArchivoImagen = null;
        this.archivoImagen = null;
    }

    descargarArchivoImagen(){
        if(this.registro && this.registro.imagen) {
            window.open(this.registro.imagen, "_blank");
        }
    }

    async obtenerMinimoContrasena() {
        var configuracion = this.auth.configuracionEmpresaActual();
        if(configuracion) {
            this.minimoContrasena = configuracion.minimoCaracteresContrasena;
            if(!this.minimoContrasena) this.minimoContrasena = 8;
        }
    }

    async solicitarCodigoAccesoEspecial() {
        this.cargando = true;
        var res = await this.service.solictarCodigoAccesoEspecial();
        if(!res.error) {
            let nuevoCodigo = res.data.nuevoCodigo;
            this.libraryService.crearNotificacionGrande('Su código de acceso especial es: ' + nuevoCodigo + ' y tiene una vigencia de 2 minutos.', 'danger');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }
}
