import { Certificado } from "./certificado";
import { Poliza } from "./poliza";
import { Usuario } from "./usuario";

export class PermisoAccesoCliente {
    constructor(
        public id: number,
        public correo: string,
        public tipo: string,
        public ClienteId: any,
        public PolizaId: any,
        public CertificadoId: any,
        public AgrupadorId: number,
        public EmpresaId: number,
    ){}

    nombreTipo: string;
    cliente: Usuario;
    nombreCliente: string;
    poliza: Poliza;
    nombrePoliza: string;
    certificado: Certificado;
    nombreCertificado: string;

    polizasCliente: Poliza[];
    certificadosPoliza: Certificado[];
}