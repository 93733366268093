import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter  } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { AuthService } from 'src/app/auth/auth.service';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    titulo: string,
    mensaje: string,
    nombrePlaceholder: string,
    fechaDatePicker: string,
    nombreDatePicker: string,
    payload: any,
}

@Component({
    selector: 'generic-dialog-datepicker',
    templateUrl: './generic-dialog-datepicker.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class GenericDialogDatepicker {
    constructor(
        public dialogRef: MatDialogRef<GenericDialogDatepicker>,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close({
            accion: 'confirmar',
            fechaDatePicker: this.data.fechaDatePicker,
        });
    }
}