import { CampoTipoCertificado } from './campoTipoCertificado';
import { Ramo } from './ramo';

export class TipoCertificado {
    constructor(
        public id: number,
        public nombre: string,
        public observaciones: string,
        public RamoId: number,
        public EmpresaId: number,
    ){}

    ramo: Ramo;
    campos: CampoTipoCertificado[];
}