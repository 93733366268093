import { Ramo } from './ramo';

export class TipoSiniestro {
    constructor(
        public id: number,
        public nombre: string,
        public descripcion: string,
        public isDeleted: boolean,
        public RamoId: number,
        public EmpresaId: number,
    ){}

    ramo: Ramo;
}