import { Component, Inject } from '@angular/core';
import { MAT_DATE_FORMATS, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MatDialog } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import iconDelete from '@iconify/icons-fa-solid/trash';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { PolizasService } from 'src/app/polizas/polizas.service';
import { EmpresasService } from 'src/app/empresas/empresas.service';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { PermisoAccesoCliente } from 'src/app/models/permisoAccesoCliente';
import { UsuariosService } from '../usuarios.service';
import { Agrupador } from 'src/app/models/agrupador';
import { ClientesService } from 'src/app/clientes/clientes.service';
import { Usuario } from 'src/app/models/usuario';
import { Poliza } from 'src/app/models/poliza';
import { Certificado } from 'src/app/models/certificado';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    id: number,
    UsuarioId: number,
}

@Component({
    selector: 'dialog-nuevo-permiso-acceso-cliente',
    templateUrl: './dialog-nuevo-permiso-acceso-cliente.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogNuevoPermisoAccesoCliente {
    apiURLs = new APIUrls();
    modoCreacion: boolean = true;
    modoEdicion: boolean = false;
    modoDetalle: boolean = false;
    iconDelete = iconDelete;
    cargando: boolean = false;
    id: number
    registro: PermisoAccesoCliente;
    jsonConverters = new JSONConverters();
    agrupadores: Agrupador[];
    clientes: Usuario[];
    polizas: Poliza[];
    certificados: Certificado[];

    // * Cambios de estado * 
    urlBusquedaCambiosEstado = this.apiURLs.baseURL + this.apiURLs.cambiosEstadosDireccionesClientesURL + '/search';
    columnasCambiosEstados = [
        { prop: 'fechaFormato', name: 'Fecha', sortable: true, cellTemplate: null },
        { prop: 'nombreUsuario', name: 'Usuario', sortable: true, cellTemplate: null },
        { prop: 'estadoAnterior', name: 'Anterior', sortable: false, cellTemplate: null },
        { prop: 'estadoSiguiente', name: 'Siguiente', sortable: false, cellTemplate: null },
    ];
    camposCambiosEstados = [
        { nombre: 'Fecha', campo: 'fecha', tipo: 'rango-fechas', categorias: null },
    ];
    filtrosCambiosEstados = [];

    constructor(
        public dialogRef: MatDialogRef<DialogNuevoPermisoAccesoCliente>,
        public service: UsuariosService,
        public clientesService: ClientesService,
        public empresasService: EmpresasService,
        public polizasService: PolizasService,
        public auth: AuthService,
        public dialog: MatDialog,
        private libraryService: LibraryService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit() {
        // Arreglar estilo
        var dialog = document.getElementById("dialogOverflow");
        var dialogContainer = null;
        if(dialog) dialogContainer = dialog.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer.className += ' dialogOverflow';

        this.id = this.data.id;
        this.modoCreacion = !this.data.id;
        this.modoEdicion = !this.modoCreacion;
        this.modoDetalle = false;

        this.obtenerRegistro();
        this.obtenerAgrupadores();
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close({
            accion: 'confirmar',
        });
    }

    async obtenerRegistro() {
        this.cargando = true;
        if(this.modoCreacion) {
            // Inicializar registro 
            this.registro = new PermisoAccesoCliente(null, null, null, this.data.UsuarioId, null, null, null, null, this.auth.idEmpresaActual());
        }
        else {
            // Obtener registro
            var res = await this.service.obtenerPermisoAccesoClientePorId(this.id);
            if(!res.error) {
                this.registro = res.data.registro;
            }
            else {
                this.cerrar();
            }
        }
        this.cargando = false;
    }

    // * * * * * Editar datos * * * * *
    // Guardar datos
    async guardar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res;
        if(this.modoCreacion) {
            res = await this.service.crearPermisoAccesoCliente(this.registro);
        }
        else if (this.modoEdicion) {
            res = await this.service.actualizarPermisoAccesoCliente(this.registro);
        }
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            if(this.modoCreacion) {
                this.confirmar();
            }
            this.auth.buscadorReload.next();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // Eliminar datos
    abrirEliminar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar registro',
                mensaje: '¿Está seguro de que desea eliminar este registro?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminar();
            }
        });
    }

    async eliminar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.eliminarPermisoAccesoCliente(this.id);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    registroValido() {
        if(!this.registro) return false;

        if(!this.registro.tipo) return false;
        if(!this.registro.AgrupadorId) return false;
        if(this.registro.tipo == 'agrupador' && (!this.registro.AgrupadorId)) return false;
        if(this.registro.tipo == 'cliente' && (!this.registro.AgrupadorId || !this.registro.ClienteId)) return false;
        if(this.registro.tipo == 'poliza' && (!this.registro.AgrupadorId || !this.registro.ClienteId || !this.registro.PolizaId)) return false;
        if(this.registro.tipo == 'certificado' && (!this.registro.AgrupadorId || !this.registro.ClienteId || !this.registro.PolizaId || !this.registro.CertificadoId)) return false;

        return true;
    }

    async obtenerAgrupadores() {
        this.cargando = true;

        var res = await this.clientesService.obtenerTodosAgrupadores();
        if(!res.error) {
            this.agrupadores = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');

        this.cargando = false;
    }

    seleccionarAgrupador(AgrupadorId: number) {
        this.cargando = true;
        this.registro.AgrupadorId = AgrupadorId;
        this.registro.ClienteId = null;
        this.registro.PolizaId = null;
        this.registro.CertificadoId = null;

        this.obtenerClientesAgrupador();

        this.cargando = false;
    }

    async obtenerClientesAgrupador() {
        this.cargando = true;
        this.clientes = null;
        this.polizas = null;
        this.certificados = null;

        if(this.registro.AgrupadorId) {
            var res = await this.clientesService.obtenerTodosClientesAgrupador(this.registro.AgrupadorId);
            if(!res.error) {
                this.clientes = res.data.registros;
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }

        this.cargando = false;
    }

    seleccionarCliente(ClienteId: number) {
        this.cargando = true;
        this.registro.ClienteId = ClienteId;
        this.registro.PolizaId = null;
        this.registro.CertificadoId = null;

        this.obtenerPolizasCliente();

        this.cargando = false;
    }

    async obtenerPolizasCliente() {
        this.cargando = true;
        this.polizas = null;
        this.certificados = null;

        if(this.registro.ClienteId) {
            var res = await this.polizasService.obtenerTodasPolizasCliente(this.registro.ClienteId);
            if(!res.error) {
                this.polizas = res.data.registros;
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }

        this.cargando = false;
    }

    seleccionarPoliza(PolizaId: number) {
        this.cargando = true;
        this.registro.PolizaId = PolizaId;
        this.registro.CertificadoId = null;

        this.obtenerCertificadosPoliza();

        this.cargando = false;
    }

    async obtenerCertificadosPoliza() {
        this.cargando = true;
        this.certificados = null;

        if(this.registro.PolizaId) {
            var res = await this.polizasService.obtenerTodosCertificadosPoliza(this.registro.PolizaId);
            if(!res.error) {
                this.certificados = res.data.registros;
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }

        this.cargando = false;
    }

    seleccionarCertificado(CertificadoId: number) {
        this.cargando = true;
        this.registro.CertificadoId = CertificadoId;
        this.cargando = false;
    }
}
