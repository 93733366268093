import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { APIService } from './api.service';
import { ConnectionService } from './connection.service';
import { HttpClientModule } from '@angular/common/http';
import { LibraryService } from '../library/library.service';
import { AuthGuardLogueado } from '../auth/auth-guard-logueado';
import { AuthGuardCliente } from '../auth/auth-guard-cliente';
import { UsuariosService } from '../usuarios/usuarios.service';
import { AuthGuardPermisos } from '../auth/auth-guard-permisos';
import { ClientesService } from '../clientes/clientes.service';
import { EmpresasService } from '../empresas/empresas.service';
import { AreasService } from '../areas/areas.service';
import { AseguradorasService } from '../aseguradoras/aseguradoras.service';
import { RamosService } from '../ramos/ramos.service';
import { PolizasService } from '../polizas/polizas.service';
import { CobrosService } from '../cobros/cobros.service';
import { ProspectosService } from '../prospectos/prospectos.service';
import { MapasProcesosService } from '../mapas-procesos/mapas-procesos.service';
import { MensajeriaService } from '../mensajeria/mensajeria.service';
import { ReclamosService } from '../reclamos/reclamos.service';
import { SolicitudesService } from '../solicitudes/solicitudes.service';
import { AvisosService } from '../avisos/avisos.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    declarations: [
        
    ],
    exports: [
        
    ],
    providers: [
        APIService,
        ConnectionService,
        LibraryService,
        UsuariosService,
        ClientesService,
        EmpresasService,
        AreasService,
        AseguradorasService,
        RamosService,
        PolizasService,
        AvisosService,
        CobrosService,
        ProspectosService,
        MapasProcesosService,
        MensajeriaService,
        ReclamosService,
        SolicitudesService,
        // Auth guards
        AuthGuardLogueado,
        AuthGuardCliente,
        AuthGuardPermisos,
    ]
})
export class APIModule {
}
