import { Moneda } from './moneda';
import { Usuario } from './usuario';
import { Poliza } from './poliza';
import { SeguimientoReclamo } from './seguimientoReclamo';
import { FacturaReclamo } from './facturaReclamo';
import { RazonRamo } from './razonRamo';
import { TipoSiniestro } from './tipoSiniestro';
import { CertificadoReclamo } from './certificadoReclamo';
import { TipoReclamo } from './tipoReclamo';
import { CampoTipoReclamo } from './campoTipoReclamo';
import { ValorReclamo } from './valorReclamo';

export class Reclamo {
    constructor(
        public id: number,
        public tipoReclamo: string,
        public numeroInterno: string,
        public numeroAseguradora: string,
        public estado: string,
        public fechaIngreso: string,
        public fechaReportado: string,
        public fechaPresentadoAseguradora: string,
        public fechaOcurrido: string,
        public fechaPagado: string,
        public fechaCierre: string,
        public fechaRecordatorio: string,
        public observaciones: string,
        public observacionesSiniestro: string,
        public observacionesFacturas: string,
        public direccionSiniestro: string,
        public descripcionSiniestro: string,
        public emergencia: boolean,
        public activo: boolean,
        public isDeleted: boolean,
        public tipoCambio: number,
        public totalFacturado: number,
        public montoReclamado: number,
        public montoPagado: number,
        public ajustador: string,
        public taller: string,
        public numeroComplemento: string,
        public diagnostico: string,
        public personaAfectada: string,
        public aplicaEnvioAvisoAperturaCierre: string,
        public avisoReclamoMaternidad: string,
        public fechaAltaComplemento: string,
        public ultimaVisualizacion: string,
        public monedaInternacional: boolean,
        public proveedor: string,
        public nombrePaciente: string,
        public telefono1: string,
        public telefono2: string,
        public reporteAseguradora: string,
        public horaRecepcionAutorizacion: string,
        public horaAutorizacionAsegurado: string,
        public perdidaTotalRobo: boolean,
        public roboEquipoEspecial: boolean,
        public reinstalacionSumaAsegurada: boolean,
        public datosTerceros: string,
        public tipoAutorizacion: string,
        public correo1: string,
        public UsuarioId: number,
        public UsuarioEncargadoId: number,
        public PolizaId: number,
        public TipoSiniestroId: number,
        public TipoReclamoId: number,
        public MonedaId: number,
        public EmpresaId: number,
    ){}

    numeroInternoMostrar: string;
    nombreBreadcrumbs: string;
    ultimaVisualizacionFormato: string;
    fechaAltaComplementoFormato: string;
    fechaAltaComplementoDate: Date;
    fechaIngresoFormato: string;
    fechaIngresoDate: Date;
    fechaReportadoFormato: string;
    fechaReportadoDate: Date;
    fechaPresentadoAseguradoraFormato: string;
    fechaPresentadoAseguradoraDate: Date;
    fechaOcurridoFormato: string;
    fechaOcurridoDate: Date;
    fechaPagadoFormato: string;
    fechaPagadoDate: Date;
    fechaCierreFormato: string;
    fechaCierreDate: Date;
    fechaRecordatorioFormato: string;
    fechaRecordatorioDate: Date;
    diasProceso: number = 0;
    usuario: Usuario;
    usuarioEncargado: Usuario;
    poliza: Poliza;
    certificados: CertificadoReclamo[];
    tipoSiniestro: TipoSiniestro;
    modelTipoReclamo: TipoReclamo;
    moneda: Moneda;
    seguimientos: SeguimientoReclamo[];
    ultimoSeguimiento: SeguimientoReclamo;
    fechaUltimoSeguimientoFormato: string;
    facturas: FacturaReclamo[];
    idsRazones: number[] = [];
    razones: RazonRamo[];
    campos: CampoTipoReclamo[];
    valores: ValorReclamo[];
    // Nombres para mostrar
    nombreReclamo: string = '';
    nombreUsuario: string = '';
    nombreUsuarioEncargado: string = '';
    nombreEstado: string = '';
    nombreTipoSiniestro: string = '';
    nombreMoneda: string = '';
    nombrePoliza: string = '';
    nombreRamo: string = '';
    nombreAseguradora: string = '';
    nombreCertificados: string = '';
    nombreCliente: string = '';
    numeroPoliza: string = '';
    numeroCertificado: string = '';
    nombreDependiente: string = '';
    nombreTipoReclamo: string = '';
    nombrePersonaAfectada: string = '';
    nombreAfectado: string = '';
}
