export class InformacionCliente {
    constructor(
        public id: number,
        public tipo: string,
        public clasificacion: string,
        public clase: string,
        public activo: boolean,
        public nit: string,
        public numeroRegistro: string,
        public direccionFiscal: string,
        public requiereFacturaFisica: boolean,
        public observacionesFacturacion: string,
        public razonSocial: string,
        public lugarTrabajo: string,
        public direccionTrabajo: string,
        public direccionAdicional: string,
        public ocupacion: string,
        public lugarCobro: string,
        public lugarCorrespondencia: string,
        public tipoDocumento: string,
        public numeroDocumento: string,
        public pasaporte: string,
        public lugarEmisionDocumento: string,
        public ciudadania: string,
        public clienteDesde: string,
        public personaExpuestaPoliticamente: boolean,
        public actuaEnNombrePropio: boolean,
        public archivoDocumento: string,
        public archivoRecibo: string,
        public nombreContacto: string,
        public puestoContacto: string,
        public observacionesContacto: string,
        // Matriz de riesgo Costa Rica
        public tipoClienteRiesgo: string,
        public paisNacimiento: string,
        public actividadGeneradoraRecursos: string,
        public paisActividadEconomica: string,
        public tipoActivosPropiosActividadCliente: string,
        public productosServiciosUtilizados: string,
        public caracter: string,
        public valorMontoAsegurado: string,
        public medioPago: string,
        public tipoIngresosOrigenRecursos: string,
        public formularioKYC: string,
        public nivelRiesgo: number,
        public giroNegocio: string,
        public representanteLegal: string,
        public aplicaVisacuota: boolean,
        public cantidadVisacuota: number,

        public tipoDocumentoRiesgo: string,
        public numeroDocumentoRiesgo: string,
        public estadoCivil: string,
        public notasAdicionales: string,
        public fechaVencimientoDocumento: string,
        public fechaFallecido: string,
        public fechaActualizacionKYC: string,
        public existeFormularioKYC: boolean,
        public presentoDocumentoIdentificacionKYC: boolean,
        public constanciaDomicilioKYC: boolean,
        public firmaDeclaracionJuradaKYC: boolean,
        public presentoPersoneriaVigenteKYC: boolean,
        public consentimientoInformadoKYC: boolean,

        public nivelRiesgoTipoCliente: number,
        public nivelRiesgoActividadGeneradoraRecursos: number,
        public nivelRiesgoCiudadania: number,
        public nivelRiesgoPaisNacimiento: number,
        public nivelRiesgoPaisActividadEconomica: number,
        public nivelRiesgoTipoActivoPropio: number,
        public nivelRiesgoProductos: number,
        public nivelRiesgoCaracter: number,
        public nivelRiesgoValorAsegurado: number,
        public nivelRiesgoMedioPago: number,
        public nivelRiesgoTipoIngresos: number,
    ){}
    
    public autorCICAC: boolean;
    public ingresoPromedio: number;
    public clienteDesdeFormato: string;
    public clienteDesdeDate: Date;
    public tipoClienteNombre: string;
    public clasificacionNombre: string;
    public tipoDocumentoNombre: string;
    public fechaVencimientoDocumentoFormato: string;
    public fechaVencimientoDocumentoDate: Date;
}
