import { Empresa } from "./empresa";

export class Banco {
    constructor(
        public id: number,
        public nombre: string,
        public EmpresaId: number,
    ){}
    
    Empresa: Empresa;
}