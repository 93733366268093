export class ContactoAseguradora {
    constructor(
        public id: number,
        public nombre: string,
        public apellido: string,
        public puesto: string,
        public correo1: string,
        public correo2: string,
        public telefono1: string,
        public telefono2: string,
        public direccion: string,
        public observaciones: string,
        public AseguradoraId: number,
        public AreaId: number,
    ){}
    
    public meta: any;
    public nombreCompleto: string;
}