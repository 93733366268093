import { Reclamo } from './reclamo';
import { Moneda } from './moneda';
import { CertificadoReclamo } from './certificadoReclamo';
import { RazonRamo } from './razonRamo';

export class FacturaReclamo {
    constructor(
        public id: number,
        public fecha: string,
        public numero: string,
        public estado: string,
        public proveedor: string,
        public descripcion: string,
        public monto: number,
        public valorCubierto: number,
        public valorNoCubierto: number,
        public impuestoCubierto: number,
        public impuestoNoCubierto: number,
        public entregada: boolean,
        public noAplicarImpuesto: boolean,
        public mostrarDatosMonedaOriginal: boolean,
        public tipoCambio: number,
        public montoOriginal: number,
        public motivoRechazo: string,
        public isDeleted: boolean,
        public ReclamoId: number,
        public CertificadoReclamoId: number,
        public RazonRamoId: number,
        public MonedaId: number,
        public MonedaAnteriorId: number,
        public EmpresaId: number,
    ){}

    fechaFormato: string;
    fechaDate: Date;
    reclamo: Reclamo;
    razonRamo: RazonRamo;
    nombreRazonRamo: string;
    certificadoReclamo: CertificadoReclamo;
    nombreCertificado: string;
    moneda: Moneda;
    nombreFactura: string = '';
    nombreEstado: string = '';
}