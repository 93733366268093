import { Certificado } from './certificado';
import { DocumentoCobro } from './documentoCobro';
import { Poliza } from './poliza';
import { Usuario } from './usuario';

export class Mensajeria {
    constructor(
        public id: number,
        public tipo: string,
        public tipoEnvio: string,
        public estado: string,
        public emisor: string,
        public direccionEmisor: string,
        public receptor: string,
        public direccionReceptor: string,
        public descripcion: string,
        public fechaAreaEncargada: string,
        public fechaRecepcion: string,
        public fechaMensajero: string,
        public fechaCliente: string,
        public fechaProveedor: string,
        public fechaCierre: string,
        public fecha: string,
        public cerrado: boolean,
        public isDeleted: boolean,
        public programarEnvio: boolean,
        public fechaProgramarEnvio: string,
        public UsuarioMensajeriaId: number,
        public UsuarioEjecutivoId: number,
        public UsuarioAsignadoId: number,
        public PolizaId: number,
        public ClienteId: number,
        public AseguradoraId: number,
        public SolicitudPolizaId: number,
        public EmpresaId: number,
    ){}

    fechaAreaEncargadaFormato: string;
    fechaAreaEncargadaDate: Date;
    fechaRecepcionFormato: string;
    fechaRecepcionDate: Date;
    fechaMensajeroFormato: string;
    fechaMensajeroDate: Date;
    fechaClienteFormato: string;
    fechaClienteDate: Date;
    fechaProveedorDate: Date;
    fechaProveedorFormato: string;
    fechaCierreDate: Date;
    fechaCierreFormato: string;
    fechaProgramarEnvioDate: Date;
    fechaProgramarEnvioFormato: string;
    fechaDate: Date;
    fechaFormato: string;
    nombreTipo: string = '';
    nombreEstado: string = '';
    nombreMensaje: string = '';
    nombreUsuarioMensajeria: string = '';
    nombreUsuarioEjecutivo: string = '';
    nombreUsuarioAsignado: string = '';
    usuarioMensajeria: Usuario;
    usuarioEjecutivo: Usuario;
    usuarioAsignado: Usuario;
    poliza: Poliza;
    certificados: Certificado[];
    idsCertificados: number[];
    cobros: DocumentoCobro[];
    idsCobros: number[];
    nombreCertificados: string;
    meta: any = {};
    observaciones: string;
}