import { Component, Inject } from '@angular/core';
import { MAT_DATE_FORMATS, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MatDialog } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import iconDelete from '@iconify/icons-fa-solid/trash';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { Poliza } from 'src/app/models/poliza';
import { ClausulaGarantiaPoliza } from 'src/app/models/clausulaGarantiaPoliza';
import { Moneda } from 'src/app/models/moneda';
import { JSONConverters } from 'src/app/models/JSONConverters';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    clausula: ClausulaGarantiaPoliza,
    poliza: Poliza,
    monedas: Moneda[],
    modoEdicion: boolean,
}

@Component({
    selector: 'dialog-nuevo-clausula-garantia-poliza',
    templateUrl: './dialog-nuevo-clausula-garantia-poliza.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogNuevoClausulaGarantiaPoliza {
    apiURLs = new APIUrls();
    modoCreacion: boolean = true;
    modoEdicion: boolean = false;
    modoDetalle: boolean = false;
    clausula: ClausulaGarantiaPoliza;
    iconDelete = iconDelete;
    jsonConverters = new JSONConverters();
    mostrarTabla: boolean = false;

    // * Cláusulas de garantía pólizas *
    columnasBitacoraClausulaGarantia = [
      { prop: 'fechaFormato', name: 'Fecha', sortable: true, cellTemplate: null },
      { prop: 'estadoAnterior', name: 'Estado anterior', sortable: true, cellTemplate: null },
      { prop: 'estadoNuevo', name: 'Estado nuevo', sortable: true, cellTemplate: null },
      { prop: 'usuario.nombreCompleto', name: 'Usuario', sortable: true, cellTemplate: null },

  ];
  camposBitacoraClausulaGarantia = [
      { nombre: 'Estado nuevo', campo: 'estadoNuevo', tipo: 'texto', categorias: null },
      { nombre: 'Fecha', campo: 'fecha', tipo: 'rango-fechas', categorias: null },

  ];
  filtrosBitacoraClausulaGarantia = [];
  urlBusquedaBitacoraClausulaGarantia = this.apiURLs.baseURL + this.apiURLs.polizasURL + '/searchBitacoraClausulaGarantia';

    constructor(
        public dialogRef: MatDialogRef<DialogNuevoClausulaGarantiaPoliza>,
        public service: AuthService,
        public auth: AuthService,
        public dialog: MatDialog,
        private libraryService: LibraryService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit() {
        this.clausula = this.data.clausula;
        this.modoCreacion = this.clausula.id == null;
        this.modoDetalle = !this.modoCreacion;
        if(!this.modoCreacion) {
            this.modoEdicion = this.data.modoEdicion;
            this.modoDetalle = !this.modoEdicion;
        }

        if(this.modoCreacion) {
            // Si la poliza tiene el estado de 'nueva' o 'emision', usamos la fechaVigenciaInicio como fechaAlta
            if(this.data.poliza.estado == 'nueva' || this.data.poliza.estado == 'emision') {
                this.clausula.fechaAltaDate = this.data.poliza.fechaVigenciaInicioDate;
            } else {
                // Si la poliza tiene otro estado, usamos la fecha de hoy como fechaAlta
                this.clausula.fechaAltaDate = this.libraryService.convertirFecha(new Date(), 'date', 'YYYY-MM-DD');
                this.clausula.fechaAlta = this.libraryService.convertirFecha(new Date(), 'date', 'YYYY-MM-DD');
            }
        }

        this.mostrarTabla = true;
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close({
            accion: 'confirmar',
            clausula: this.clausula,
        });
    }

    abrirEliminar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar cláusula de garantía',
                mensaje: '¿Está seguro de que desea eliminar esta cláusula?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminar();
            }
        });
    }

    eliminar() {
        this.dialogRef.close({
            accion: 'eliminar',
            clausula: this.clausula,
        });
    }

    registroValido(): boolean {
        var clausula = this.clausula;
        if(!clausula) return false;
        if(!clausula.descripcion || clausula.descripcion.trim() == '') return false;

        return true;
    }

    actualizarFecha($event, campo){
        if($event && $event.value) {
            this.clausula[campo] = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    paramsEspecialesBitacoraClausulaGarantias() {
      var params = '';
      if(this.clausula.id) params += '&ClausulaGarantiaPolizaId=' + this.clausula.id;
      return params;
    }
}
