import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { Usuario } from 'src/app/models/usuario';
import { APIUrls } from 'src/app/api/apiUrls';
import { Agrupador } from 'src/app/models/agrupador';
import * as _moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { LibraryService } from 'src/app/library/library.service';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconView from '@iconify/icons-fa-solid/info-circle';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import { PolizasService } from 'src/app/polizas/polizas.service';
import { Poliza } from 'src/app/models/poliza';
import { PlantillaMapaProceso } from 'src/app/models/plantillaMapaProceso';
import { Router } from '@angular/router';
import { MapasProcesosService } from 'src/app/mapas-procesos/mapas-procesos.service';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { Certificado } from 'src/app/models/certificado';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    PasoMapaProcesoId: number,
    MapaProcesoId: number,
    UsuarioResponsableId: number,
    usuario: Usuario,
}

@Component({
    selector: 'dialog-asignar-paso-mapa',
    templateUrl: './dialog-asignar-paso-mapa.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogAsignarPasoMapaProceso {
    apiURLs = new APIUrls();
    jsonConverters = new JSONConverters();

    // URLs
    urlAutocompleteEjecutivos = this.apiURLs.baseURL + this.apiURLs.usuariosURL + '/autocompleteEjecutivos';
    columnasEjecutivos = [
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'apellido', name: 'Apellido', sortable: true, cellTemplate: null },
        { prop: 'correo1', name: 'Correo', sortable: true, cellTemplate: null },
        { prop: 'nombreArea', name: 'Área', sortable: true, cellTemplate: null },
    ];
    camposEjecutivos = [
        { nombre: 'Nombre', campo: 'general', tipo: 'texto', categorias: null },
    ];

    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconView = iconView;
    iconCheck = iconCheck;
    iconClose = iconClose;
    iconPlus = iconPlus;

    // Datos
    cargando: boolean = false;
    UsuarioResponsableId: number;
    usuario: Usuario;
    usuarios: Usuario[] = [];
    PasoMapaProcesoId: number;
    MapaProcesoId: number;

    constructor(
        public dialogRef: MatDialogRef<DialogAsignarPasoMapaProceso>,
        public mapasProcesosService: MapasProcesosService,
        public usuariosService: UsuariosService,
        public libraryService: LibraryService,
        public auth: AuthService,
        public router: Router,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit(){
        this.PasoMapaProcesoId = this.data.PasoMapaProcesoId;
        this.MapaProcesoId = this.data.MapaProcesoId;
        this.UsuarioResponsableId = this.data.UsuarioResponsableId;
        this.usuario = this.data.usuario;
        if(this.usuario) {
            this.usuarios = [this.usuario];
        }
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    async confirmar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.mapasProcesosService.asignarPasoMapaProceso(this.PasoMapaProcesoId, this.MapaProcesoId, this.UsuarioResponsableId);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.dialogRef.close(true);
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    registroValido(): boolean {
        if(!this.UsuarioResponsableId) return false;
        return true;
    }

    // * * * * * Actualizaciones de controles * * * * *
    actualizarUsuarioResponsable(items: Usuario[]) {
        if(items && items.length > 0) {
            this.UsuarioResponsableId = items[0].id;
            this.usuario = items[0];
        }
        else this.UsuarioResponsableId = null;
    }
}