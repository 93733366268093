import { Usuario } from './usuario';
import { Moneda } from './moneda';
import { DocumentoCobro } from './documentoCobro';
import { DocumentoCredito } from './documentoCredito';

export class AplicacionDocumentoCredito {
    constructor(
        public id: number,
        public tipo: string,
        public fechaAplicacion: string,
        public monto: number,
        public numeroCheque: string,
        public banco: string,
        public observaciones: string,
        public DocumentoCobroId: number,
        public DocumentoCreditoId: number,
        public UsuarioId: number,
        public MonedaId: number,
        public EmpresaId: number,
        public PlanillaLiquidacionId: number,
    ){}

    fechaAplicacionFormato: string;
    fechaAplicacionDate: Date;
    usuario: Usuario;
    moneda: Moneda;
    documentoCobro: DocumentoCobro;
    documentoCredito: DocumentoCredito;
    meta: any = {};
    tipoNombre: string;
    nombreUsuario: string;
}