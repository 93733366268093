import { CampoTipoCertificado } from './campoTipoCertificado';

export class ValorCertificado {
    constructor(
        public id: number,
        public valor: string,
        public texto: string,
        public fecha: string,
        public CertificadoId: number,
        public CampoTipoCertificadoId: number,
    ){}

    fechaDate: Date;
    campo: CampoTipoCertificado;
}