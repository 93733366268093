import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'vex-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        fadeInUp400ms
    ]
})
export class LoginComponent implements OnInit {
    correo: string;
    password: string;
    form: FormGroup;
    cargando: boolean = false;
    fondoLogin: string = '"/assets/img/prointel-fondo-login.jpg"';
    apiURLs = new APIUrls();

    inputType = 'password';
    visible = false;

    icVisibility = icVisibility;
    icVisibilityOff = icVisibilityOff;

    constructor(private router: Router,
        public auth: AuthService,
        private usuariosService: UsuariosService,
        private fb: FormBuilder,
        private cd: ChangeDetectorRef,
        private snackbar: MatSnackBar,
        private titleService: Title,
    ) {}

    onEnter() {
        this.login();
    }

    ngOnInit() {
        this.titleService.setTitle('Iniciar sesión' + this.apiURLs.nombreSitioTitle);
        this.form = this.fb.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    async login() {
        this.cargando = true;
        var loginRes = await this.auth.login(this.correo, this.password);
        if(loginRes.error) {
            // Mostrar notificación de error
            this.snackbar.open(loginRes.mensaje, 'OK', {
                duration: 10000
            });
            this.cargando = false;
        }
        else {
            // Sí logró entrar
            this.router.navigate(['/']);
        }
        this.cargando = false;
        this.cd.detectChanges();
    }

    toggleVisibility() {
        if (this.visible) {
            this.inputType = 'password';
            this.visible = false;
            this.cd.markForCheck();
        } else {
            this.inputType = 'text';
            this.visible = true;
            this.cd.markForCheck();
        }
    }
}