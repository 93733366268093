import { Pais } from "./pais";

export class Departamento {
    constructor(
        public id: number,
        public nombre: string,
        public PaisId: number,
    ){}

    nombrePais: string;
    pais: Pais;
}