import { ValorReclamo } from './valorReclamo';

export class CampoTipoReclamo {
    constructor(
        public id: number,
        public nombre: string,
        public obligatorio: boolean,
        public activo: boolean,
        public tipo: string,
        public orden: number,
        public TipoReclamoId: number,
        public EmpresaId: number,
    ){}

    valor: ValorReclamo;
    usaPorcentaje: boolean = false;
    usaMoneda: boolean = false;
    usaValor: boolean = false;
    usaTexto: boolean = false;
    usaFecha: boolean = false;
}