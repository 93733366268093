import { Certificado } from './certificado';
import { Usuario } from './usuario';

export class PagadorCertificado {
    constructor(
        public id: number,
        public nombre: string,
        public porcentaje: number,
        public digitos: string,
        public metodoPago: string,
        public fechaVencimiento: string,
        public banco: string,
        public tipoCuenta: string,
        public ClienteId: number,
        public CertificadoId: number,
        public EmpresaId: number,
    ){}

    cliente: Usuario;
    certificado: Certificado;
    clientes: Usuario[] = [];
}