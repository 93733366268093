import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconEdit from '@iconify/icons-fa-solid/edit';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconGenerate from '@iconify/icons-fa-solid/money-bill';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconCheck from '@iconify/icons-fa-solid/check';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { CobrosService } from 'src/app/cobros/cobros.service';
import { PlanillaCobro } from 'src/app/models/planillaCobro';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { DialogPreviewDownloadDocumentComponent } from 'src/app/library/dialog-preview-download-document/dialog-preview-download-document.component';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-detalle-planilla',
    templateUrl: './detalle-planilla.component.html',
    styleUrls: ['./detalle-planilla.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DetallePlanillaComponent implements OnInit {
    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;
    @ViewChild('cellMonto', { static: true }) cellMonto: TemplateRef<any>;

    crumbs = [
        { nombre: 'Cobros', link: '/cobros/cobrado' },
        { nombre: 'Planillas', link: '/cobros/planillas' },
        { nombre: 'Detalle planilla', link: '/cobros/detalle-planilla/' },
    ];
    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconEdit = iconEdit;
    iconDownload = iconDownload;
    iconGenerate = iconGenerate;
    iconPlus = iconPlus;
    iconDelete = iconDelete;
    iconCheck = iconCheck;
    
    // Banderas y generales
    cargando: boolean = true;
    titulo: string;
    apiURLs: APIUrls = new APIUrls();
    
    // Datos
    id: number;
    registro: PlanillaCobro;

    // Documentos de cobros
    columnasDocumentosCobros = [
        { prop: 'pagador.nombreCompleto', name: 'Pagador', sortable: true, cellTemplate: null },
        { prop: 'poliza.numero', name: 'No. póliza', sortable: true, cellTemplate: null },
        { prop: 'poliza.nombreAseguradora', name: 'Aseguradora', sortable: true, cellTemplate: null },
        { prop: 'fechaEmisionFormato', name: 'Emisión', sortable: true, cellTemplate: null },
        { prop: 'numeroRequisito', name: 'No. req.', sortable: true, cellTemplate: null },
        { prop: 'meta', name: 'Monto', sortable: true, cellTemplate: null },
        { prop: 'numeroPagoNombre', name: 'No. pago', sortable: true, cellTemplate: null },
        { prop: 'nombreUsuarioAplicador', name: 'Aplicado por', sortable: false, cellTemplate: null },
    ];

    constructor(
        public service: CobrosService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * * 
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        this.agregarTemplatesDocumentos();
        await this.obtenerRegistro();
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        if(params['id']){
            this.id = parseInt(params['id']);
        }
        if(params['id']){
            this.id = parseInt(params['id']);
            this.crumbs[2].link = '/cobros/detalle-planilla/' + this.id;
        }
    }

    agregarTemplatesDocumentos() {
        this.columnasDocumentosCobros.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
        this.columnasDocumentosCobros[5].cellTemplate = this.cellMonto;
    }

    // * * * * * Obtener datos * * * * * 
    // Obtener el registro o inicializar uno nuevo
    async obtenerRegistro() {
        this.cargando = true;
        this.titulo = 'Planilla de cobro #' + this.id;
        // Obtener registro
        var res = await this.service.obtenerPlanillaCobroPorId(this.id);
        if(!res.error) {
            this.registro = res.data.registro;
            // Asignar título
            this.titleService.setTitle('Planilla de cobro #' + this.registro.id + this.apiURLs.nombreSitioTitle);
        }
        else {
            this.router.navigate(['/404']);
        }
        this.cargando = false;
    }

    abrirEliminar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar planilla',
                mensaje: '¿Está seguro de que desea eliminar esta planilla?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminar();
            }
        });
    }

    // Eliminar datos
    async eliminar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.eliminarPlanillaCobro(this.id);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.router.navigate(['/cobros/planillas']);
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    async marcarCongelada(congelada: boolean){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.marcarPlanillaCongelada(this.id, congelada);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    onActivateDocumentos($event) {
        if($event && $event.type == 'click' && !$event.column.checkboxable) {
            var row = $event.row;
            this.router.navigate([ '/cobros/detalle/' + row['id'] ]);
        }
    }

    getRowClass(row) {
        return {
            'hoverRow': true
        };
    }

    // * * * Descargar * * *
    async descargarPDF() {
        if(this.registro) {
            this.cargando = true;
            var res = await this.service.descargarPDFPlanilla(this.id, false);
            if (!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            } else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            this.cargando = false;
        }
    }

    async visualizarEstadoCuentaPDF(){
        this.cargando = true;
        var res = await this.service.descargarPDFPlanilla(this.id, true);
        if (res.error) this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;

        const fileReader = new FileReader();
        fileReader.onload = () => {
            const pdfSrc = new Uint8Array(fileReader.result as ArrayBuffer);
            this.abrirPreviewDocumento(pdfSrc)
        };
        await fileReader.readAsArrayBuffer(res.data.blob);
    }

    abrirPreviewDocumento(pdfSrc) {
        const dialogRef = this.dialog.open(DialogPreviewDownloadDocumentComponent, {
            data: {
                pdfSrc: pdfSrc,
                titulo: 'Planilla final por aseguradora',
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {

            }
        });
  }
}
