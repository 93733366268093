import { Usuario } from './usuario';

export class AclaracionPoliza {
    constructor(
        public id: number,
        public observaciones: string,
        public nombreUsuario: string,
        public fecha: string,
        public PolizaId: number,
        public UsuarioId: number,
        public EmpresaId: number,
    ){}

    fechaFormato: string;
    fechaDate: Date;
    usuario: Usuario;
    observacionesResumen: string;
    nombreUsuarioIngreso: string;
}