import { Usuario } from './usuario';

export class CambioEstadoMensajeria {
    constructor(
        public id: number,
        public fecha: string,
        public estadoAnterior: string,
        public estadoSiguiente: string,
        public diasEstadoAnterior: number,
        public observaciones: string,
        public UsuarioId: number,
        public UsuarioAsignadoId: number,
        public MensajeriaId: number,
        public EmpresaId: number,
    ){}

    usuario: Usuario;
    nombreUsuario: string;
    usuarioAsignado: Usuario;
    nombreUsuarioAsignado: string;
    fechaFormato: string;
    nombreEstadoAnterior: string;
    nombreEstadoSiguiente: string;
}