import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { APIUrls } from '../../api/apiUrls';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { JSONConverters } from 'src/app/models/JSONConverters';
import iconEdit from '@iconify/icons-fa-solid/edit';
import iconAdd from '@iconify/icons-fa-solid/plus';
import iconDownload from '@iconify/icons-fa-solid/download';
import { MapasProcesosService } from '../mapas-procesos.service';
import { AuthService } from 'src/app/auth/auth.service';
import { MatDialog } from '@angular/material';
import { DialogNuevoMapaProceso } from '../dialog-nuevo-mapa-proceso/dialog-nuevo-mapa-proceso.component';
import { DialogReporteMapasProcesos } from '../dialog-reporte-mapas/dialog-reporte-mapas.component';

@Component({
    selector: 'app-mapas-procesos',
    templateUrl: './mapas-procesos.component.html',
    styleUrls: ['./mapas-procesos.component.scss']
})
export class MapasProcesosComponent implements OnInit {
    // Templates de celdas
    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;
    @ViewChild('cellBotones', { static: true }) cellBotones: TemplateRef<any>;
    @ViewChild('cellImagen', { static: true }) cellImagen: TemplateRef<any>;
    apiURLs = new APIUrls();

    // Columnas de tabla
    columnas = [
        { prop: 'id', name: 'No.', sortable: true, cellTemplate: null },
        { prop: 'nombrePlantilla', name: 'Tipo de proceso', sortable: true, cellTemplate: null },
        { prop: 'nombrePoliza', name: 'Póliza', sortable: true, cellTemplate: null },
        { prop: 'duracionDias', name: 'Días', sortable: true, cellTemplate: null },
        { prop: 'nombrePasos', name: 'Pasos', sortable: true, cellTemplate: null },
        { prop: 'idExterno', name: 'No. gestión migrado', sortable: true, cellTemplate: null },
        { prop: 'numeroSolicitudMigrada', name: 'No. solicitud migrada', sortable: true, cellTemplate: null },
        { prop: 'SolicitudPolizaId', name: 'No. solicitud Prosis', sortable: true, cellTemplate: null },
        { prop: 'nombreUsuarioResponsable', name: 'Responsable', sortable: true, cellTemplate: null },
        { prop: 'nombreEstado', name: 'Estado', sortable: true, cellTemplate: null },
        { prop: 'fechaActualizacionFormato', name: 'Último proceso', sortable: true, cellTemplate: null },
        { prop: 'nombreUsuario', name: 'Creado por', sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    campos = [
        { nombre: 'No. mapa Prosis', campo: 'id', tipo: 'texto', categorias: null },
        { nombre: 'No. gestión migrado', campo: 'idExterno', tipo: 'texto', categorias: null },
        { nombre: 'No. solicitud migrada', campo: 'solicitudIdAnterior', tipo: 'texto', categorias: null },
        { nombre: 'No. solicitud Prosis', campo: 'SolicitudPolizaId', tipo: 'texto', categorias: null },
        { nombre: 'Tipo de mapa', campo: 'nombre-plantilla', tipo: 'texto', categorias: null },
        { nombre: 'Último proceso', campo: 'fechaActualizacion', tipo: 'rango-fechas', categorias: null },
    ];

    // Filtros
    filtros = [
        // Estado
        { nombre: 'Estado', campo: 'estado', tipo: 'select-multiple', valor: null, mostrar: true, categorias: this.apiURLs.estadosMapasProcesos },
        { nombre: 'Número de póliza', campo: 'numeroPoliza', tipo: 'texto', valor: null, mostrar: true, categorias: null },
    ];

    crumbs = [
        { nombre: 'Mapas de proceso', link: '/mapas-procesos/todos' },
    ];

    cargando: boolean = false;
    jsonConverters = new JSONConverters();
    urlBusqueda = this.apiURLs.baseURL + this.apiURLs.mapasProcesosURL + '/search';
    titulo: string = 'Mapas de proceso';
    mostrarTabla: boolean = false;
    iconEdit = iconEdit;
    iconAdd = iconAdd;
    iconDownload = iconDownload;
    modo: string = 'todos'

    constructor(
        public service: MapasProcesosService,
        public auth: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.agregarFiltros();
        this.agregarTemplates();
        this.mostrarTabla = true;
    }

    obtenerRuta(params: Params) {
        this.titulo = 'Mapas de proceso';
        if(this.route.snapshot.url.length > 0) {
            var ruta = this.route.snapshot.url[0].path;
            switch(ruta) {
                case 'pendientes': {
                    this.titulo = 'Mis pendientes'
                    this.modo = ruta;
                    break;
                }
                case 'creados': {
                    this.titulo = 'Mapas creados por mí'
                    this.modo = ruta;
                    break;
                }
                case 'todos': {
                    this.titulo = 'Todos mis mapas'
                    this.modo = ruta;
                    break;
                }
                case 'general': {
                    this.titulo = 'Todos los mapas'
                    this.modo = ruta;
                    break;
                }
            }
        }
        this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
    }

    agregarTemplates() {
        this.columnas.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
    }

    async agregarFiltros() {
        
    }

    calcularParamsEspeciales(): string {
        var paramsEspeciales = '';
        
        if(this.modo == 'todos') paramsEspeciales += '&todos=1';
        if(this.modo == 'pendientes') paramsEspeciales += '&pendientes=1';
        if(this.modo == 'creados') paramsEspeciales += '&creados=1';
        
        paramsEspeciales += '&UsuarioId=' + this.auth.idUsuarioActual();

        return paramsEspeciales;
    }

    abrirNuevoMapaProceso() {
        const dialogRef = this.dialog.open(DialogNuevoMapaProceso, {
            data: {
                PolizaId: null,
                poliza: null,
            }
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }

    abrirGenerarReporteMapas() {
        const dialogRef = this.dialog.open(DialogReporteMapasProcesos, {
            data: {
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result != null) {}
        });
    }
}
