import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import moment from 'moment';
import iconDelete from '@iconify/icons-fa-solid/trash';
import { Router } from '@angular/router';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Mensajeria } from 'src/app/models/mensajeria';
import { Aseguradora } from 'src/app/models/aseguradora';
import { Usuario } from 'src/app/models/usuario';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
import { MensajeriaService } from 'src/app/mensajeria/mensajeria.service';
import { AseguradorasService } from 'src/app/aseguradoras/aseguradoras.service';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    MensajeriaId: number,
    PolizaId: number,
    SolicitudPolizaId: number,
    tipoCreacion: string,
}

@Component({
    selector: 'dialog-nueva-mensajeria',
    templateUrl: './dialog-nueva-mensajeria.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogNuevaMensajeria {
    apiURLs = new APIUrls();
    cargando: boolean;
    modoCreacion: boolean = true;
    modoEdicion: boolean = false;
    modoDetalle: boolean = false;
    registro: Mensajeria;
    jsonConverters: JSONConverters = new JSONConverters();
    titulo: string = 'Mensaje';
    tipoEmision: string = this.apiURLs.tiposMensajeriaEmision[0].id;
    clientes: Usuario[] = [];
    aseguradoras: Aseguradora[];
    usuarios: Usuario[] = [];
    usuario: Usuario;
    usuariosAsignados: Usuario[] = [];
    usuarioAsignado: Usuario;

    iconDelete = iconDelete;

    columnasClientes = [
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'apellido', name: 'Apellido', sortable: true, cellTemplate: null },
        { prop: 'nombreAgrupador', name: 'Agrupador', sortable: true, cellTemplate: null },
        { prop: 'telefono1', name: 'Teléfono', sortable: true, cellTemplate: null },
        { prop: 'correo1', name: 'Correo electrónico', sortable: true, cellTemplate: null },
    ];
    camposClientes = [
        { nombre: 'Nombre', campo: 'general', tipo: 'texto', categorias: null },
    ];
    urlAutocompleteClientes = this.apiURLs.baseURL + this.apiURLs.informacionClientesURL + '/search';

    urlAutocompleteEjecutivos = this.apiURLs.baseURL + this.apiURLs.usuariosURL + '/autocompleteAgentesEjecutivos';
    columnasEjecutivos = [
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'apellido', name: 'Apellido', sortable: true, cellTemplate: null },
        { prop: 'correo1', name: 'Correo', sortable: true, cellTemplate: null },
        { prop: 'nombreArea', name: 'Área', sortable: true, cellTemplate: null },
    ];
    camposEjecutivos = [
        { nombre: 'Nombre', campo: 'general', tipo: 'texto', categorias: null },
    ];

    constructor(
        public dialogRef: MatDialogRef<DialogNuevaMensajeria>,
        public service: MensajeriaService,
        public usuariosService: UsuariosService,
        public aseguradorasService: AseguradorasService,
        public auth: AuthService,
        public libraryService: LibraryService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
    }
    
    ngOnInit() {
        // Arreglar estilo
        var dialog = document.getElementById("dialogOverflow");
        var dialogContainer = null;
        if(dialog) dialogContainer = dialog.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer.className += ' dialogOverflow';

        this.initializeData();
    }

    initializeData() {
        this.modoCreacion = this.data.MensajeriaId == null;
        this.modoDetalle = !this.modoCreacion;
        this.modoEdicion = false;
        if(this.modoCreacion) this.titulo = 'Nuevo mensaje';
        else if(this.modoEdicion) this.titulo = 'Edición de mensaje';
        else if(this.modoDetalle) this.titulo = 'Detalle de mensaje';
        this.obtenerRegistro();
        this.obtenerAseguradoras();
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close({mensaje: this.registro});
    }

    async obtenerRegistro() {
        this.cargando = true;
        if(this.modoCreacion) {
            // Asignar título
            // Inicializar registro 
            if(this.data.tipoCreacion == 'envio') {
                this.titulo = 'Solicitud de envío de mensajería';
                this.registro = new Mensajeria(null, this.data.tipoCreacion, this.apiURLs.tiposEnviosMensajeria[0].id, 'area-encargada', null, null, null, null, null, null, null, null, null, null, null, null, false, false, false, null, null, this.auth.idUsuarioActual(), null, null, null, null, null, this.auth.idEmpresaActual());
            }
            else if(this.data.tipoCreacion == 'recepcion') {
                this.titulo = 'Recepción de mensajería';
                this.registro = new Mensajeria(null, this.data.tipoCreacion, 'fisico', 'recepcion', null, null, null, null, null, null, null, null, null, null, null, null, false, false, false, null, this.auth.idUsuarioActual(), null, null, null, null, null, null, this.auth.idEmpresaActual());
            }
            this.registro.PolizaId = this.data.PolizaId;
            this.registro.SolicitudPolizaId = this.data.SolicitudPolizaId;
        }
        else if(this.modoEdicion || this.modoDetalle) {
            // Obtener registro
            var res = await this.service.obtenerMensajeriaPorId(this.data.MensajeriaId);
            if(!res.error) {
                this.registro = res.data.registro;
                this.titulo = this.registro.nombreMensaje;
            }
            else {
                this.router.navigate(['/404']);
            }
        }
        this.cargando = false;
    }

    async guardar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res;
        if(this.modoCreacion) {
            res = await this.service.crearMensajeria(this.registro);
        }
        else if (this.modoEdicion || this.modoDetalle) {
            res = await this.service.actualizarMensajeria(this.registro);
        }
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            if(this.modoCreacion) {
                this.data.MensajeriaId = res.data.result.result.id;
                this.auth.buscadorReload.next();
                this.cerrar();
            }
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    cambiarModoEdicion() {
        this.modoCreacion = false;
        this.modoDetalle = false;
        this.modoEdicion = true;
        this.titulo = 'Edición de mensaje';
    }

    registroValido(): boolean {
        if (!this.registro) return false;
        return true;
    }

    actualizarFecha($event, campo){
        if($event && $event.value) {
            this.registro[campo] = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    actualizarCliente(items: Usuario[]) {
        var ClienteId = null;
        if(items && items.length > 0) {
            ClienteId = items[0].id;
            var nombreCliente = items[0].nombreCompleto;
            var direccionCliente = items[0].direccion;
            this.registro.ClienteId = ClienteId;
            this.registro.receptor = nombreCliente;
            this.registro.direccionReceptor = direccionCliente;
        }
    }

    actualizarAseguradora() {
        var AseguradoraId = this.registro.AseguradoraId;
        if(AseguradoraId) {
            var index = this.libraryService.indexOf(this.aseguradoras, 'id', AseguradoraId);
            if(index != -1) {
                var aseguradora: Aseguradora = this.aseguradoras[index];
                var nombreCliente = aseguradora.nombre;
                var direccionCliente = aseguradora.direccion;
                this.registro.receptor = nombreCliente;
                this.registro.direccionReceptor = direccionCliente;
            }
        }
    }

    actualizarUsuarioEjecutivo(items: Usuario[]) {
        if(items && items.length > 0) {
            this.registro.UsuarioEjecutivoId = items[0].id;
            this.registro.usuarioEjecutivo = items[0];
        }
        else this.registro.UsuarioEjecutivoId = null;
    }

    actualizarUsuarioAsignado(items: Usuario[]) {
        if(items && items.length > 0) {
            this.registro.UsuarioAsignadoId = items[0].id;
            this.registro.usuarioAsignado = items[0];
        }
        else this.registro.UsuarioAsignadoId = null;
    }

    async obtenerAseguradoras() {
        this.cargando = true;
        var res = await this.aseguradorasService.obtenerTodasAseguradoras();
        if(!res.error) {
            this.aseguradoras = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }
}
