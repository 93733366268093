export class ContactoUsuario {
    constructor(
        public id: number,
        public nombre: string,
        public puesto: string,
        public area: string,
        public telefono1: string,
        public telefono2: string,
        public correo1: string,
        public correo2: string,
        public direccionCobro: string,
        public direccionCorrespondencia: string,
        public observaciones: string,
        public UsuarioId: number,
        public EmpresaId: number,
    ){}
    
    public meta: any;
    public nombreCompleto: string;
    seleccionado: boolean = false;
}