import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { APIUrls } from '../../api/apiUrls';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'app-inicio',
    templateUrl: './inicio.component.html',
    styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {
    apiUrls = new APIUrls();
    cargando: boolean = false;
    fondoLogin = '"/assets/img/oficina-prointel.jpeg"';

    atajos = [
        {
            nombre: 'Listado de pólizas',
            permiso: this.auth.tienePermisoPara(this.apiUrls.modulos.POLIZAS.id, this.apiUrls.nivelesAutorizacion.LECTURA),
            icono: 'assets/img/icons/polizas.png',
            ruta: '/polizas/'
        },
        {
            nombre: 'Agregar nueva póliza',
            permiso: this.auth.tienePermisoPara(this.apiUrls.modulos.POLIZAS.id, this.apiUrls.nivelesAutorizacion.ESCRITURA),
            icono: 'assets/img/icons/crear-poliza.png',
            ruta: '/polizas/nuevo/'
        },
        {
            nombre: 'Listado de clientes',
            permiso: this.auth.tienePermisoPara(this.apiUrls.modulos.CLIENTES.id, this.apiUrls.nivelesAutorizacion.LECTURA),
            icono: 'assets/img/icons/clientes.png',
            ruta: '/clientes/'
        },
        {
            nombre: 'Agregar nuevo cliente',
            permiso: this.auth.tienePermisoPara(this.apiUrls.modulos.CLIENTES.id, this.apiUrls.nivelesAutorizacion.ESCRITURA),
            icono: 'assets/img/icons/crear-cliente.png',
            ruta: '/clientes/nuevo/'
        },
        {
            nombre: 'Listado de reclamos',
            permiso: this.auth.tienePermisoPara(this.apiUrls.modulos.RECLAMOS.id, this.apiUrls.nivelesAutorizacion.LECTURA),
            icono: 'assets/img/icons/reclamos.png',
            ruta: '/reclamos/todos'
        },
        {
            nombre: 'Agregar nuevo reclamo',
            permiso: this.auth.tienePermisoPara(this.apiUrls.modulos.RECLAMOS.id, this.apiUrls.nivelesAutorizacion.ESCRITURA),
            icono: 'assets/img/icons/crear-reclamo.png',
            ruta: '/reclamos/nuevo/'
        },
        {
            nombre: 'Requerimientos por cobrar',
            permiso: this.auth.tienePermisoPara(this.apiUrls.modulos.COBROS.id, this.apiUrls.nivelesAutorizacion.LECTURA),
            icono: 'assets/img/icons/por-cobrar.png',
            ruta: '/cobros/cobro/'
        },
        {
            nombre: 'Requerimientos cobrados',
            permiso: this.auth.tienePermisoPara(this.apiUrls.modulos.COBROS.id, this.apiUrls.nivelesAutorizacion.LECTURA),
            icono: 'assets/img/icons/cobrados.png',
            ruta: '/cobros/cobrado/'
        },
    ];

    constructor(
        public auth: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title
    ) { }

    ngOnInit() {
        this.router.navigate(['/analytics/mis-analytics']);
        this.titleService.setTitle('Inicio' + this.apiUrls.nombreSitioTitle);
    }
}
