import { Usuario } from "./usuario";

export class BitacoraCambioRiesgoCliente {
    constructor(
        public id: number,
        public nivelRiesgoTipoCliente: number,
        public nivelRiesgoActividadGeneradoraRecursos: number,
        public nivelRiesgoCiudadania: number,
        public nivelRiesgoPaisNacimiento: number,
        public nivelRiesgoPaisActividadEconomica: number,
        public nivelRiesgoTipoActivoPropio: number,
        public nivelRiesgoProductos: number,
        public nivelRiesgoCaracter: number,
        public nivelRiesgoValorAsegurado: number,
        public nivelRiesgoMedioPago: number,
        public nivelRiesgoTipoIngresos: number,
        public nivelRiesgo: number,
        public fecha: string,
        public EmpresaId: number,
        public InformacionClienteId: number,
        public ClienteId: number,
    ){}

    fechaFormato: string;
    fechaDate: Date;
    cliente: Usuario;
}
