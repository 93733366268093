import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

// Formato de fechas
import { AuthService } from '../../auth/auth.service';
import { Subscription } from 'rxjs';
import iconIncorrecto from '@iconify/icons-fa-solid/times';
import iconCorrecto from '@iconify/icons-fa-solid/check';

@Component({
    selector: 'app-calidad-check',
    templateUrl: './calidad-check.component.html',
    styleUrls: ['./calidad-check.component.scss'],
})
export class CalidadCheckComponent implements OnInit {
    @Input() campo: string;
    @Input() valor: any;
    @Input() correcto: boolean = false;
    @Input() disabled: boolean = false;
    @Output() checkChange: EventEmitter<any> = new EventEmitter();
    valorInicial: any;
    // Íconos
    iconIncorrecto = iconIncorrecto;
    iconCorrecto = iconCorrecto;
    subscriptions: Subscription;

    constructor(
        public authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private changeDetector: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.subscriptions = new Subscription();
        this.valorInicial = this.valor;

        this.checkCampo(false);
    }

    ngOnDestroy(){
        this.subscriptions.unsubscribe();
    }

    checkCampo(toggle: boolean = true) {
        if(toggle) this.correcto = !this.correcto;
        this.checkChange.emit({
            campo: this.campo,
            correcto: this.correcto,
            valorInicial: this.valorInicial,
            valorActual: this.valor,
        });
    }
}
