import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';

export interface DialogData {
    titulo: string,
    mensaje: string,
    payload: any,
}

@Component({
    selector: 'generic-dialog-confirmacion',
    templateUrl: './generic-dialog-confirmacion.component.html',
})
export class GenericDialogConfirmacion {
    constructor(
        public dialogRef: MatDialogRef<GenericDialogConfirmacion>,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    cerrar() {
        this.dialogRef.close('cerrar');
    }

    confirmar() {
        this.dialogRef.close('confirmar');
    }
}