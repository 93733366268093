import { TipoCertificado } from './tipoCertificado';
import { Inciso } from './inciso';
import { Poliza } from './poliza';
import { IncisoCertificado } from './incisoCertificado';
import { CampoTipoCertificado } from './campoTipoCertificado';
import { ValorCertificado } from './valorCertificado';
import { BeneficiarioDependienteCertificado } from './beneficiarioDependienteCertificado';
import { PagadorCertificado } from './pagadorCertificado';
import { RevisionPoliza } from './revisionPoliza';
import { PlantillaRamo } from './plantillaRamo';
import { Declaracion } from './declaracion';
import { DeducibleMonedaCertificado } from './deducibleMonedaCertificado';
import { IncisoHistoricoCertificado } from './incisoHistoricoCertificado';
import { DireccionCertificado } from './direccionCertificado';


export class Certificado {
    constructor(
        public id: number,
        public nombre: string,
        public estado: string,
        public fechaAlta: string,
        public fechaBaja: string,
        public fechaReporteAlta: string,
        public fechaReporteBaja: string,
        public fechaVigenciaInicio: string,
        public fechaVigenciaFin: string,
        public sumaAsegurada: number,
        public primaFacturada: number,
        public primaAnualizada: number,
        public primaEnDeposito: number,
        public tipoCambio: number,
        public porcentajeTasa: number,
        public pagador: string,
        public observaciones: string,
        public observaciones2: string,
        public observaciones3: string,
        public numero: string,
        public numeroInt: number,
        public exclusionTemporal: string,
        public fechaExclusionTemporal: Date,
        public exclusionPermanente: string,
        public cuentaMonetaria: string,
        public clausulasGarantia: string,
        //Relaciones
        public PolizaId: number,
        public TipoCertificadoId: number,
        public PlantillaRamoId: number,
        public EmpresaId: number,
        public RazonPerdidaId: number,

        public TipoVehiculoId: number,
        public MarcaVehiculoId: number,
        public LineaVehiculoId: number,

        public tieneDispositivoSeguridad: boolean,
        public dispositivoSeguridad: string,
        public condicion: string,
        public fechaInicioDispositivoSeguridad: string,
        public fechaFinDispositivoSeguridad: string,
        public referenciaDispositivoSeguridad: string,
    ){}

    //Fechas
    fechaAltaFormato: string;
    fechaAltaDate: Date;
    fechaBajaFormato: string;
    fechaBajaDate: Date;
    fechaReporteAltaFormato: string;
    fechaReporteAltaDate: Date;
    fechaReporteBajaFormato: string;
    fechaReporteBajaDate: Date;
    fechaVigenciaInicioFormato: string;
    fechaVigenciaInicioDate: Date;
    fechaVigenciaFinFormato: string;
    fechaVigenciaFinDate: Date;
    fechaExclusionTemporalFormato: string;
    fechaExclusionTemporalDate: Date;
    fechaInicioDispositivoSeguridadFormato: string;
    fechaInicioDispositivoSeguridadDate: Date;
    fechaFinDispositivoSeguridadFormato: string;
    fechaFinDispositivoSeguridadDate: Date;

    fechaVigenciaMinima: string;
    fechaVigenciaMinimaFormato: string;
    fechaVigenciaMinimaDate: Date;

    tipoCertificado: TipoCertificado;
    plantillaRamo: PlantillaRamo;
    nombrePlantillaRamo: string;
    incisos: IncisoCertificado[];
    incisosHistoricos: IncisoHistoricoCertificado[];
    poliza: Poliza;
    numeroPoliza: string;
    campos: CampoTipoCertificado[];
    valores: ValorCertificado[];
    beneficiarios: BeneficiarioDependienteCertificado[];
    dependientes: BeneficiarioDependienteCertificado[];
    pagadores: PagadorCertificado[];
    idsPagadores: number[];
    nombresPagadores: string;
    revision: RevisionPoliza;
    nombreCertificado: string = '';
    nombreTipoCertificado: string = '';
    nombreNumeroCertificado: string = '';
    totalPrimaNetaDeclaraciones: number;
    primaAFavorAsegurado: number;
    nombreEstado: string;
    nombreIncisoAux: string;
    nombreCertificadoResumen = '';
    tarifa: number;
    declaraciones: Declaracion[];
    deduciblesMoneda: DeducibleMonedaCertificado[] = [];
    idsDeduciblesMoneda: number[] = [];

    // Para importación
    nombreInciso: string;
    idPagador1: number;
    porcentajePagador1: number;
    idPagador2: number;
    porcentajePagador2: number;

    auxDireccion: DireccionCertificado;
}
