import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { PlantillaSolicitud } from '../models/plantillaSolicitud';

@Injectable()
export class SolicitudesService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
    ) { }

    async obtenerPlantillaSolicitudPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasSolicitudesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.plantillaSolicitudDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearPlantillaSolicitud(registro: PlantillaSolicitud) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasSolicitudesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarPlantillaSolicitud(registro: PlantillaSolicitud) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasSolicitudesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarPlantillaSolicitud(PlantillaSolicitudId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasSolicitudesURL + '/id/' + PlantillaSolicitudId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Plantilla eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Desactivar
    public async desactivarPlantillaSolicitud(PlantillaSolicitudId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasSolicitudesURL + '/desactivar';
            const res = await this.connection.postRequest(url, { PlantillaSolicitudId: PlantillaSolicitudId });
            return { error: null, data: { mensaje: 'Plantilla de solicitud desactivada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Activar
    public async activarPlantillaSolicitud(PlantillaSolicitudId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasSolicitudesURL + '/activar';
            const res = await this.connection.postRequest(url, { PlantillaSolicitudId: PlantillaSolicitudId });
            return { error: null, data: { mensaje: 'Plantilla de solicitud activada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Obtener datos * * * * *
    async obtenerTodasPlantillasSolicitudes(tipoUso: string, modulo: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasSolicitudesURL + '?';
            if(tipoUso) url += '&tipoUso=' + tipoUso;
            if(modulo) url += '&modulo=' + modulo;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.plantillaSolicitudDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las plantillas de solicitudes.');
        }
    }

    // * * * * * Archivos * * * * *
    async guardarArchivoEnServidorPlantillasSolicitudes(archivo: File){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasSolicitudesURL + '/uploadArchivo';
            const res = await this.connection.uploadFile(url, archivo, true);
            if(!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }
}