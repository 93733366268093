import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { APIUrls } from '../../api/apiUrls';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { JSONConverters } from 'src/app/models/JSONConverters';
import iconEdit from '@iconify/icons-fa-solid/edit';
import iconList from '@iconify/icons-fa-solid/list';
import { CobrosService } from '../cobros.service';
import { AuthService } from 'src/app/auth/auth.service';
import { LibraryService } from 'src/app/library/library.service';
import { MatDialog } from '@angular/material';

@Component({
    selector: 'app-cobradores',
    templateUrl: './cobradores.component.html',
    styleUrls: ['./cobradores.component.scss']
})
export class CobradoresComponent implements OnInit {
    // Templates de celdas
    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;

    apiURLs = new APIUrls();
    jsonConverters = new JSONConverters();
    
    // Columnas de tabla
    columnas = [
        { prop: 'nombreCompleto', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'codigo', name: 'Código', sortable: true, cellTemplate: null },
        { prop: 'telefono1', name: 'Teléfono 1', sortable: true, cellTemplate: null },
        { prop: 'telefono2', name: 'Teléfono 2', sortable: true, cellTemplate: null },
        { prop: 'correo1', name: 'Correo', sortable: true, cellTemplate: null },
    ];
    
    // Campos para buscador
    campos = [
        { nombre: 'Nombre', campo: 'full', tipo: 'texto', categorias: null },
        { nombre: 'Código', campo: 'codigo', tipo: 'texto', categorias: null },
    ];

    filtros = [];
    urlBusqueda = this.apiURLs.baseURL + this.apiURLs.cobradoresURL + '/search';

    crumbs = [
        { nombre: 'Cobradores', link: '/cobradores/' },
    ];

    titulo: string = 'Cobradores';
    mostrarTabla: boolean = false;
    iconEdit = iconEdit;
    iconList = iconList;
    cargando: boolean = false;
    idsSeleccionados: number[];

    constructor(
        public service: CobrosService,
        public auth: AuthService,
        public libraryService: LibraryService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        private dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        this.agregarTemplates();
        this.mostrarTabla = true;
    }

    obtenerRuta(params: Params) {
        if(this.route.snapshot.url.length > 0) {
            var ruta = this.route.snapshot.url[0].path;
            this.crumbs[0].nombre = this.titulo;
            this.crumbs[0].link = '/cobradores/';
        }
        this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
    }

    agregarTemplates() {
        this.columnas.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
    }

    calcularParamsEspeciales(): string {
        var paramsEspeciales = '';

        return paramsEspeciales;
    }
}
