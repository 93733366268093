import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';

export interface DialogData {
    titulo: string,
    mensaje: string,
    payload: any,
    campos: any[],
}

@Component({
    selector: 'generic-dialog-campos',
    templateUrl: './generic-dialog-campos.component.html',
})
export class GenericDialogConfirmacionCampos {
    constructor(
        public dialogRef: MatDialogRef<GenericDialogConfirmacionCampos>,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close({ accion: 'confirmar', campos: this.data.campos });
    }
}