import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { APIUrls } from '../../api/apiUrls';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { JSONConverters } from 'src/app/models/JSONConverters';
import iconEdit from '@iconify/icons-fa-solid/edit';
import { MapasProcesosService } from '../mapas-procesos.service';

@Component({
    selector: 'app-plantillas-mapas',
    templateUrl: './plantillas-mapas.component.html',
    styleUrls: ['./plantillas-mapas.component.scss']
})
export class PlantillasMapasComponent implements OnInit {
    // Templates de celdas
    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;
    @ViewChild('cellBotones', { static: true }) cellBotones: TemplateRef<any>;
    @ViewChild('cellImagen', { static: true }) cellImagen: TemplateRef<any>;

    // Columnas de tabla
    columnas = [
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'duracionDias', name: 'Duración en días', sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    campos = [
        { nombre: 'Nombre', campo: 'nombre', tipo: 'texto', categorias: null },
    ];

    // Filtros
    filtros = [
        
    ];

    crumbs = [
        { nombre: 'Plantillas de mapas de proceso', link: '/plantillas' },
    ];

    apiURLs = new APIUrls();
    jsonConverters = new JSONConverters();
    urlBusqueda = this.apiURLs.baseURL + this.apiURLs.plantillasMapasProcesosURL + '/search';
    titulo: string = 'Plantillas de mapas de proceso';
    mostrarTabla: boolean = false;
    iconEdit = iconEdit;

    constructor(
        public service: MapasProcesosService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.agregarFiltros();
        this.agregarTemplates();
        this.mostrarTabla = true;
    }

    obtenerRuta(params: Params) {
        this.titulo = 'Plantillas de mapas de proceso';
        if(this.route.snapshot.url.length > 0) {
            var ruta = this.route.snapshot.url[0].path;
        }
        this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
    }

    agregarTemplates() {
        this.columnas.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
    }

    async agregarFiltros() {
        
    }

    calcularParamsEspeciales(): string {
        var paramsEspeciales = '';
        
        return paramsEspeciales;
    }
}
