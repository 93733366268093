export class DocumentoUsuario {
    constructor(
        public id: number,
        public titulo: string,
        public observaciones: string,
        public extension: string,
        public archivo: string,
        public FormularioClienteId: number,
        public UsuarioId: number,
        public EmpresaId: number,
    ){}
}