export class DetalleDeclaracion {
    constructor(
        public id: number,
        public numeroEmbarque: string,
        public fechaInicioEmbarque: string,
        public numeroFactura: string,
        public descripcionMercaderia: string,
        public via: string,
        public origen: string,
        public destino: string,
        public valorEmbarque: number,
        public gastosAdicionales: number,
        public totalDeclarado: number,
        public tasa: number,
        public primaNeta: number,
        public DeclaracionId: number,
        public EmpresaId: number,
    ){}

    fechaInicioEmbarqueFormato: string;
    fechaInicioEmbarqueDate: Date;
}