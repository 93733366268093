import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { Ramo } from 'src/app/models/ramo';
import { RamosService } from 'src/app/ramos/ramos.service';
import { MapasProcesosService } from '../mapas-procesos.service';
import { PlantillaMapaProceso } from 'src/app/models/plantillaMapaProceso';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PlantillaPasoMapaProceso } from 'src/app/models/plantillaPasoMapaProceso';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-nuevo-plantilla-mapa-proceso',
    templateUrl: './nuevo-plantilla-mapa-proceso.component.html',
    styleUrls: ['./nuevo-plantilla-mapa-proceso.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class NuevoPlantillaMapaProcesoComponent implements OnInit {
    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    
    // Banderas y generales
    cargando: boolean = false;
    modoEdicion: boolean;
    modoCreacion: boolean;
    titulo: string;
    apiURLs: APIUrls = new APIUrls();
    
    // Datos
    id: number;
    registro: PlantillaMapaProceso;
    ramos: Ramo[];
    tiposPasosMapaProceso = this.apiURLs.tiposPasosMapaProceso;

    crumbs = [
        { nombre: 'Plantillas de mapas de proceso', link: '/mapas-procesos/plantillas' },
        { nombre: 'Editar plantilla', link: '/mapas-procesos/editar' },
    ];

    constructor(
        public service: MapasProcesosService,
        public ramosService: RamosService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * * 
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        this.obtenerRegistro();
        this.obtenerRamos();
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        this.modoCreacion = ruta === 'nuevo-plantilla';
        this.modoEdicion = ruta === 'editar-plantilla';
        if(params['id']){
            this.id = parseInt(params['id']);
            this.crumbs[1].link = '/mapas-procesos/editar-plantilla/' + this.id;
        }
        if(this.modoCreacion) {
            this.crumbs[1].link = '/mapas-procesos/nuevo-plantilla';
        }
    }

    // * * * * * Obtener datos * * * * * 
    // Obtener el registro o inicializar uno nuevo
    async obtenerRegistro() {
        this.cargando = true;
        if(this.modoCreacion) {
            // Asignar título
            this.titulo = 'Agregar plantilla de mapa de proceso';
            this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
            // Inicializar registro 
            this.registro = new PlantillaMapaProceso(-1, null, null, true, false, this.auth.idEmpresaActual());
        }
        else if(this.modoEdicion) {
            this.titulo = 'Editar plantilla';
            // Obtener registro
            var res = await this.service.obtenerPlantillaMapaProcesoPorId(this.id);
            if(!res.error) {
                this.registro = res.data.registro;
                // Asignar título
                this.titleService.setTitle(this.registro.nombre + this.apiURLs.nombreSitioTitle);
            }
            else {
                this.router.navigate(['/404']);
            }
        }
        this.cargando = false;
    }

    // Obtener
    async obtenerRamos() {
        this.cargando = true;
        var res = await this.ramosService.obtenerTodosRamos();
        if(!res.error) {
            this.ramos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    // * * * * * Editar datos * * * * *
    // Guardar datos
    async guardar(){
        this.cargando = true;

        for (const paso of this.registro.pasos) {
            if(!paso.duracionDias) paso.duracionDias = 0;
        }

        // Enviar el registro para guardar
        this.ordenarNumerosPasos();
        var res;
        if(this.modoCreacion) {
            res = await this.service.crearPlantillaMapaProceso(this.registro);
        }
        else if (this.modoEdicion) {
            res = await this.service.actualizarPlantillaMapaProceso(this.registro);
        }
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            if(this.modoCreacion) this.router.navigate(['/mapas-procesos/editar-plantilla/' + res.data.result.result.id]);
            this.auth.comprobarAutenticacion(false);
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // Eliminar datos
    abrirEliminar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Desactivar plantilla',
                mensaje: '¿Está seguro de que desea desactivar esta plantilla? Dejará de aparecer en ciertas búsquedas.',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminar();
            }
        });
    }

    async eliminar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.desactivarPlantillaMapaProceso(this.id);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.auth.comprobarAutenticacion(false);
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    async activar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.activarPlantillaMapaProceso(this.id);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * * * Validaciones * * * * * 
    formularioValido() {
        if(!this.registro) return false;
        if(!this.registro.pasos || this.registro.pasos.length == 0) return false;

        this.registro.pasos.forEach(paso => {
            if(!paso.descripcion || !paso.duracionDias || !paso.tipo) return false;
        });

        return true;
    }

    // * * * * * Actualizaciones de controles * * * * *
    // Actualiza el id de ramos
    actualizarRamos(items: Ramo[]) {
        var idsRamos: number[] = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            idsRamos.push(item.id);
        }
        this.registro.idsRamos = idsRamos;
    }

    // * * * * * Pasos * * * * *
    agregarPaso() {
        if(!this.registro.pasos) this.registro.pasos = [];
        var nuevoPaso = new PlantillaPasoMapaProceso(null, null, this.tiposPasosMapaProceso[0].id, null, null, true, false, false, this.registro.id, this.auth.idEmpresaActual());
        this.registro.pasos.push(nuevoPaso);

        this.ordenarNumerosPasos();
    }
 
    dropPaso(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.registro.pasos, event.previousIndex, event.currentIndex);
        this.ordenarNumerosPasos();
    }

    eliminarPaso(index: number) {
        if(index != null && this.registro.pasos.length > index) {
            this.registro.pasos.splice(index, 1);
        }
        this.ordenarNumerosPasos();
    }

    ordenarNumerosPasos() {
        if(this.registro.pasos) {
            this.registro.duracionDias = 0;
            for (let i = 0; i < this.registro.pasos.length; i++) {
                this.registro.pasos[i].numero = i + 1;
                if(this.registro.pasos[i].duracionDias) this.registro.duracionDias += parseInt(this.registro.pasos[i].duracionDias.toString());
            }
        }
    }
}
