export class GastoProducto {
    constructor(
        public id: number,
        public nombre: string,
        public tipo: string,
        public porcentaje: number,
        public montoFijo: number,
        public MonedaId: number,
        public ProductoId: number,
    ){}
}