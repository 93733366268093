import { Component, Inject } from '@angular/core';
import { MAT_DATE_FORMATS, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MatDialog } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { SeguimientoReclamo } from 'src/app/models/seguimientoReclamo';
import { LibraryService } from 'src/app/library/library.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Reclamo } from 'src/app/models/reclamo';
import iconDelete from '@iconify/icons-fa-solid/trash';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { PolizasService } from 'src/app/polizas/polizas.service';
import { Municipio } from 'src/app/models/municipio';
import { Departamento } from 'src/app/models/departamento';
import { Zona } from 'src/app/models/zona';
import { DireccionCliente } from 'src/app/models/direccionCliente';
import { Pais } from 'src/app/models/pais';
import { EmpresasService } from 'src/app/empresas/empresas.service';
import { DireccionCertificado } from 'src/app/models/direccionCertificado';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    id: number,
    CertificadoId: number,
}

@Component({
    selector: 'dialog-nuevo-direccion-certificado',
    templateUrl: './dialog-nuevo-direccion-certificado.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogNuevoDireccionCertificado {
    apiURLs = new APIUrls();
    modoCreacion: boolean = true;
    modoEdicion: boolean = false;
    modoDetalle: boolean = false;
    iconDelete = iconDelete;
    cargando: boolean = false;
    id: number
    registro: DireccionCertificado;
    paises: Pais[];
    departamentos: Departamento[];
    municipios: Municipio[];
    zonas: Zona[];

    constructor(
        public dialogRef: MatDialogRef<DialogNuevoDireccionCertificado>,
        public service: PolizasService,
        public empresasService: EmpresasService,
        public polizasService: PolizasService,
        public auth: AuthService,
        public dialog: MatDialog,
        private libraryService: LibraryService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit() {
        this.id = this.data.id;
        this.modoCreacion = !this.data.id;
        this.modoEdicion = !this.modoCreacion;
        this.modoDetalle = false;

        this.obtenerRegistro();
        this.obtenerPaises();
        this.obtenerDepartamentos();
        this.obtenerMunicipios();
        this.obtenerZonas();
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close({
            accion: 'confirmar',
        });
    }

    async obtenerRegistro() {
        this.cargando = true;
        if(this.modoCreacion) {
            // Inicializar registro 
            this.registro = new DireccionCertificado(-1, this.apiURLs.tiposDirecciones[0].id, null, null, null, null, null, null, null, this.data.CertificadoId);
        }
        else {
            // Obtener registro
            var res = await this.service.obtenerDireccionCertificadoPorId(this.id);
            if(!res.error) {
                this.registro = res.data.registro;
            }
            else {
                this.cerrar();
            }
        }
        this.cargando = false;
    }

    // * * * * * Editar datos * * * * *
    // Guardar datos
    async guardar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res;
        if(this.modoCreacion) {
            res = await this.service.crearDireccionCertificado(this.registro);
        }
        else if (this.modoEdicion) {
            res = await this.service.actualizarDireccionCertificado(this.registro);
        }
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            if(this.modoCreacion) {
                this.confirmar();
            }
            this.auth.buscadorReload.next();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // Eliminar datos
    abrirEliminar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar registro',
                mensaje: '¿Está seguro de que desea eliminar este registro?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminar();
            }
        });
    }

    async eliminar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.eliminarDireccionCertificado(this.id);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    registroValido() {
        if(!this.registro) return false;
        if(!this.registro.complemento) return false;
        if(!this.registro.PaisId) return false;
        if(!this.registro.DepartamentoId) return false;
        if(!this.registro.MunicipioId) return false;
        if(!this.registro.ZonaId) return false;

        return true;
    }

    async obtenerPaises() {
        this.cargando = true;
        var res = await this.empresasService.obtenerTodosPaises();
        if(!res.error) {
            this.paises = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerDepartamentos() {
        this.cargando = true;
        var res = await this.empresasService.obtenerTodosDepartamentos();
        if(!res.error) {
            this.departamentos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerMunicipios() {
        this.cargando = true;
        var res = await this.empresasService.obtenerTodosMunicipios();
        if(!res.error) {
            this.municipios = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerZonas() {
        this.cargando = true;
        var res = await this.empresasService.obtenerTodosZonas();
        if(!res.error) {
            this.zonas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }
}
