import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconSearch from '@iconify/icons-fa-solid/search';
import iconCandadoDetalle from '@iconify/icons-fa-solid/lock';
import iconCandadoEditar from '@iconify/icons-fa-solid/lock-open';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { CobrosService } from '../cobros.service';
import { PlanillaCobro } from 'src/app/models/planillaCobro';
import { Aseguradora } from 'src/app/models/aseguradora';
import { Moneda } from 'src/app/models/moneda';
import { EmpresasService } from 'src/app/empresas/empresas.service';
import { AseguradorasService } from 'src/app/aseguradoras/aseguradoras.service';
import { DocumentoCobro } from 'src/app/models/documentoCobro';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-nuevo-planilla-cobro',
    templateUrl: './nuevo-planilla-cobro.component.html',
    styleUrls: ['./nuevo-planilla-cobro.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class NuevoPlanillaCobroComponent implements OnInit {
    @ViewChild('cellMoneda', { static: true }) cellMoneda: TemplateRef<any>;

    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconSearch = iconSearch;
    iconCandadoDetalle = iconCandadoDetalle;
    iconCandadoEditar = iconCandadoEditar;
    
    // Banderas y generales
    cargando: boolean = false;
    modoEdicion: boolean;
    modoDetalle: boolean;
    modoCreacion: boolean;
    titulo: string;
    apiURLs: APIUrls = new APIUrls();
    fechaHoy = new Date();
    
    // Datos
    id: number;
    ids: number[];
    registro: PlanillaCobro;
    aseguradoras: Aseguradora[];
    monedas: Moneda[];
    documentosCobro: DocumentoCobro[];
    idsDocumentos: number[];
    formasCobro = this.apiURLs.formasCobroCreacion;

    crumbs = [
        { nombre: 'Requirimientos cobrados', link: '/cobros/cobrado' },
        { nombre: 'Planillas de cobro', link: '/cobros/planillas' },
        { nombre: 'Nueva planilla de cobro', link: '/cobros/nuevo-planilla-cobro' },
    ];

    constructor(
        public service: CobrosService,
        public empresasService: EmpresasService,
        public aseguradorasService: AseguradorasService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * * 
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
        this.route.queryParams.subscribe((params: Params) => {
            if(params['idsDocumentos']) {
                this.ids = params['idsDocumentos'].split(',');
                this.obtenerDocumentosCobrados();
            }
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.obtenerRegistro();
        this.obtenerMonedas();
        this.obtenerAseguradoras();
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        this.modoCreacion = ruta === 'nuevo-planilla-cobro';
        this.modoEdicion = ruta === 'editar-planilla-cobro';
        this.modoDetalle = ruta === 'detalle-planilla-cobro';
        if(params['id']){
            this.id = parseInt(params['id']);
        }
    }

    // * * * * * Obtener datos * * * * * 
    // Obtener el registro o inicializar uno nuevo
    async obtenerRegistro() {
        this.cargando = true;
        if(this.modoCreacion) {
            // Asignar título
            this.titulo = 'Agregar planilla de cobro';
            this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
            this.registro = new PlanillaCobro(null, null, null, null, null, null, null, null, null, null, null, this.auth.idUsuarioActual(), this.auth.idEmpresaActual());
            this.registro.fechaDate = new Date();
            this.actualizarFecha({ value: { _d: new Date() } }, 'fecha');
            this.registro.fechaHastaDate = new Date();
            this.actualizarFecha({ value: { _d: new Date() } }, 'fechaHasta');
        }
        else if(this.modoEdicion || this.modoDetalle) {
            this.titulo = 'Editar planilla de cobro ' + this.id;
            this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
            // Obtener registro
            var res = await this.service.obtenerPlanillaCobroPorId(this.id);
            if(!res.error) {
                this.registro = res.data.registro;
                this.documentosCobro = this.registro.documentos;
                this.crumbs[2].link = '/cobros/detalle-planilla/' + this.id;
            }
            else {
                this.router.navigate(['/404']);
            }
        }
        this.cargando = false;
    }

    // * * * * * Editar datos * * * * *
    // Guardar datos
    async guardar(){
        this.cargando = true;

        this.registro.documentos = this.documentosCobro;
        this.registro.idsDocumentos = this.idsDocumentos;

        // Enviar el registro para guardar
        var res;
        if(this.modoCreacion) {
            res = await this.service.crearPlanillaCobro(this.registro);
        }
        else if(this.modoEdicion) {
            res = await this.service.actualizaPlanillaCobro(this.registro);
        }
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            if(this.modoCreacion) this.router.navigate(['/cobros/detalle-planilla/' + res.data.result.result.id]);
            this.auth.comprobarAutenticacion(false);
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * * * Documentos * * * * *
    async obtenerDocumentosCobrados() {
        this.cargando = true;
        if(this.modoCreacion && this.registro.fecha && this.registro.fechaHasta) {
            var res = await this.service.obtenerDocumentosCobradosFiltro(this.registro.idsAseguradoras, this.registro.MonedaId, this.id, this.registro.fecha, this.registro.fechaHasta);
            if(!res.error) {
                this.documentosCobro = res.data.registros;
                this.idsDocumentos = res.data.idsDocumentos;
            }
            else {
                this.documentosCobro = [];
            }
        }
        if(this.modoCreacion && this.ids) {
            var res = await this.service.obtenerDocumentosIds(this.ids);
            if(!res.error) {
                this.documentosCobro = res.data.registros;
            }
            else {
                this.documentosCobro = [];
            }
        }
        this.cargando = false;
    }

    eliminarDocumento(indexDocumento: number) {
        if(indexDocumento != null && this.documentosCobro && this.documentosCobro.length > indexDocumento) {
            this.documentosCobro.splice(indexDocumento, 1);
        }
    }

    actualizarFecha($event, campo){
        if($event && $event.value) {
            this.registro[campo] = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
        this.obtenerDocumentosCobrados();
    }

    // * * * Eliminar datos * * *
    abrirEliminar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar planilla de cobro',
                mensaje: '¿Está seguro de que desea eliminar esta planilla de cobro?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminar();
            }
        });
    }

    async eliminar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.eliminarPlanillaCobro(this.id);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.router.navigate(['/cobros/planillas/']);
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * * * Monedas * * * * *
    async obtenerMonedas() {
        this.cargando = true;
        let EmpresaId = await this.auth.idEmpresaActual();
        var res = await this.empresasService.obtenerTodasMonedasEmpresa(EmpresaId);
        if(!res.error) {
            this.monedas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerAseguradoras() {
        this.cargando = true;
        var res = await this.aseguradorasService.obtenerTodasAseguradoras();
        if(!res.error) {
            this.aseguradoras = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }
 
    // * * * * * Validaciones * * * * * 
    formularioValido() {
        if(!this.registro) return false;
        if(!this.documentosCobro || this.documentosCobro.length == 0) return false;
        return true;
    }
}