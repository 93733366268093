import { Empresa } from "./empresa";

export class MarcaVehiculo {
    constructor(
        public id: number,
        public nombre: string,
        public EmpresaId: number,
    ){}

    nombreEmpresa: string;
    Empresa: Empresa;
}
