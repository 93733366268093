import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { JSONConverters } from 'src/app/models/JSONConverters';
import moment from 'moment';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import { Usuario } from 'src/app/models/usuario';
import { Poliza } from 'src/app/models/poliza';
import { CobrosService } from '../cobros.service';
import { AseguradorasService } from 'src/app/aseguradoras/aseguradoras.service';
import { DialogPreviewDownloadDocumentComponent } from 'src/app/library/dialog-preview-download-document/dialog-preview-download-document.component';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};
export interface DialogData {
    polizaDefault: Poliza,
    pagadorDefault: Usuario,
}

@Component({
    selector: 'dialog-carga-masiva-liquidados',
    templateUrl: './dialog-carga-masiva-liquidados.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogCargaMasivaLiquidadosComponent {
    apiURLs = new APIUrls();
    fechaInicioDate: Date;
    fechaInicio: string;
    fechaFinDate: Date;
    fechaFin: string;
    cargando: boolean = false;
    conRequerimientos: boolean = false;
    incluirCobrados: boolean = true;
    incluirPorCobrar: boolean = true;
    incluirNotasCredito: boolean = false;

    iconDownload = iconDownload;
    iconUpload = iconUpload;
    rutaArchivoPagos: string;
    archivoPagos: File;
    analisis: boolean = true;
    datosAnalisis: any[];

    jsonConverters: JSONConverters = new JSONConverters();

    constructor(
        public dialogRef: MatDialogRef<DialogCargaMasivaLiquidadosComponent>,
        public dialog: MatDialog,
        public service: CobrosService,
        public aseguradorasService: AseguradorasService,
        public libraryService: LibraryService,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit(){
        
    }

    // * * * Confirmar * * *
    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close(null);
    }

    async descargarPlantilla(){
        this.cargando = true;
        // Enviar el registro para guardar
        var res = await this.service.descargarExcelPlantillaLiquidados('Plantilla de liquidados');

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    seleccionarArchivoPagos(event: any){
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.rutaArchivoPagos = file.name;
            this.archivoPagos = file;
            this.analizarArchivoExcelPagos();
        }
    }

    async analizarArchivoExcelPagos() {
        if(this.archivoPagos) {
            this.cargando = true;
            var resLectura = await this.service.lecturaImportacionLiquidadosPlantilla(this.archivoPagos, this.auth.idEmpresaActual());
            if(resLectura.error) {
                // Error al leer archivo
                this.libraryService.crearNotificacion(resLectura.mensaje, 'danger');
                this.cargando = false;
            }
            else {
                let registros = resLectura.resultados;
                this.subirRegistrosPagos(registros, this.conRequerimientos);
            }
        }
    }

    async subirRegistrosPagos(registros: any[], conRequerimientos: boolean){
        try {
            this.cargando = true;
            if(!registros || registros.length == 0) throw new Error('No hay datos para subir.');
    
            // Enviar el registro para guardar
            var res = await this.service.subirImportacionLiquidadosPlantilla(registros, this.auth.idEmpresaActual(), this.analisis, conRequerimientos);
            
            if(!res.error) {
                this.datosAnalisis = res.data.result.messages;
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            }
            else {
                this.datosAnalisis = null;
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            this.limpiarArchivoPagos();
            this.cargando = false;
        } catch(error) {
            this.cargando = false;
            this.libraryService.crearNotificacion(error.message, 'danger');
        }
    }

    activarSeleccionArchivoPagos(conRequerimientos: boolean){
        this.conRequerimientos = conRequerimientos;
        this.limpiarArchivoPagos();
        document.getElementById('file-input-archivo-pagos').click();
    }

    limpiarArchivoPagos(){
        this.rutaArchivoPagos = null;
        this.archivoPagos = null;
    }
}