import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardPermisos } from '../auth/auth-guard-permisos';
import { APIUrls } from '../api/apiUrls';
import { CobrosComponent } from './cobros/cobros.component';
import { DetalleCobroComponent } from './detalle-cobro/detalle-cobro.component';
import { DetallePlanillaComponent } from './detalle-planilla/detalle-planilla.component';
import { PlanillasCobrosComponent } from './planillas/planillas.component';
import { NuevoDocumentoCreditoComponent } from './nuevo-documento-credito/nuevo-documento-credito.component';
import { CreditosComponent } from './creditos/creditos.component';
import { DetalleCreditoComponent } from './detalle-credito/detalle-credito.component';
import { NuevoDocumentoCobroComponent } from './nuevo-documento-cobro/nuevo-documento-cobro.component';
import { CobradoresComponent } from './cobradores/cobradores.component';
import { NuevoCobradorComponent } from './nuevo-cobrador/nuevo-cobrador.component';
import { NuevoPlanillaCobroComponent } from './nuevo-planilla-cobro/nuevo-planilla-cobro.component';
import { ImportacionCobrosComponent } from './importacion-cobros/importacion-cobros.component';
import { AnalyticsComisionesComponent } from './analytics-comisiones/analytics-comisiones.component';
import { ContenedorAnalyticsCobrosComponent } from './analytics-cobros/contenedor-analytics-cobros.component';
import { PlantillasGestionesCobrosComponent } from './plantillas-gestiones-cobros/plantillas-gestiones-cobros.component';
import { NuevoPlantillaGestionCobroComponent } from './nuevo-plantilla-gestion-cobro/nuevo-plantilla-gestion-cobro.component';
import { NuevoGestionCobroComponent } from './nuevo-gestion-cobro/nuevo-gestion-cobro.component';
import { GestionesCobrosComponent } from './gestiones-cobros/gestiones-cobros.component';
import { PlanillasLiquidacionComponent } from './planillas-liquidacion/planillas-liquidacion.component';
import { NuevoPlanillaLiquidacionComponent } from './nuevo-planilla-liquidacion/nuevo-planilla-liquidacion.component';
import { BancosComponent } from './bancos/bancos.component';
import { NuevoBancoComponent } from './nuevo-banco/nuevo-banco.component';
import { ContenedorAnalyticsComisionesComponent } from './analytics-comisiones-v2/contenedor-analytics-cobros-v2.component';
import { ReporteComisionesProyectadasComponent } from './reporte-comisiones-proyectadas/reporte-comisiones-proyectadas.component';
import { LiquidacionCobrosComponent } from './liquidacion-cobros/liquidacion-cobros.component';
import { ReporteComisionesAgentesComponent } from './reporte-comisiones-agentes/reporte-comisiones-agentes.component';

var apiURLs = new APIUrls();
const routes: Routes = [
    {
        path: 'bancos/todos',
        component: BancosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'cobro',
        component: CobrosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'cobrado',
        component: CobrosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'liquidado',
        component: CobrosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COMISIONES.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'anulado',
        component: CobrosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'cobradores',
        component: CobradoresComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.EMPRESAS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'nuevo-banco',
        component: NuevoBancoComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.EMPRESAS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'editar-banco/:id',
        component: NuevoBancoComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.EMPRESAS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'detalle-banco/:id',
        component: NuevoBancoComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.EMPRESAS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'nuevo-cobrador',
        component: NuevoCobradorComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.EMPRESAS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'editar-cobrador/:id',
        component: NuevoCobradorComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.EMPRESAS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'planillas',
        component: PlanillasCobrosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'planillas-liquidacion',
        component: PlanillasLiquidacionComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'plantillas-gestiones-cobros',
        component: PlantillasGestionesCobrosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.ADMINISTRADOR }
    },
    {
        path: 'nuevo-plantilla-gestion-cobro',
        component: NuevoPlantillaGestionCobroComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.ADMINISTRADOR }
    },
    {
        path: 'editar-plantilla-gestion-cobro/:id',
        component: NuevoPlantillaGestionCobroComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.ADMINISTRADOR }
    },
    {
        path: 'detalle-plantilla-gestion-cobro/:id',
        component: NuevoPlantillaGestionCobroComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.ADMINISTRADOR }
    },
    {
        path: 'gestiones-cobros',
        component: GestionesCobrosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'nuevo-gestion-cobro',
        component: NuevoGestionCobroComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'editar-gestion-cobro/:id',
        component: NuevoGestionCobroComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'detalle-gestion-cobro/:id',
        component: NuevoGestionCobroComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'creditos-todos',
        component: CreditosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'creditos-por-aplicar',
        component: CreditosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'creditos-aplicados',
        component: CreditosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'creditos-notas-devolucion',
        component: CreditosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'creditos-primas-deposito',
        component: CreditosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'creditos-pagados',
        component: CreditosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'detalle/:id',
        component: DetalleCobroComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'detalle-planilla/:id',
        component: DetallePlanillaComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'nuevo-planilla-liquidacion',
        component: NuevoPlanillaLiquidacionComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COMISIONES.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'editar-planilla-liquidacion/:id',
        component: NuevoPlanillaLiquidacionComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COMISIONES.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'detalle-planilla-liquidacion/:id',
        component: NuevoPlanillaLiquidacionComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COMISIONES.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'nuevo-documento-cobro/:PolizaId',
        component: NuevoDocumentoCobroComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.POLIZAS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'editar-documento-cobro/:id',
        component: NuevoDocumentoCobroComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.POLIZAS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'nuevo-nota-devolucion/:PolizaId',
        component: NuevoDocumentoCreditoComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'nuevo-prima-deposito/:PolizaId',
        component: NuevoDocumentoCreditoComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'nuevo-nota-devolucion',
        component: NuevoDocumentoCreditoComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'nuevo-prima-deposito',
        component: NuevoDocumentoCreditoComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'editar-credito/:id',
        component: NuevoDocumentoCreditoComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'detalle-credito/:id',
        component: DetalleCreditoComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
    {
        path: 'nuevo-planilla-cobro',
        component: NuevoPlanillaCobroComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'importacion-cobros',
        component: ImportacionCobrosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS.id, nivel: apiURLs.nivelesAutorizacion.ADMINISTRADOR }
    },
    {
        path: 'comisiones',
        component: AnalyticsComisionesComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COMISIONES_ANALYTICS.id, nivel: apiURLs.nivelesAutorizacion.PROPIO }
    },
    {
        path: 'analytics',
        component: ContenedorAnalyticsCobrosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COBROS_ANALYTICS.id, nivel: apiURLs.nivelesAutorizacion.PROPIO }
    },
    {
        path: 'analytics-comisiones',
        component: ContenedorAnalyticsComisionesComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COMISIONES_ANALYTICS.id, nivel: apiURLs.nivelesAutorizacion.PROPIO }
    },
    {
        path: 'comisiones-proyectadas',
        component: ReporteComisionesProyectadasComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COMISIONES_ANALYTICS.id, nivel: apiURLs.nivelesAutorizacion.PROPIO }
    },
    {
        path: 'liquidacion-cobros',
        component: LiquidacionCobrosComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COMISIONES.id, nivel: apiURLs.nivelesAutorizacion.ESCRITURA }
    },
    {
        path: 'reporte-comisiones-agentes',
        component: ReporteComisionesAgentesComponent,
        canActivate: [ AuthGuardPermisos ],
        data: { modulo: apiURLs.modulos.COMISIONES.id, nivel: apiURLs.nivelesAutorizacion.LECTURA }
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CobrosRoutingModule {
}
