import { Usuario } from './usuario';
import { MapaProceso } from './mapaProceso';
import { PlantillaPasoMapaProceso } from './plantillaPasoMapaProceso';

export class PasoMapaProceso {
    constructor(
        public id: number,
        public numero: number,
        public duplicado: boolean,
        public numeroDuplicado: number,
        public fechaAceptado: string,
        public fechaCompletado: string,
        public fechaAsignado: string,
        public aceptado: boolean,
        public completado: boolean,
        public duracionDias: number,
        public observaciones: string,
        public puntuacion: number,
        public MapaProcesoId: number,
        public PlantillaPasoMapaProcesoId: number,
        public UsuarioAsignadorId: number,
        public UsuarioResponsableId: number,
        public EmpresaId: number,
    ){}

    fechaAceptadoFormato: string;
    fechaAceptadoDate: Date;
    fechaCompletadoDate: Date;
    fechaCompletadoFormato: string;
    fechaAsignadoDate: Date;
    fechaAsignadoFormato: string;
    mapaProceso: MapaProceso;
    plantilla: PlantillaPasoMapaProceso;
    usuarioAsignador: Usuario;
    usuarioResponsable: Usuario;
    usuariosResponsablesAux: Usuario[] = [];
    usuariosAsignadoresAux: Usuario[] = [];
    meta: any = {};
    numeroCompleto: string = '';
    nombrePaso: string = '';
    nombreTipo: string = '';
    tipoPaso: string = '';
    nombreUsuarioResponsable: string = '';
    nombreUsuarioAsignador: string = '';
}