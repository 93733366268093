import { Poliza } from './poliza';
import { RevisionPoliza } from './revisionPoliza';

export class CorreccionControlCalidad {
    constructor(
        public id: number,
        public campo: string,
        public valorAnterior: string,
        public valorCorregido: string,
        public observaciones: string,
        public correcto: boolean,
        public PolizaId: number,
        public CertificadoId: number,
        public RevisionPolizaId: number,
        public EmpresaId: number,
    ){}

    revision: RevisionPoliza;
    poliza: Poliza;
}