import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { APIUrls } from '../../api/apiUrls';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { JSONConverters } from 'src/app/models/JSONConverters';
import iconEdit from '@iconify/icons-fa-solid/edit';
import { UsuariosService } from '../usuarios.service';

@Component({
    selector: 'app-usuarios',
    templateUrl: './usuarios.component.html',
    styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {
    crumbs = [
        { nombre: 'Usuarios', link: '/usuarios' },
    ];
    // Templates de celdas
    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;
    @ViewChild('cellBotones', { static: true }) cellBotones: TemplateRef<any>;

    // Columnas de tabla
    columnas = [
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'apellido', name: 'Apellido', sortable: true, cellTemplate: null },
        { prop: 'codigo', name: 'Código', sortable: true, cellTemplate: null },
        { prop: 'correo1', name: 'Correo electrónico', sortable: true, cellTemplate: null },
        { prop: 'puesto', name: 'Puesto', sortable: true, cellTemplate: null },
        { prop: 'area.nombre', name: 'Área', sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    campos = [
        { nombre: 'Nombre', campo: 'nombreCompleto', tipo: 'texto', categorias: null },
        { nombre: 'Código', campo: 'codigo', tipo: 'texto', categorias: null },
        { nombre: 'Correo', campo: 'correo1', tipo: 'texto', categorias: null },
    ];

    // Filtros
    filtros = [
        // Área
        { nombre: 'Área', campo: 'area', tipo: 'select-unico', valor: null, mostrar: false, categorias: []},
        { nombre: 'Activo', campo: 'activo', tipo: 'select-unico', valor: null, mostrar: false, categorias: [
            { nombre: 'Sí', id: '1' },
            { nombre: 'No', id: '0' },
            { nombre: 'Ambos', id: null },
        ]},
    ];

    posiblesEstados = [
        { nombre: 'Activo', color: 'black' },
        { nombre: 'Inactivo', color: 'red' },
    ]

    apiURLs = new APIUrls();
    jsonConverters = new JSONConverters();
    urlBusqueda = this.apiURLs.baseURL + this.apiURLs.usuariosURL + '/search';
    titulo: string = 'Usuarios';
    modo: string = 'usuarios';
    mostrarTabla: boolean = false;
    iconEdit = iconEdit;

    constructor(
        public service: UsuariosService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.agregarFiltros();
        this.agregarTemplates();
        this.mostrarTabla = true;
    }

    obtenerRuta(params: Params) {
        this.titulo = 'Usuarios';
        if(this.route.snapshot.url.length > 0) {
            var ruta = this.route.snapshot.url[0].path;
            this.modo = ruta;
            switch(this.modo) {

            }
        }
        this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
    }

    agregarTemplates() {
        this.columnas.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
    }

    async agregarFiltros() {
        var resAreas = await this.service.obtenerTodasAreas();
        if(!resAreas.error) this.filtros[0].categorias = resAreas.data.registros;
    }

    calcularParamsEspeciales(): string {
        var paramsEspeciales = '';
        
        return paramsEspeciales;
    }
}
