import { Cobertura } from './cobertura';

import { Certificado } from './certificado';
import { DeducibleIncisoPlantillaRamo } from './deducibleIncisoPlantillaRamo';
import { PlantillaRamo } from './plantillaRamo';

export class IncisoPlantillaRamo {
    constructor(
        public id: number,
        public nombre: string,
        public declarativo: boolean,
        public acumulaSuma: boolean,
        public descripcion: string,
        public sumaAsegurada: number,
        public primaFacturada: number,
        public primaSinDependientes: number,
        public primaCon1Dependiente: number,
        public primaConMasDependientes: number,
        public primaAnualizada: number,
        public primaEnDeposito: number,
        public tipoCambio: number,
        public porcentajeTasa: number,
        public tarifa: number,
        public tipoTasa: string,
        public PlantillaRamoId: number,
        public EmpresaId: number,
    ){}

    plantillaRamo: PlantillaRamo;
    seleccionado: boolean = false;
    coberturasSinAsignar: Cobertura[];
    coberturas: Cobertura[] = [];
    idsCoberturas: number[] = [];
    deducibles: DeducibleIncisoPlantillaRamo[] = [];
    idsDeducibles: number[] = [];
    busquedaCoberturasSinAsignar: string;
    busquedaCoberturasAsignadas: string;
}