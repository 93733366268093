import { Empresa } from './empresa';

export class TipoVehiculo {
    constructor(
        public id: number,
        public nombre: string,
        public EmpresaId: number,
    ) { }

    Empresa: Empresa;
}