export class AccionistaCliente {
    constructor(
        public id: number,
        public nombre: string,
        public numeroIdentificacion: string,
        public tipoIdentificacion: string,
        public paisConstitucion: string,
        public correo: string,
        public porcentajeParticipacion: number,
        public ClienteId: number,
        public EmpresaId: number,
    ){}
}