import { Usuario } from './usuario';
import { PlantillaMapaProceso } from './plantillaMapaProceso';
import { PasoMapaProceso } from './pasoMapaProceso';
import { Poliza } from './poliza';
import { Certificado } from './certificado';
import { SolicitudPoliza } from './solicitudPoliza';

export class MapaProceso {
    constructor(
        public id: number,
        public observaciones: string,
        public duracionDias: number,
        public fechaCreacion: string,
        public fechaFinalizacion: string,
        public fechaActualizacion: string,
        public numeroAseguradora: string,
        public completado: boolean,
        public cancelado: boolean,
        public pasoActual: number,
        public totalPasos: number,
        public idExterno: string,
        public PlantillaMapaProcesoId: number,
        public UsuarioId: number,
        public UsuarioResponsableId: number,
        public EmpresaId: number,
    ){}

    fechaCreacionFormato: string;
    fechaCreacionDate: Date;
    fechaFinalizacionDate: Date;
    fechaFinalizacionFormato: string;
    fechaActualizacionDate: Date;
    fechaActualizacionFormato: string;
    plantilla: PlantillaMapaProceso;
    usuario: Usuario;
    usuarioResponsable: Usuario;
    poliza: Poliza;
    pasos: PasoMapaProceso[];
    certificados: Certificado[];
    idsCertificados: number[] = [];
    meta: any = {};
    nombreMapa: string = '';
    nombreEstado: string = '';
    nombrePlantilla: string = '';
    nombreUsuario: string = '';
    nombreUsuarioResponsable: string = '';
    nombrePoliza: string = '';
    nombreCertificados: string = '';
    nombrePasos: string = '';
    numeroSolicitudMigrada: number;
    SolicitudPolizaId: number;
    solicitud: SolicitudPoliza;
}