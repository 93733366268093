import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import { Usuario } from '../../models/usuario';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconError from '@iconify/icons-fa-solid/exclamation-circle';
import iconCandadoDetalle from '@iconify/icons-fa-solid/lock';
import iconCandadoEditar from '@iconify/icons-fa-solid/lock-open';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { UsuariosService } from '../usuarios.service';
import { Area } from 'src/app/models/area';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-nuevo-usuario',
    templateUrl: './nuevo-usuario.component.html',
    styleUrls: ['./nuevo-usuario.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class NuevoUsuarioComponent implements OnInit {
    crumbs = [
        { nombre: 'Usuarios', link: '/usuarios' },
        { nombre: 'Datos usuario', link: '/usuarios/editar/' },
    ];
    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconError = iconError;
    iconCandadoDetalle = iconCandadoDetalle;
    iconCandadoEditar = iconCandadoEditar;
    
    // Banderas y generales
    cargando: boolean = false;
    modoEdicion: boolean;
    modoCreacion: boolean;
    titulo: string;
    apiURLs: APIUrls = new APIUrls();
    
    // Datos
    id: number;
    usuario: Usuario;
    areas: Area[];
    agentes: Usuario[];

    // Archivos 
    rutaArchivoImagen: string;
    archivoImagen: File;
    modoSubirImagen: boolean = true;
    rutaArchivoPortada: string;
    archivoPortada: File;
    modoSubirPortada: boolean = true;

    // Contraseña
    nuevoPassword: string;
    confirmarPassword: string;
    minimoContrasena: number = 8;

    constructor(
        public service: UsuariosService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * * 
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.obtenerAreas();
        await this.obtenerRegistro();
        await this.obtenerAgentes();
        this.obtenerMinimoContrasena();
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        this.modoCreacion = ruta === 'nuevo';
        this.modoEdicion = ruta === 'editar';
        if(params['id']){
            this.id = parseInt(params['id']);
        }
        if(params['id']){
            this.id = parseInt(params['id']);
            this.crumbs[1].link = '/usuarios/editar/' + this.id;
        }
    }

    // * * * * * Obtener datos * * * * * 
    // Obtener el registro o inicializar uno nuevo
    async obtenerRegistro() {
        this.cargando = true;
        if(this.modoCreacion) {
            // Asignar título
            this.titulo = 'Agregar usuario';
            this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
            // Inicializar registro 
            this.usuario = new Usuario(-1, null, null, null, null, null, null, null, null, null, null, true, false, true, false, false, false, false, false, false, null, null, null, null, null, null, null);
        }
        else if(this.modoEdicion) {
            this.titulo = 'Editar información de usuario';
            // Obtener registro
            var res = await this.service.obtenerUsuarioPorId(this.id);
            if(!res.error) {
                this.usuario = res.data.registro;
                // Asignar título
                this.titleService.setTitle(this.usuario.nombreCompleto + this.apiURLs.nombreSitioTitle);
            }
            else {
                this.router.navigate(['/404']);
            }
        }
        this.cargando = false;
    }

    // Obtener países
    async obtenerAreas() {
        this.cargando = true;
        var res = await this.service.obtenerTodasAreas();
        if(!res.error) {
            this.areas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerAgentes() {
        this.cargando = true;
        var res = await this.service.obtenerTodosAgentes();
        if(!res.error) {
            this.agentes = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    // * * * * * Editar datos * * * * *
    // Guardar datos
    async guardar(){
        this.cargando = true;

        // Subir imagen
        if(this.modoSubirImagen && this.archivoImagen) {
            var resUpload = await this.service.guardarArchivoEnServidorUsuarios(this.archivoImagen);
            if(resUpload.error){
                this.libraryService.crearNotificacion(resUpload.error.mensajeError, 'danger');
                throw new Error();
            }
            else {
                var url = resUpload.data.url;
                this.usuario.imagen = url;
            }
        }

        // Subir portada
        if(this.modoSubirPortada && this.archivoPortada) {
            var resUpload = await this.service.guardarArchivoEnServidorUsuarios(this.archivoPortada);
            if(resUpload.error){
                this.libraryService.crearNotificacion(resUpload.error.mensajeError, 'danger');
                throw new Error();
            }
            else {
                var url = resUpload.data.url;
                this.usuario.portada = url;
            }
        }

        if(this.usuario.idsAgentesCarteraArray.length > 0) this.usuario.idsAgentesCartera = this.usuario.idsAgentesCarteraArray.toString();
        else this.usuario.idsAgentesCartera = null;

        if(this.usuario.idsVendedoresCarteraArray.length > 0) this.usuario.idsVendedoresCartera = this.usuario.idsVendedoresCarteraArray.toString();
        else this.usuario.idsVendedoresCartera = null;

        // Enviar el registro para guardar
        var res;
        if(this.modoCreacion) {
            res = await this.service.crearUsuario(this.usuario);
        }
        else if (this.modoEdicion) {
            res = await this.service.actualizarUsuario(this.usuario);
        }
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            if(this.modoCreacion) this.router.navigate(['/usuarios/editar/' + res.data.result.result.id]);
            this.auth.comprobarAutenticacion(false);
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // Eliminar datos
    abrirEliminar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Desactivar usuario',
                mensaje: '¿Está seguro de que desea desactivar este usuario? No podrá ingresar al sistema y no aparecerá en ciertas búsquedas.',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminar();
            }
        });
    }

    async eliminar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.desactivarUsuario(this.id);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.auth.comprobarAutenticacion(false);
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    async activar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.activarUsuario(this.id);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.auth.comprobarAutenticacion(false);
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * * * Validaciones * * * * * 
    formularioValido() {
        if(!this.usuario) return false;
        
        if(this.usuario.correo1 && !this.libraryService.validateEmail(this.usuario.correo1)) return false;
        if(this.usuario.correo2 && !this.libraryService.validateEmail(this.usuario.correo2)) return false;

        return true;
    }

    // * * * * * Actualizaciones de controles * * * * *
    // Actualizar fecha de nacimiento
    actualizarFechaNacimiento($event){
        if($event && $event.value) {
            this.usuario.fechaNacimiento = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    // * * * * * Archivos * * * * *
    // Imagen
    seleccionarImagen(event: any){
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.rutaArchivoImagen = file.name;
            this.archivoImagen = file;
        }
    }

    activarSeleccionImagen(){
        document.getElementById('file-input-imagen').click();
    }

    limpiarArchivoImagen(){
        this.rutaArchivoImagen = null;
        this.archivoImagen = null;
    }

    descargarArchivoImagen(){
        if(this.usuario && this.usuario.imagen) {
            window.open(this.usuario.imagen, "_blank");
        }
    }

    // Portada
    seleccionarPortada(event: any){
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.rutaArchivoPortada = file.name;
            this.archivoPortada = file;
        }
    }

    activarSeleccionPortada(){
        document.getElementById('file-input-portada').click();
    }

    limpiarArchivoPortada(){
        this.rutaArchivoPortada = null;
        this.archivoPortada = null;
    }

    descargarArchivoPortada(){
        if(this.usuario && this.usuario.portada) {
            window.open(this.usuario.portada, "_blank");
        }
    }
    
    // * * * Contraseña * * * 
    // Reiniciar contraseña
    async reiniciarContrasena(){
        if(this.validFormPassword()) {
            this.cargando = true;
            var res = await this.service.reiniciarPassword(this.nuevoPassword, this.id, false);
            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            this.cargando = false;
        }
    }

    validFormPassword(): boolean {
        if(!this.nuevoPassword || !this.confirmarPassword) return false;
        if(this.nuevoPassword.trim() == '' || this.confirmarPassword.trim() == '') return false;
        if(this.nuevoPassword.length < this.minimoContrasena) return false;
        return this.nuevoPassword == this.confirmarPassword;
    }

    async obtenerMinimoContrasena() {
        var configuracion = this.auth.configuracionEmpresaActual();
        if(configuracion) {
            this.minimoContrasena = configuracion.minimoCaracteresContrasena;
            if(!this.minimoContrasena) this.minimoContrasena = 8;
        }
    }

    tabTieneErrores(tab: string) {
        switch(tab) {
            case 'Datos generales': {
                if(
                    !this.usuario.codigo ||
                    !this.usuario.nombre ||
                    !this.usuario.apellido ||
                    !this.usuario.AreaId ||
                    !this.usuario.correo1
                ) return true;

                if(this.usuario.esVendedor) {
                    if(
                        !this.usuario.metaMensual ||
                        !this.usuario.metaTrimestral ||
                        !this.usuario.metaSemestral ||
                        !this.usuario.metaAnual ||
                        !this.usuario.porcentajeComision ||
                        !this.usuario.porcentajeComision2
                    ) return true;
                }
                break;
            }
        }
        return false;
    }
}
