export class BeneficiarioDependienteCertificado {
    constructor(
        public id: number,
        public nombre: string,
        public tipo: string,
        public genero: string,
        public parentesco: string,
        public estado: string,
        public fechaNacimiento: string,
        public fechaAlta: string,
        public fechaBaja: string,
        public fechaReporteAlta: string,
        public fechaReporteBaja: string,
        public fechaVigenciaInicio: string,
        public fechaVigenciaFin: string,
        public observaciones: string,
        public porcentajeBeneficio: number,
        public CertificadoId: number,
    ){}

    fechaNacimientoDate: Date;
    fechaNacimientoFormato: string;
    fechaAltaDate: Date;
    fechaAltaFormato: string;
    fechaBajaDate: Date;
    fechaBajaFormato: string;
    fechaReporteAltaDate: Date;
    fechaReporteAltaFormato: string;
    fechaReporteBajaDate: Date;
    fechaReporteBajaFormato: string;
    fechaVigenciaInicioDate: Date;
    fechaVigenciaInicioFormato: string;
    fechaVigenciaFinDate: Date;
    fechaVigenciaFinFormato: string;
    numeroCertificado: string;
}