import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconAnalizar from '@iconify/icons-ic/twotone-search';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog, PageEvent } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { Usuario } from 'src/app/models/usuario';
import { GenericDialogErrores } from 'src/app/library/generic-dialog-errores/generic-dialog-errores.component';
import { Agrupador } from 'src/app/models/agrupador';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
import { PolizasService } from '../polizas.service';
import { Ramo } from 'src/app/models/ramo';
import { Producto } from 'src/app/models/producto';
import { Aseguradora } from 'src/app/models/aseguradora';
import { Moneda } from 'src/app/models/moneda';
import { RamosService } from 'src/app/ramos/ramos.service';
import { AseguradorasService } from 'src/app/aseguradoras/aseguradoras.service';
import { ClientesService } from 'src/app/clientes/clientes.service';
import { EmpresasService } from 'src/app/empresas/empresas.service';
import { Poliza } from 'src/app/models/poliza';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-importacion-polizas',
    templateUrl: './importacion-polizas.component.html',
    styleUrls: ['./importacion-polizas.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class ImportacionPolizasComponent implements OnInit {
    // Íconos
    iconBack = iconBack;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconAnalizar = iconAnalizar;
    
    // Banderas y generales
    cargando: boolean = false;
    titulo: string = 'Importación de pólizas';
    apiURLs: APIUrls = new APIUrls();

    // Generales importación
    rutaArchivo: string;
    archivo: File;
    registros: Poliza[];
    erroresLectura: any[];
    analisis = false;
    tamanoPagina: number = 15;
    pagina: number = 0;
    selectedIndex: number = 0;

    // Resultado de análisis
    registrosNuevos: number;
    registrosEditados: number;
    erroresAnalisis: any[];

    // Específicos
    agentes: Usuario[];
    ejecutivos: Usuario[];
    ramos: Ramo[];
    productos: Producto[];
    aseguradoras: Aseguradora[];
    agrupadores: Agrupador[];
    monedas: Moneda[];
    clientes: Usuario[];

    crumbs = [
        { nombre: 'Pólizas', link: '/polizas' },
        { nombre: 'Importación de pólizas', link: '/polizas/importacion-polizas' },
    ];

    constructor(
        public service: PolizasService,
        public usuariosService: UsuariosService,
        public ramosService: RamosService,
        public aseguradorasService: AseguradorasService,
        public clientesService: ClientesService,
        public empresasService: EmpresasService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * * 
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        this.titleService.setTitle(this.titulo);
        await this.obtenerAgentes();
        await this.obtenerEjecutivos();
        await this.obtenerRamos();
        await this.obtenerProductos();
        await this.obtenerAseguradoras();
        await this.obtenerAgrupadores();
        await this.obtenerMonedas();
        await this.obtenerClientes();
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
    }

    // * * * * * Validaciones * * * * * 
    formularioValido() {
        return true;
    }

    // * * * * * Selccionar archivo * * * * *
    seleccionarArchivo(event: any){
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.rutaArchivo = file.name;
            this.archivo = file;
            this.analisis = false;
            this.analizarArchivoExcel();
        }
    }

    activarSeleccionArchivo(){
        document.getElementById('file-input-archivo').click();
    }

    limpiarArchivo(){
        this.rutaArchivo = null;
        this.archivo = null;
        this.analisis = false;
        this.erroresLectura = null;
        this.registros = null;
        this.pagina = 0;
    }

    // * * * * * Análisis de archivo * * * * *
    async analizarArchivoExcel() {
        if(this.archivo) {
            this.cargando = true;
            var resLectura = await this.service.lecturaImportacionPolizas(this.archivo, this.agentes, this.ejecutivos, this.clientes, this.aseguradoras, this.agrupadores, this.monedas, this.ramos, this.productos, this.auth.idEmpresaActual());
            if(resLectura.error) {
                // Error al leer archivo
                this.libraryService.crearNotificacion(resLectura.mensaje, 'danger');
                throw new Error();
            }
            else {
                this.erroresLectura = resLectura.errores;
                this.registros = resLectura.resultados;
                this.libraryService.crearNotificacion(resLectura.mensaje, 'success');
            }
            this.cargando = false;
        }
    }

    async analizarRegistros(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.analizarImportacionPolizas(this.registros, this.auth.idEmpresaActual());
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.analisis = true;
            this.registrosNuevos = res.data.registrosNuevos;
            this.registrosEditados = res.data.registrosEditados;
            this.erroresAnalisis = res.data.errores;
            this.selectedIndex = 1;
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            this.analisis = false;
        }
        this.cargando = false;
    }

    async subirRegistros(){
        try {
            this.cargando = true;
            if(!this.analisis) throw new Error('Debe realizar analizar la importación antes de poder subirla.');
            if(!this.registros || this.registros.length == 0) throw new Error('No hay datos para subir.');
    
            // Enviar el registro para guardar
            var res = await this.service.subirImportacionPolizas(this.registros, this.auth.idEmpresaActual());
            
            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.router.navigate(['/polizas']);
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            this.cargando = false;
        } catch(error) {
            this.cargando = false;
            this.libraryService.crearNotificacion(error.message, 'danger');
        }
    }

    abrirDialogErrores() {
        const dialogRef = this.dialog.open(GenericDialogErrores, {
            data: {
                titulo: 'Errores de lectura de archivo CSV',
                errores: this.erroresLectura,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            
        });
    }

    // * * * * * Tabla * * * * *
    eliminarRegistro(index) {
        if(this.registros) {
            this.registros.splice(index, 1);
        }
    }

    pageUpdate($event: PageEvent) {
        this.pagina = $event.pageIndex;
        this.tamanoPagina = $event.pageSize;
    }

    // * * * * * Edición de modelos * * * * *
    async obtenerAgentes() {
        this.cargando = true;
        
        var res = await this.usuariosService.obtenerTodosAgentes();
        if(!res.error) {
            this.agentes = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        
        this.cargando = false;
    }

    async obtenerEjecutivos() {
        this.cargando = true;
        var res = await this.usuariosService.obtenerTodosEjecutivos();
        if(!res.error) {
            this.ejecutivos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerRamos() {
        this.cargando = true;
        var res = await this.ramosService.obtenerTodosRamos();
        if(!res.error) {
            this.ramos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerProductos() {
        this.cargando = true;
        var res = await this.ramosService.obtenerTodosProductos(this.auth.idEmpresaActual());
        if(!res.error) {
            this.productos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerAseguradoras() {
        this.cargando = true;
        var res = await this.aseguradorasService.obtenerTodasAseguradoras();
        if(!res.error) {
            this.aseguradoras = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerAgrupadores() {
        this.cargando = true;
        
        var res = await this.clientesService.obtenerTodosAgrupadores();
        if(!res.error) {
            this.agrupadores = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        
        this.cargando = false;
    }

    async obtenerClientes() {
        this.cargando = true;
        
        var res = await this.clientesService.obtenerTodosClientes();
        if(!res.error) {
            this.clientes = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        
        this.cargando = false;
    }

    async obtenerMonedas() {
        this.cargando = true;
        var res = await this.empresasService.obtenerTodasMonedas();
        if(!res.error) {
            this.monedas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }
}
