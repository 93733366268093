import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';

export interface DialogData {
    titulo: string,
    mensaje: string,
    comentarios: string,
    nombreComentarios: string,
    payload: any,
}

@Component({
    selector: 'generic-dialog-confirmacion-comentarios',
    templateUrl: './generic-dialog-confirmacion-comentarios.component.html',
})
export class GenericDialogConfirmacionComentarios {
    constructor(
        public dialogRef: MatDialogRef<GenericDialogConfirmacionComentarios>,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close({
            accion: 'confirmar',
            comentarios: this.data.comentarios,
        });
    }
}