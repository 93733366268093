import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import moment from 'moment';
import { PolizasService } from '../polizas.service';
import iconDelete from '@iconify/icons-fa-solid/trash';
import { Certificado } from 'src/app/models/certificado';
import { Router } from '@angular/router';
import { PlantillaRamo } from 'src/app/models/plantillaRamo';
import { Poliza } from 'src/app/models/poliza';
import { RamosService } from 'src/app/ramos/ramos.service';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { TipoCertificado } from 'src/app/models/tipoCertificado';

export interface DialogData {
    poliza: Poliza,
}

@Component({
    selector: 'dialog-descargar-plantilla-certificado',
    templateUrl: './dialog-descargar-plantilla-certificado.component.html',
})
export class DialogDescargarPlantillaCertificados {
    apiURLs = new APIUrls();
    tiposCertificados: TipoCertificado[] = [];
    TipoCertificadoId: number;
    poliza: Poliza;
    cargando: boolean;
    jsonConverters: JSONConverters = new JSONConverters();

    constructor(
        public dialogRef: MatDialogRef<DialogDescargarPlantillaCertificados>,
        public polizasService: PolizasService,
        public ramosService: RamosService,
        public auth: AuthService,
        public libraryService: LibraryService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
    }
    
    ngOnInit() {
        this.poliza = this.data.poliza;
        this.obtenerTiposCertificados();
    }

    async obtenerTiposCertificados() {
        this.cargando = true;
        if(this.poliza && this.poliza.RamoId) {
            var res = await this.polizasService.obtenerTodosTiposCertificadosRamo(this.poliza.RamoId);
            if(!res.error) {
                this.tiposCertificados = res.data.registros;
                if(!this.TipoCertificadoId && this.tiposCertificados.length > 0) {
                    this.TipoCertificadoId = this.tiposCertificados[0].id;
                }
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close(true);
    }

    async descargar(){
        this.cargando = true;
        // Enviar el registro para guardar
        var res = await this.polizasService.descargarExcelPlantillaCertificados('Plantilla de certificados.xlsx', this.TipoCertificadoId, this.poliza.id);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.confirmar();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }
}
