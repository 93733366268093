import { Inciso } from './inciso';

export class Deducible {
    constructor(
        public id: number,
        public nombre: string,
        public porcentaje: number,
        public tipoBase: string,
        public dias: number,
        public valorMaximo: number,
        public valorMinimo: number,
        public valorFijo: number,
        public tipoCambio: number,
        public observaciones: string,
        public participacion: number,
        public exoneracion: boolean,
        public IncisoId: number,
        public EmpresaId: number,
    ){}

    inciso: Inciso;
}