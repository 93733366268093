import { Reclamo } from './reclamo';
import { Usuario } from './usuario';

export class SeguimientoReclamo {
    constructor(
        public id: number,
        public numero: number,
        public observaciones: string,
        public fecha: string,
        public fechaSeguimiento: string,
        public horaRecordatorio: string,
        public realizado: boolean,
        public ReclamoId: number,
        public UsuarioId: number,
        public EmpresaId: number,
    ){}

    fechaFormato: string;
    fechaDate: Date;
    fechaSeguimientoFormato: string = '';
    fechaSeguimientoDate: Date;
    usuario: Usuario;
    nombreUsuario: string;
    observacionesResumen: string;
    reclamo: Reclamo;
    numeroReclamo: string;
}